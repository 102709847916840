import { EASINGS } from "../utils";
import { Toast } from "./Toast";

export class ToastManager extends Phaser.Events.EventEmitter {
    constructor(scene) {
        super();
        this.scene = scene;
        this.toasts = [];

        this.toastDuration = 3000;

        this.posTopY = this.scene.scale.height * 0.1;
        this.posCenterY = this.scene.scale.height * 0.5;
        this.posBottomY = this.scene.scale.height * 0.9;
    }

    /**
     *
     * @param {*} message - What to display
     * @param {*} from - From which side the toast should appear: "top", "bottom", "left", "right"
     * @param {*} to - Where toast should go: "top", "center", "bottom"
     * @param {*} textStyle - textStyle configuration
     */
    showToast(
        message,
        from = "bottom",
        to = "bottom",
        textStyle = {},
        duration,
        backgroundAlpha
    ) {
        const toast = new Toast(
            this.scene,
            0,
            0,
            message,
            textStyle,
            backgroundAlpha
        );
        this.toasts.push(toast);

        this._positionToast(toast, from, to);
        const toastHidingPosition = { x: toast.x, y: toast.y };
        this._playShowToastAnimation(toast, to);

        toast.once("clicked", () => {
            this._playHideToastAnimation(toast, toastHidingPosition);
        });

        if (duration === undefined || duration !== 0) {
            this.scene.time.delayedCall(duration ?? this.toastDuration, () => {
                this._playHideToastAnimation(toast, toastHidingPosition);
            });
        }
    }

    _positionToast(toast, from, to) {
        const width = toast.displayWidth;
        const height = toast.displayHeight;
        const centerX = this.scene.scale.width * 0.5;
        const leftX = -10 - width;
        const rightX = this.scene.scale.width + width + 10;
        const hideTopY = -10 - height;
        const centerY = this.scene.scale.height * 0.5;
        const hideBottomY = this.scene.scale.height + height + 10;

        switch (from) {
            case "top":
                // same in every case
                toast.x = centerX;
                toast.y = hideTopY;
                break;
            case "bottom":
                // same in every case
                toast.x = centerX;
                toast.y = hideBottomY;
                break;
            case "left":
                toast.x = leftX;
                toast.y = this._getYPosFromTo(to);
                break;
            case "right":
                toast.x = rightX;
                toast.y = this._getYPosFromTo(to);
                break;
        }
    }

    _playShowToastAnimation(toast, to) {
        const pos = this._getDestinationPosition(to);
        const offset =
            to === "top"
                ? toast.displayHeight * 0.5
                : to === "bottom"
                  ? -toast.displayHeight * 0.5
                  : 0;
        this.scene?.tweens.add({
            targets: toast,
            x: pos.x,
            y: pos.y + offset,
            duration: 500,
            ease: EASINGS.BackEaseOut
        });
    }
    _playHideToastAnimation(toast, hidinPosition) {
        if (toast?.isHiding()) {
            return;
        }
        toast.setHiding(true);
        this.scene?.tweens.add({
            targets: toast,
            x: hidinPosition.x,
            y: hidinPosition.y,
            duration: 500,
            ease: EASINGS.BackEaseIn,
            onComplete: () => {
                toast.destroy();
                this.toasts.shift();
            }
        });
    }

    _getDestinationPosition(to) {
        const centerX = this.scene.scale.width * 0.5;
        switch (to) {
            case "top":
                return { x: centerX, y: this.posTopY };
            case "center":
                return { x: centerX, y: this.posCenterY };
            case "bottom":
                return { x: centerX, y: this.posBottomY };
        }
    }

    _getYPosFromTo(to) {
        switch (to) {
            case "top":
                return this.posTopY;
            case "center":
                return this.posCenterY;
            case "bottom":
                return this.posBottomY;
        }
    }
}
