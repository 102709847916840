import { asyncAnimation, isDarkMode } from "./utils";
import { useUserStore } from "../../store/userStore";

export const TransitionType = {
    SLIDE: "slide",
    VSLIDE: "vslide",
    FADE: "fade",
    WASH: "wash",
    INSTANT: "instant",
    PIXELATE: "pixelate",
    DISSOLVE: "dissolve"
};

export default class SceneTransitionUtil {
    constructor(scene, swipeSoundLeft, swipeSoundRight) {
        this.scene = scene;
        this.sceneManager = scene.scene;
        this.swipeSoundLeft = swipeSoundLeft;
        this.swipeSoundRight = swipeSoundRight;
    }

    transitionTo(
        currentScene,
        targetSceneKey,
        mainScene,
        direction,
        launchData,
        transition
    ) {
        if (
            direction === "previous" &&
            useUserStore().userPrefs &&
            useUserStore().userPrefs?.sound !== false
        ) {
            this.swipeSoundLeft.play();
        } else if (
            useUserStore().userPrefs &&
            useUserStore().userPrefs?.sound !== false
        ) {
            this.swipeSoundRight.play();
        }

        if (!transition) {
            transition = {
                previous: isDarkMode()
                    ? TransitionType.FADE
                    : TransitionType.WASH,
                next: isDarkMode() ? TransitionType.FADE : TransitionType.WASH
            };
        }

        const transitionEffect = this._getTransitionFromType(
            transition[direction]
        );
        transitionEffect(currentScene, targetSceneKey, direction, launchData);
        this.postTransitionActions(mainScene);
    }

    postTransitionActions(mainScene) {
        const uiScene = mainScene.scene.get("UIScene");
        uiScene.handleSceneTransition(
            mainScene.currentIndex,
            mainScene.visitedIndexes
        );
        document.dispatchEvent(
            new CustomEvent("sceneChanged", {
                detail: { currentScene: mainScene.currentIndex }
            })
        );
    }

    _slideTransition(currentScene, targetSceneKey, direction, launchData) {
        const cam = currentScene.cameras.main;
        const targetScene = this.sceneManager.get(targetSceneKey);
        const targetCam = targetScene.cameras.main;
        const defaultWidth = currentScene.cameras.default.width;

        targetCam.setViewport(0, 0, defaultWidth, targetCam.height);
        cam.setViewport(0, 0, defaultWidth, cam.height);

        targetScene.events.on("transitioncomplete", () => {
            cam.setViewport(0, 0, defaultWidth, cam.height);
        });
        currentScene.scene.transition({
            target: targetSceneKey,
            sleep: true,
            duration: 500,
            data: launchData,
            onUpdate: (progress) => {
                const t = Phaser.Math.Easing.Expo.Out(progress);
                let camX, camW, targetCamX, targetCamW, scrollX;

                if (direction === "previous") {
                    camX = t * defaultWidth;
                    camW = (1 - t) * defaultWidth;
                    targetCamX = 0;
                    targetCamW = t * defaultWidth;
                    scrollX = (1 - t) * defaultWidth;
                } else {
                    camX = -t * defaultWidth;
                    camW = defaultWidth;
                    targetCamX = (1 - t) * defaultWidth;
                    targetCamW = defaultWidth;
                    scrollX = 0;
                }

                cam.setViewport(camX, 0, camW, cam.height);
                targetCam.setViewport(
                    targetCamX,
                    0,
                    targetCamW,
                    targetCam.height
                );
                targetCam.setScroll(scrollX, 0);
            }
        });
    }

    _vslideTransition(currentScene, targetSceneKey, direction, launchData) {
        const cam = currentScene.cameras.main;
        const targetScene = this.sceneManager.get(targetSceneKey);
        const targetCam = targetScene.cameras.main;
        const defaultHeight = currentScene.cameras.default.height;

        targetCam.setViewport(0, 0, targetCam.width, defaultHeight);
        cam.setViewport(0, 0, cam.width, defaultHeight);

        targetScene.events.on("transitioncomplete", () => {
            cam.setViewport(0, 0, cam.width, defaultHeight);
        });
        currentScene.scene.transition({
            target: targetSceneKey,
            sleep: true,
            duration: 500,
            data: launchData,
            onUpdate: (progress) => {
                const t = Phaser.Math.Easing.Expo.Out(progress);
                let camY, camH, targetCamY, targetCamH, scrollY;

                if (direction === "previous") {
                    camY = t * defaultHeight;
                    camH = (1 - t) * defaultHeight;
                    targetCamY = 0;
                    targetCamH = t * defaultHeight;
                    scrollY = (1 - t) * defaultHeight;
                } else {
                    camY = -t * defaultHeight;
                    camH = defaultHeight;
                    targetCamY = (1 - t) * defaultHeight;
                    targetCamH = defaultHeight;
                    scrollY = 0;
                }

                cam.setViewport(0, camY, cam.width, camH);
                targetCam.setViewport(
                    0,
                    targetCamY,
                    targetCam.width,
                    targetCamH
                );
                targetCam.setScroll(0, scrollY);
            }
        });
    }

    _instantTransition(currentScene, targetSceneKey, direction, launchData) {
        this.sceneManager.sleep(currentScene.scene.key);
        this.sceneManager.run(targetSceneKey, launchData);
    }

    async _dissolveTransition(
        currentScene,
        targetSceneKey,
        direction,
        launchData
    ) {
        const cam = currentScene.cameras.main;
        const targetScene = this.sceneManager.get(targetSceneKey);
        const targetCam = targetScene.cameras.main;

        targetCam.setAlpha(0);
        this.sceneManager.run(targetSceneKey, launchData);

        currentScene.tweens.add({
            targets: targetCam,
            alpha: 1,
            duration: 250,
            ease: "Linear"
        });

        await asyncAnimation(
            currentScene.tweens.add({
                targets: cam,
                alpha: 0,
                duration: 250,
                ease: "Linear",
                onComplete: (tween) => {
                    tween.emit("done");
                }
            })
        );

        this.sceneManager.sleep(currentScene.scene.key);
        cam.setAlpha(1);
    }

    async _pixelateTransition(
        currentScene,
        targetSceneKey,
        direction,
        launchData
    ) {
        const cam = currentScene.cameras.main;
        const targetScene = this.sceneManager.get(targetSceneKey);
        const backgroundScene = this.sceneManager.get("Scene0");
        const targetCam = targetScene.cameras.main;
        const backgroundSceneCam = this.sceneManager.get("Scene0").cameras.main;

        if (!currentScene.pixelatedEffect) {
            currentScene.pixelatedEffect = cam.postFX.addPixelate(-1);
        }

        if (!backgroundScene.pixelatedEffect) {
            backgroundScene.pixelatedEffect =
                backgroundSceneCam.postFX.addPixelate(-1);
        }

        if (!targetScene.pixelatedEffect) {
            targetScene.pixelatedEffect = targetCam.postFX.addPixelate(40);
        }

        await asyncAnimation(
            currentScene.tweens.add({
                targets: [
                    backgroundScene.pixelatedEffect,
                    currentScene.pixelatedEffect
                ],
                amount: 40,
                duration: 250,
                ease: "Linear",
                onComplete: (tween) => {
                    tween.emit("done");
                }
            })
        );

        this.sceneManager.sleep(currentScene.scene.key);
        this.sceneManager.run(targetSceneKey, launchData);

        targetScene.pixelatedEffect.amount = 40;
        targetScene.tweens.add({
            targets: [
                currentScene.pixelatedEffect,
                backgroundScene.pixelatedEffect,
                targetScene.pixelatedEffect
            ],
            amount: -1,
            duration: 250,
            ease: "Linear",
            onComplete: (tween) => {
                tween.emit("done");
            }
        });
    }

    _fadeTransition(currentScene, targetSceneKey, direction, launchData) {
        const cam = currentScene.cameras.main;
        const targetScene = this.sceneManager.get(targetSceneKey);
        const targetCam = targetScene.cameras.main;
        cam.once("camerafadeoutcomplete", () => {
            this.sceneManager.sleep(currentScene.scene.key);
            this.sceneManager.run(targetSceneKey, launchData);
            targetCam.fadeIn(250, 51, 51, 51);
            cam.fadeIn(0, 51, 51, 51);
        });
        cam.fadeOut(150, 51, 51, 51);
    }

    _washTransition(currentScene, targetSceneKey, direction, launchData) {
        const cam = currentScene.cameras.main;
        const targetScene = this.sceneManager.get(targetSceneKey);
        const targetCam = targetScene.cameras.main;
        cam.once("camerafadeoutcomplete", () => {
            this.sceneManager.sleep(currentScene.scene.key);
            this.sceneManager.run(targetSceneKey, launchData);
            targetCam.fadeIn(250, 247, 247, 247);
            cam.fadeIn(0, 247, 247, 247);
        });
        cam.fadeOut(150, 247, 247, 247);
    }

    _getTransitionFromType(type) {
        switch (type) {
            case TransitionType.SLIDE:
                return this._slideTransition.bind(this);
            case TransitionType.VSLIDE:
                return this._vslideTransition.bind(this);
            case TransitionType.FADE:
                return this._fadeTransition.bind(this);
            case TransitionType.WASH:
                return this._washTransition.bind(this);
            case TransitionType.INSTANT:
                return this._instantTransition.bind(this);
            case TransitionType.PIXELATE:
                return this._pixelateTransition.bind(this);
            case TransitionType.DISSOLVE:
                return this._dissolveTransition.bind(this);
            default:
                return this._slideTransition.bind(this);
        }
    }
}
