import {
    EASINGS,
    getTextColor,
    hexNumberToString,
    loadBucketAssets,
    TEXT_FONT
} from "../../../utils/utils";
import { Spaceship } from "./Spaceship";

export interface SpaceShipProgressBarConfig {
    steps: number;
    width: number;
}
export class SpaceShipProgressBar extends Phaser.GameObjects.Container {
    private spaceship: Spaceship;
    private flag: Phaser.GameObjects.Image;
    private progressText: Phaser.GameObjects.Text;

    private config: SpaceShipProgressBarConfig;

    private moveShipAnimationTween?: Phaser.Tweens.Tween;

    private currentStep: number = 1;

    constructor(
        scene: Phaser.Scene,
        x: number,
        y: number,
        config: SpaceShipProgressBarConfig
    ) {
        super(scene, x, y);
        this.config = config;

        this.initializeFlag();
        this.initializeProgressText();
        this.initializeSpaceship();

        this.add([this.flag, this.spaceship, this.progressText]);

        this.scene.add.existing(this);
    }

    public static preloadAssets(scene: Phaser.Scene): void {
        console.log("A1");
        Spaceship.preloadAssets(scene);
        loadBucketAssets(scene, {
            folderPath: ["GreatLibrary", "Assets"],
            keyList: ["flag"]
        });
    }

    public progress(): void {
        this.currentStep++;
        this.playMoveShipAnimation(
            (this.config.width / this.config.steps) * (this.currentStep - 1)
        );
        if (this.currentStep === this.config.steps + 1) {
            this.playShipFlyAwayAnimation();
            this.playRotateFlagAnimation();
        } else {
            this.progressText.setText(
                `${this.currentStep}/${this.config.steps}`
            );
        }
    }

    private playMoveShipAnimation(x: number): void {
        this.moveShipAnimationTween?.stop();
        this.moveShipAnimationTween = this.scene.tweens.add({
            targets: this.spaceship,
            x,
            duration: 250,
            ease: EASINGS.CubicEaseOut
        });
    }

    private playShipFlyAwayAnimation(): void {
        this.moveShipAnimationTween?.stop();
        this.scene.tweens.add({
            targets: this.spaceship,
            x: this.scene.scale.width,
            duration: 250,
            ease: EASINGS.CubicEaseIn
        });
    }

    private playRotateFlagAnimation(): void {
        this.scene.tweens.add({
            targets: this.flag,
            angle: 1080,
            delay: 100,
            duration: 750,
            ease: EASINGS.CubicEaseOut
        });
    }

    private initializeSpaceship(): void {
        this.spaceship = new Spaceship(this.scene, 0, -10).setScale(0.75);
    }

    private initializeFlag(): void {
        this.flag = this.scene.add
            .image(this.config.width, 0, "flag")
            .setAngle(25)
            .setScale(0.3);
    }

    private initializeProgressText(): void {
        this.progressText = this.scene.add
            .text(this.config.width, 50, `1/${this.config.steps}`, {
                fontFamily: TEXT_FONT.WorkSans,
                fontSize: 50,
                color: hexNumberToString(getTextColor())
            })
            .setOrigin(0.5);
    }
}
