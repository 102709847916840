<!-- Usage: <user-feedback-modal :feedback-types="{ comment: true, rating: true, likeDislike: false }"></user-feedback-modal> -->
<template>
    <ion-modal :isOpen="isOpen" @didDismiss="closeModal">
        <modalToolbar :nodeTitle="$t('uifeedback.title')" @closeModal="closeModal" />
        <ion-content :forceOverscroll="false" class="ion-padding">
            <ion-text class="feedback-intro center-text">
                <h2>{{ $t('uifeedback.header') }}</h2>
                <p>{{ $t('uifeedback.paragraph') }}</p>
            </ion-text>
            <form @submit.prevent="submitFeedback" class="feedback-form">
                <ion-label class="ion-padding" style="font-weight:bold;">{{ $t('uifeedback.share') }}</ion-label>
                <ion-item v-if="feedbackTypes.comment">
                    <ion-textarea class="custom" v-model="feedback.comment" :rows="rowCount"
                        :placeholder="$t('uifeedback.textarea_placeholder')" autoGrow="true"></ion-textarea>
                </ion-item>
                <ion-item v-if="feedbackTypes.rating">
                    <ion-select v-model="feedback.rating" interface="popover" label="Rate your experience:">
                        <ion-select-option v-for="n in 5" :key="n" :value="n">{{ n }} Star{{ n > 1 ? 's' : ''
                            }}</ion-select-option>
                    </ion-select>
                </ion-item>
                <!-- TO ASK: are we using this at all? -->
                <ion-item v-if="feedbackTypes.likeDislike">
                    <ion-label>Do you like our app so far?</ion-label>
                    <ion-toggle v-model="feedback.liked" />
                </ion-item>
            </form>
        </ion-content>
        <ion-footer>
            <ion-button type="submit" id="submit" expand="block" class="submit-button ion-padding"
                @click="submitFeedback" :disabled="isSubmitDisabled">{{ $t('uifeedback.submit') }}</ion-button>
        </ion-footer>
        <ion-toast trigger="submit" message="Feedback submitted <3" position="top" :duration="4000"></ion-toast>
    </ion-modal>
</template>

<script>
import { useModalStore } from '../store/modalStore';
import modalToolbar from '~/components/modal/modalToolbar.vue';
import { useUserStore } from '@/store/userStore'
import pkg from '../../../package.json';

const mStore = useModalStore();

export default {
    name: 'UserFeedbackModal',
    components: {
        modalToolbar
    },
    props: {
        feedbackTypes: {
            type: Object,
            default: () => ({
                comment: true,
                rating: false,
                likeDislike: false
            })
        },
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            feedback: {
                comment: '',
                rating: null,
                liked: false
            },
            rowCount: 5
        };
    },
    mounted() {
        if (!this.feedbackTypes.rating) {
            this.rowCount += 3;
        }
        if (!this.feedbackTypes.likeDislike) {
            this.rowCount += 3;
        }
    },
    methods: {
        async submitFeedback() {
            try {
                const uStore = useUserStore();
                const feedbackData = {
                    user: uStore.user?.id ?? null,
                    comment: this.feedback.comment,
                    rating: this.feedback.rating,
                    page_path: this.$route.path,
                    app_version: pkg.version
                };

                feedbackData.liked = this.feedbackTypes.likeDislike ? this.feedback.liked : null;
                await $fetch(this.$config.public.API_BASE_URL + 'auth/ui_feedback/', {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + uStore.accessToken,
                    }),
                    body: JSON.stringify(feedbackData)
                }).then(res => {
                    console.log('Feedback submitted successfully', res);
                });

                this.feedback = {
                    comment: '',
                    rating: null,
                    liked: false
                };
            } catch (err) {
                console.error('Error submitting feedback:', err);
            }
            this.closeModal();
        },
        closeModal() {
            if (mStore.isFeedbackModalOpen) {
                mStore.toggleFeedbackModal()
            }
        }
    },
    computed: {
        isSubmitDisabled() {
            let isDisabled = false;
            if (this.feedbackTypes.comment && !this.feedback.comment) {
                isDisabled = true;
            }
            if (this.feedbackTypes.rating && this.feedback.rating === null) {
                isDisabled = true;
            }
            if (this.feedbackTypes.likeDislike && this.feedback.liked === undefined) {
                isDisabled = true;
            }
            return isDisabled;
        }
    }
};
</script>

<style scoped>
.feedback-intro p {
    padding: 16px;
    font-size: 1.1rem;
    border-radius: 10px;
    margin-bottom: 24px;
    line-height: 1.5;
}

.feedback-form {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.submit-button {
    margin-top: 2%;
    margin-bottom: 4%;
    border-radius: 8px;
}

ion-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
</style>
