import Phaser from "phaser";
import { GreatLibraryBaseScene } from "./GreatLibraryBaseScene";
import {
    GreatLibrarySceneKey,
    GreatLibraryScenesManager
} from "./GreatLibraryScenesManager";
import {
    getBackgroundColor,
    getCurrentLanguage,
    getTextColor,
    hexNumberToString,
    TEXT_FONT
} from "../../utils/utils";
import { useKeywordQuestionStore } from "../../../store/keywordQuestionStore";
import type { QuestionConfig } from "../../utils/quizes/quizUtils";
import { TexturesHelper } from "../../utils/TexturesHelper";
import glText from "../../local_text/greatLibrary.json";
import { NodesList, NodesListEvent } from "./widgets/NodesList";
import {
    GraphicsButton,
    GraphicsButtonEvent
} from "../../utils/ui/buttons/GraphicsButton";

class GreatLibraryExamOptionsScene extends GreatLibraryBaseScene {
    private background: Phaser.GameObjects.Rectangle;
    private startButton: GraphicsButton;

    private nodesList: NodesList;
    private headerText: Phaser.GameObjects.Text;
    private questionsPoolText: Phaser.GameObjects.Text;
    constructor() {
        super(GreatLibrarySceneKey.ExamOptions);
    }

    public init() {}

    public preload() {}

    public override create() {
        super.create();
        this.initializeBackground();
        this.initializeNodesList();
        this.initializeStartButton();
        this.initializeQuestionsPoolText();

        this.setStartButtonAvailability();

        this.bindEventHandlers();
    }

    protected handleUiSceneHomeButtonPressed(): void {
        if (!this.scene.isActive()) {
            return;
        }
        GreatLibraryScenesManager.changeScene(GreatLibrarySceneKey.MainHall);
    }

    private bindEventHandlers(): void {
        this.startButton.on(GraphicsButtonEvent.Released, () => {
            GreatLibraryScenesManager.changeScene(
                GreatLibrarySceneKey.Exam,
                false,
                {
                    nodes: this.nodesList.getSelectedNodeIds()
                }
            );
        });

        this.nodesList.on(NodesListEvent.NodesChecklistChanged, () => {
            this.setStartButtonAvailability();
        });
    }

    private setStartButtonAvailability(): void {
        const availableQuestionsCount = this.getAvailableQuestionsCount();
        this.questionsPoolText.setText(
            `${glText[getCurrentLanguage()].options.availableQuestions}: ${availableQuestionsCount}`
        );

        this.startButton.disable(availableQuestionsCount === 0);
        if (availableQuestionsCount === 0) {
            this.questionsPoolText.setTint(0xff0000);
        } else {
            this.questionsPoolText.clearTint();
        }
    }

    private initializeBackground(): void {
        this.background = this.add.rectangle(
            this.scale.width * 0.5,
            this.scale.height * 0.5,
            this.scale.width,
            this.scale.height,
            getBackgroundColor()
        );
    }

    private initializeMask(): void {
        TexturesHelper.drawLine(
            this,
            this.w(0.1),
            this.w(0.9),
            this.headerText.y + this.headerText.height,
            this.headerText.y + this.headerText.height,
            3,
            getTextColor(),
            0.25
        );
        TexturesHelper.drawLine(
            this,
            this.w(0.1),
            this.w(0.9),
            this.headerText.y + this.headerText.height + this.h(0.65),
            this.headerText.y + this.headerText.height + this.h(0.65),
            3,
            getTextColor(),
            0.25
        );
        this.checklistMask = this.make
            .graphics()
            .fillStyle(0xff0000, 0.5)
            .fillRect(
                0,
                this.headerText.y + this.headerText.height,
                this.w(),
                this.h(0.65)
            )
            .createGeometryMask();
    }

    initializeNodesList(): void {
        this.nodesList = new NodesList(this, "questions");
    }

    private initializeStartButton(): void {
        this.startButton = new GraphicsButton(
            this,
            this.scale.width * 0.5,
            this.scale.height * 0.875,
            {
                text: glText[getCurrentLanguage()].options.start
            }
        );
    }

    private initializeQuestionsPoolText(): void {
        this.questionsPoolText = this.add
            .text(
                this.w(0.5),
                this.h(0.8),
                `${glText[getCurrentLanguage()].options.availableQuestions}: ${this.getAvailableQuestionsCount()}`,
                {
                    fontFamily: TEXT_FONT.WorkSans,
                    fontSize: 40,
                    color: hexNumberToString(getTextColor())
                }
            )
            .setOrigin(0.5);
    }

    private getAvailableQuestionsCount(): number {
        const nodeIds = this.nodesList.getSelectedNodeIds();

        const store = useKeywordQuestionStore();
        let knownQuestions: QuestionConfig[] = store.knownQuestions;
        if (nodeIds) {
            knownQuestions = knownQuestions.filter((q) =>
                nodeIds.includes(q.node)
            );
        }
        return knownQuestions.length;
    }
}

export default GreatLibraryExamOptionsScene;
