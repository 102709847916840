import type GreatLibraryUiScene from "./GreatLibraryUiScene";

export enum GreatLibrarySceneKey {
    FlashCards = "GreatLibraryFlashCardsScene",
    FlashCardsOptions = "GreatLibraryFlashCardsOptionsScene",
    Exam = "GreatLibraryExamScene",
    ExamOptions = "GreatLibraryExamOptionsScene",
    MainHall = "GreatLibraryMainHallScene",
    Ui = "GreatLibraryUiScene"
}

export class GreatLibraryScenesManager {
    private static currentScene: Phaser.Scene;

    private static scenePlugin: Phaser.Scenes.ScenePlugin;

    public static initialize(startingScene: Phaser.Scene): void {
        this.currentScene = startingScene;
        this.scenePlugin = startingScene.scene;
    }

    public static changeScene(
        key: GreatLibrarySceneKey,
        stopCurrentScene?: boolean,
        data?: any
    ): void {
        if (this.currentScene.scene.key === key) {
            return;
        }
        if (stopCurrentScene) {
            this.currentScene.scene.stop();
        } else {
            this.currentScene.scene.sleep();
        }

        this.currentScene.scene.run(key, data);
        this.currentScene = this.scenePlugin.get(key);

        this.currentScene.scene.bringToTop(key);
        this.currentScene.scene.bringToTop(GreatLibrarySceneKey.Ui);
        const uiScene = this.scenePlugin.get(GreatLibrarySceneKey.Ui);
        if (!uiScene) {
            return;
        }
        if (this.currentScene.scene.key === GreatLibrarySceneKey.MainHall) {
            (uiScene as GreatLibraryUiScene).setHomeButtonToExit();
        } else {
            (uiScene as GreatLibraryUiScene).setHomeButtonToBack();
        }
    }
}
