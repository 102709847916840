import { loadBucketAssets } from "../utils";

export const StarsFeedbackModuleEvent = {
    Rated: "rated"
};

export class RatingFeedbackModule extends Phaser.GameObjects.Container {
    constructor(scene, x, y) {
        super(scene, x, y);

        this.stars = [];

        this._initializeStars();

        this.add([...this.stars]);

        const bounds = this.getBounds();
        this.setSize(bounds.width, bounds.height);

        this._bindEventHandlers();

        this.scene.add.existing(this);
    }

    static preloadAssets(scene) {
        loadBucketAssets(scene, {
            folderPath: ["General", "UIElements", "Feedback"],
            keyList: ["star"]
        });
    }

    _initializeStars() {
        for (let i = 0; i < 5; i++) {
            this.stars.push(
                this.scene.add
                    .image((i - 2) * 130, 0, "star")
                    .setScale(0.6)
                    .setAlpha(0.3)
                    .setInteractive()
            );
        }
    }

    _updateStars(index) {
        for (let i = 0; i < this.stars.length; i++) {
            this.stars[i].setAlpha(i <= index ? 1 : 0.3);
        }
    }

    _bindEventHandlers() {
        for (let i = 0; i < this.stars.length; i++) {
            this.stars[i].on("pointerdown", () => {
                this._updateStars(i);
                this.emit(StarsFeedbackModuleEvent.Rated, i + 1);
            });
        }
    }
}
