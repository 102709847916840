export function getMarginL(scene) {
    if (!scene || !scene.scale) return 0; // Defaulting to 0 if scene or scene.scale is null
    return scene.scale.width * 0.06;
}

export function getMarginR(scene, object) {
    if (!scene || !scene.scale || !object || typeof object.width !== "number")
        return 0; // Defaulting to 0 if invalid inputs
    const leftMargin = scene.scale.width * 0.06;
    return scene.scale.width - leftMargin - object.width;
}

export function getMarginT(scene) {
    if (!scene || !scene.scale) return 0; // Defaulting to 0 if scene or scene.scale is null
    return scene.scale.height * 0.12;
}

export function getBannerMarginT(scene) {
    if (!scene || !scene.scale) return 0; // Defaulting to 0 if scene or scene.scale is null
    return scene.scale.height * 0.01;
}

export function getSpacer(scene) {
    if (!scene || !scene.scale) return 10; // Default value if scene or scene.scale is null
    const widthSpacer = scene.scale.width * 0.01;
    const heightSpacer = scene.scale.height * 0.05;
    return (widthSpacer + heightSpacer) / 2;
}

// TODO: Remove these legacy functions
export function BannerStyle(scene, fill) {
    const baseFontSize = 26;
    const fontSize = `${(scene.scale.width / 500) * baseFontSize}px`;
    return {
        fontFamily: "Work Sans",
        fontSize: fontSize,
        fill: fill
    };
}

export function ExamAidStyle(scene, options = {}) {
    const baseFontSize = 22;
    const fontSize = `${(scene.scale.width / 500) * baseFontSize}px`;
    const horizontalPaddingPercentage = 0.05;
    const bgWidth = scene.scale.width - getMarginL(scene) * 3;

    const {
        wordWrapWidth = bgWidth -
            2 * scene.scale.width * horizontalPaddingPercentage,
        align = "center",
        fill = 0x414042,
        backgroundFill = 0x808080,
        lineSpacing = 0,
        fixedWidth = scene.scale.width * 0.8
    } = options;

    return {
        fontFamily: "Work Sans",
        fontSize: fontSize,
        fill: fill,
        align: align,
        halign: "center", // 'left'|'center'|'right'
        lineSpacing: lineSpacing,
        fixedWidth: fixedWidth,
        backgroundColor: backgroundFill,
        backgroundCornerRadius: 5,
        wrap: {
            mode: 1,
            width: wordWrapWidth
        }
    };
}

export function TextStyle(scene, options = {}) {
    const baseFontSize = 30;
    //const calculatedFontSize = `${(scene.scale.width / 500) * baseFontSize}px`;

    const {
        wordWrapWidth = scene.scale.width - getMarginL(scene) * 2,
        align = "left",
        fill = "#414042",
        maxLines = 0,
        lineSpacing = 10,
        fixedWidth = 0,
        fixedHeight = 0,
        fontSizeOverride = null
    } = options;

    return {
        fontFamily: "Work Sans",
        fontSize: fontSizeOverride !== null ? fontSizeOverride : baseFontSize,
        fill: fill,
        align: align,
        maxLines: maxLines,
        lineSpacing: lineSpacing,
        fixedWidth: fixedWidth,
        fixedHeight: fixedHeight,
        wordWrap: {
            width: wordWrapWidth
        }
    };
}
