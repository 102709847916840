import Phaser from "phaser";
import { GreatLibraryBaseScene } from "./GreatLibraryBaseScene";
import {
    GreatLibrarySceneKey,
    GreatLibraryScenesManager
} from "./GreatLibraryScenesManager";
import { useKeywordQuestionStore } from "../../../store/keywordQuestionStore";
import {
    getBackgroundColor,
    getCurrentLanguage,
    getTextColor,
    hexNumberToString,
    TEXT_FONT
} from "../../utils/utils";

import { ScrollBar } from "../../utils/ui/draggables/ScrollBar";
import type { FlashCardConfig } from "./widgets/FlashCard";
import type { GreatLibraryFlashCardsSceneConfig } from "./GreatLibraryFlashCardsScene";
import glText from "../../local_text/greatLibrary.json";
import { NodesList, NodesListEvent } from "./widgets/NodesList";
import {
    GraphicsButton,
    GraphicsButtonEvent
} from "../../utils/ui/buttons/GraphicsButton";

export interface KeywordConfig {
    id: number;
    definition_id: number;
    keyword: string;
    language: string;
    taught_node: number;
}

export interface DefinitionConfig {
    id: number;
    definition: { [key: string]: string };
}

class GreatLibraryFlashCardsOptionsScene extends GreatLibraryBaseScene {
    private background: Phaser.GameObjects.Rectangle;
    private startButton: GraphicsButton;

    private nodesList: NodesList;
    private questionsPoolText: Phaser.GameObjects.Text;
    private scrollBar: ScrollBar;

    constructor() {
        super(GreatLibrarySceneKey.FlashCardsOptions);
    }

    public init() {}

    public preload() {}

    public override create() {
        super.create();
        this.initializeBackground();
        this.initializeNodesList();
        this.initializeStartButton();
        this.initializeQuestionsPoolText();

        this.setStartButtonAvailability();

        this.bindEventHandlers();
    }

    protected handleUiSceneHomeButtonPressed(): void {
        if (!this.scene.isActive()) {
            return;
        }
        GreatLibraryScenesManager.changeScene(GreatLibrarySceneKey.MainHall);
    }

    private bindEventHandlers(): void {
        this.startButton.on(GraphicsButtonEvent.Released, () => {
            GreatLibraryScenesManager.changeScene(
                GreatLibrarySceneKey.FlashCards,
                false,
                {
                    flashCardsConfig: this.getFlashCardsConfig()
                } as GreatLibraryFlashCardsSceneConfig
            );
        });

        this.nodesList.on(NodesListEvent.NodesChecklistChanged, () => {
            this.setStartButtonAvailability();
        });
    }

    private setStartButtonAvailability(): void {
        const availableKeywordsCount = this.getAvailableKeywordsCount();
        this.questionsPoolText.setText(
            `${glText[getCurrentLanguage()].options.availableKeywords}: ${availableKeywordsCount}`
        );

        this.startButton.disable(availableKeywordsCount === 0);
        if (availableKeywordsCount === 0) {
            this.questionsPoolText.setTint(0xff0000);
        } else {
            this.questionsPoolText.clearTint();
        }
    }

    private initializeBackground(): void {
        this.background = this.add.rectangle(
            this.scale.width * 0.5,
            this.scale.height * 0.5,
            this.scale.width,
            this.scale.height,
            getBackgroundColor()
        );
    }

    initializeNodesList(): void {
        this.nodesList = new NodesList(this, "keywords");
    }

    private initializeStartButton(): void {
        this.startButton = new GraphicsButton(
            this,
            this.scale.width * 0.5,
            this.scale.height * 0.875,
            {
                text: glText[getCurrentLanguage()].options.start
            }
        );
    }

    private initializeQuestionsPoolText(): void {
        this.questionsPoolText = this.add
            .text(
                this.w(0.5),
                this.h(0.8),
                `${glText[getCurrentLanguage()].options.availableKeywords}: ${this.getAvailableKeywordsCount()}`,
                {
                    fontFamily: TEXT_FONT.WorkSans,
                    fontSize: 40,
                    color: hexNumberToString(getTextColor())
                }
            )
            .setOrigin(0.5);
    }

    private getAvailableKeywordsCount(): number {
        const nodeIds = this.nodesList.getSelectedNodeIds();

        const store = useKeywordQuestionStore();
        let knownKeywords: KeywordConfig[] = store.known_keywords;
        if (nodeIds) {
            knownKeywords = knownKeywords.filter(
                (keyword) =>
                    nodeIds.includes(keyword.taught_node) &&
                    keyword.language === getCurrentLanguage()
            );
        }
        return knownKeywords.length;
    }

    private getFlashCardsConfig(): FlashCardConfig[] {
        const nodeIds = this.nodesList.getSelectedNodeIds();
        const store = useKeywordQuestionStore();
        let knownKeywords: KeywordConfig[] = store.known_keywords;
        if (nodeIds) {
            knownKeywords = knownKeywords.filter(
                (keyword) =>
                    nodeIds.includes(keyword.taught_node) &&
                    keyword.language === getCurrentLanguage()
            );
        }
        const definitions: DefinitionConfig[] = store.definitions;
        const getDefinitionOfId = (id: number) => {
            return definitions.find((def) => def.id === id);
        };
        const flashCardConfigs: FlashCardConfig[] = [];
        for (const keyword of knownKeywords) {
            const definition = getDefinitionOfId(keyword.definition_id)
                ?.definition[getCurrentLanguage()];
            if (!definition) {
                console.warn("Could not find definition for keyword", keyword);
                continue;
            }
            flashCardConfigs.push({
                question: keyword.keyword,
                answer: definition
            });
        }
        return flashCardConfigs;
    }
}

export default GreatLibraryFlashCardsOptionsScene;
