import { PushNotifications } from "@capacitor/push-notifications";
import { Capacitor } from "@capacitor/core";
import { useGuestStore } from "../store/guestStore";

import type {
    ActionPerformed,
    PushNotificationSchema,
    Token
} from "@capacitor/push-notifications";

export const pushNotification = {
    async register() {
        if (!Capacitor.isNativePlatform()) {
            console.warn(
                "Push Notifications are only available on native platforms"
            );
            return;
        }

        const result = await PushNotifications.requestPermissions();
        if (result.receive !== "granted") {
            console.log("Permission for push notifications was denied");
            return;
        }

        await PushNotifications.register();

        PushNotifications.addListener("registration", (token: Token) => {
            console.log(
                "PUSH NOTIFICATION - REGISTRATION - token: " + token.value
            );
            const gStore = useGuestStore();
            gStore.setFcmToken(token.value);
            console.log(gStore.fcmToken);
        });

        PushNotifications.addListener("registrationError", (error: any) => {
            console.error(
                "PUSH NOTIFICATION - REGISTRATION ERROR - error: " +
                    JSON.stringify(error)
            );
        });

        PushNotifications.addListener(
            "pushNotificationReceived",
            (notification: PushNotificationSchema) => {
                console.log(
                    "PUSH NOTIFICATION - PUSH NOTIFICATION RECEIVED - notification: " +
                        JSON.stringify(notification)
                );
            }
        );

        PushNotifications.addListener(
            "pushNotificationActionPerformed",
            (notification: ActionPerformed) => {
                console.log(
                    "PUSH NOTIFICATION - PUSH NOTIFICATION ACTION PERFORMED - notification: " +
                        JSON.stringify(notification)
                );
            }
        );

        setTimeout(async () => {
            try {
                await PushNotifications.removeAllDeliveredNotifications();
                console.log("Removed all delivered notifications");
            } catch (error) {
                console.error(
                    "Error removing all delivered notifications",
                    error
                );
            }
        }, 2500);
    }
};
