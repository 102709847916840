import { EASINGS } from "../../../utils/utils";
import { Step } from "./ProgressBar/Step";

export type ProgressBarCustomColors = { current: number, unseen: number, visited: number}[]

export class ProgressBar extends Phaser.GameObjects.Container {

    private currentIndex: number;
    private previousIndex?: number;
    private sceneCount: number;
    private visitedIndexes: number[];

    private previousStepsLimit: number;
    private visibleStepsLimit: number;

    private steps: Step[];

    constructor(scene: Phaser.Scene, x: number, y: number, sceneCount: number, customProgressBarColors: ProgressBarCustomColors) {
        super(scene, x, y);

        this.currentIndex = 0;
        this.previousIndex = undefined;
        this.sceneCount = sceneCount;
        this.visitedIndexes = [0];

        this.previousStepsLimit = 2;
        this.visibleStepsLimit = 11;

        this.initializeSteps(customProgressBarColors);
        this.redrawSteps();
        this.changeStepsVisibility();

        this.scene.add.existing(this);
    }

    public updateProgress(index: number, visitedIndexes: number[]): void {
        this.previousIndex = this.currentIndex;
        this.currentIndex = index;
        this.visitedIndexes = visitedIndexes;

        this.redrawSteps();
        this.changeStepsVisibility();
    }

    private initializeSteps(customProgressBarColors: ProgressBarCustomColors): void {
        this.steps = [];
        for (let i = 0; i < this.sceneCount; i++) {
            const step = new Step(
                this.scene,
                20 + i * 40,
                10,
                customProgressBarColors[i + 1]
            );
            this.steps.push(step);
            this.add(step);
        }
    }

    private redrawSteps(): void {
        this.steps.forEach((step, index) => {
            if (index === this.currentIndex) {
                step.drawAsCurrent();
            } else if (this.visitedIndexes.includes(index)) {
                step.drawAsVisited();
            } else {
                step.drawAsUnseen();
            }
        });
        if (
            this.currentIndex >
            this.sceneCount - (this.visibleStepsLimit - this.previousStepsLimit)
        ) {
            return;
        }
        if (
            this.currentIndex > this.previousStepsLimit ||
            (this.previousIndex ?? 0) > this.previousStepsLimit
        ) {
            this.moveSteps(true);
        }
    }

    private moveSteps(left = true): void {
        const moveBy = left ? -40 : 40;
        this.steps.forEach((step, index) => {
            const newX =
                20 +
                index * 40 +
                (this.currentIndex - this.previousStepsLimit) * moveBy;
            this.scene?.tweens.add({
                targets: step,
                x: newX,
                duration: 300,
                ease: EASINGS.CubicEaseOut
            });
        });
    }

    private changeStepsVisibility(): void {
        this.steps.forEach((step, index) => {
            // further scenes
            if (index > this.currentIndex) {
                step.show(
                    index <
                        this.visibleStepsLimit +
                            Math.max(
                                0,
                                this.currentIndex - this.previousStepsLimit
                            )
                );
                return;
            }
            // current scene
            if (index === this.currentIndex) {
                return;
            }
            // previous scenes
            if (
                this.currentIndex >
                this.sceneCount -
                    (this.visibleStepsLimit - this.previousStepsLimit)
            ) {
                return;
            }
            step.show(this.currentIndex - this.previousStepsLimit <= index);
        });
    }
}
