<template>
    <ion-popover id="nodeModalPopover" :showBackdrop="false" :isOpen="isOpen" @ionPopoverWillDismiss="closeModal">
        <div class="container">
            <ion-button v-if="isGuest && (nodeFolder == '' || nodeFolder == undefined)" class="shadow-button" mode="ios"
                color="warning" shape="round" @click="handleSignupToAccess">
                {{ $t('home.signup_to_access') }}
            </ion-button>
            <ion-button v-else-if="nodeFolder == undefined || nodeFolder == ''" class="shadow-button" mode="ios"
                disabled color="danger" shape="round">
                {{ $t('home.node_unavailable') }}
            </ion-button>
            <div v-else-if="hasAccess || isStarterNode">
                <div class="timer">
                    <ion-text class="timer-text" color="white">3 min</ion-text>
                    <GlobalIcon class="timer-icon" :iconName="stopwatchOutline" :size="'small'" :color="'light'" />
                </div>
                <ion-button @click="handleGoToNode" color="primary" mode="ios" shape="round"
                    class="launch-btn shadow-button">
                    {{ $t('home.start_node') }}
                </ion-button>
            </div>
            <ion-button v-else-if="!isGuest" class="shadow-button" color="warning" shape="round" mode="ios"
                @click="openSubModal()">
                {{ $t('home.subscribe') }}
            </ion-button>
        </div>
    </ion-popover>
</template>

<script lang="ts">
import GlobalIcon from '../../components/GlobalIcon.vue';
import { useModalStore } from '../../store/modalStore';
import { useNodeStore } from '../../store/nodeStore';
import { useVngStore } from '../../store/vngStore';
import { stopwatchOutline } from 'ionicons/icons';
import { useUserStore } from '../../store/userStore';

const uStore = useUserStore();
const mStore = useModalStore();
const nStore = useNodeStore();

export default {
    name: 'NodeModal',
    components: {
        GlobalIcon
    },
    setup() {
        return {
            stopwatchOutline
        };
    },
    computed: {
        hasAccess() {
            return uStore.hasAccess;
        },
        isOpen() {
            return mStore.isNodeModalOpen;
        },
        isGuest() {
            return !uStore.loggedIn;
        },
        nodeFolder() {
            return nStore.availableNodeContent[nStore.selectedNodeID].scene_folder || undefined;
        },
        isStarterNode() {
            return ['Intro'].includes(nStore.availableNodes[nStore.selectedNodeID].name);
        }
    },
    methods: {
        closeModal() {
            useVngStore().resetSelectedNodes();
            if (mStore.isNodeModalOpen && uStore.loggedIn) {
                mStore.toggleNodeModal(false);
            }
        },
        openSubModal() {
            this.closeModal();
            mStore.toggleSubscriptionModal(true);
        },
        handleGoToNode() {
            this.$emit('goToNode');
        },
        handleSignupToAccess() {
            this.closeModal();
            mStore.toggleSignupModal(true);
        }
    }
}
</script>


<style scoped>
ion-popover {
    --offset-y: 9rem;
    --background: transparent;
    --box-shadow: none;
    --min-width: 10rem;
}

ion-popover.no-backdrop-pointer {
    pointer-events: none;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: visible;
    min-height: 9.25rem;
    pointer-events: all;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
    }

    70% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.95);
    }
}

.node-title {
    position: relative;
    display: inline-block;
}

.node-title::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
    width: 125%;
    height: 2px;
    background-color: currentColor;
    border-radius: 4px;
}

.launch-btn {
    border-radius: 12px;
    font-size: 20px;
    z-index: 15;
    line-height: 20px;
    font-weight: 500;
    font-size: 1.2em;
    line-height: 2em;
    animation: pulse 2s infinite ease-in-out, fadeIn 1s forwards;
    width: 9rem;
}

.launch-btn:active {
    background-color: #004099;
    transform: translateY(1px);
}

.timer {
    display: inline-flex;
    font-size: 16px;
    padding: 5px 10px;
    position: absolute;
    padding-top: 4.25rem;
    right: .5rem;
    z-index: 5;
    pointer-events: none;
}

.timer-text {
    font-size: 16px;
    margin-right: 2px;
}

.timer-icon {
    margin-top: 2px;
}
</style>