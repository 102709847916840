<template>
    <ion-modal :isOpen="isOpen" @didDismiss="closeModal">
        <div class="modal-content">
            <modalToolbar @closeModal="closeModal" />
            <div class="subscription-card-container">
                <SubscriptionCard @completedSubscription=handleSubscription() />
            </div>
        </div>
    </ion-modal>
</template>

<script>
import { useModalStore } from '../store/modalStore';
import { useUserStore } from '../store/userStore';
import modalToolbar from '~/components/modal/modalToolbar.vue';
import SubscriptionCard from './subscription/SubscriptionCard.vue';

const mStore = useModalStore();

export default {
    name: 'SubscriptionModal',
    components: {
        modalToolbar,
        SubscriptionCard
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleSubscription() {
            useUserStore().checkUserPostValidation();
            this.closeModal();
        },
        closeModal() {
            if (mStore.isSubscriptionModalOpen) {
                mStore.toggleSubscriptionModal(false)
            }
        }
    }
};
</script>

<style scoped>
.modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.subscription-card-container {
    flex-grow: 1;
    display: flex;
    align-items: center;
}
</style>
