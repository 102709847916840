<template>
    <ion-button class="ion-padding" @click="goToStore" color="primary" expand="block">
        Update
        <GlobalIcon class="ion-margin-start" :iconName="getStoreIcon" color="white" :size="'large'" />
    </ion-button>
</template>

<script>
import GlobalIcon from '~/components/GlobalIcon.vue';
import { Capacitor } from '@capacitor/core';
import { AppUpdate } from "@capawesome/capacitor-app-update";
import { logoGooglePlaystore, logoAppleAppstore, globe } from 'ionicons/icons';

export default {
    components: {
        GlobalIcon
    },
    setup() {
        return {
            logoGooglePlaystore,
            logoAppleAppstore,
            globe
        };
    },
    methods: {
        goToStore() {
            if (Capacitor.isNativePlatform()) {
                AppUpdate.openAppStore();
            } else {
                console.log('Not a native platform')
            }
        }
    },
    computed: {
        getStoreIcon() {
            if (Capacitor.getPlatform() === 'android') {
                return logoGooglePlaystore;
            } else if (Capacitor.getPlatform() === 'ios') {
                return logoAppleAppstore;
            } else {
                return globe
            }
        }
    }
}
</script>