import { loadPublicSpritesheet } from "../utils";

export enum SFX {
    JetFire10 = "JetFire10",
    JetFire10Loop = "JetFire10Loop",
    BurstWindWhite4 = "BurstWindWhite4",
    ChargeWindLoopWhite4 = "ChargeWindLoopWhite4",
    ChargeWindWhite4 = "ChargeWindWhite4",
    ChargeWindWhite4Fast = "ChargeWindWhite4Fast",
    SmokeBurstWhite2 = "SmokeBurstLoopWhite2",
    SmokeBurstThickLoopWhite8 = "SmokeBurstThickLoopWhite8",
    FireBurst2 = "FireBurst2",
    FireBurst3 = "FireBurst3",
    FireBurst4 = "FireBurst4",
    FireBurst5 = "FireBurst5"
}

export type SFXManagerConfig = { [key in SFX]?: boolean };

export class SFXManager extends Phaser.Events.EventEmitter {
    // TODO: We can change that to an array
    private static config: SFXManagerConfig = {};

    private static alreadyLoadedTextures: string[] = [];

    private static effectsSettings: Map<
        SFX,
        {
            config: {
                spritesheet: string;
                frameWidth: number;
                frameHeight: number;
                endFrame: number;
                start: number;
                end: number;
                framerate: number;
                repeat?: number;
            };
        }
    > = new Map([
        [
            SFX.JetFire10,
            {
                config: {
                    spritesheet: "Jet_Fire_v10_spritesheet",
                    frameWidth: 256,
                    frameHeight: 256,
                    endFrame: 63,
                    start: 0,
                    end: 63,
                    framerate: 50
                }
            }
        ],
        [
            SFX.JetFire10Loop,
            {
                config: {
                    spritesheet: "Jet_Fire_v10_Loop_spritesheet",
                    frameWidth: 256,
                    frameHeight: 256,
                    endFrame: 63,
                    start: 1,
                    end: 63,
                    framerate: 50,
                    repeat: -1
                }
            }
        ],
        [
            SFX.ChargeWindLoopWhite4,
            {
                config: {
                    spritesheet: "Charge_Wind_Loop_White_v4_spritesheet",
                    frameWidth: 256,
                    frameHeight: 256,
                    endFrame: 63,
                    start: 0,
                    end: 63,
                    framerate: 50,
                    repeat: -1
                }
            }
        ],
        [
            SFX.ChargeWindWhite4,
            {
                config: {
                    spritesheet: "Charge_Wind_Loop_White_v4_A_spritesheet",
                    frameWidth: 256,
                    frameHeight: 256,
                    endFrame: 63,
                    start: 0,
                    end: 63,
                    framerate: 50
                }
            }
        ],
        [
            SFX.ChargeWindWhite4Fast,
            {
                config: {
                    spritesheet: "Charge_Wind_Loop_White_v4_A_spritesheet",
                    frameWidth: 256,
                    frameHeight: 256,
                    endFrame: 63,
                    start: 0,
                    end: 63,
                    framerate: 100
                }
            }
        ],
        [
            SFX.SmokeBurstWhite2,
            {
                config: {
                    spritesheet: "Smoke_Burst_Loop_White_v2_A_spritesheet",
                    frameWidth: 256,
                    frameHeight: 256,
                    endFrame: 63,
                    start: 0,
                    end: 63,
                    framerate: 50
                }
            }
        ],
        [
            SFX.BurstWindWhite4,
            {
                config: {
                    spritesheet: "Burst_Wind_White_v4_spritesheet",
                    frameWidth: 256,
                    frameHeight: 256,
                    endFrame: 15,
                    start: 0,
                    end: 15,
                    framerate: 50
                }
            }
        ],
        [
            SFX.SmokeBurstThickLoopWhite8,
            {
                config: {
                    spritesheet: "Smoke_Burst_Thick_Loop_White_v8_spritesheet",
                    frameWidth: 256,
                    frameHeight: 256,
                    endFrame: 63,
                    start: 0,
                    end: 63,
                    framerate: 90
                }
            }
        ],
        [
            SFX.FireBurst2,
            {
                config: {
                    spritesheet: "Fire_Burst_v2_spritesheet",
                    frameWidth: 256,
                    frameHeight: 256,
                    endFrame: 63,
                    start: 0,
                    end: 63,
                    framerate: 50
                }
            }
        ],
        [
            SFX.FireBurst3,
            {
                config: {
                    spritesheet: "Fire_Burst_v3_spritesheet",
                    frameWidth: 256,
                    frameHeight: 256,
                    endFrame: 63,
                    start: 0,
                    end: 63,
                    framerate: 50
                }
            }
        ],
        [
            SFX.FireBurst4,
            {
                config: {
                    spritesheet: "Fire_Burst_v4_spritesheet",
                    frameWidth: 256,
                    frameHeight: 256,
                    endFrame: 63,
                    start: 0,
                    end: 63,
                    framerate: 50
                }
            }
        ],
        [
            SFX.FireBurst5,
            {
                config: {
                    spritesheet: "Fire_Burst_v5_spritesheet",
                    frameWidth: 256,
                    frameHeight: 256,
                    endFrame: 15,
                    start: 0,
                    end: 15,
                    framerate: 50
                }
            }
        ]
    ]);

    public static preloadAssets(
        scene: Phaser.Scene,
        config: SFXManagerConfig
    ): void {
        this.config = {
            ...this.config,
            ...config
        };

        for (const [key, value] of Object.entries(this.config)) {
            if (value) {
                const data = this.effectsSettings.get(key);
                if (!data) {
                    continue;
                }
                if (
                    this.alreadyLoadedTextures.includes(data.config.spritesheet)
                ) {
                    continue;
                } else {
                    this.alreadyLoadedTextures.push(data.config.spritesheet);
                }
                loadPublicSpritesheet(scene, {
                    folderPath: ["spritesheets", "sfx"],
                    keyList: [data.config.spritesheet],
                    configs: [data.config]
                });
            }
        }
    }

    public static initialize(scene: Phaser.Scene): void {
        try {
            for (const [key, value] of Object.entries(this.config)) {
                if (!value) {
                    continue;
                }
                const data = this.effectsSettings.get(key);
                if (!data) {
                    continue;
                }

                scene.anims.create({
                    key,
                    frames: scene.anims.generateFrameNumbers(
                        data.config.spritesheet,
                        {
                            start: data.config.start,
                            end: data.config.end
                        }
                    ),
                    frameRate: data.config.framerate,
                    repeat: data.config.repeat
                });
            }
        } catch (e) {
            console.warn(e);
        }
    }

    public static resetAlreadyLoadedTextures(): void {
        this.alreadyLoadedTextures = [];
    }
}
