import { TexturesHelper } from "../TexturesHelper";
import {
    EASINGS,
    asyncAnimation,
    getTextColor,
    hexNumberToString,
    loadBucketAssets
} from "../utils";

export const ProgressBarFeedbackModuleEvent = {
    Rated: "rated"
};

export class ProgressBarFeedbackModule extends Phaser.GameObjects.Container {
    constructor(scene, x, y, config) {
        super(scene, x, y);

        // TODO: Set to be in the middle
        this.selectedOptionIndex = 1;
        this.movingHandle = false;

        this.config = {
            options: [
                "Confusing or unnecessary",
                "Neutral, neither helpful nor confusing",
                "Helpful and clarifying"
            ],
            width: 600,
            height: 30,
            radius: 12,
            color: 0xffffff,
            outline: 3,
            outlineColor: 0x000000,
            ...config
        };

        this.options = [];
        this.optionsX = [];

        this.spacing = this.config.width / this.config.options.length + 1;
        for (let i = 0; i < this.config.options.length; i++) {
            const x =
                -this.config.width * 0.5 +
                this.spacing * (i + 1) -
                this.spacing * 0.5;
            this.optionsX.push(x);
        }

        this._initializeBar();
        this._initializeOptionSlots();
        this._initializeHandle();
        this._initializeOptions();

        this.add([
            this.progressBar,
            this.graphics,
            this.handle,
            ...this.options
        ]);

        const bounds = this.getBounds();
        this.setSize(bounds.width, 400);

        this.setInteractive();

        this._bindEventHandlers();

        this._setOnClosestPosition();

        this.scene.input.keyboard.on("keydown-S", () => {
            this._playWiggleAnimation();
        });

        this.scene.add.existing(this);
    }

    static preloadAssets(scene) {
        loadBucketAssets(scene, {
            folderPath: ["General", "UIElements", "Feedback"],
            keyList: ["star"]
        });
    }

    getSelectedOption() {
        return this.config.options[this.selectedOptionIndex];
    }

    _initializeOptions() {
        for (let i = 0; i < this.config.options.length; i++) {
            this.options.push(
                this.scene.add
                    .text(this.optionsX[i], 0, this.config.options[i], {
                        fontFamily: "Work Sans",
                        fontSize: "30px",
                        color: hexNumberToString(getTextColor())
                    })
                    .setWordWrapWidth(this.spacing - 20)
                    .setAlign("center")
                    .setOrigin(0.5, 1)
                    .setInteractive()
            );
        }
    }

    _initializeBar() {
        TexturesHelper.createRoundedRectangleTexture(
            this.scene,
            "feedbackBarImage",
            this.config.width,
            this.config.height,
            this.config.radius,
            0xffffff,
            1,
            0,
            this.config.outlineColor
        );
        this.progressBar = this.scene.add
            .image(0, 75, "feedbackBarImage")
            .setInteractive(
                new Phaser.Geom.Rectangle(0, -40, this.config.width, 100),
                Phaser.Geom.Rectangle.Contains
            )
            .setTint(0x3498db, 0x2ecc71, 0x3498db, 0x2ecc71);
    }

    _initializeOptionSlots() {
        this.graphics = this.scene.add.graphics();
        for (const x of this.optionsX) {
            this.graphics.fillStyle(0x414042, 0.75);
            this.graphics.fillCircle(x, 75, 10);
        }
    }

    _initializeHandle() {
        TexturesHelper.createRoundedRectangleTexture(
            this.scene,
            "feedbackHandleImage",
            60,
            90,
            12,
            0xffcc04,
            1,
            2,
            0x000000
        );
        // this.handle = this.scene.add.image(0, 75, "feedbackHandleImage");

        this.handle = this.scene.add.graphics();
        this.handle.fillStyle(0xffcc04, 1);
        this.handle.fillRoundedRect(-30, 30, 60, 90, 12);
        this.handle.lineStyle(2, 0x000000);
        this.handle.strokeRoundedRect(-30, 30, 60, 90, 12);
        this.handle.setInteractive(
            new Phaser.Geom.Rectangle(-50, 25, 100, 100),
            Phaser.Geom.Rectangle.Contains
        );
        this.scene.input.setDraggable(this.handle);
    }

    _bindEventHandlers() {
        this.scene.input.on("pointermove", (pointer) => {
            if (!this.movingHandle) {
                return;
            }
            // NOTE: This is quazi hack to make a container within container work with global pointer positioning. This will only work
            // for the only current use case - inside FeedbackPopup
            this._moveHandle(pointer.x - this.scene.scale.width * 0.5);
        });
        this.scene.input.on("pointerup", (pointer) => {
            this.movingHandle = false;
        });
        this.progressBar.on("pointerdown", (pointer, x, y) => {
            this.movingHandle = true;
            this._moveHandle(x - this.config.width * 0.5);
        });
        this.progressBar.on("pointerup", () => {
            this.movingHandle = false;
            this._setOnClosestPosition();
        });

        this.handle.on("dragstart", (pointer, dragX) => {
            this.movingHandle = true;
        });
        this.handle.on("drag", (pointer, dragX) => {
            const halfWidth = this.config.width * 0.5;
            const x = Phaser.Math.Clamp(dragX, -halfWidth, halfWidth);
            this.handle.x = x;
        });
        this.handle.on("dragend", () => {
            this.movingHandle = false;
            this._setOnClosestPosition();
        });
        this.handle.on("pointerup", () => {
            this.movingHandle = false;
            this._setOnClosestPosition();
        });
    }

    _moveHandle(x) {
        const halfWidth = this.config.width * 0.5;
        this._playMoveHandleToPositionAnimation(
            Phaser.Math.Clamp(x, -halfWidth, halfWidth)
        );
    }

    _setOnClosestPosition() {
        const closestX = this.optionsX.reduce((prev, curr) =>
            Math.abs(curr - this.handle.x) < Math.abs(prev - this.handle.x)
                ? curr
                : prev
        );
        this._playMoveHandleToPositionAnimation(closestX);
        this.selectedOptionIndex = this.optionsX.findIndex(
            (x) => x === closestX
        );
        this._playIncreaseSelectedOptionTextSizeAnimation(
            this.selectedOptionIndex
        );

        this.emit(
            ProgressBarFeedbackModuleEvent.Rated,
            this.config.options[this.selectedOptionIndex]
        );
    }

    _playMoveHandleToPositionAnimation(x) {
        this.moveHandleTween = this.scene?.tweens.add({
            targets: this.handle,
            x,
            duration: 150,
            ease: EASINGS.CubicEaseOut
        });
    }

    _playIncreaseSelectedOptionTextSizeAnimation(index) {
        for (const option of this.options) {
            this.scene?.tweens.add({
                targets: option,
                scale: 1,
                duration: 150
            });
        }
        this.scene?.tweens.add({
            targets: this.options[index],
            scale: 1.1,
            duration: 150
        });
    }

    async _playWiggleAnimation() {
        this.scene?.tweens.add({
            targets: this.handle,
            duration: 300,
            scale: 1.2,
            ease: EASINGS.SineEaseInOut
        });

        await asyncAnimation(
            this.scene?.tweens.add({
                targets: this.handle,
                duration: 100,
                angle: -10,
                onComplete: (tween) => {
                    tween.emit("done");
                }
            })
        );

        await asyncAnimation(
            this.scene?.tweens.add({
                targets: this.handle,
                duration: 100,
                angle: 10,
                yoyo: true,
                repeat: 1,
                onComplete: (tween) => {
                    tween.emit("done");
                }
            })
        );

        this.scene?.tweens.add({
            targets: this.handle,
            duration: 100,
            angle: 0
        });

        this.scene?.tweens.add({
            targets: this.handle,
            duration: 300,
            scale: 1,
            ease: EASINGS.SineEaseInOut
        });
    }
}
