<template>
    <div class="progress-info">
        <div @click="openSelect()">
            <div v-if="questlineDetails.completion_percentage != null">
                <p class="progress-text"> {{ isUpdating ? 'Updating...' : questlineDetails.questline_name }}
                </p>
                <div class="centered-progress">
                    <ion-progress-bar class="shadow-button"
                        :value="questlineDetails.completion_percentage"></ion-progress-bar>
                </div>
            </div>
            <div v-else>
                <p class="progress-text">No Questline</p>
                <div class="centered-progress">
                    <ion-progress-bar></ion-progress-bar>
                </div>
            </div>
        </div>
        <div>
            <ion-select interface="alert" :interfaceOptions="{
                header: 'Select a questline:',
                subHeader: 'Questlines are your adventure maps, guiding you through the galaxy to pass your certification.'
            }" ref="questlineSelect" class="hidden-select bold-header" v-model="questlineID"
                @ionChange="saveQuestline();">
                <ion-select-option v-for="questline in questlines" :key="questline.id" :value="questline.id">
                    {{ questline.name }}
                </ion-select-option>
            </ion-select>
        </div>
    </div>
</template>

<script>
import { useUserStore } from '@/store/userStore'
import { useModalStore } from '@/store/modalStore'

const uStore = useUserStore()
const mStore = useModalStore()

export default {
    emits: ['questlineSaved'],
    data() {
        return {
            questlines: [],
            questlineID: '',
            isUpdating: false
        }
    },
    props: {
        enableClick: {
            type: Boolean,
            default: true
        }
    },
    created() {
        this.questlineID = uStore.user.currentquest
        this.fetchQuestlines()
    },
    computed: {
        isProgressModalOpen() {
            return mStore.isProgressModalOpen
        },
        questlineDetails() {
            if (uStore.questlineDetails == null) {
                return {
                    completion_percentage: null,
                    questline_name: 'No Questline'
                }
            }
            return uStore.questlineDetails
        }
    },
    methods: {
        openSelect() {
            if (!this.enableClick) return
            this.$nextTick(() => {
                this.$refs.questlineSelect.$el.open()
            })
        },
        fetchQuestlines() {
            try {
                $fetch(this.$config.public.API_BASE_URL + 'questline/', {
                    method: 'GET',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + uStore.accessToken,
                    }),
                }).then(res => {
                    this.questlines = res
                })
            } catch (err) {
                console.log(err)
            }
        },
        async saveQuestline() {
            try {
                this.isUpdating = true;
                const updatedUser = await uStore.updateUser({ currentquest: this.questlineID });
                this.$emit('questlineSaved', this.questlineID);
                this.questlineID = updatedUser.currentquest;
            } catch (err) {
                console.warn('Error saving questline:', err);
            } finally {
                setTimeout(() => {
                    this.isUpdating = false;
                }, 3000);
            }
        }
    },
};
</script>

<style scoped>
.progress-info {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5px;
}

.progress-text {
    margin-top: 0;
    margin-bottom: 0;
    align-self: flex-start;
    color: whitesmoke;
    filter: drop-shadow(-2px 2px 2px rgba(0, 0, 0, .71));
}

.centered-progress {
    display: absolute;
    justify-content: center;
    width: 100%;
}

ion-progress-bar {
    height: 8px;
    width: 100%;
    border-radius: 10px;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

ion-card {
    position: absolute;
    top: 100px;
    left: 50px;
    z-index: 5000;
}

.outline {
    border-color: #403E39;
    border-width: thin;
    border-style: solid;
    border-radius: 12px;
}

.hidden-select {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -100%;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

ion-progress-bar {
    --background: #403E39;
    --buffer-background: #403E39;
    --progress-background: var(--ion-color-primary);
}
</style>