import Phaser from "phaser";
import UIScene from "./utils/UIScene/UIscene";
import SceneTransitionUtil from "./utils/SceneTransitionUtil";
import { useModalStore } from "../store/modalStore";
import { bucketBaseUrl, getProgressBarColors } from "./utils/utils";
import { EVENTS } from "./utils/events";
import { AudioManager } from "./utils/sound/AudioManager";
import { useUserStore } from "../store/userStore";
import { useNodeStore } from "../store/nodeStore";
import { SFXManager } from "./utils/effects/SFXManager";

class MainScene extends Phaser.Scene {
    constructor() {
        super("MainScene");
        this.folder = "";
        this.nodeID = "";
        this.scenes = [];
        this.sceneCount = "";
        this.api_url = "";
        this.visitedIndexes = [0];
        this.currentIndex = 0;
        this.transitionInProgress = false;
        this.hasScene0 = false;
        this.mStore = useModalStore();
    }

    init(data) {
        this.cleanupScenes();
        this.folder = data.nodeData.scene_folder;
        this.nodeID = data.nodeData.node_id;
        this.api_url = data.nuxtConfigData.API_BASE_URL;
        // Set global variables
        this.game.registry.set("api_url", this.api_url);
        this.game.registry.set("folder", this.folder);
        this.game.registry.set("nodeID", this.nodeID);
    }

    preload() {
        this.load.audio("swipeSound", [
            "https://storage.googleapis.com/sensei_assets/websrc/General/Audio/swipe.wav"
        ]);
    }

    cleanupScenes() {
        // For each scene, remove it
        this.scenes.forEach((sceneKey) => {
            if (this.scene.isActive(sceneKey)) {
                this.scene.stop(sceneKey);
            }
            this.scene.remove(sceneKey);
        });

        // Reset scenes array and currentIndex
        this.scenes = [];
        this.currentIndex = 0;
    }

    reset() {
        this.cleanupScenes();
        this.folder = "";
        this.sceneCount = 0;
        this.currentIndex = 0;
    }

    async create() {
        SFXManager.resetAlreadyLoadedTextures();
        this._initializeAudioManager();
        const swipeSoundLeft = this.sound.add("swipeSound");
        swipeSoundLeft.setVolume(0.05);
        const swipeSoundRight = this.sound.add("swipeSound");
        swipeSoundRight.setVolume(0.05);
        this.transitionUtil = new SceneTransitionUtil(
            this,
            swipeSoundLeft,
            swipeSoundRight
        );

        const promises = [];
        // TODO: ScenesData is currently being used only for deducing ProgressBar bricks colors. It should be removed and way of getting this info
        // refactored
        this.scenesData = [];
        promises.push(this.loadAndLaunchScene0());

        let index = 1;
        while (true) {
            const fileName = `scene${index}`;
            try {
                // TODO: Remove bgURL from all scenes
                const {
                    default: SceneClass,
                    bgUrl,
                    sceneData
                } = await import(`./scenes/${this.folder}/${fileName}.js`);
                this.scenesData.push(sceneData);
                const sceneKey = `Scene${index}`;
                this.scenes.push(sceneKey);
                this.scene.add(sceneKey, SceneClass, false);
                // Queueing assets to load from future scenes
                SceneClass.preloadAssets?.(this);
                index++;
            } catch (error) {
                if (error.message.includes("Unknown variable dynamic import")) {
                    break;
                } else {
                    console.error(`Failed to load ${fileName}.js:`, error);
                    break;
                }
            }
        }
        promises.push(
            new Promise((resolve, reject) => {
                this.load.once("complete", () => {
                    resolve();
                });
            })
        );
        promises.push(this.loadAndLaunchUIScene());

        // We launch assets loading manually here and proceed like we did with Scene1
        this.load.start();

        // We make sure UIScene is loaded and operational before we start to show scenes.
        // This is needed so we can access UIScene methods like popups etc at any time
        Promise.all(promises).then(() => {
            this.handleAllAssetsLoaded();
            this.scene.manager.dump();
        });

        this.sceneCount = index - 1;
    }

    // TODO: Change name to "BackgroundScene"? Would be more explicit
    async loadAndLaunchScene0() {
        return new Promise(async (resolve, reject) => {
            // Load and launch scene0 if it exists
            try {
                const { default: Scene0Class, sceneData } = await import(
                    `./scenes/${this.folder}/scene0.js`
                );
                // NOTE: We're making sure scenesData[0] is always scene0's data
                this.scenesData.unshift(sceneData);
                const scene0Key = "Scene0";
                this.scene.add(scene0Key, Scene0Class, false);
                this.scene.launch(scene0Key);
                this.scene.sendToBack(scene0Key);
                this.hasScene0 = true;
                resolve();
            } catch (error) {
                console.warn(error);
                console.log(`Scene0 not found`);
                resolve();
            }
        });
    }

    async loadAndLaunchUIScene() {
        return new Promise((resolve, reject) => {
            const capitalizedFolderName =
                this.folder.charAt(0).toUpperCase() + this.folder.slice(1);
            let url = `${bucketBaseUrl}/${capitalizedFolderName}/UIElements/`;

            try {
                fetch(url).then((response) => {
                    // NOTE: Kind of a hacky way to get the sceneCount if we're dealing with Under Construction node
                    if (this.folder === "underConstruction") {
                        this.sceneCount =
                            this._getUnderConstructionNodeScenesCount();
                        this.scenesData =
                            this._getUnderConstructionNodeScenesData();
                    }
                    if (response.status === 200) {
                        this.scene.add("UIScene", UIScene, true, {
                            sceneCount: this.sceneCount,
                            scenesData: this.scenesData,
                            folderName: capitalizedFolderName
                        });
                    } else {
                        this.scene.add("UIScene", UIScene, true, {
                            sceneCount: this.sceneCount,
                            scenesData: this.scenesData,
                            folderName: "General"
                        });
                    }
                    let uiScene = this.scene.get("UIScene");
                    uiScene.events.on(
                        EVENTS.UIScene.previousSceneRequested,
                        this.handlePreviousSceneRequested,
                        this
                    );
                    uiScene.events.on(
                        EVENTS.UIScene.nextSceneRequested,
                        this.handleNextSceneRequested,
                        this
                    );
                    uiScene.events.on(
                        EVENTS.UIScene.homeButtonClicked,
                        this.handleHomeButtonClick,
                        this
                    );
                    uiScene.events.on(
                        EVENTS.UIScene.feebackButtonClicked,
                        this.handleFeedbackButtonClick,
                        this
                    );
                    uiScene.events.on(
                        EVENTS.UIScene.keywordButtonClicked,
                        this.handleKeywordButtonClick,
                        this
                    );
                    uiScene.events.on(
                        EVENTS.UIScene.summaryCardNextNodeRequested,
                        this.handleSummaryCardNextNodeRequested,
                        this
                    );
                    uiScene.events.on(
                        EVENTS.UIScene.summaryCardExitToMapRequested,
                        this.handleSummaryCardExitToMapRequested,
                        this
                    );
                    resolve();
                });
            } catch (error) {
                console.log(`UIScene not found`);
                console.log(error);
                reject();
            }
        });
    }

    isDarkMode() {
        return useUserStore().userPrefs?.dark_mode ?? false;
    }

    handleCompletionEmit(value) {
        if (this.mStore.isNodeOpen) {
            this.mStore.toggleNode(false);
        }
        document.dispatchEvent(new Event("phaserComplete"));
    }

    handleNextEmit(value) {
        console.log("Received Next emit from Phaser scene:", value);
        if (this.currentIndex < this.scenes.length - 1) {
            this.transitionTo(this.currentIndex + 1);
        } else {
            console.log("No more scenes to transition to from Phaser scene");
        }
    }

    handleAllAssetsLoaded() {
        this.currentIndex = 0;
        let uiScene = this.scene.get("UIScene");
        uiScene.currentIndex = this.currentIndex;
        this.scene.launch(`Scene${this.currentIndex + 1}`, {
            onCompletion: this.handleCompletionEmit.bind(this),
            onNext: this.handleNextEmit.bind(this)
        });
        document.dispatchEvent(new Event("scene1Loaded"));
    }

    handleSwipe(swipe) {
        if (swipe.left && this.currentIndex < this.scenes.length - 1) {
            this.transitionTo(this.currentIndex + 1, "next");
        } else if (swipe.right && this.currentIndex > 0) {
            this.transitionTo(this.currentIndex - 1, "previous");
        }
    }

    handlePreviousSceneRequested() {
        this.transitionTo(this.currentIndex - 1, "previous");
    }

    handleNextSceneRequested() {
        this.transitionTo(this.currentIndex + 1, "next");
    }

    handleHomeButtonClick() {
        if (this.mStore.isNodeOpen) {
            this.mStore.toggleNode(false);
        }
    }
    handleFeedbackButtonClick() {
        let sceneKey = this.scenes[this.currentIndex];
        document.dispatchEvent(
            new CustomEvent("feedbackButtonPressed", {
                detail: {
                    sceneKey: sceneKey
                }
            })
        );
    }

    handleKeywordButtonClick() {
        document.dispatchEvent(new Event("keywordButtonPressed"));
    }

    async handleSummaryCardNextNodeRequested(nodeId) {
        if (nodeId === undefined) {
            console.warn("NO NODE ID PROVIDED FOR THE NEXT LESSON");
        }
        const uStore = useUserStore();
        await uStore.addNode(useNodeStore().selectedNodeID);
        useNodeStore().updateSelectedNodeID(nodeId);
        document.dispatchEvent(new Event("phaserNext"));
    }

    handleSummaryCardExitToMapRequested() {
        if (this.mStore.isNodeOpen) {
            this.mStore.toggleNode(false);
            document.dispatchEvent(new Event("phaserComplete"));
        }
    }

    getCurrentSceneIndex() {
        return this.currentIndex;
    }

    async transitionTo(index, direction) {
        if (
            index < 0 ||
            index >= this.scenes.length ||
            index === this.currentIndex ||
            this.transitionInProgress
        )
            return;

        const defaultTransitionOverwrite = this.scenesData[0]?.transition;
        const currentSceneTransitionType =
            this.scenesData[this.currentIndex + 1]?.transition;

        const outgoingScene = this.scene.get(this.scenes[this.currentIndex]);
        const incomingSceneKey = this.scenes[index];
        this.currentIndex = index;
        this.visitedIndexes.push(this.currentIndex);

        this.transitionInProgress = true;
        outgoingScene.events.once("sleep", () => {
            this.transitionInProgress = false;
        });

        const launchData = {
            onCompletion: async () => {
                let uiScene = this.scene.get("UIScene");
                await uiScene.tryToShowFeedbackPopup();
                if (useUserStore().loggedIn && useUserStore().hasAccess) {
                    await uiScene.showSummaryCardPopup();
                } else {
                    this.handleCompletionEmit();
                }
            },
            onNext: this.handleNextEmit.bind(this)
        };

        if (this.hasScene0) {
            try {
                this.scene
                    .get("Scene0")
                    .handleCurrentIndexChanged(this, this.currentIndex + 1);
            } catch {
                console.warn(
                    "Scene0 does not implement handleCurrentIndexChanged"
                );
            }
        }

        this.transitionUtil.transitionTo(
            outgoingScene,
            incomingSceneKey,
            this,
            direction,
            launchData,
            currentSceneTransitionType ?? defaultTransitionOverwrite
        );
    }

    _initializeAudioManager() {
        this.registry.set("audioManager", new AudioManager());
    }

    _getUnderConstructionNodeScenesCount() {
        // deduce scenes count based on default english content
        const content =
            useNodeStore().availableNodeContent[this.registry.get("nodeID")]
                .node_scene_text["en"];

        // look for highest scene number and acount for finishing quiz scene
        const sceneKeys = Array.from(Object.keys(content)).map((sceneKey) =>
            Number(sceneKey.split("Scene")[1])
        );
        return sceneKeys.sort((a, b) => b - a)[0] + 1;
    }

    _getUnderConstructionNodeScenesData() {
        const content =
            useNodeStore().availableNodeContent[this.registry.get("nodeID")]
                .node_scene_text["en"];
        const data = [];
        for (let i = 0; i <= this.sceneCount; i += 1) {
            const sceneContent = content[`Scene${i}`];
            // normal paragraph scene
            if (sceneContent) {
                data.push(undefined);
            } else {
                // finishing quiz scene
                if (i === this.sceneCount) {
                    data.push({
                        progressBarColor: {
                            visited: getProgressBarColors().QUIZ_VISITED,
                            current: getProgressBarColors().FINISH_CURRENT,
                            unseen: getProgressBarColors().FINISH_UNSEEN
                        }
                    });
                    // normal quiz scene
                } else {
                    data.push({
                        progressBarColor: {
                            visited: getProgressBarColors().QUIZ_VISITED,
                            current: getProgressBarColors().QUIZ_CURRENT,
                            unseen: getProgressBarColors().QUIZ_UNSEEN
                        }
                    });
                }
            }
        }
        return data;
    }
}

export default MainScene;
