<template>
  <div class="loading-container">
    <ion-text :color="theme">
      <p class="loading-text">Fetching</p>
    </ion-text>
    <ion-spinner class="spinner" name="dots" :color="theme"></ion-spinner>
  </div>
</template>

<script>
import { IonSpinner } from '@ionic/vue';

export default {
  components: {
    IonSpinner
  },
  props: {
    theme: {
      type: String,
      default: 'dark'
    },
  }
}
</script>

<style scoped>
.loading-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-color: rgba(255, 255, 255, 0);
  z-index: 1000;
}

.loading-text {
  font-size: 16px;
  letter-spacing: 1px;
}

.spinner {
  margin-left: 5px;
  margin-top: 10px;
  margin-right: 10px;
}
</style>