<template>
    <div class="ion-margin">
        <h1 class="center-text">{{ $t('home.signup.email.header') }}</h1>
        <ion-text class="center-text">{{ $t('home.signup.email.text') }}</ion-text>
        <EmailInput @update:email="email = $event" @update:isEmailTaken="isTaken = $event" fontSize="sm">
        </EmailInput>
        <div class="button-container ion-padding-top">
            <ion-button class="prev-button" fill="outline" color="primary" @click="nextSlide(-1)">
                <GlobalIcon :iconName="arrowBackOutline" color="dark" :size="'medium'"></GlobalIcon>
            </ion-button>
            <ion-button class="next-button" expand="block" color="primary" @click="nextSlide" :disabled="isTaken">{{
                $t('home.signup.email.next') }}</ion-button>
        </div>
        <ion-text class="center-text ion-padding-top ion-padding-bottom">{{ $t('home.signup.email.or') }}</ion-text>
        <Sso @loginComplete="emitLoginComplete" @loginFailed="emitLoginFailed($event)"></Sso>
    </div>
</template>

<script>
import { useGuestStore } from '@/store/guestStore';
import Sso from '~/components/Sso.vue'
import EmailInput from './EmailInput.vue';
import GlobalIcon from '../GlobalIcon.vue';
import { arrowBackOutline } from 'ionicons/icons';

const gStore = useGuestStore();

export default {
    name: 'EmailSlide',
    emits: ['nextSlide', 'loginComplete'],
    components: {
        Sso,
        EmailInput,
        GlobalIcon
    },
    setup() {
        return {
            arrowBackOutline
        };
    },
    data() {
        return {
            email: '',
            isTaken: true,
            swiper: null,
            submittedEmail: false
        }
    },
    methods: {
        nextSlide(direction) {
            if (direction === -1) return this.swiper.slidePrev()
            gStore.updateUserSignupData({ email: this.email });
            if (!this.submittedEmail) {
                gStore.submitActivity("entered_email", this.email);
                this.submittedEmail = true;
            }
            this.swiper.slideNext()
        },
        emitLoginComplete() { // TODO: Maybe we can keep this the same, just ensure the subscription modal appears if the user isnt subbed.
            this.$emit('loginComplete');
        },
        emitLoginFailed(error) {
            console.error(JSON.stringify(error));
        }
    },
    mounted() {
        this.swiper = useSwiper()
    },
    watch: {
    }
}
</script>

<style scoped>
.email-status {
    text-align: center;
    color: #414042;
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: 1;
    gap: 10px;
}

.next-button,
.prev-button {
    height: 1rem;
}

.next-button {
    width: calc(75% - 5px);
}

.prev-button {
    aspect-ratio: 1 / 1;
    width: auto;
    max-width: calc(25% - 5px);
}
</style>