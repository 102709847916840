<template>
    <ion-page class="fade-in">
        <Transition>
            <ion-header v-show="!mStore.isNodeOpen" style="pointer-events: none;" class="ion-no-border">
                <ion-toolbar v-show="!isGuest && !anyNodeSelected">
                    <ion-buttons slot="start">
                        <ion-button @click="mStore.toggleFeedbackModal">
                            <GlobalIcon :size="'large'" :iconName="create" :shadow="true" :color="'white'" />
                        </ion-button>
                        <ion-button @click="mStore.toggleUserPersModal()">
                            <GlobalIcon :size="'large'" :iconName="bagHandle" :shadow="true" :color="'white'" />
                        </ion-button>
                    </ion-buttons>
                    <ProgressSelect v-if="!isGuest" @questlineSaved="fetchQuestlineProgress(); fetchLattice();"
                        :enableClick="false">
                    </ProgressSelect>
                    <ion-buttons slot="end">
                        <Streak id="streak-trigger"></Streak>
                        <StreakPopover trigger="streak-trigger"></StreakPopover>
                        <!-- <ion-button @click="mStore.toggleFriendsModal()">
                            <GlobalIcon :size="'large'" :iconName="people" :badge="friendRequests" :color="'white'">
                            </GlobalIcon>
                        </ion-button> -->
                        <ion-button router-link="/dashboard/">
                            <GlobalIcon :size="'large'" :shadow="true" :iconName="menu" :color="'white'" />
                        </ion-button>
                    </ion-buttons>
                </ion-toolbar>
                <div class="guest-toolbar" v-show="isGuest">
                    <div class="logo-text-container">
                        <a href="https://skillrocket.ca/" target="_blank"><img src="~/assets/logos/logo.png"
                                @click="resetgStore()" class="logo-image" /></a>
                    </div>
                    <div class="country-container">
                        <CountryDropBox @click.once="gStore.submitActivity('lang_opened')"></CountryDropBox>
                    </div>
                </div>
            </ion-header>
        </Transition>
        <Transition>
            <LatticeLoading v-if="nStore.latticeFetchInProgress && !isGuest" :theme="'white'"></LatticeLoading>
        </Transition>
        <ion-content :forceOverscroll="false" :fullscreen="true" style="--ion-background-color: black">
        </ion-content>
        <div v-show="!mStore.isNodeOpen" class="graph-container">
            <v-network-graph v-if="vngStore.vNGconfig" ref="vNGComp" class="graph"
                :style="{ '--global-edge-opacity': edgeOpacity }" :event-handlers="vngStore.eventHandlers()"
                v-model:selected-nodes="vngStore.selectedNodes" v-model:zoom-level="vngStore.zoomLevel"
                v-model:layouts="nStore.layouts" :configs="vngStore.vNGconfig" :nodes="nStore.availableNodes"
                :edges="nStore.availableEdges" :layers="layers">
                <template #background>
                    <!-- <image id="background" href="@/assets/backgrounds/Lattice.png" :x="-vngStore.boundaryWidth / 2"
                        :y="-vngStore.boundaryHeight / 2" :width="vngStore.boundaryWidth"
                        :height="vngStore.boundaryHeight" preserveAspectRatio="none" pointer-events="none" /> -->
                    <g v-for="(star, index) in vngStore.stars" :key="index" class="star-animation"
                        :style="{ animationDuration: star.duration + 's' }" pointer-events="none">
                        <circle :opacity="anyNodeSelected ? 0.075 : 1" :cx="star.x - star.size / 2"
                            :cy="star.y + star.size / 2" :r="star.size" fill="url(#shadow-gradient)" />
                        <circle :opacity="anyNodeSelected ? 0.25 : 1" :cx="star.x" :cy="star.y" :fill="star.color"
                            :r="star.size" />
                    </g>
                </template>
                <template #override-node-label="{ nodeId, scale, text, x, y, config }">
                    <defs>
                        <filter x="-0.05" y="-0.05" width="1.1" height="1.1" id="solid">
                            <feFlood flood-color="black" result="bg" :flood-opacity=".8" />
                            <feMerge>
                                <feMergeNode in="bg" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                    <text class="node-label" x="0" y="0" filter="url(#solid)"
                        :font-size="isSelectedNode(nodeId) ? (config.fontSize + 12) * scale : config.fontSize * scale"
                        :text-anchor="'middle'" :dominant-baseline="'hanging'" :fill="config.color"
                        :transform="`translate(${x} ${y})`"
                        :opacity="isSelectedNode(nodeId) ? 1 : (nodeHasSceneFolder(nodeId) ? (anyNodeSelected ? 0.25 : 1) : 0.25)">
                        {{ text }}
                    </text>
                </template>
                <template #override-node="{ nodeId, scale, config, ...slotProps }">
                    <!-- circle for filling background -->
                    <g :class="nodeId === '-5' ? 'pop-in-guest' : 'pop-in'">
                        <g v-if="introArrow">
                            <image class="pointer-arrow" :opacity="anyNodeSelected ? 0 : 1" x="-1.25em" y="-5.5em"
                                width="2.5em" height="2.5em" xlink:href="assets/images/newarrow.png" />
                            <animateTransform attributeName="transform" type="translate" values="0,0; 0,-15; 0,0"
                                begin="0s" dur="2.5s" repeatCount="indefinite" />
                        </g>
                        <circle
                            v-if="(nodeStatuses[nodeId] === 'available' && nodeHasSceneFolder(nodeId) || nodeId === '-1' || nodeId === '-5') && !anyNodeSelected"
                            class="available-pulse" :r="config.radius * scale" fill="none"
                            :stroke="`${nStore.availableNodes[nodeId].strokecolor}`" />
                        <g
                            :style="{ transition: 'transform 0.3s ease-in-out', transform: 'scale(' + (isSelectedNode(nodeId) ? 1.25 : 1) + ')' }">
                            <circle :cx="isGuest ? -config.radius * .2 : -config.radius * .1"
                                :cy="isGuest ? config.radius * .2 : config.radius * .1" :r="config.radius * scale * 1.1"
                                fill="url(#shadow-gradient)" />
                            <image class="face-picture" :x="-config.radius * scale" :y="-config.radius * scale"
                                :width="config.radius * scale * 2" :height="config.radius * scale * 2"
                                :href="nStore.planetImagePaths[nodeId]" clip-path="url(#faceCircle)"
                                :opacity="isSelectedNode(nodeId) ? 1 : (nodeHasSceneFolder(nodeId) ? (anyNodeSelected ? 0.25 : 1) : 0.25)" />
                            <!-- Face circle -->
                            <circle class="face-circle" :r="config.radius * scale + 7" fill="none" :stroke="0"
                                v-bind="slotProps" />
                            <circle :r="config.radius * scale" fill="none" stroke="whitesmoke"
                                :stroke-width="!isGuest ? 3 : 12"
                                :opacity="isSelectedNode(nodeId) ? 1 : (nodeHasSceneFolder(nodeId) ? (anyNodeSelected ? 0.25 : 1) : 0.25)" />
                        </g>
                        <!-- badge -->
                        <g class="badge-group"
                            v-if="nStore.availableNodes[nodeId].badgeimage && nodeHasSceneFolder(nodeId)">
                            <circle :class="{ 'badge-wiggle': (nStore.getNodeStatus(nodeId) === 'available') }"
                                :cx="config.radius * scale + 3" :cy="-config.radius * scale + 3"
                                :r="(nStore.getNodeStatus(nodeId) === 'available') ? 15 : 25"
                                fill="url(#badge-gradient)" />
                            <image class="badge-image"
                                :class="{ 'badge-wiggle': (nStore.getNodeStatus(nodeId) === 'available') }"
                                :x="config.radius * scale - 15" :y="-config.radius * scale - 15" :width="30"
                                :height="30" :xlink:href="`${nStore.availableNodes[nodeId].badgeimage}`"
                                :opacity="isSelectedNode(nodeId) ? 1 : (nodeHasSceneFolder(nodeId) ? (anyNodeSelected ? 0.25 : 1) : 0.25)" />
                        </g>
                    </g>
                </template>
                <defs>
                    <radialGradient id="shadow-gradient" cx="50%" cy="50%" r="70%" fx="50%" fy="50%">
                        <stop offset="60%" style="stop-color:rgba(0,0,0,0.5); stop-opacity:1" />
                        <stop offset="70%" style="stop-color:rgba(0,0,0,0); stop-opacity:1" />
                    </radialGradient>
                </defs>
                <defs>
                    <radialGradient id="ship-shadow-gradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                        <stop offset="60%" style="stop-color:rgba(0,0,0,0.2); stop-opacity:1" />
                        <stop offset="85%" style="stop-color:rgba(0,0,0,0); stop-opacity:1" />
                    </radialGradient>
                </defs>
                <defs>
                    <radialGradient id="badge-gradient" cx="50%" cy="50%" r="35%" fx="50%" fy="50%">
                        <stop offset="50%" style="stop-color:rgba(0,0,0,0.2); stop-opacity:1" />
                        <stop offset="100%" style="stop-color:rgba(0,0,0,0); stop-opacity:1" />
                    </radialGradient>
                </defs>
            </v-network-graph>
            <div class="shadow-container"></div>
            <Transition>
                <ion-row v-if="!(mStore.isNodeOpen || anyNodeSelected)"
                    class="ion-justify-content-center ion-align-items-end ion-padding">
                    <div class="button-container">
                        <div class="center-button">
                            <Transition>
                                <ion-button v-if="!vngStore.disableRecenter && uStore.loggedIn"
                                    class="recenter-button shadow-button" size="small" color="white"
                                    @click="vngStore.centerNode(false)">{{
                                        $t('home.recenter')
                                    }}
                                </ion-button>
                            </Transition>
                        </div>
                        <div class="center-button">
                            <ion-button v-if="nStore.getAvailableNodeCount > 1 && hasAccess"
                                class="continue-overlay shadow-button" @click="goToNode(true)">
                                <div class="button-content">
                                    {{ $t('home.next_lesson') }}
                                </div>
                            </ion-button>
                        </div>
                        <div v-if="isGuest">
                            <div class="index-container">
                                <div class="launch-container">
                                    <Transition>
                                        <LaunchButton v-show="!hasLaunched" class="launch-button"
                                            @click="goToGuestNode()">
                                        </LaunchButton>
                                    </Transition>
                                </div>
                                <div class="auth-container">
                                    <ion-button class="signup-button shadow-button" @contextmenu.prevent=""
                                        @mousedown.prevent="" @click="mStore.toggleSignupModal(true)"
                                        @click.once="gStore.submitActivity('signup_opened', 'From Lattice')"
                                        expand="block">
                                        {{ $t('home.signup.title') }}
                                    </ion-button>
                                    <ion-button class="login-button shadow-button" @contextmenu.prevent=""
                                        @mousedown.prevent="" @click="mStore.toggleLoginModal(true)"
                                        @click.once="gStore.submitActivity('login_opened')" expand="block">
                                        <ion-text color="light">{{ $t('home.login.title') }}</ion-text>
                                    </ion-button>
                                </div>
                            </div>
                            <p class="terms-link">
                                <a class="center-text" href="https://www.cloudcompany.ca/privacy" target="_blank">{{
                                    $t('home.privacy') }}</a>
                            </p>
                        </div>
                        <div v-else-if="!hasAccess" class="center-button">
                            <ion-button class="continue-overlay shadow-button" color="warning"
                                @click="mStore.toggleSubscriptionModal(true)">
                                <div class="button-content">
                                    {{ $t('home.subscribe') }}
                                </div>
                            </ion-button>
                        </div>
                    </div>
                </ion-row>
            </Transition>
            <SsoModal :isOpen="mStore.isSsoModalOpen" />
        </div>
        <!-- Node Modal-->
        <Transition>
            <ion-content v-if="mStore.isNodeOpen" :fullscreen="false" :forceOverscroll="false"
                style="--background: transparent; z-index: 50000; position: absolute;">
                <div class="phaser-container">
                    <PhaserLoader>
                    </PhaserLoader>
                </div>
            </ion-content>
        </Transition>
        <NodeModal @goToNode="goToNode" />
        <TermsModal :isOpen="mStore.isTermsModalOpen" />
        <Feedback :isOpen="mStore.isFeedbackModalOpen"
            :feedback-types="{ comment: true, rating: false, likeDislike: false }" />
        <!-- <FriendsModal :isOpen="mStore.isFriendsModalOpen" /> -->
        <UserPersonalizationModal :isOpen="mStore.isUserPersModalOpen" />
        <LoginModal :isOpen="mStore.isLoginModalOpen" />
        <SignupModal :isOpen="mStore.isSignupModalOpen" />
        <SignupActionModal :isOpen="mStore.isSignupActionModalOpen" />
        <PasswordResetModal :isOpen="mStore.isPasswordResetModalOpen" />
        <OutOfDateModal :isOpen="mStore.isOutOfDateModalOpen" />
        <WelcomeModal :isOpen="mStore.isWelcomeModalOpen" />
        <PatchNotesModal :isOpen="mStore.isPatchNotesModalOpen" />
        <SubscriptionModal :isOpen="mStore.isSubscriptionModalOpen" />
        <Questlinemodal :isOpen="mStore.isQuestlineModalOpen" />
        <NotiToast></NotiToast>
    </ion-page>
</template>

<script lang="ts">
import { ref } from 'vue'
import { useNodeStore } from '../store/nodeStore.ts'
import { useUserStore } from '../store/userStore.ts'
import { useFriendStore } from '../store/friendStore.ts'
import { useModalStore } from '../store/modalStore.ts'
import { useGuestStore } from '../store/guestStore.ts'
import { useVngStore } from '../store/vngStore.ts'
import { useKeywordQuestionStore } from '../store/keywordQuestionStore.ts'
import * as vNG from "v-network-graph"
import ProgressSelect from '../components/ProgressSelect.vue'
import Streak from '../components/Streak.vue'
import StreakPopover from '../components/StreakPopover.vue'
import GlobalIcon from '../components/GlobalIcon.vue';
import TermsModal from '../components/TermsModal.vue'
import NodeModal from '../components/nodes/NodeModal.vue'
// import FriendsModal from '../components/FriendsModal.vue'
import SsoModal from '../components/signup/SsoModal.vue'
import UserPersonalizationModal from '../components/UserPersonalizationModal.vue';
import Feedback from '../components/Feedback.vue';
import Questlinemodal from '../components/QuestlineModal.vue'
import PhaserLoader from '../components/nodes/PhaserLoader.vue'
import LoginModal from '../components/loginModal.vue';
import SignupModal from '../components/signupModal.vue';
import SignupActionModal from '../components/signup/SignupActionModal.vue';
import PasswordResetModal from '../components/passwordResetModal.vue';
import OutOfDateModal from '../components/OutOfDateModal.vue';
import PatchNotesModal from '../components/PatchNotesModal.vue';
import SubscriptionModal from '../components/SubscriptionModal.vue';
import WelcomeModal from '../components/WelcomeModal.vue';
import NotiToast from '../components/toasts/NotiToast.vue'
import { menu, arrowForward, closeCircleOutline, brush, people, map, create, bagHandle, logIn } from 'ionicons/icons';
import { checkAppVersion } from '../utils/utils.ts';
import CountryDropBox from '../components/CountryDropBox.vue';
import LatticeLoading from '../components/LatticeLoading.vue'
import audioMixin from '../mixins/audioMixin.ts'
import { useBackButton } from '@ionic/vue';
import debounce from 'lodash/debounce';
import LaunchButton from '../components/LaunchButton.vue';
import { pushNotification } from '../utils/pushNotification.ts';
import { Capacitor } from '@capacitor/core';
import { hapticsImpactLight } from '../utils/haptics.ts'
import { fetchVngComp } from "../utils/latticeUtils.ts";

export default {
    mixins: [audioMixin],
    components: {
        ProgressSelect,
        Streak,
        StreakPopover,
        GlobalIcon,
        TermsModal,
        NodeModal,
        SsoModal,
        Questlinemodal,
        LatticeLoading,
        Feedback,
        // FriendsModal,
        PhaserLoader,
        UserPersonalizationModal,
        LoginModal,
        SignupModal,
        PasswordResetModal,
        OutOfDateModal,
        PatchNotesModal,
        SubscriptionModal,
        NotiToast,
        SignupActionModal,
        CountryDropBox,
        WelcomeModal,
        LaunchButton
    },
    setup() {
        const vNGComp = ref<vNG.Instance>();
        const nStore = useNodeStore();
        const uStore = useUserStore();
        const fStore = useFriendStore();
        const mStore = useModalStore();
        const gStore = useGuestStore();
        const kqStore = useKeywordQuestionStore();
        const vngStore = useVngStore();
        useBackButton(100, () => {
            return Promise.resolve(undefined);
        })

        return {
            vNGComp, nStore, uStore, fStore, gStore, people,
            mStore, kqStore, vngStore, menu, arrowForward, logIn,
            closeCircleOutline, brush, map, create, bagHandle
        }
    },
    data() {
        return {
            hasTutorialShown: false,
            friendRequests: 0,
            layers: {
                background: "base",
                badge: "nodes",
            },
            clickCount: 0,
            startupDelay: 750,
            hasLaunched: false,
        }
    },
    methods: {
        goToGuestNode() {
            this.gStore.submitActivity("node_opened", this.nStore.availableNodes[this.nStore.selectedNodeID].name);
            hapticsImpactLight();
            this.vngStore.ship.animateDustClouds();
            this.vngStore.ship.startOrbitingTarget(
                this.nStore.layouts.nodes[this.nStore.selectedNodeID].x,
                this.nStore.layouts.nodes[this.nStore.selectedNodeID].y,
                this.nStore.selectedNodeID
            ).then(() => {
                setTimeout(() => {
                    this.mStore.toggleNode(true);
                    this.hasLaunched = true;
                }, 500);
            });
        },
        goToNode(nextButton = false) {
            if (!nextButton) {
                this.mStore.toggleNodeModal(false)
            }
            if (this.selectedNodeSceneFolder != '' || this.selectedNodeSceneFolder != undefined) {
                if (this.nStore.selectedNodeID == 0) {
                    this.nStore.updateSelectedNodeID(this.uStore.user?.currentnode);
                }
                this.mStore.toggleNode(true);
            }
            if (this.isGuest) {
                this.gStore.submitActivity("node_opened", this.nStore.availableNodes[this.nStore.selectedNodeID].name);
            }
        },
        isSelectedNode(nodeId: number) {
            return this.vngStore.selectedNodes.map(String).includes(nodeId.toString());
        },
        nodeHasSceneFolder(nodeId: number) {
            const sceneFolder = this.nStore.availableNodeContent[nodeId]?.scene_folder;
            return !!sceneFolder;
        },
        fetchLattice(initialDelay = false, recenter = true) {
            this.kqStore.fetchLatestKQs();
            this.nStore.getLatticeData().then(() => {
                if (recenter) {
                    setTimeout(() => {
                        this.vngStore.centerNode(false, this.uStore.user?.currentnode);
                    }, initialDelay ? 1750 : 0);
                }
            }).catch((error: any) => {
                console.error("Error fetching lattice data:", error);
            });
        },
        async fetchQuestlineProgress() {
            await this.uStore.updateQuestlineDetails()
        },
        debounceSaveLayouts: debounce(async function (layouts) {
            //@ts-ignore
            this.nStore.updatelayouts(layouts);
        }, 1250),
        resetgStore() {
            console.log("Click count: ", this.clickCount);
            if (this.clickCount !== 4) {
                this.clickCount++;
            } else {
                console.log("Resetting guest store");
                this.clickCount = 0;
                this.gStore.clear();
                this.nStore.$reset();
                this.gStore.setMockLattice();
            }
        },
        getNumberAfterHyphen(edgeId: string): number | null {
            const match = edgeId.match(/-(\d+)/);
            return match ? Number(match[1]) : null;
        },
        assignEdgeOpacity(): void {
            this.$nextTick(() => {
                const edges = document.querySelectorAll('.v-ng-layer-edges.v-ng-graph-objects .v-ng-edge');
                edges.forEach(edge => {
                    const edgeId = edge.getAttribute('data-edge-id');
                    const numberAfterHyphen = this.getNumberAfterHyphen(edgeId);
                    if (numberAfterHyphen !== null) {
                        const targetHasSceneFolder = this.nodeHasSceneFolder(numberAfterHyphen);
                        edge.style.setProperty('--edge-initial-opacity', targetHasSceneFolder ? '1' : '0.25');
                    }
                });
            });
        }
    },
    watch: {
        'nStore.layouts': {
            handler: function (newValue) {
                if (this.nStore.layouts !== newValue) {
                    this.debounceSaveLayouts.call(this, newValue);
                }
            }
        },
        'nStore.availableEdges': {
            handler: function () {
                this.assignEdgeOpacity();
            }
        },
        'fStore.incomingRequests': {
            deep: true,
            handler: async function (newValue) {
                this.friendRequests = newValue.length;
            },
        },
        // deselect nodes when route changes to avoid vng crash
        $route(to, from) {
            if (from.name === 'dashboard' && this.isGuest) { // User must have logged out
                this.nStore.updateSelectedNodeID(-5);
                this.hasLaunched = false;
            }
            this.vngStore.resetSelectedNodes();
        }
    },
    computed: {
        hasAccess() {
            return this.uStore.hasAccess
        },
        introArrow() {
            const isLoggedInUser = Object.keys(this.nStore.availableNodes).length === 1;
            const isGuestUser = this.isGuest && this.gStore.completedGuestNode === false;

            return isLoggedInUser || isGuestUser;
        },
        selectedNodeSceneFolder(): string | undefined {
            const nodeContent = this.nStore.availableNodeContent[this.nStore.selectedNodeID];
            return nodeContent ? nodeContent.scene_folder : undefined;
        },
        isGuest() {
            return !this.uStore.loggedIn;
        },
        anyNodeSelected() {
            return this.vngStore.selectedNodes.length > 0;
        },
        edgeOpacity() {
            return this.anyNodeSelected ? 0.25 : 1;
        },
        nodeStatuses() {
            const statuses = {};
            Object.keys(this.nStore.availableNodes).forEach((nodeId) => {
                statuses[nodeId] = this.nStore.getNodeStatus(nodeId);
            });
            return statuses;
        },
        moonVisibility() {
            return this.isGuest ? 'visible' : 'hidden';
        }
    },
    async created() {
        if (Capacitor.isNativePlatform()) {
            checkAppVersion();
            pushNotification.register();
        }
        if (this.gStore.showVersionUpdateModal) {
            setTimeout(() => {
                this.mStore.togglePatchNotesModal(true);
            }, this.startupDelay);
        }
        if (!this.isGuest && this.uStore.user) {
            // user is logged in
            this.uStore.updateUserDetailsAndPrefs();
            if (this.fStore.incomingRequests.length > 0) {
                this.friendRequests = this.fStore.incomingRequests.length
            }
            this.uStore.startUpdateUserInterval();
            this.fetchQuestlineProgress();
        } else {
            // guest user
            await this.gStore.setMockLattice();
            this.nStore.updateSelectedNodeID(-5)
        }
    },
    mounted() {
        // Pass VngComp and ref to store
        this.$nextTick(async () => {
            try {
                await fetchVngComp(this.vNGComp, (vngComp, graphElement, svgLatticeElement) => {
                    useVngStore().setVngComp(vngComp, graphElement, svgLatticeElement);
                }
                );
                this.assignEdgeOpacity();
            } catch (error) {
                console.error("Failed to initialize VngComp:", error);
            }
        });
    },
}
</script>

<style scoped>
.guest-toolbar {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}

.country-container {
    float: right;
    margin-right: 1rem;
    margin-top: 2.5rem;
    pointer-events: none;
    overflow: visible !important;
    contain: none !important;
}

[dir="rtl"] .country-container {
    float: left;
    margin-left: 1rem;
    margin-top: 2.5rem;
    margin-right: 0;
}

.graph-container {
    width: 100%;
    height: 100vh;
    position: absolute;
}

.graph {
    width: 100%;
    height: 100vh;
    background: radial-gradient(100% 100% at 50% 100%, #6161BA 0%, #2C2C54 100%);
}

ion-toolbar {
    --background: transparent;
    --ion-color-base: transparent !important;
    pointer-events: auto;
    overflow: visible !important;
    contain: none !important;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.button-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 2rem;
    z-index: 1;
    gap: 15px;
    pointer-events: none;
}

.shadow-container {
    position: absolute;
    width: 100%;
    height: 21%;
    bottom: 0;
    background-color: black;
    z-index: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0.5) 80%, rgba(0, 0, 0, .75) 100%);
    pointer-events: none;
}

.continue-overlay {
    height: 3rem;
    margin-bottom: 1rem;
    width: 85%;
    max-width: 1100px;
}

.center-button {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;
}

.continue-overlay,
.recenter-button {
    text-align: center;
    pointer-events: auto;
}

.index-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;
    width: 95vw;
}

.index-container ion-button {
    flex-grow: 1;
}

.auth-container {
    display: inline-flex;
    margin-bottom: -.5rem;
    width: 23em;
}

.launch-container {
    margin-bottom: 1.75em;
}

.launch-button {
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
    --su-color-bg: #6964ff50;
    --su-color-progress-normal-bg: #6964ff50;
    --su-color-progress-complete-bg: #6964ff50;
    --su-color-progress-normal-fg: rgba(255, 255, 255, 0.80);
    --su-color-text-normal: white;
    --su-color-text-success: white;
    --su-color-text-error: white;
    animation: popInAnimationFtu 3.5s ease-out forwards;
    border-radius: 16px;
}

.signup-button {
    font-size: 1.05 rem;
    pointer-events: auto;
}

.login-button {
    --background: rgba(0, 0, 0, 0.15);
    --background-activated: rgba(0, 0, 0, 0.2);
    --box-shadow: none;
    pointer-events: auto;
    transition: opacity 0.3s ease-in-out;
}

.terms-link {
    margin-bottom: .75rem;
    font-size: 0.8rem;
    color: white;
    pointer-events: auto;
}

.button-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.recenter-button {
    --background: #3A6B8C;
    --color: white;
    --border-radius: 12px;
    --box-shadow: none;
    --padding-start: 1.25rem;
    --padding-end: 1.25rem;
    --padding-top: 0.25rem;
    --padding-bottom: 0.25rem;
    width: 6rem;
}

.logo-text-container {
    position: absolute;
    width: 100%;
    pointer-events: none;
    overflow: visible !important;
    contain: none !important;
}

.logo-image {
    max-height: 2.5rem;
    margin-top: 2rem;
    margin-left: 1rem;
    pointer-events: auto;
}

[dir="rtl"] .logo-image {
    margin-left: 0;
    margin-right: 1rem;
}

ion-modal {
    backdrop-filter: blur(1px);
}

.face-circle,
.face-picture {
    transition: all 0.1s linear;
}

.available-pulse {
    animation: available-pulse 4s ease-out infinite;
    pointer-events: none;
    animation-timing-function: ease-out;
}

@keyframes available-pulse {

    0% {
        opacity: 1;
        transform: scale(1);
    }


    100% {
        opacity: 0;
        transform: scale(1.75);
    }
}

.face-picture {
    pointer-events: none;
}

.pop-in {
    animation: popInAnimation .75s ease-out forwards;
}

@keyframes popInAnimation {
    0% {
        transform: scale(0);
    }

    75% {
        transform: scale(1.15);
    }

    100% {
        transform: scale(1);
    }
}

.pop-in-guest {
    animation: popInAnimationFtu 2.75s ease-out forwards;
}

@keyframes popInAnimationFtu {

    0%,
    60% {
        transform: scale(0);
    }

    80% {
        transform: scale(1.15);
    }

    100% {
        transform: scale(1);
    }
}

.fade-in {
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    background-color: #000000;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
        background-color: #000000;
    }

    100% {
        opacity: 1;
        background-color: #000000;
    }
}

.badge-group {
    pointer-events: none;
}

.badge-image {
    pointer-events: none;
}

.badge-wiggle {
    animation: wiggle 3s cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
    transform-origin: 50% 50%;
    transform-box: content-box;
    width: 1.5rem;
    height: 1.5rem;
}

@keyframes wiggle {

    0%,
    100% {
        transform: rotate(-10deg);
        scale: 1;
    }

    50% {
        transform: rotate(10deg);
        scale: 1.2;
    }
}

.star-animation {
    animation: starAnimation 2s ease-in-out infinite;
    transition: opacity 0.3s ease;
    transform-box: content-box;
    transform-origin: 50% 50%;
}

@keyframes starAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(2);
    }

    100% {
        transform: scale(1);
    }
}

.v-ng-layer-edges .v-ng-graph-objects .v-ng-line-background {
    z-index: -1;
    pointer-events: none;
}

.phaser-container {
    width: 100%;
    height: 100%;
    background-color: transparent;
}

a {
    color: #3A6B8C;
    text-decoration: underline;
}


.node-label {
    transition: opacity 0.3s ease, font-size 0.3s ease, y 0.3s ease;
}

.pointer-arrow {
    transition: opacity 0.3s ease;
}

svg text {
    clip-path: inset(-5px -5px -5px -5px round 16px);
}

.v-network-graph :deep(.v-ng-edge) {
    filter: opacity(clamp(0.25, calc(var(--edge-initial-opacity, 1) * var(--global-edge-opacity, 1)), 1));
    transition: filter 0.3s ease;
}

.v-network-graph :deep(.v-ng-viewport.svg-pan-zoom_viewport>#moon) {
    visibility: v-bind(moonVisibility);
}
</style>