<template>
    <div class="ion-margin">
        <div v-if="!isLoading">
            <h3 class="center-text">{{ $t('home.signup.details.header') }}</h3>
            <p class="ion-padding-top"><b>{{ $t('home.signup.details.name') }}</b></p>
            <ion-input type="text" :placeholder="$t('home.signup.details.name_placeholder')" clearInput
                class="ion-margin-top ion-margin-bottom custom" v-model="fullName" @input="parseName"></ion-input>
            <p class="ion-padding-top"><b>{{ $t('home.signup.details.password.title') }}</b></p>
            <PasswordInput @password-validation="passwordPassed = $event" @update:password="password = $event">
            </PasswordInput>
            <div class="button-container ion-padding">
                <ion-button class="prev-button" fill="outline" color="primary" @click="nextSlide(-1)">
                    <GlobalIcon :iconName="$i18n.locale === 'ar' ? arrowForwardOutline : arrowBackOutline"
                        :color="'dark'" :size="'medium'"></GlobalIcon>
                </ion-button>
                <ion-button class="next-button" expand="block" color="primary" @click="nextSlide(1)"
                    :disabled="!allFieldsFilled">Next</ion-button>
            </div>
        </div>
        <div v-else class="ion-margin">
            <h1 class="center-text">{{ $t('home.signup.details.signup.header') }}</h1>
            <ion-text class="center-text">{{ $t('home.signup.details.signup.text') }}</ion-text>
            <Loading />
        </div>
    </div>
</template>

<script>
import { useGuestStore } from '@/store/guestStore';
import GlobalIcon from '../GlobalIcon.vue';
import PasswordInput from './PasswordInput.vue';
import { arrowBackOutline, arrowForwardOutline } from 'ionicons/icons';

const gStore = useGuestStore();

export default {
    name: 'DetailsSlide',
    emits: ['nextSlide', 'createAccount'],
    components: {
        GlobalIcon,
        PasswordInput
    },
    data() {
        return {
            password: '',
            fullName: '',
            first: '',
            last: '',
            swiper: null,
            passwordPassed: false,
            isLoading: false
        }
    },
    setup() {
        return {
            arrowBackOutline,
            arrowForwardOutline
        };
    },
    methods: {
        parseName() {
            const names = this.fullName.trim().split(' ');
            this.first = names[0] || '';
            this.last = names.slice(1).join(' ') || 'Unknown';
        },
        nextSlide(direction) {
            if (direction === -1) return this.swiper.slidePrev()
            gStore.updateUserSignupData({ "first_name": this.first, "last_name": this.last, "password": this.password });
            this.signupUser()
        },
        async signupUser() {
            this.isLoading = true;
            try {
                gStore.signupUser(false);
            } catch (err) {
                console.log(err);
            } finally {
                this.swiper.slideNext()
                this.isLoading = false;
            }
        }
    },
    computed: {
        allFieldsFilled() {
            return !!this.first && !!this.last && !!this.passwordPassed;
        }
    },
    mounted() {
        this.swiper = useSwiper()
    },
}
</script>

<style scoped>
.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: 1;
    gap: 10px;
}

.next-button,
.prev-button {
    height: 1rem;
}

.next-button {
    width: calc(75% - 5px);
}

.prev-button {
    aspect-ratio: 1 / 1;
    width: auto;
    max-width: calc(25% - 5px);
}
</style>