<template>
    <ion-modal :isOpen="isOpen" @didPresent="modalDidOpen" :backdrop-dismiss="false">
        <ion-header>
            <ion-toolbar>
                <ion-title>Acknowledgement</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content :forceOverscroll="false">
            <div class="terms-container ion-padding-top">
                <div class="version ion-text-center ion-padding-bottom">
                    Version: {{ termsAndConditions.major_version }}.{{ termsAndConditions.minor_version }}
                </div>
                <div class="content ion-padding">
                    <IonText v-html="termsAndConditions.content"></IonText>
                </div>
                <loading v-if="isLoading" />
                <IonText v-if="errorOccured" class="ion-text-center" color="danger">An error occurred</IonText>
            </div>
        </ion-content>
        <ion-footer>
            <ion-button expand="block ion-margin" @click="acceptTerms()" :disabled="isLoading">I Agree</ion-button>
        </ion-footer>
    </ion-modal>
</template>

<script>
import { useUserStore } from '../store/userStore'
import { useModalStore } from '../store/modalStore';
import loading from './loading.vue';

export default {
    components: {
        loading
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            canDismiss: true,
            errorOccured: false,
            isLoading: true,
            termsAndConditions: {
                content: '',
                major_version: 0,
                minor_version: 0
            },
        };
    },
    methods: {
        modalDidOpen() {
            this.fetchTerms();
        },
        async fetchTerms() {
            const uStore = useUserStore();
            try {
                const res = await $fetch(this.$config.public.API_BASE_URL + `auth/terms_and_conditions/get_latest_version/`, {
                    headers: new Headers({
                        'Authorization': 'Bearer ' + uStore.accessToken,
                    }),
                    method: 'GET',
                });
                this.termsAndConditions = res;
                this.isLoading = false;
            } catch (error) {
                console.error("An error occurred while fetching terms:", error);
                this.errorOccured = true;
            }
        },
        async acceptTerms() {
            const uStore = useUserStore();
            const mStore = useModalStore();
            try {
                const response = await $fetch(this.$config.public.API_BASE_URL + `auth/terms_and_conditions/accept_terms/`, {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + uStore.accessToken,
                    }),
                    body: JSON.stringify({
                        major_version: this.termsAndConditions.major_version,
                        minor_version: this.termsAndConditions.minor_version
                    })
                });
                if (response.message && response.message === "Terms and conditions accepted successfully.") {
                    uStore.setAcceptedTerms(true);
                    uStore.updateUserDetailsAndPrefs();
                    mStore.toggleTermsModal(false);
                } else {
                    console.error("Error accepting terms:", response.error || "Passing user for now.");
                }
            } catch (error) {
                uStore.setAcceptedTerms(true);
                uStore.updateUserDetailsAndPrefs();
                mStore.toggleTermsModal(false);
                console.error("An error occurred while setting terms acceptance:", error);
                this.errorOccured = true;
            }
        }
    }
}
</script>

<style scoped>
.terms-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 20px;
    overflow: hidden;
}

.version {
    font-size: 0.9rem;
    color: #888;
}

.content {
    flex-grow: 1;
    padding: 15px;
    border-radius: 5px;
    font-size: 0.9rem;
    line-height: 1.4;
    overflow-y: auto;
}
</style>