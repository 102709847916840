class AssetManager {
    constructor() {
        // TODO: This is breaking hot reload :/
        this.assets = new Map();
        this.atlases = new Map();
        this.spritesheets = new Map();
        this.audio = new Map();
    }

    loadAsset(scene, key, url) {
        // if (this.assets.get(key) === "loaded") {
        //     return Promise.resolve();
        // }
        const promise = new Promise((resolve, reject) => {
            scene.load.once("filecomplete-image-" + key, () => {
                // this.assets.set(key, "loaded");
                resolve();
            });
        });
        scene.load.image(key, url);

        return promise;
    }

    /**
     *
     * @param {*} scene
     * @param {*} key
     * @param {*} url
     * @param {*} config - define size by scale or with width & height values: { scale: 1 } / { width: 300, height: 300 }
     */
    loadSVG(scene, key, url, config = undefined) {
        // if (this.assets.get(key) === "loaded") {
        //     return Promise.resolve();
        // }
        scene.load.svg(key, url, config);

        return new Promise((resolve, reject) => {
            scene.load.once("filecomplete-svg-" + key, () => {
                // this.assets.set(key, "loaded");
                resolve();
            });
        });
    }

    loadAtlas(scene, key, imageUrl, configUrl) {
        // if (this.atlases.get(key) === "loaded") {
        //     return Promise.resolve();
        // }
        scene.load.atlas(key, imageUrl, configUrl);

        return new Promise((resolve, reject) => {
            scene.load.once("filecomplete-atlas-" + key, () => {
                // this.atlases.set(key, "loaded");
                resolve();
            });
        });
    }

    loadSpritesheet(scene, key, imageUrl, config) {
        // if (this.spritesheets.get(key) === "loaded") {
        //     return Promise.resolve();
        // }
        scene.load.spritesheet(key, imageUrl, config);

        return new Promise((resolve, reject) => {
            scene.load.once("filecomplete-spritesheet-" + key, () => {
                // this.spritesheets.set(key, "loaded");
                resolve();
            });
        });
    }

    loadAudio(scene, key, urls) {
        // if (this.audio.get(key) === "loaded") {
        //     return Promise.resolve();
        // }
        scene.load.audio(key, urls);

        return new Promise((resolve, reject) => {
            scene.load.once("filecomplete-audio-" + key, () => {
                // this.audio.set(key, "loaded");
                resolve();
            });
        });
    }
}

export const assetManager = new AssetManager();
