import Phaser from "phaser";
import {
    FlashCard,
    FlashCardEvent,
    type FlashCardConfig
} from "./widgets/FlashCard";
import {
    EASINGS,
    asyncAnimation,
    getBackgroundColor,
    getCurrentLanguage,
    getTextColor,
    hexNumberToString,
    isBetween,
    loadBucketAssets,
    loadBucketAudio,
    wait
} from "../../utils/utils";
import { Spaceship } from "./widgets/Spaceship";
import { GreatLibraryBaseScene } from "./GreatLibraryBaseScene";
import {
    GreatLibrarySceneKey,
    GreatLibraryScenesManager
} from "./GreatLibraryScenesManager";
import glText from "../../local_text/greatLibrary.json";

export interface GreatLibraryFlashCardsSceneConfig {
    flashCardsConfig: FlashCardConfig[];
}

class GreatLibraryFlashCardsScene extends GreatLibraryBaseScene {
    private background: Phaser.GameObjects.Rectangle;
    private topLight: Phaser.GameObjects.Image;
    private bottomLight: Phaser.GameObjects.Image;
    private negativeText: Phaser.GameObjects.Text;
    private positiveText: Phaser.GameObjects.Text;

    private remainingQuestionsQueue: FlashCardConfig[];
    private answeredQuestionsQueue: FlashCardConfig[];

    private moveCardBackAnimationTween?: Phaser.Tweens.Tween;

    constructor() {
        super(GreatLibrarySceneKey.FlashCards);
    }

    public preload() {}

    public init() {}

    public static preloadAssets(scene: Phaser.Scene) {
        Spaceship.preloadAssets(scene);
        loadBucketAssets(scene, {
            folderPath: ["GreatLibrary", "Assets"],
            keyList: ["flag"]
        });
        loadBucketAssets(scene, {
            folderPath: ["General", "Masks"],
            keyList: ["edgeLight"]
        });
        loadBucketAudio(scene, {
            folderPath: ["Quizes", "Audio", "Optimized"],
            keyList: ["claps", "confetti_gun", "win"]
        });
    }

    public override create(data: GreatLibraryFlashCardsSceneConfig) {
        super.create();
        this.remainingQuestionsQueue = data.flashCardsConfig;
        this.answeredQuestionsQueue = [];

        this.initializeBackground();
        this.initializeTopLight();
        this.initializeBottomLight();
        this.initializeAgainText();
        this.initializeGoodToGoText();
        this.getUiScene().initializeSpaceshipProgressBar(
            data.flashCardsConfig.length
        );

        this.tryCreateNextCard();

        this.bindEventHandlers();
    }

    protected handleUiSceneHomeButtonPressed(): void {
        if (!this.scene.isActive()) {
            return;
        }
        this.getUiScene().destroySpaceshipProgressBar();
        GreatLibraryScenesManager.changeScene(
            GreatLibrarySceneKey.FlashCardsOptions,
            true
        );
    }

    private bindEventHandlers(): void {}

    private initializeBackground(): void {
        this.background = this.add
            .rectangle(
                this.scale.width * 0.5,
                this.scale.height * 0.5,
                this.scale.width,
                this.scale.height,
                getBackgroundColor()
            )
            .setInteractive();
    }

    private initializeTopLight(): void {
        this.topLight = this.add
            .image(0, 0, "edgeLight")
            .setOrigin(0, 0)
            .setDepth(2)
            .setDisplaySize(this.scale.width, this.scale.height)
            .setTint(0x2ecc71)
            .setAlpha(0);
    }

    private initializeBottomLight(): void {
        this.bottomLight = this.add
            .image(0, this.scale.height, "edgeLight")
            .setOrigin(0, 1)
            .setDepth(2)
            .setFlipY(true)
            .setDisplaySize(this.scale.width, this.scale.height)
            .setTint(0xf1c40f)
            .setAlpha(0);
    }

    private initializeAgainText(): void {
        this.negativeText = this.add
            .text(
                this.scale.width * 0.5,
                this.scale.height - 200,
                glText[getCurrentLanguage()].flashCards.oneMoreTime,
                {
                    fontFamily: "Work Sans",
                    fontSize: 60,
                    color: hexNumberToString(getTextColor())
                }
            )
            .setOrigin(0.5)
            .setAlpha(0)
            .setDepth(2);
    }

    private initializeGoodToGoText(): void {
        this.positiveText = this.add
            .text(
                this.scale.width * 0.5,
                200,
                glText[getCurrentLanguage()].flashCards.iknowThis,
                {
                    fontFamily: "Work Sans",
                    fontSize: 60,
                    color: hexNumberToString(getTextColor())
                }
            )
            .setOrigin(0.5)
            .setAlpha(0)
            .setDepth(2);
    }

    private tryCreateNextCard(): void {
        const config = this.remainingQuestionsQueue.shift();
        if (config) {
            this.createNextQuestionFlashCard(config);
        } else {
            this.handleGameFinished();
        }
    }

    private async handleGameFinished(): Promise<void> {
        this.getUiScene().emitConfetti(3000);
        this.playSound("confetti_gun", { volume: 0.1 });
        await wait(500);
        this.playSound("claps", { volume: 0.1 });
        await wait(3000);
        this.playSound("win", { volume: 0.3 });
        await wait(1000);
        this.getUiScene().destroySpaceshipProgressBar();
        GreatLibraryScenesManager.changeScene(
            GreatLibrarySceneKey.FlashCardsOptions,
            true
        );
    }

    private createNextQuestionFlashCard(config: FlashCardConfig): void {
        const card = new FlashCard(
            this,
            this.scale.width * 1.5,
            this.scale.height * 0.5,
            config
        );
        this.tweens.add({
            targets: card,
            x: this.scale.width * 0.5,
            y: this.scale.height * 0.5,
            duration: 500,
            ease: EASINGS.BackEaseOut
        });
        card.once(FlashCardEvent.Revealed, () => {
            card.disableRotation();
            this.bindCardEventHandlers(card, config);
        });
    }

    private bindCardEventHandlers(
        card: FlashCard,
        config: FlashCardConfig
    ): void {
        card.on(
            FlashCardEvent.DragStart,
            (pointer: Phaser.Input.Pointer, x: number, y: number) => {
                this.moveCardBackAnimationTween?.stop();
                this.tweens.add({
                    targets: [this.positiveText, this.negativeText],
                    alpha: 0.5,
                    duration: 250,
                    ease: EASINGS.CubicEaseIn
                });
            }
        );
        card.on(
            FlashCardEvent.DragEnd,
            async (pointer: Phaser.Input.Pointer, x: number, y: number) => {
                this.playResetLightsAnimation();
                if (
                    isBetween(
                        card.y,
                        this.scale.height * 0.3,
                        this.scale.height * 0.7
                    )
                ) {
                    this.playMoveCardBackToCenterAnimation(card);
                    return;
                }
                const markedAsDone = card.y < this.scale.height * 0.3;
                await this.playMoveCardAnimation(card, markedAsDone);
                if (markedAsDone) {
                    this.answeredQuestionsQueue.push(config);
                    this.getUiScene().progressSpaceshipProgressBar();
                } else {
                    this.remainingQuestionsQueue.push(config);
                }

                this.tryCreateNextCard();
            }
        );
        card.on(
            FlashCardEvent.Drag,
            (pointer: Phaser.Input.Pointer, dragX: number, dragY: number) => {
                card.x =
                    this.scale.width * 0.5 +
                    Phaser.Math.Easing.Cubic.Out(Math.abs(dragX) / 500) *
                        (dragX > 0 ? 100 : -100);
                card.y = this.scale.height * 0.5 + dragY;

                this.handleLightsActivation(card.y);
            }
        );
    }

    private handleLightsActivation(posY: number): void {
        if (posY < this.scale.height * 0.5) {
            const fraction =
                (this.scale.height * 0.5 - posY) / (this.scale.height * 0.5);
            this.topLight.setAlpha(fraction);
            this.positiveText.setAlpha(0.5 + fraction);
        } else {
            const fraction =
                (posY - this.scale.height * 0.5) / (this.scale.height * 0.5);
            this.bottomLight.setAlpha(fraction);
            this.negativeText.setAlpha(0.5 + fraction);
        }
    }

    private playResetLightsAnimation(): void {
        this.tweens.add({
            targets: [
                this.topLight,
                this.bottomLight,
                this.positiveText,
                this.negativeText
            ],
            alpha: 0,
            duration: 250,
            ease: EASINGS.CubicEaseOut
        });
    }

    private playMoveCardBackToCenterAnimation(card: FlashCard): void {
        this.moveCardBackAnimationTween?.stop();
        this.moveCardBackAnimationTween = this.tweens.add({
            targets: card,
            x: this.scale.width * 0.5,
            y: this.scale.height * 0.5,
            duration: 250,
            ease: EASINGS.BackEaseOut
        });
    }

    private async playMoveCardAnimation(
        card: FlashCard,
        up = true
    ): Promise<void> {
        this.moveCardBackAnimationTween?.stop();
        await asyncAnimation(
            this.tweens.add({
                targets: card,
                y: this.scale.height * (up ? -0.5 : 1.5),
                duration: 250,
                ease: EASINGS.CubicEaseOut,
                onComplete: (tween) => {
                    tween.emit("done");
                }
            })
        );
    }
}

export default GreatLibraryFlashCardsScene;
