import {
    GraphicsButton,
    type GraphicsButtonConfig
} from "../ui/buttons/GraphicsButton";
import { COLORS, hexNumberToString, TEXT_FONT } from "../utils";

export interface NextNodeButtonConfig extends GraphicsButtonConfig {
    topText?: string;
}

export class NextNodeButton extends GraphicsButton {
    private topText?: Phaser.GameObjects.Text;

    private nodeId: number;

    constructor(
        scene: Phaser.Scene,
        x: number,
        y: number,
        nodeId: number,
        config: Partial<NextNodeButtonConfig>
    ) {
        super(scene, x, y, config);
        this.nodeId = nodeId;

        this.tryInitializeTopText(config.topText);
    }

    public getNodeId(): number {
        return this.nodeId;
    }

    private tryInitializeTopText(topText?: string): void {
        if (topText) {
            this.topText = this.scene.add
                .text(0, -this.displayHeight * 0.5 + 10, topText, {
                    fontFamily: TEXT_FONT.WorkSans,
                    fontSize: 30,
                    color: hexNumberToString(COLORS.DARK_MODE.TEXT)
                })
                .setOrigin(0.5, 0);
            this.add(this.topText);

            if (this.text) {
                this.text.y += 20;
            }
        }
    }
}
