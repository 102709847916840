import { useUserStore } from "../../../store/userStore";
import { asyncAnimation } from "../utils";

export class AudioManager {
    private sounds: { [key: string]: Phaser.Sound.BaseSound[] };
    private oldMusic?: Phaser.Sound.BaseSound;
    private music?: Phaser.Sound.BaseSound;
    private audioMuted: boolean;
    constructor() {
        /**
         * Each key is a sceneKey and the value is an array of currently playing sounds
         */
        this.sounds = {};
        this.music = undefined;
        this.oldMusic = undefined;

        this.audioMuted = false;
    }

    muteAudio(scene: Phaser.Scene, value: boolean) {
        this.audioMuted = value;
        scene.sound.mute = value;
    }

    public async playMusic(
        scene: Phaser.Scene,
        key: string,
        config: Phaser.Types.Sound.SoundConfig
    ) {
        try {
            if (this.music?.key === key) {
                console.warn("trying to play the same music key. Aborting");
                return;
            }
            if (this.music) {
                this.stopMusic(scene, 500);
            }

            if (
                useUserStore().userPrefs &&
                useUserStore().userPrefs?.sound === false
            )
                return;
            this.music = scene.sound.add(key, config);
            scene.tweens.addCounter({
                from: 0,
                to: config.volume,
                duration: 5000,
                onUpdate: (tween) => {
                    this.music.volume = tween.getValue();
                }
            });
            this.music.play();

            return this.music;
        } catch (error) {
            console.warn(error);
            this.music?.stop();
            this.music?.destroy();

            if (
                useUserStore().userPrefs &&
                useUserStore().userPrefs?.sound === false
            )
                return;

            this.music = scene.sound.add(key, config);
            this.music.play();
        }
    }

    async stopMusic(scene: Phaser.Scene, duration = 0) {
        if (duration === 0) {
            this.music?.stop();
            return;
        }
        if (!this.music || !scene) {
            return;
        }
        try {
            this.oldMusic = this.music;
            scene.tweens.add({
                targets: this.oldMusic,
                volume: 0,
                duration: duration,
                onComplete: (tween) => {
                    this.oldMusic?.stop();
                    this.oldMusic?.destroy();
                }
            });
        } catch (error) {
            console.warn(error);
        }
    }

    pauseMusic() {
        if (this.music) {
            this.music.pause();
        }
    }

    resumeMusic() {
        if (this.music) {
            this.music.resume();
        }
    }

    play(
        scene: Phaser.Scene,
        key: string,
        config: Phaser.Types.Sound.SoundConfig
    ) {
        const sceneKey = scene.scene.key;
        if (scene.scene.isSleeping(sceneKey)) {
            return;
        }

        if (!this.sounds[sceneKey]) {
            this.sounds[sceneKey] = [];
        }

        if (
            useUserStore().userPrefs &&
            useUserStore().userPrefs?.sound === false
        )
            return;

        const sound = scene.sound.add(key, config);
        this.sounds[sceneKey].push(sound);

        sound.once("complete", () => {
            this.sounds[sceneKey] = this.sounds[sceneKey].filter(
                (s) => s !== sound
            );
            sound.destroy();
        });
        sound.play();
        return sound;
    }

    pause(scene: Phaser.Scene, key: string) {
        const sceneKey = scene.scene.key;

        if (!this.sounds[sceneKey]) {
            return;
        }

        this.sounds[sceneKey].forEach((sound) => {
            if (sound.key === key) {
                sound.pause();
            }
        });
    }

    stop(scene: Phaser.Scene, key: string) {
        const sceneKey = scene.scene.key;

        if (!this.sounds[sceneKey]) {
            return;
        }

        this.sounds[sceneKey].forEach((sound) => {
            if (sound.key === key) {
                sound.stop();
                sound.destroy();
            }
        });

        this.sounds[sceneKey] = this.sounds[sceneKey].filter(
            (sound) => sound.key !== key
        );
    }

    stopAllSceneSounds(scene: Phaser.Scene) {
        const sceneKey = scene.scene.key;

        if (!this.sounds[sceneKey]) {
            return;
        }

        this.sounds[sceneKey].forEach((sound) => {
            sound.stop();
            sound.destroy();
        });

        this.sounds[sceneKey] = [];
    }

    isAudioMuted() {
        return this.audioMuted;
    }

    isSoundPlaying(scene: Phaser.Scene, key: string) {
        const sceneKey = scene.scene.key;

        if (!this.sounds[sceneKey]) {
            return false;
        }

        return this.sounds[sceneKey].some((sound) => sound.key === key);
    }
}
