import { useKeywordQuestionStore } from "../../../../store/keywordQuestionStore";
import { useNodeStore } from "../../../../store/nodeStore";
import { TexturesHelper } from "../../../utils/TexturesHelper";
import {
    getTextColor,
    hexNumberToString,
    TEXT_FONT
} from "../../../utils/utils";
import {
    GreatLibraryCheckButton,
    GreatLibraryCheckButtonEvent
} from "./GreatLibraryCheckButton";

export enum NodesChecklistEvent {
    Changed = "changed"
}

export interface NodesChecklistConfig {
    resource: "keywords" | "questions";
    excludedNodeNames?: string[];
}

export class NodesChecklist extends Phaser.GameObjects.Container {
    private checkHeaders: Map<number, Phaser.GameObjects.Text>;
    private checkButtons: Map<number, GreatLibraryCheckButton>;
    private config: Required<NodesChecklistConfig>;
    constructor(
        scene: Phaser.Scene,
        x: number,
        y: number,
        config: NodesChecklistConfig
    ) {
        super(scene, x, y);
        this.config = {
            excludedNodeNames: ["Study Hub", "Intro", "Demo"],
            ...config
        };

        this.initializeNodesChecklist();

        this.add([
            ...this.checkButtons.values(),
            ...this.checkHeaders.values()
        ]);
        const bounds = this.getBounds();
        this.setSize(bounds.width, bounds.height);

        this.bindEventHandlers();

        this.scene.add.existing(this);
    }

    public getSelectedNodeIds(): number[] {
        const selectedNodeIds: number[] = [];
        for (const id of this.checkButtons.keys()) {
            if (this.checkButtons.get(id)?.isChecked()) {
                selectedNodeIds.push(id);
            }
        }
        return selectedNodeIds;
    }

    private initializeNodesChecklist(): void {
        this.checkButtons = new Map<number, GreatLibraryCheckButton>();
        this.checkHeaders = new Map<number, Phaser.GameObjects.Text>();

        let i = 0;
        const availableNodes = useNodeStore().availableNodes;
        // TODO: Get keys with proper type without the need of casting
        const nodesIds = Object.keys(availableNodes);
        for (const id of nodesIds) {
            const nodeId = parseInt(id);
            if (
                this.config.excludedNodeNames.includes(
                    availableNodes[nodeId].name
                )
            ) {
                continue;
            }
            if (this.config.resource === "questions") {
                if (!this.nodeHasKnownQuestions(nodeId)) {
                    continue;
                }
            } else {
                if (!this.nodeHasKnownKeywords(nodeId)) {
                    continue;
                }
            }
            this.checkHeaders.set(
                nodeId,
                this.scene.add
                    .text(
                        this.scene.scale.width * -0.35,
                        this.scene.scale.height * 0.07 * i,
                        availableNodes[nodeId].name,
                        {
                            fontFamily: TEXT_FONT.WorkSans,
                            fontSize: 34,
                            color: hexNumberToString(getTextColor()),
                            wordWrap: {
                                width: this.scene.scale.width * 0.5
                            },
                            lineSpacing: 0
                        }
                    )
                    .setOrigin(0, 0.5)
            );

            this.checkButtons.set(
                nodeId,
                new GreatLibraryCheckButton(
                    this.scene,
                    this.scene.scale.width * 0.35 - 75,
                    this.scene.scale.height * 0.07 * i
                )
            );

            const lineY = this.scene.scale.height * 0.07 * i + 50;
            this.add(
                TexturesHelper.drawLine(
                    this.scene,
                    this.scene.scale.width * -0.35,
                    this.scene.scale.width * 0.35 - 10,
                    lineY,
                    lineY,
                    2,
                    getTextColor(),
                    0.25
                )
            );

            i += 1;
        }
    }

    private bindEventHandlers(): void {
        for (const [id, button] of this.checkButtons) {
            button.on(
                GreatLibraryCheckButtonEvent.Checked,
                (checked: boolean) => {
                    this.checkHeaders.get(id)?.setAlpha(checked ? 1 : 0.5);
                    this.emit(NodesChecklistEvent.Changed);
                }
            );
        }
    }

    private nodeHasKnownKeywords(nodeId: number): boolean {
        const keywords = useKeywordQuestionStore().known_keywords;
        return keywords.some((keyword) => keyword.taught_node === nodeId);
    }

    private nodeHasKnownQuestions(nodeId: number): boolean {
        const questions = useKeywordQuestionStore().known_questions;
        return questions.some((question) => question.node === nodeId);
    }
}
