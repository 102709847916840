<template>
    <ion-modal :isOpen="isOpen" @didDismiss="closeModal" :backdropDismiss="false">
        <div class="container">
            <h1 class="center-text">{{ $t('home.signup.questline_selection.header') }}</h1>
            <ion-text class="center-text">{{ $t('home.signup.questline_selection.text') }}</ion-text>
        </div>
        <QuestlineSelection class="ion-margin" @selectedQuestline="submit($event)"></QuestlineSelection>
    </ion-modal>
</template>

<script>
import { useModalStore } from '../store/modalStore';
import { useUserStore } from '../store/userStore';
import QuestlineSelection from './signup/QuestlineSelection.vue';

const mStore = useModalStore();

export default {
    name: 'QuestlineModal',
    components: {
        QuestlineSelection
    },
    data() {
        return {
        };
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        closeModal() {
            mStore.toggleQuestlineModal(false);
        },
        async submit(event) {
            await useUserStore().updateUser({ currentquest: event });
            this.closeModal();
        }
    }
}
</script>

<style scoped>
ion-modal {
    position: absolute;
}

ion-modal::part(backdrop) {
    background: rgb(54, 55, 57);
    opacity: 1;
}

.container {
    margin-top: 2rem;
}


h1 {
    font-size: 1.25rem;
    font-weight: 500;
}

ion-text {
    font-size: 0.875rem;
    font-weight: 400;
}
</style>