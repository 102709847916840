import {
    EASINGS,
    getTertiaryColor,
    getUiDisabledColor,
    loadBucketAssets
} from "../../../utils/utils";

export enum GreatLibraryCheckButtonEvent {
    Checked = "checked"
}

export class GreatLibraryCheckButton extends Phaser.GameObjects.Container {
    protected background: Phaser.GameObjects.Graphics;
    protected circle: Phaser.GameObjects.Image;

    private pressed: boolean;
    private checked: boolean;

    private readonly ACTIVE_COLOR = getTertiaryColor();
    private readonly INACTIVE_COLOR = getUiDisabledColor();

    constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, x, y);

        this.checked = true;

        const width = 125;
        const height = 75;

        this.initializeBackground(width, height);
        this.initializeCircle(height);

        this.add([this.background, this.circle]);
        this.setSize(width, height);

        this.setInteractive({ cursor: "pointer" });

        this.bindEventHandlers();

        this.scene.add.existing(this);
    }

    public static preloadAssets(scene: Phaser.Scene) {
        loadBucketAssets(scene, {
            folderPath: ["GreatLibrary", "Assets"],
            keyList: ["circle"]
        });
    }

    public isChecked(): boolean {
        return this.checked;
    }

    private initializeBackground(width: number, height: number): void {
        this.background = this.scene.add.graphics();
        this.background.fillStyle(this.ACTIVE_COLOR, 1);
        this.background.fillRoundedRect(
            -width / 2,
            -height / 2,
            width,
            height,
            36
        );
    }

    private initializeCircle(height: number): void {
        this.circle = this.scene.add.image(25, 0, "circle").setScale(0.45);
    }

    private bindEventHandlers() {
        this.on(Phaser.Input.Events.POINTER_DOWN, () => {
            this.pressed = true;
        });
        this.on(Phaser.Input.Events.POINTER_UP, () => {
            if (this.pressed) {
                this.toggle();
                this.emit(GreatLibraryCheckButtonEvent.Checked, this.checked);
                this.pressed = false;
            }
        });
        this.on(Phaser.Input.Events.POINTER_OUT, () => {
            this.pressed = false;
        });
    }

    private toggle(): void {
        this.checked = !this.checked;

        this.background.clear();
        if (this.checked) {
            this.background.fillStyle(this.ACTIVE_COLOR, 1);
        } else {
            this.background.fillStyle(this.INACTIVE_COLOR, 1);
        }

        this.background.fillRoundedRect(
            -this.width / 2,
            -this.height / 2,
            this.width,
            this.height,
            36
        );

        this.playCircleAnimation();
    }

    private playCircleAnimation(): void {
        this.scene.tweens.add({
            targets: this.circle,
            x: this.checked ? 25 : -25,
            duration: 500,
            ease: EASINGS.ExpoEaseOut
        });
    }
}
