import { getMarginL, getMarginT, getSpacer } from "./styles";
import { assetManager } from "./assetManager";
import { useUserStore } from "../../store/userStore";
import { useGuestStore } from "../../store/guestStore";
import { Capacitor } from "@capacitor/core";
import type { Language } from "../../interfaces/user";
import { useKeywordQuestionStore } from "../../store/keywordQuestionStore";

export const bucketBaseUrl = "https://cdn.cloudcompany.ca/websrc";
export const publicBaseUrl = "";

const rootStyles = getComputedStyle(document.documentElement.childNodes[0]);

/**
 * https://rexrainbow.github.io/phaser3-rex-notes/docs/site/ease-function/
 */
export const EASINGS = {
    Linear: "Linear",
    QuadEaseIn: "Quad.easeIn",
    CubicEaseIn: "Cubic.easeIn",
    QuartEaseIn: "Quart.easeIn",
    QuintEaseIn: "Quint.easeIn",
    SineEaseIn: "Sine.easeIn",
    ExpoEaseIn: "Expo.easeIn",
    CircEaseIn: "Circ.easeIn",
    BackEaseIn: "Back.easeIn",
    BounceEaseIn: "Bounce.easeIn",
    QuadEaseOut: "Quad.easeOut",
    CubicEaseOut: "Cubic.easeOut",
    QuartEaseOut: "Quart.easeOut",
    QuintEaseOut: "Quint.easeOut",
    SineEaseOut: "Sine.easeOut",
    ExpoEaseOut: "Expo.easeOut",
    CircEaseOut: "Circ.easeOut",
    BackEaseOut: "Back.easeOut",
    BounceEaseOut: "Bounce.easeOut",
    QuadEaseInOut: "Quad.easeInOut",
    CubicEaseInOut: "Cubic.easeInOut",
    QuartEaseInOut: "Quart.easeInOut",
    QuintEaseInOut: "Quint.easeInOut",
    SineEaseInOut: "Sine.easeInOut",
    ExpoEaseInOut: "Expo.easeInOut",
    CircEaseInOut: "Circ.easeInOut",
    BackEaseInOut: "Back.easeInOut",
    BounceEaseInOut: "Bounce.easeInOut"
};

export const TEXT_FONT = {
    WorkSans: "Work Sans",
    Poppins: "Poppins",
    Pixelify: "Pixelify Sans",
    PermanentMarker: "Permanent Marker",
    CaveatBrush: "Caveat Brush",
    Mulish: "Mulish"
};

export const COLORS = {
    PRIMARY: 0xffc857,
    PRIMARY_CONTRAST: 0x1e1e1e,
    PRIMARY_SHADE: 0xe6b34d,
    PRIMARY_TINT: 0xffd36f,

    SECONDARY: 0xdd6e42,
    SECONDARY_CONTRAST: 0xffffff,
    SECONDARY_SHADE: 0xc35f3a,
    SECONDARY_TINT: 0xe4805e,

    TERTIARY: 0x92dce5,
    TERTIARY_CONTRAST: 0x1e1e1e,
    TERTIARY_SHADE: 0x82c1ca,
    TERTIARY_TINT: 0xa1e0eb,

    DISABLED: 0xb9b9b9,

    // WHERE TO GET THIS FROM?
    LIGHT_MODE: {
        TEXT: 0x161616,
        KEYWORD_HIGHLIGHT: 0x4f46e5,
        BACKGROUND: 0xf7f7f7
    },

    DARK_MODE: {
        TEXT: 0xe0e0e0,
        KEYWORD_HIGHLIGHT: 0x4f46e5,
        BACKGROUND: 0x333333
    },

    QUIZ: {
        BACKGROUND_PRIMARY: 0x74b9ff,
        BACKGROUND_SECONDARY: 0xa29bfe,
        SQUARES: [
            { idle: 0x16a085, hover: 0x1abc9c, text: 0xe0e0e0 },
            { idle: 0x2980b9, hover: 0x3498db, text: 0xe0e0e0 },
            { idle: 0x8e44ad, hover: 0x9b59b6, text: 0xe0e0e0 },
            { idle: 0xf39c12, hover: 0xf1c40f, text: 0xe0e0e0 }
        ],
        SLICES: [
            { idle: 0x16a085, hover: 0x1abc9c, text: 0xe0e0e0 },
            { idle: 0x2980b9, hover: 0x3498db, text: 0xe0e0e0 },
            { idle: 0x8e44ad, hover: 0x9b59b6, text: 0xe0e0e0 },
            { idle: 0xf39c12, hover: 0xf1c40f, text: 0xe0e0e0 }
        ],
        MATCH_SIDES: [0x1abc9c, 0xf1c40f, 0x3498db, 0x9b59b6, 0xe74c3c]
    },

    PROGRESS_BAR: {
        CURRENT: 0xffc857,
        VISITED: 0x888888,
        UNSEEN: 0xd1d1d1,

        QUIZ_UNSEEN: 0x8e44ad,
        QUIZ_CURRENT: 0xaf66cf,
        QUIZ_VISITED: 0x7a3794,

        FINISH_CURRENT: 0xaf66cf,
        FINISH_UNSEEN: 0x8e44ad
    },

    SPEECH_BUBBLE: 0xdfe6e9,

    PLANE_STICKER_GREEN: 0x1abc9c,
    PLANE_STICKER_RED: 0xe74c3c,
    PLANE_STICKER_BLUE: 0x3498db,
    PLANE_STICKER_YELLOW: 0xf1c40f
};

export async function wait(ms: number): Promise<void> {
    return new Promise((resolve) => window.setTimeout(resolve, ms));
}

export async function waitFor(promises: Promise<void>[]) {
    return await Promise.all(promises);
}

export function bucketBackgroundUrl(dir, fileName = "", extension = "png") {
    return `${bucketBaseUrl}/${dir}/Backgrounds/${fileName}.${extension}`;
}

export function loadBucketAssets(scene: Phaser.Scene, options = {}) {
    // loadBucketAssets(this, {
    //     folderPath: ['Hardware', 'Assets', ...],
    //     keyList: ['block1', 'block2', ...]
    //     aliases: { block1: 'block1Alias', block2: 'block2Alias'}
    // });
    const { folderPath = [], keyList = [], aliases = {} } = options;

    const basePath =
        folderPath.length > 0
            ? `${bucketBaseUrl}/${folderPath.join("/")}/`
            : `${bucketBaseUrl}/`;
    keyList.forEach((key) => {
        const url = `${basePath}${key}.png`;
        assetManager.loadAsset(scene, options.aliases?.[key] ?? key, url);
    });
}

/**    - folderPath: ['Hardware', 'Assets', ...]
 *     - keyList: ['block1', 'block2', ...]
 *     - aliases: { block1: 'block1Alias', block2: 'block2Alias'}
 *     - configs: { block1: { width: 300, height: 300}, block2: { scale: 0.5 }}
 */
export function loadBucketSVG(scene, options = {}) {
    const {
        folderPath = [],
        keyList = [],
        aliases = {},
        configs = {}
    } = options;

    const basePath =
        folderPath.length > 0
            ? `${bucketBaseUrl}/${folderPath.join("/")}/`
            : `${bucketBaseUrl}/`;
    keyList.forEach((key) => {
        const url = `${basePath}${key}.svg`;
        assetManager.loadSVG(
            scene,
            options.aliases?.[key] ?? key,
            url,
            configs?.[key] ?? undefined
        );
    });
}

// TODO: Make it possible to define extensions for each record
export function loadBucketAudio(scene, options = {}, extension = "mp3") {
    const { folderPath = [], keyList = [] } = options;
    const basePath =
        folderPath.length > 0
            ? `${bucketBaseUrl}/${folderPath.join("/")}/`
            : `${bucketBaseUrl}/`;
    keyList.forEach((key) => {
        const urls = [`${basePath}${key}.${extension}`];
        assetManager.loadAudio(scene, key, urls);
    });
}

export function loadBucketAtlas(scene, options = {}) {
    const { folderPath = [], keyList = [] } = options;
    const basePath =
        folderPath.length > 0
            ? `${bucketBaseUrl}/${folderPath.join("/")}/`
            : `${bucketBaseUrl}/`;
    keyList.forEach((key) => {
        const imageUrl = `${basePath}${key}.png`;
        const configUrl = `${basePath}${key}.json`;
        assetManager.loadAtlas(scene, key, imageUrl, configUrl);
    });
}

export function loadBucketSpritesheet(scene, options = {}) {
    const { folderPath = [], keyList = [], configs = [] } = options;
    const basePath =
        folderPath.length > 0
            ? `${bucketBaseUrl}/${folderPath.join("/")}/`
            : `${bucketBaseUrl}/`;
    for (let i = 0; i < keyList.length; i++) {
        const key = keyList[i];
        const imageUrl = `${basePath}${key}.png`;
        const config = configs[i];
        assetManager.loadSpritesheet(scene, key, imageUrl, config);
    }
}

export function loadPublicSpritesheet(scene, options = {}) {
    const { folderPath = [], keyList = [], configs = [] } = options;
    const basePath =
        folderPath.length > 0
            ? `${publicBaseUrl}/${folderPath.join("/")}/`
            : `${publicBaseUrl}/`;
    for (let i = 0; i < keyList.length; i++) {
        const key = keyList[i];
        const imageUrl = `${basePath}${key}.png`;
        const config = configs[i];
        assetManager.loadSpritesheet(scene, key, imageUrl, config);
    }
}

export function createPreloader(components) {
    return function (scene) {
        components.forEach((component) => {
            if (typeof component.preload === "function") {
                component.preload(scene);
            }
        });
    };
}

export function getHorizontalCenter(scene, objWidth) {
    return (scene.scale.width - objWidth) / 2;
}

export function calculateYPosition(scene, y, ignoreSpacer = false) {
    // Handle y as int or as object with getBottomLeft function
    let val;
    if (typeof y === "number") {
        val = y;
    } else if (y && typeof y.getBottomLeft === "function") {
        val = y.getBottomLeft().y + getSpacer(scene);
    } else {
        val = getMarginT(scene);
    }
    if (ignoreSpacer) val -= getSpacer(scene);
    return val;
}

export function addBackgroundColor(scene, color1 = 0xffffff, color2 = null) {
    const graphics = scene.add.graphics();

    if (color2) {
        graphics.fillGradientStyle(color1, color1, color2, color2, 1);
    } else {
        graphics.fillStyle(color1);
    }

    graphics.fillRect(0, 0, scene.scale.width, scene.scale.height);
    return graphics;
}

export function fitObjectToCanvas(scene, obj) {
    const { width, height } = scene.scale;
    const scale = Math.min(width / obj.width, height / obj.height);
    obj.setScale(scale);
    obj.setPosition(width / 2, height / 2);
    return obj;
}

/**
 * Wrapper for Phaser Tweens to easily await them.
 * IMPORTANT: Tween needs to emit "done" event. Preferably inside onComplete callback.
 */
export async function asyncAnimation(tween: Phaser.Tweens.Tween) {
    return new Promise((resolve, reject) => {
        try {
            tween.on("done", () => {
                resolve();
            });
        } catch (error) {
            reject();
        }
    });
}

export function randomFromArray<T>(array: T[]) {
    if (array.length === 0) {
        return undefined;
    }
    return array[Math.floor(Math.random() * array.length)];
}

export function popRandom(array) {
    if (array.length === 0) {
        return undefined;
    }
    return array.splice(Math.floor(Math.random() * array.length), 1)[0];
}

export function randomFrom(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export function isBetween(value, from, to) {
    if (value < from) {
        return false;
    }
    if (value > to) {
        return false;
    }
    return true;
}

// ============ SOCIAL FEATURES ==============

// There's no way to share custom message if I recall correctly
export function shareToFacebook() {
    const url = encodeURIComponent("https://skillrocket.ca/");
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;

    window.open(facebookShareUrl, "_blank");
}

export function shareScoreToX(score: number, gameName: string) {
    const text = encodeURIComponent(
        `I scored ${score} points in ${gameName}! Try to beat my score! #SkillRocket`
    );
    const url = encodeURIComponent("https://skillrocket.ca/"); // Replace with your game’s URL
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${text}&url=${url}`;

    window.open(twitterShareUrl, "_blank");
}

// ============================================

export function isDarkMode() {
    return useUserStore().userPrefs?.dark_mode ?? false;
}

export function getBackgroundColor() {
    return isDarkMode()
        ? COLORS.DARK_MODE.BACKGROUND
        : COLORS.LIGHT_MODE.BACKGROUND;
}

export function getTextColor() {
    return isDarkMode() ? COLORS.DARK_MODE.TEXT : COLORS.LIGHT_MODE.TEXT;
}

export function getQuizColors() {
    return isDarkMode() ? COLORS.QUIZ : COLORS.QUIZ;
}

export function getKeywordHighlightColor() {
    return isDarkMode()
        ? COLORS.DARK_MODE.KEYWORD_HIGHLIGHT
        : COLORS.LIGHT_MODE.KEYWORD_HIGHLIGHT;
}

export function getPrimaryColor(): number {
    return COLORS.PRIMARY;
}

export function getPrimaryContrastColor(): number {
    return COLORS.PRIMARY_CONTRAST;
}

export function getSecondaryColor(): number {
    return COLORS.SECONDARY;
}

export function getTertiaryColor(): number {
    return COLORS.TERTIARY;
}

export function getUiDisabledColor() {
    return COLORS.DISABLED;
}

export function getProgressBarColors() {
    return isDarkMode() ? COLORS.PROGRESS_BAR : COLORS.PROGRESS_BAR;
}

export function getKeywordsWithDefinitions() {
    const knownKeywords = useKeywordQuestionStore().known_keywords;
    const unknownKeywords = useKeywordQuestionStore().unknown_keywords;

    const filteredKnownKeywords = knownKeywords
        .filter((obj) => obj.language === getCurrentLanguage())
        .map((obj) => {
            return {
                keyword: obj.keyword.toLowerCase(),
                definition: getDefinitionFromId(obj.definition_id)
            };
        });

    const filteredUnknownKeywords = unknownKeywords
        .filter((obj) => obj.language === getCurrentLanguage())
        .map((obj) => {
            return {
                keyword: obj.keyword.toLowerCase(),
                definition: getDefinitionFromId(obj.definition_id)
            };
        });

    const keywordDefinitionMap = new Map();
    for (const knownKeyword of filteredKnownKeywords) {
        keywordDefinitionMap.set(knownKeyword.keyword, {
            definition: knownKeyword.definition,
            known: true
        });
    }
    for (const unknownKeyword of filteredUnknownKeywords) {
        keywordDefinitionMap.set(unknownKeyword.keyword, {
            definition: unknownKeyword.definition,
            known: false
        });
    }
    return keywordDefinitionMap;
}

function getDefinitionFromId(id: string) {
    const definitions = useKeywordQuestionStore().definitions;
    const definitionObj = definitions.find((def) => def.id == id);
    return (
        definitionObj?.definition[getCurrentLanguage()] ??
        definitionObj?.definition["en"] ??
        "NO DEFINITION FOUND"
    );
}

export function isGuestMode() {
    return !useUserStore().loggedIn;
}

export function getCurrentLanguage(): Language {
    if (isGuestMode()) {
        return useGuestStore().languageCode || "en";
    } else {
        return useUserStore().user?.language || "en";
    }
}

export function getPadding() {
    let padding = {
        x: 25,
        y: 45
    };

    if (Capacitor.getPlatform() === "ios") {
        padding = {
            x: 25,
            y: 80
        };
    }
    return padding;
}

export function checkAABBCollision(
    r1: { x: number; y: number; width: number; height: number },
    r2: { x: number; y: number; width: number; height: number }
): boolean {
    if (
        r1.x - r1.width * 0.5 < r2.x + r2.width * 0.5 &&
        r1.x + r1.width * 0.5 > r2.x - r2.width * 0.5 &&
        r1.y - r1.height * 0.5 < r2.y + r2.height * 0.5 &&
        r1.y + r1.height * 0.5 > r2.y - r2.height * 0.5
    ) {
        return true;
    }
    return false;
}

export function getPointFromAngle(
    x1: number,
    y1: number,
    angle: number,
    distance: number
) {
    const x = x1 + distance * Math.cos(Phaser.Math.DegToRad(angle));
    const y = y1 + distance * Math.sin(Phaser.Math.DegToRad(angle));
    return { x, y };
}

export function hexNumberToString(num: number) {
    return "#" + num.toString(16).padStart(6, "0");
}
