export interface ScrollBarConfig {
    width: number;
    height: number;
    visibleDistance: number;
    totalDistance: number;
    backgroundColor: number;
    scrollColor: number;
}
export class ScrollBar extends Phaser.GameObjects.Container {
    private background: Phaser.GameObjects.Graphics;
    private scroll: Phaser.GameObjects.Graphics;

    private scrollHeight: number;
    private config: ScrollBarConfig;
    constructor(
        scene: Phaser.Scene,
        x: number,
        y: number,
        config: ScrollBarConfig
    ) {
        super(scene, x, y);
        this.config = config;
        this.scrollHeight =
            this.config.height *
            (this.config.visibleDistance / this.config.totalDistance);

        this.initializeBackground();
        this.initializeScroll();

        this.add([this.background, this.scroll]);

        this.scene.add.existing(this);
    }

    public scrollTo(percentage: number): void {
        this.scroll.y = (this.config.height - this.scrollHeight) * percentage;
    }

    private initializeBackground(): void {
        this.background = this.scene.add.graphics();

        this.background.fillStyle(this.config.backgroundColor, 0.25);
        this.background.fillRoundedRect(
            -this.config.width * 0.5,
            -this.config.height * 0.5,
            this.config.width,
            this.config.height,
            8
        );
    }

    private initializeScroll(): void {
        this.scroll = this.scene.add.graphics();

        this.scroll.fillStyle(this.config.scrollColor, 0.5);
        this.scroll.fillRoundedRect(
            -this.config.width * 0.5,
            -this.config.height * 0.5,
            this.config.width,
            this.scrollHeight,
            8
        );
    }
}
