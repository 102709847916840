import { EASINGS, asyncAnimation } from "../../../utils/utils";

export interface FlashCardConfig {
    question: string;
    answer: string;
}

export enum FlashCardEvent {
    Revealed = "FlashCard:Revealed",
    Hidden = "FlashCard:Hidden",
    DragStart = "FlashCard:DragStart",
    DragEnd = "FlashCard:DragEnd",
    Drag = "FlashCard:Drag"
}

export class FlashCard extends Phaser.GameObjects.Container {
    private front: Phaser.GameObjects.Plane;
    private back: Phaser.GameObjects.Plane;

    private interactiveRect: Phaser.GameObjects.Rectangle;

    private revealed: boolean = false;
    private rotationAnimationOngoing: boolean = false;
    private rotationDisabled: boolean = false;

    private readonly WIDTH = 640;
    private readonly HEIGHT = 890;

    private static index: number = 0;

    constructor(
        scene: Phaser.Scene,
        x: number,
        y: number,
        config: FlashCardConfig
    ) {
        super(scene, x, y);

        this.front = this.scene.add
            .plane(
                0,
                0,
                this.createQuestionPlaneTexture(config.question),
                undefined
            )
            .setDisplaySize(this.WIDTH, this.HEIGHT);

        this.back = this.scene.add
            .plane(
                0,
                0,
                this.createAnswerPlaneTexture(config.answer),
                undefined
            )
            .setDisplaySize(this.WIDTH, this.HEIGHT);

        this.back.rotateY = 90;

        this.interactiveRect = this.scene.add
            .rectangle(0, 0, this.WIDTH, this.HEIGHT, 0x000000, 0)
            .setInteractive({ draggable: true });

        this.add([this.front, this.back, this.interactiveRect]);

        this.setSize(this.WIDTH, this.HEIGHT);

        this.bindEventHandlers();

        FlashCard.index++;

        this.scene.add.existing(this);
    }

    public disableRotation(): void {
        this.rotationDisabled = true;
    }

    private bindEventHandlers(): void {
        this.interactiveRect.on(Phaser.Input.Events.POINTER_UP, () => {
            this.rotateCard();
        });
        this.interactiveRect.on(
            Phaser.Input.Events.DRAG_START,
            (
                pointer: Phaser.Input.Pointer,
                globalX: number,
                globalY: number
            ) => {
                this.emit(FlashCardEvent.DragStart, pointer, globalX, globalY);
            }
        );
        this.interactiveRect.on(
            Phaser.Input.Events.DRAG_END,
            (
                pointer: Phaser.Input.Pointer,
                globalX: number,
                globalY: number
            ) => {
                this.emit(FlashCardEvent.DragEnd, pointer, globalX, globalY);
            }
        );
        this.interactiveRect.on(
            Phaser.Input.Events.DRAG,
            (pointer: Phaser.Input.Pointer, dragX: number, dragY: number) => {
                this.emit(FlashCardEvent.Drag, pointer, dragX, dragY);
            }
        );
    }

    private async rotateCard(): Promise<void> {
        if (this.rotationAnimationOngoing) {
            return;
        }

        if (this.rotationDisabled) {
            return;
        }

        this.rotationAnimationOngoing = true;
        await this.playRotateAnimation();
        this.rotationAnimationOngoing = false;
        this.revealed = !this.revealed;
        this.emit(
            this.revealed ? FlashCardEvent.Revealed : FlashCardEvent.Hidden
        );
    }

    private async playRotateAnimation(): Promise<void> {
        await asyncAnimation(
            this.scene.tweens.add({
                targets: this.revealed ? this.back : this.front,
                rotateY: -90,
                duration: 250,
                ease: EASINGS.QuadEaseIn,
                onComplete: (tween) => {
                    tween.emit("done");
                }
            })
        );
        (this.revealed ? this.back : this.front).rotateY = 90;
        await asyncAnimation(
            this.scene.tweens.add({
                targets: this.revealed ? this.front : this.back,
                rotateY: 0,
                duration: 250,
                ease: EASINGS.QuadEaseOut,
                onComplete: (tween) => {
                    tween.emit("done");
                }
            })
        );
    }

    private createQuestionPlaneTexture(text: string): string {
        const key = `flashCardQuestionPlane${FlashCard.index}`;
        const width = this.WIDTH;
        const height = this.HEIGHT;
        const renderTexture = this.scene.add.renderTexture(0, 0, width, height);

        const background = this.scene.add.graphics();
        background.fillStyle(0x9b59b6, 1);
        background.fillRoundedRect(0, 0, width, height, 25);

        const question = this.scene.add
            .text(this.WIDTH * 0.5, this.HEIGHT * 0.5, text, {
                fontFamily: "Work Sans",
                fontSize: 40,
                align: "center",
                fontStyle: "bold"
            })
            .setWordWrapWidth(this.WIDTH * 0.9)
            .setOrigin(0.5);

        renderTexture.draw(background);
        renderTexture.draw(question);

        renderTexture.saveTexture(key);
        renderTexture.destroy();

        background.destroy();
        question.destroy();

        return key;
    }

    private createAnswerPlaneTexture(text: string): string {
        const key = `flashCardAnswerPlane${FlashCard.index}`;
        const width = this.WIDTH;
        const height = this.HEIGHT;
        const renderTexture = this.scene.add.renderTexture(0, 0, width, height);

        const background = this.scene.add.graphics();
        background.fillStyle(0x1abc9c, 1);
        background.fillRoundedRect(0, 0, width, height, 25);

        const answer = this.scene.add
            .text(this.WIDTH * 0.5, this.HEIGHT * 0.5, text, {
                fontFamily: "Work Sans",
                fontSize: 40,
                align: "center",
                fontStyle: "bold"
            })
            .setWordWrapWidth(this.WIDTH * 0.9)
            .setOrigin(0.5);

        renderTexture.draw(background);
        renderTexture.draw(answer);

        renderTexture.saveTexture(key);
        renderTexture.destroy();

        background.destroy();
        answer.destroy();

        return key;
    }
}
