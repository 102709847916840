export class TextHelper {
    static setMaxTextSize(
        text,
        maxWidth,
        maxHeight,
        minFontSize,
        maxFontSize,
        unit = "px"
    ) {
        text.setFontSize(maxFontSize ?? text.style.fontSize);
        while (text.width > maxWidth || text.height > (maxHeight ?? Infinity)) {
            text.setFontSize(
                this.getTextFontSize(text.style.fontSize, unit) - 1
            );
            if (
                minFontSize &&
                minFontSize >= this.getTextFontSize(text.style.fontSize, unit)
            ) {
                return;
            }
            if (text.width <= 0) {
                return;
            }
        }
    }

    static getTextFontSize(fontSize, unit) {
        return parseInt(`${fontSize}`.replace(unit, ""));
    }
}
