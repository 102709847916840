import { EASINGS, getProgressBarColors } from "../../../../utils/utils";

export class Step extends Phaser.GameObjects.Graphics {
    constructor(scene, x, y, colors) {
        super(scene, {
            x: x,
            y: y
        });

        this.config = {
            colors: {
                visited: getProgressBarColors().VISITED,
                current: getProgressBarColors().CURRENT,
                unseen: getProgressBarColors().UNSEEN,
                ...colors
            },
            radius: 10,
            width: 38,
            height: {
                visited: 40,
                current: 60,
                unseen: 40
            }
        };

        this.currentHeight = this.config.height.unseen;
        this.drawAsUnseen();

        this.scene.add.existing(this);
    }

    show(show = true) {
        this.scene?.tweens.add({
            targets: this,
            alpha: show ? 1 : 0,
            duration: 300,
            ease: EASINGS.CubicEaseOut
        });
    }

    drawAsVisited() {
        this._changeHeightTween(
            this.config.height.visited,
            this.config.colors.visited
        );
    }

    drawAsCurrent() {
        this._changeHeightTween(
            this.config.height.current,
            this.config.colors.current
        );
    }

    drawAsUnseen() {
        this._changeHeightTween(
            this.config.height.unseen,
            this.config.colors.unseen
        );
    }

    _changeHeightTween(height, color) {
        this.scene?.tweens.addCounter({
            from: this.currentHeight,
            to: height,
            duration: 300,
            onUpdate: (tween) => {
                this.clear();
                this.fillStyle(color);
                this.fillRoundedRect(
                    0,
                    0,
                    this.config.width,
                    tween.getValue(),
                    this.config.radius
                );
            },
            onComplete: () => {
                this.currentHeight = height;
            }
        });
    }
}
