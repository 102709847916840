<template>
    <div>
        <h3 class="title center-text">
            <span>{{ $t('patchnotes.version') }}</span> - {{ version }}
        </h3>
        <div class="list-container" ref="listContainer">
            <ul class="list">
                <li v-for="(change, index) in $tm('patchnotes.changes')" :key="index" v-html="change"></li>
            </ul>
        </div>
        <div class="scroll-arrow" ref="scrollArrow">
            ↓
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import gsap from 'gsap';
import pkg from '../../package.json';

export default defineComponent({
    name: 'PatchNotes',
    data() {
        return {
            version: pkg.version,
            animations: [] as gsap.core.Tween[],
        };
    },
    mounted() {
        if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
            return;
        }

        const listContainer = this.$refs.listContainer as HTMLElement;
        const scrollArrow = this.$refs.scrollArrow as HTMLElement;
        setTimeout(() => {
            if (listContainer.scrollHeight > listContainer.clientHeight) {
                const maxScroll = listContainer.scrollHeight - listContainer.clientHeight;
                const scrollAmount = 30;
                const toScroll = scrollAmount > maxScroll ? maxScroll : scrollAmount;


                this.animations.push(
                    gsap.to(listContainer, {
                        scrollTop: toScroll,
                        duration: .75,
                        yoyo: true,
                        repeat: 1,
                        repeatDelay: .1,
                        ease: 'power1.inOut',
                    })
                );

                if (scrollArrow) {
                    this.animations.push(
                        gsap.to(scrollArrow, {
                            opacity: 1,
                            y: 10,
                            duration: 1,
                            yoyo: true,
                            repeat: -1,
                            ease: 'power1.inOut',
                        })
                    );
                }
            }
        }, 2000);
    },
    methods: {
        killAnimations() {
            this.animations.forEach(animation => animation.kill());
            this.animations = [];
        }
    },
    beforeUnmount() {
        this.$nextTick(() => {
            this.killAnimations();
        });
    }
});
</script>

<style scoped>
.title {
    font-weight: bold;
}

.list-container {
    max-height: 300px;
    overflow-y: auto;
    padding: 0.75em;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

.list-container::-webkit-scrollbar {
    width: 8px;
}

.list-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.list-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.list-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.list {
    font-size: 1rem;
    line-height: 1.75;
    font-family: var(--ion-font-family);
    margin: 0;
    padding: 0;
    list-style: none;
}

.scroll-arrow {
    text-align: center;
    font-size: 1.5rem;
    color: #555;
    margin-top: 10px;
    opacity: 0;
    transform: translateY(0);
}
</style>