import { EVENTS } from "../events";
import {
    EASINGS,
    asyncAnimation,
    getCurrentLanguage,
    getPadding,
    randomFromArray,
    wait
} from "../utils";
import { TextStyle } from "../styles";
import FeedbackPopup from "../feedback/FeedbackPopup";
import { TexturesHelper } from "../TexturesHelper";
import { TopBar, TopBarEvents } from "./TopBar";
import { BottomBar, BottomBarEvents } from "./BottomBar";
import { useUserStore } from "../../../store/userStore";
import { useGuestStore } from "../../../store/guestStore";
import { ToastManager } from "./ToastManager";
import { AppExplanationTutorial } from "./Tutorials/AppExplanationTutorial";
import pkg from "../../../package.json";
import { GraphicsButton } from "../ui/buttons/GraphicsButton";
import {
    SummaryCardPopup,
    SummaryCardPopupEvent
} from "../popups/SummaryCardPopup";
import feedbackText from "../../local_text/feedback.json";

// TODO: CHANGE TO TYPESCRIPT FILE AND IMPLEMENT UISCENEBASE
class UIScene extends Phaser.Scene {
    static folderName = "";

    constructor() {
        super("UIScene");
        this.currentIndex = 0;
        this.visitedIndexes = [0];
        this.currentDefBoxTween = null;

        this.debugRectClicksCounter = 0;
        this.backButtonLocked = false;
        this.nextButtonLocked = false;

        this.ratedScenes = {};
    }

    init(data) {
        this.folderName = data.folderName;
        this.sceneCount = data.sceneCount;
        this.scenesData = data.scenesData ?? {};
    }

    preload() {
        GraphicsButton.preloadAssets(this);
        TopBar.preloadAssets(this, this.folderName);
        BottomBar.preloadAssets(this, this.folderName);
        AppExplanationTutorial.preloadAssets(this, this.folderName);

        FeedbackPopup.preloadAssets(this);
        SummaryCardPopup.preloadAssets(this);
        TexturesHelper.createRectangleTexture(
            this,
            "confetti",
            Math.ceil(this.scale.width * 0.036),
            Math.ceil(this.scale.height * 0.006),
            0xffffff
        );
    }

    create() {
        let padding = getPadding();

        const customProgressBarColors = this.scenesData.map(
            (data) => data?.progressBarColor ?? undefined
        );

        this.topBar = new TopBar(
            this,
            padding,
            this.sceneCount,
            customProgressBarColors
        );
        this.bottomBar = new BottomBar(this, padding, this.sceneCount);

        this.toastManager = new ToastManager(this);

        // Handle the display of the definition box
        this.defBox = null;

        this.debugModeRectangle = this.add
            .rectangle(0, 200, 100, 100, 0xff0000, 0)
            .setInteractive()
            .setOrigin(0, 0.5);

        this.confettiEmitter = this.add.particles(
            this.scale.width * 0.5,
            -this.scale.height * 0.1,
            "confetti",
            {
                lifespan: { min: 1000, max: 3000 },
                gravityY: 1440,
                rotate: { min: 0, max: 360 },
                scale: { min: 1, max: 2 },
                speedX: { min: -500, max: 500 },
                tint: [0xf1c40f, 0xe74c3c, 0x9b59b6, 0x1abc9c],
                frequency: 10
            }
        );
        this.confettiEmitter.stop();
        this._bindEventHandlers();

        this._tryStartAppExplanationTutorial();
    }

    lockBackButtonNavigation(lock = true) {
        this.backButtonLocked = lock;
        if (lock) {
            this.bottomBar?.disableBackButtonNavigation(true);
        }
    }

    lockNextButtonNavigation(lock = true) {
        this.nextButtonLocked = lock;
        if (lock) {
            this.bottomBar?.disableNextButtonNavigation(true);
        }
    }
    disableNavigation(nextButton = true, backButton = true) {
        if (!this.nextButtonLocked) {
            this.bottomBar?.disableNextButtonNavigation(nextButton);
        }
        if (!this.backButtonLocked) {
            this.bottomBar?.disableBackButtonNavigation(backButton);
        }
    }

    showMaskImage(show = true) {
        this.topBar.showMaskImage(show);
    }

    showToast(
        message,
        from = "bottom",
        to = "bottom",
        textStyle = {},
        duration,
        backgroundAlpha
    ) {
        this.toastManager.showToast(
            message,
            from,
            to,
            textStyle,
            duration,
            backgroundAlpha
        );
    }

    startAppExplanationTutorialTutorial(delay) {
        if (!this.appExplanationTutorial) {
            this.appExplanationTutorial = new AppExplanationTutorial(this);
        }
        this.appExplanationTutorial.startTutorial(delay);
    }

    startTutorial(TutorialClass, delay, data) {
        new TutorialClass(this, data).startTutorial(delay);
    }

    async emitConfetti(duration = 1000, delay = 0) {
        if (delay) {
            await wait(delay);
        }
        this.confettiEmitter.start();
        await wait(duration);
        this.confettiEmitter.stop();
    }

    async showFeedbackPopup(
        headerText,
        progressBarOptions,
        feedbackTypes,
        showSubmitButton,
        onClose = undefined
    ) {
        const sceneKey = `Scene${this.currentIndex + 1}`;
        const popup = new FeedbackPopup(
            this,
            this.scale.width * 0.5,
            this.scale.height * 0.5,
            {
                headerText,
                progressBarOptions,
                feedbackTypes,
                showSubmitButton,
                onClose,
                sceneKey
            }
        );
        popup.once("submit", (data) => {
            this._submitFeedback(data.comment, data.rating, data.liked, "Node");
        });
        await popup.open();
    }

    _tryStartAppExplanationTutorial() {
        const folder = this.game.registry.get("folder");
        if (!["packets"].includes(folder)) {
            return;
        }
        if (
            useUserStore().user?.completed_tutorial ||
            useGuestStore().completedTutorial
        ) {
            return;
        }
        const delay = folder === "packets" ? 2000 : 4000;
        this.startAppExplanationTutorialTutorial(delay);
    }

    async tryToShowFeedbackPopup() {
        const record = this._getRandomFeedbackQuestion();
        await this.showFeedbackPopup(
            record.question,
            record.options,
            ["progressBar"],
            true
        );
    }

    async showSummaryCardPopup() {
        const popup = new SummaryCardPopup(
            this,
            this.scale.width * 0.5,
            this.scale.height * 0.5
        );
        popup.once(SummaryCardPopupEvent.NextNodeRequested, (nodeId) => {
            this.events.emit(
                EVENTS.UIScene.summaryCardNextNodeRequested,
                nodeId
            );
        });

        popup.once(SummaryCardPopupEvent.ExitToMapRequested, () => {
            this.events.emit(EVENTS.UIScene.summaryCardExitToMapRequested);
        });
    }

    hideNextSceneButton(hide = true) {
        const debugMode = this.registry.get("debugMode");
        if (debugMode) {
            this.bottomBar?.hideRightButton(false);
            return;
        }
        this.bottomBar?.hideRightButton(hide);
    }

    handleSceneTransition(currentIndex, visitedIndexes) {
        this.currentIndex = currentIndex;
        this.topBar?.updateProgressBar(currentIndex, visitedIndexes);
        this.topBar?.updateSceneNumberText(currentIndex);
        this.bottomBar?.updateButtonVisibility(currentIndex);
    }

    requestNextScene() {
        this.events.emit(EVENTS.UIScene.nextSceneRequested);
    }

    requestPreviousScene() {
        this.events.emit(EVENTS.UIScene.previousSceneRequested);
    }

    updateBottomBarButtonVisibility() {
        this.bottomBar.updateButtonVisibility(this.currentIndex);
    }

    displayDefinition(definition) {
        this.closeDefinitionBox();

        this.openingNewDefinition = true;
        const boxWidth = this.scale.width;
        const boxX = this.scale.width / 2;
        this.defBox?.destroy();
        this.defBox = this.add.container(boxX, 0).setDepth(100);
        const defTextStyle = TextStyle(this);

        // Create temporary text to measure height
        const tempText = this.add
            .text(
                0,
                0,
                `${definition.keyword}:\n${definition.definition}`,
                defTextStyle
            )
            .setWordWrapWidth(boxWidth * 0.9);
        const textHeight = tempText.heightTiles;
        tempText.destroy();

        this.defBox.boxHeight = textHeight + this.scale.height * 0.01;
        const boxY = -this.defBox.boxHeight;

        const defBoxBg = this.rexUI.add.roundRectangle(
            0,
            0,
            boxWidth,
            this.defBox.boxHeight,
            { bl: 10, br: 10 },
            0xffffff
        );
        defBoxBg.setStrokeStyle(2, 0x000000);
        const defText = this.add
            .text(
                0,
                0,
                `${definition.keyword}:\n${definition.definition}`,
                defTextStyle
            )
            .setWordWrapWidth(boxWidth * 0.9)
            .setOrigin(0.5, 0.5);
        this.defBox.add(defBoxBg);
        this.defBox.add(defText);

        this.currentDefBoxTween = this.tweens.add({
            targets: this.defBox,
            y: this.defBox.boxHeight / 2,
            duration: 500,
            ease: "Power2",
            paused: false
        });
    }

    handlePointerDown(pointer) {
        if (this.defBox) {
            const bounds = this.defBox.getBounds();
            if (!bounds.contains(pointer.x, pointer.y)) {
                this.closeDefinitionBox();
            }
        }
    }

    closeDefinitionBox() {
        if (this.currentDefBoxTween) {
            this.currentDefBoxTween.stop();
            this.currentDefBoxTween = null;
        }

        if (this.defBox) {
            const defBoxToClose = this.defBox;
            this.defBox = null;

            this.tweens.add({
                targets: defBoxToClose,
                y: -defBoxToClose.boxHeight,
                duration: 500,
                ease: "Power2",
                paused: false,
                onComplete: () => {
                    defBoxToClose.destroy();
                }
            });
        }
    }

    hideProgressBar(hide = true) {
        this.topBar.hideProgressBar(hide);
    }

    w(fraction = 1) {
        return this.scale.width * fraction;
    }

    h(fraction = 1) {
        return this.scale.height * fraction;
    }

    _bindEventHandlers() {
        // TODO: Remove debug mode on start // REMOVE SHREK
        // this._handleEnterDebugMode();

        this.events.on("displayDefinition", () => {
            this.displayDefinition();
        });

        this.input.on("pointerdown", () => {
            this.handlePointerDown();
        });

        this.debugModeRectangle.on("pointerdown", () => {
            this.debugRectClicksCounter++;
            if (this.debugRectClicksCounter >= 10) {
                this.debugModeRectangle.destroy();
                this._handleEnterDebugMode();
            }
        });

        this._bindTopBarEventHandlers();
        this._bindBottomBarEventHandlers();
    }

    _bindTopBarEventHandlers() {
        this.topBar.on(TopBarEvents.homeButtonClicked, () => {
            hapticsImpactHeavy();
            this.events.emit(EVENTS.UIScene.homeButtonClicked);
        });

        this.topBar.on(TopBarEvents.feebackButtonClicked, () => {
            hapticsImpactMedium();
            this.events.emit(EVENTS.UIScene.feebackButtonClicked);
        });

        this.topBar.on(TopBarEvents.keywordButtonClicked, () => {
            hapticsImpactLight();
            this.events.emit(EVENTS.UIScene.keywordButtonClicked);
        });
    }

    _bindBottomBarEventHandlers() {
        this.bottomBar.on(BottomBarEvents.leftButtonClicked, () => {
            hapticsImpactLight();
            this.sys.game.events.emit(EVENTS.UIScene.buttonLeftPressed);
        });

        this.bottomBar.on(BottomBarEvents.rightButtonClicked, () => {
            hapticsImpactLight();
            this.sys.game.events.emit(EVENTS.UIScene.buttonRightPressed);
        });

        this.bottomBar.on(BottomBarEvents.restartSceneButtonClicked, () => {
            this._restartCurrentScene();
        });
    }

    _handleEnterDebugMode() {
        this.registry.set("debugMode", true);
        this.hideNextSceneButton(false);
        this.bottomBar.handleEnterDebugMode();
        this.topBar.handleEnterDebugMode();
    }

    async _submitFeedback(comment, rating, liked, sceneKey) {
        try {
            const uStore = useUserStore();
            const api_url = this.game.registry.get("api_url");
            const folder = this.game.registry.get("folder");

            const feedbackData = {
                comment: comment ?? null,
                rating: rating ?? null,
                liked: liked ?? null,
                user: uStore.user?.id ?? null,
                scene_key: sceneKey ?? `Scene${this.currentIndex + 1}`,
                node_name: folder,
                app_version: pkg.version
            };
            console.log("Submitting feedback:", feedbackData);

            const headerText = uStore.accessToken
                ? {
                      Authorization: "Bearer " + uStore.accessToken,
                      "Content-Type": "application/json"
                  }
                : {
                      "Content-Type": "application/json"
                  };

            const response = await fetch(api_url + "auth/phaser_feedback/", {
                method: "POST",
                headers: new Headers(headerText),
                body: JSON.stringify(feedbackData)
            });
            console.log("Feedback submitted successfully", response);
        } catch (err) {
            console.error("Error submitting feedback:", err);
        }
    }

    _getRandomFeedbackQuestion() {
        return randomFromArray(feedbackText[getCurrentLanguage()]);
    }

    getAudioManager() {
        return this.registry.get("audioManager");
    }

    async _restartCurrentScene() {
        const scene = this.scene.get(`Scene${this.currentIndex + 1}`);
        this.getAudioManager().stopAllSceneSounds(scene);
        scene.scene.restart();
    }

    getQuizQuestionText() {
        return this.questionText.text;
    }

    showQuizQuestion(question) {
        this.questionText?.destroy();
        this.questionBackground?.destroy();

        const margin = this.scale.height * 0.01;
        const posX = this.scale.width * 0.5;
        const posY = 200;
        this.questionText = this.add
            .text(posX, 0, question, {
                fontFamily: "Work Sans",
                fontSize: "45px",
                color: "#ffffff"
            })
            .setWordWrapWidth(this.scale.width * 0.9)
            .setAlign("center")
            .setOrigin(0.5, 0)
            .setScrollFactor(0);
        this.questionBackground = this.add
            .rectangle(
                posX,
                posY,
                this.scale.width,
                this.questionText.height + margin * 2,
                0x000000,
                0.35
            )
            .setOrigin(0.5, 0)
            .setScrollFactor(0);

        this.questionText.y = posY + margin;
        this.questionText.setDepth(1);
    }

    async destroyQuizQuestion(instant = false) {
        if (this.questionText && this.questionBackground) {
            if (this) {
                if (!instant) {
                    await asyncAnimation(
                        this.tweens.add({
                            targets: [
                                this.questionText,
                                this.questionBackground
                            ],
                            alpha: 0,
                            duration: 250,
                            ease: EASINGS.CubicEaseOut,
                            onComplete: (tween) => {
                                tween.emit("done");
                            }
                        })
                    );
                }
            }
            this.questionBackground.destroy();
            this.questionText.destroy();
        }
    }

    isDarkMode() {
        return useUserStore().userPrefs?.dark_mode ?? false;
    }
}

export default UIScene;
