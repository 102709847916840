export class TexturesHelper {
    static createRectangleTexture(
        scene: Phaser.Scene,
        textureKey: string,
        width: number,
        height: number,
        color: number,
        lineThickness?: number,
        lineColor?: number
    ) {
        const rectangleTexture = scene.add
            .graphics()
            .fillStyle(color, 1)
            .fillRect(0, 0, width, height);

        if (lineThickness) {
            rectangleTexture.lineStyle(
                lineThickness ?? 1,
                lineColor ?? 0x000000
            );
            rectangleTexture.strokeRect(0, 0, width, height);
        }
        rectangleTexture.generateTexture(textureKey, width, height);
        rectangleTexture.destroy();
    }

    static createRoundedRectangleTexture(
        scene: Phaser.Scene,
        textureKey: string,
        width: number,
        height: number,
        radius: number,
        color: number,
        alpha: number,
        lineThickness: number,
        lineColor: number
    ) {
        const rectangleTexture = scene.add
            .graphics()
            .fillStyle(color, alpha ?? 1)
            .lineStyle(lineThickness ?? 1, lineColor)
            .fillRoundedRect(0, 0, width, height, radius);

        if (lineThickness) {
            rectangleTexture.strokeRoundedRect(0, 0, width, height, radius);
        }
        rectangleTexture.generateTexture(textureKey, width, height);
        rectangleTexture.destroy();
    }

    static createCircleTexture(
        scene: Phaser.Scene,
        textureKey: string,
        radius: number,
        color: number,
        alpha: number,
        lineThickness: number,
        lineColor: number
    ) {
        const lT = lineThickness ?? 0;
        const lColor = lineColor ?? 0x000000;
        const circleTexture = scene.add
            .graphics()
            .fillStyle(color, alpha ?? 1)
            .lineStyle(lT, lColor)
            .fillCircle(radius + lT, radius + lT, radius);

        if (lT) {
            circleTexture.strokeCircle(radius + lT, radius + lT, radius);
        }
        circleTexture.generateTexture(
            textureKey,
            radius * 2 + lT * 2,
            radius * 2 + lT * 2
        );
        circleTexture.destroy();
    }

    static drawLine(
        scene: Phaser.Scene,
        x1: number,
        x2: number,
        y1: number,
        y2: number,
        lineThickness = 5,
        lineColor = 0xffffff,
        alpha = 1
    ): Phaser.GameObjects.Graphics {
        const graphics = scene.add
            .graphics()
            .lineStyle(lineThickness, lineColor, alpha);

        graphics.lineBetween(x1, y1, x2, y2);

        return graphics;
    }

    public static createStickerTexture(
        scene: Phaser.Scene,
        textureKey: string,
        backgroundWidth: number,
        backgroundHeight: number,
        backgroundColor?: number
    ): string {
        if (scene.textures.exists(textureKey)) {
            return textureKey;
        }
        const width = backgroundWidth;
        const height = backgroundHeight;
        const renderTexture = scene.add.renderTexture(
            scene.scale.width * 0.5,
            500,
            width,
            height
        );

        const outline = scene.add.graphics();
        outline.fillStyle(0xffffff, 1);
        outline.fillRoundedRect(0, 0, width, height, 20);

        const background = scene.add.graphics();
        background.fillStyle(backgroundColor ?? 0xffffff, 1);
        background.fillRoundedRect(5, 5, width - 10, height - 10, 20);

        renderTexture.draw(outline);
        renderTexture.draw(background);

        renderTexture.saveTexture(textureKey);
        renderTexture.destroy();

        outline.destroy();
        background.destroy();

        return textureKey;
    }
}
