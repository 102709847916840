import type { NodeSceneBase } from "../NodeSceneBase";
import {
    GraphicsButton,
    GraphicsButtonEvent
} from "../ui/buttons/GraphicsButton";
import {
    EASINGS,
    getBackgroundColor,
    getCurrentLanguage,
    getTextColor,
    hexNumberToString,
    loadBucketAssets,
    TEXT_FONT
} from "../utils";
import type UIScene from "./UIscene";

export const BottomBarEvents = {
    leftButtonClicked: "leftButtonClicked",
    rightButtonClicked: "rightButtonClicked",
    restartSceneButtonClicked: "restartSceneButtonClicked"
};

export class BottomBar extends Phaser.GameObjects.Container {
    private buttonLeft: GraphicsButton;
    private buttonRight: GraphicsButton;

    private restartSceneButton: Phaser.GameObjects.Image;
    private timeScaleButton: Phaser.GameObjects.Text;
    private currentTimeScaleIndex = 0;
    private readonly TIME_SCALE_VALUES = [1, 2, 5, 10];

    private padding: { x: number; y: number };
    private sceneCount: number;
    private currentIndex: number;

    constructor(
        scene: UIScene,
        padding: { x: number; y: number },
        sceneCount: number
    ) {
        super(scene, 0, 0);

        this.padding = padding;
        this.sceneCount = sceneCount;

        this.initializeLeftButton();
        this.initializeRightButton();
        this.initializeRestartSceneButton();
        this.initializeTimeScaleButton();

        this.add([this.buttonLeft, this.buttonRight]);

        this.bindEventHandlers();

        this.changeTimeScale();

        this.scene.add.existing(this);
    }

    public static preloadAssets(scene: Phaser.Scene, folderName: string) {
        loadBucketAssets(scene, {
            folderPath: ["General", "Assets"],
            keyList: ["debugRestartButton"]
        });
        loadBucketAssets(scene, {
            folderPath: ["Icons"],
            keyList: ["back"]
        });
    }

    public handleEnterDebugMode(): void {
        this.restartSceneButton.setScale(0);
        this.restartSceneButton.setVisible(true);
        this.timeScaleButton.setScale(0);
        this.timeScaleButton.setVisible(true);
        this.scene?.tweens.add({
            targets: [this.restartSceneButton, this.timeScaleButton],
            scale: 0.6,
            duration: 250,
            ease: EASINGS.BackEaseOut
        });
    }

    public disableBackButtonNavigation(disable: boolean = true): void {
        this.buttonLeft?.disable(disable);
    }

    public disableNextButtonNavigation(disable: boolean = true): void {
        this.buttonRight?.disable(disable);
    }

    public hideRightButton(hide = true): void {
        this.buttonRight.setVisible(!hide);
    }

    public shakeNavigationButton(left = false): void {
        const button = left ? this.buttonLeft : this.buttonRight;
        if (!button) {
            return;
        }
        this.scene?.tweens.add({
            targets: [button],
            duration: 125,
            ease: EASINGS.QuadEaseInOut,
            scale: 3.3,
            angle: -10,
            onComplete: () => {
                this.scene?.tweens.add({
                    targets: [button],
                    duration: 125,
                    ease: EASINGS.QuadEaseInOut,
                    angle: 20,
                    yoyo: true,
                    loop: 2,
                    onComplete: () => {
                        this.scene?.tweens.add({
                            targets: [button],
                            duration: 125,
                            ease: EASINGS.QuadEaseInOut,
                            scale: 3,
                            angle: 0
                        });
                    }
                });
            }
        });
    }

    public updateButtonVisibility(currentIndex: number) {
        this.currentIndex = currentIndex;
        const isFirstChild = currentIndex === 0;
        const isLastChild = currentIndex === this.sceneCount - 1;

        const currentScene = this.scene.scene.get(
            `Scene${this.currentIndex + 1}`
        ) as NodeSceneBase;

        this.buttonLeft.setVisible(
            !isFirstChild || !currentScene.isExecutionStepAtBeginning()
        );
        this.buttonRight.setVisible(
            !isLastChild || !currentScene.isExecutionStepOutOfList()
        );
    }

    public getLeftButton(): GraphicsButton {
        return this.buttonLeft;
    }

    public getRightButton(): GraphicsButton {
        return this.buttonRight;
    }

    private initializeLeftButton(): void {
        this.buttonLeft = new GraphicsButton(
            this.scene,
            this.padding.x,
            this.scene.scale.height - this.padding.y,
            {
                width: 100,
                height: 100,

                image: { key: "back" },
                imageScale: 0.35
            }
        ).setVisible(false);

        this.buttonLeft.x += this.buttonLeft.displayWidth * 0.5;
        this.buttonLeft.y -= this.buttonLeft.displayHeight * 0.5;
    }

    private initializeRightButton(): void {
        this.buttonRight = new GraphicsButton(
            this.scene,
            this.scene.scale.width - this.padding.x,
            this.scene.scale.height - this.padding.y,
            {
                width:
                    this.scene.scale.width -
                    this.padding.x * 3 -
                    this.buttonLeft.displayWidth,
                height: 100,
                text: {
                    en: "Next",
                    fr: "Suivant",
                    ar: "التالي",
                    tr: "Sonraki"
                }[getCurrentLanguage()],
                textStyle: {
                    fontFamily: TEXT_FONT.WorkSans,
                    fontSize: 50
                }
            }
        ).setVisible(this.sceneCount > 1);

        this.buttonRight.x -= this.buttonRight.displayWidth * 0.5;
        this.buttonRight.y -= this.buttonRight.displayHeight * 0.5;
    }

    private initializeRestartSceneButton(): void {
        this.restartSceneButton = this.scene.add
            .image(
                this.scene.scale.width * 0.5,
                this.scene.scale.height - 300,
                "debugRestartButton"
            )
            .setInteractive({ useHandCursor: true })
            .setScale(0.6)
            .setVisible(false);
    }

    private initializeTimeScaleButton(): void {
        this.timeScaleButton = this.scene.add
            .text(
                this.scene.scale.width * 0.3,
                this.scene.scale.height - 300,
                "x1",
                {
                    fontFamily: TEXT_FONT.WorkSans,
                    fontSize: 100,
                    color: hexNumberToString(getTextColor()),
                    fontStyle: "bold"
                }
            )
            .setOrigin(0.5)
            .setInteractive({ cursor: "pointer" })
            .setVisible(false);
    }

    private bindEventHandlers(): void {
        this.buttonLeft.on(GraphicsButtonEvent.Released, () => {
            this.emit(BottomBarEvents.leftButtonClicked);
        });

        this.buttonRight.on(GraphicsButtonEvent.Released, () => {
            this.emit(BottomBarEvents.rightButtonClicked);
        });

        this.restartSceneButton.on(Phaser.Input.Events.POINTER_UP, () => {
            this.emit(BottomBarEvents.restartSceneButtonClicked);
            this.scene?.tweens.add({
                targets: this.restartSceneButton,
                angle: 360,
                duration: 500,
                ease: EASINGS.BackEaseOut
            });
            this.currentTimeScaleIndex = 0;
            this.timeScaleButton.setText(`x${this.TIME_SCALE_VALUES[0]}`);
        });

        this.timeScaleButton.on(Phaser.Input.Events.POINTER_UP, () => {
            this.currentTimeScaleIndex =
                (this.currentTimeScaleIndex + 1) %
                this.TIME_SCALE_VALUES.length;
            this.changeTimeScale();
        });
    }

    private changeTimeScale(): void {
        const newTimeScale = this.TIME_SCALE_VALUES[this.currentTimeScaleIndex];
        this.timeScaleButton.setText(`x${newTimeScale}`);
        this.scene.sys.game.scene.scenes.forEach((scene) =>
            scene.tweens.setGlobalTimeScale(newTimeScale)
        );
    }
}
