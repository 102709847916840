<template>
    <ion-modal :isOpen="isOpen" @didDismiss="closeModal">
        <modalToolbar :nodeTitle="''" @closeModal="closeModal" />
        <ion-content class="ion-padding-start ion-padding-end">
            <h1 style="position: inherit;" class="center-text keep-learning">Your App Needs Updating!</h1>
            <p class="center-text">Please visit the store to update it now.</p>
            <sub class="center-text ion-padding-bottom">Skipping updates may cause issues.</sub>
            <AppStoreUpdateButton></AppStoreUpdateButton>
        </ion-content>
    </ion-modal>
</template>

<script>
import { useModalStore } from '../store/modalStore';
import AppStoreUpdateButton from './AppStoreUpdateButton.vue';
import modalToolbar from '~/components/modal/modalToolbar.vue';

const mStore = useModalStore();

export default {
    name: 'OutOfDateModal',
    components: {
        modalToolbar,
        AppStoreUpdateButton
    },
    data() {
        return {
        };
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        closeModal() {
            mStore.toggleOutOfDateModal(false);
        }
    }
}
</script>

<style scoped>
ion-modal {
    --height: 40%;
    --border-radius: 16px;
    position: absolute;
    padding: 3%;
    padding-bottom: 2%;
}

ion-modal::part(backdrop) {
    background: rgb(54, 55, 57);
    opacity: 1;
}

.container {
    margin-top: -15px;
}
</style>