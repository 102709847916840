import { TextHelper } from "../../TextHelper";
import { TexturesHelper } from "../../TexturesHelper";
import { EASINGS, asyncAnimation, wait } from "../../utils";

export class MatchSidesQuizRecord extends Phaser.GameObjects.Container {
    private background: Phaser.GameObjects.Image;
    private text: Phaser.GameObjects.Text;

    private connection?: MatchSidesQuizRecord;
    private resetToDefaultPositionTween?: Phaser.Tweens.Tween;
    private rightAnchor: boolean;
    private defaultPosition: { x: number; y: number };
    private anchorXOffset: number;

    constructor(
        scene: Phaser.Scene,
        x: number,
        y: number,
        text: string,
        rightAnchor = true
    ) {
        super(scene, x, y);

        this.connection = undefined;
        this.resetToDefaultPositionTween = undefined;

        this.rightAnchor = rightAnchor;
        this.defaultPosition = { x, y };

        this.background = this.scene.add
            .image(0, 0, "recordBackground")
            .setOrigin(0.5)
            .setAlpha(0.35);

        this.anchorXOffset =
            this.background.displayWidth * 0.35 * (rightAnchor ? 1 : -1);

        this.text = this.scene.add
            .text(0, 0, text, { fontFamily: "Work Sans", fontSize: 45 })
            .setOrigin(0.5);

        TextHelper.setMaxTextSize(this.text, 250, 125, undefined, 45);

        this.add([this.background, this.text]);

        const bounds = this.getBounds();
        this.setSize(bounds.width, bounds.height);

        this.setInteractive();
        this.scene.input.setDraggable(this);

        this.scene.add.existing(this);
    }

    public static preloadAssets(scene: Phaser.Scene): void {
        TexturesHelper.createRoundedRectangleTexture(
            scene,
            "recordBackground",
            275,
            150,
            16,
            0x2c3e50,
            1
        );
    }

    public async shake(): Promise<void> {
        return asyncAnimation(
            this.scene?.tweens.add({
                targets: [this],
                duration: 50,
                ease: EASINGS.Linear,
                x: this.x + 15,
                yoyo: true,
                loop: 3,
                onComplete: (tween) => {
                    tween.emit("done");
                }
            })
        );
    }

    public getText(): string {
        return this.text.text;
    }

    public setColor(color: number): void {
        this.background.setTintFill(color);
    }

    public clearColor() {
        this.background.clearTint();
    }

    public setConnection(record: MatchSidesQuizRecord | undefined): void {
        this.connection = record;
    }

    public getConnection(): MatchSidesQuizRecord | undefined {
        return this.connection;
    }

    public getAnchorX(): number {
        return this.x + this.anchorXOffset;
    }

    public getAnchorXOffsetValue(): number {
        return this.anchorXOffset;
    }

    public resetToDefaultPosition(): void {
        this.resetToDefaultPositionTween = this.scene?.tweens.add({
            targets: this,
            x: this.defaultPosition.x,
            y: this.defaultPosition.y,
            duration: 300,
            ease: EASINGS.BackEaseOut,
            onComplete: () => {
                this.setPosition(
                    this.defaultPosition.x,
                    this.defaultPosition.y
                );
            }
        });
    }

    public stopResettingToDefaultPosition(): void {
        this.resetToDefaultPositionTween?.stop();
    }

    public setPositionOffset(
        forceX: number,
        forceY: number,
        instant = true
    ): Phaser.Tweens.Tween | undefined {
        const x =
            this.defaultPosition.x +
            this.displayWidth * Math.max(-0.1, Math.min(0.1, forceX));
        const y =
            this.defaultPosition.y +
            this.displayHeight * Math.max(-0.1, Math.min(0.1, forceY));
        if (instant) {
            this.setPosition(x, y);
        } else {
            return this.scene?.tweens.add({
                targets: this,
                x,
                y,
                duration: 200,
                ease: "Power2"
            });
        }
    }

    public async playStretchAnimation(breaksConnection = false): Promise<void> {
        this.disableInteractive();
        const positionX = this.x;
        this.scene?.tweens.add({
            targets: this,
            x: `+=${this.rightAnchor ? -60 : 60}`,
            duration: 800,
            ease: "Power2",
            onComplete: () => {
                if (breaksConnection) {
                    this.scene?.tweens.add({
                        targets: this,
                        x: `+=${this.rightAnchor ? -20 : 20}`,
                        duration: 200,
                        ease: EASINGS.SineEaseOut,
                        onComplete: () => {
                            this.setInteractive();
                        }
                    });
                } else {
                    this.scene?.tweens.add({
                        targets: this,
                        x: positionX,
                        duration: 200,
                        ease: EASINGS.BounceEaseOut,
                        onComplete: () => {
                            this.setInteractive();
                        }
                    });
                }
            }
        });
        await wait(1000);
    }
}
