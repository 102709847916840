<template>
    <div>
        <h1 class="center-text">{{ $t('home.signup.questline_selection.header') }}</h1>
        <ion-text class="center-text">{{ $t('home.signup.questline_selection.text') }}</ion-text>
        <QuestlineSelection class="selector" @selectedQuestline="nextSlide($event)"></QuestlineSelection>
    </div>
</template>

<script lang="ts">
import { useGuestStore } from '../../store/guestStore';
import QuestlineSelection from './QuestlineSelection.vue';
const gStore = useGuestStore();

export default {
    name: 'QuestlineSlide',
    emits: ['nextSlide'],
    components: {
        QuestlineSelection
    },
    data() {
        return {
            swiper: null,
        }
    },
    methods: {
        nextSlide(event: string) {
            gStore.setQuestline(event);
            this.swiper.slideNext()
        },
        emitLoginFailed(error: any) {
            console.error(JSON.stringify(error));
        }
    },
    mounted() {
        this.swiper = useSwiper()
    },
    watch: {
    }
}
</script>

<style scoped>
.selector {
    max-height: 75vh;
    overflow-y: auto;
}

h1 {
    font-size: 1.25rem;
    font-weight: 500;
}

ion-text {
    font-size: 0.875rem;
    font-weight: 400;
}
</style>