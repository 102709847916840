import { useNodeStore } from "../../../../store/nodeStore";
import {
    getTextColor,
    hexNumberToString,
    TEXT_FONT
} from "../../../utils/utils";

export class ExamSummary extends Phaser.GameObjects.Container {
    private headerTexts: Phaser.GameObjects.Text[];
    private scoreTexts: Phaser.GameObjects.Text[];

    private scores: { [key: string]: { correct: number; total: number } };
    constructor(
        scene: Phaser.Scene,
        x: number,
        y: number,
        scores: { [key: string]: { correct: number; total: number } }
    ) {
        super(scene, x, y);

        this.scores = scores;

        this.initializeTexts();

        this.add([...this.headerTexts, ...this.scoreTexts]);
        const bounds = this.getBounds();
        this.setSize(bounds.width, bounds.height);

        this.bindEventHandlers();

        this.scene.add.existing(this);
    }

    private initializeTexts(): void {
        this.headerTexts = [];
        this.scoreTexts = [];

        let i = 0;
        const availableNodes = useNodeStore().availableNodes;
        const textStyle = {
            fontFamily: TEXT_FONT.WorkSans,
            fontSize: 50,
            fontStyle: "bold",
            color: hexNumberToString(getTextColor()),
            wordWrap: {
                width: this.scene.scale.width * 0.5
            },
            lineSpacing: 0
        };
        for (const id in this.scores) {
            this.headerTexts.push(
                this.scene.add
                    .text(
                        this.scene.scale.width * -0.35,
                        this.scene.scale.height * 0.1 * i,
                        availableNodes[id].name,
                        textStyle
                    )
                    .setOrigin(0, 0.5)
            );
            this.scoreTexts.push(
                this.scene.add
                    .text(
                        this.scene.scale.width * 0.35,
                        this.scene.scale.height * 0.1 * i,
                        `${this.scores[id].correct} / ${this.scores[id].total}`,
                        textStyle
                    )
                    .setOrigin(1, 0.5)
            );
            i += 1;
        }
    }

    private bindEventHandlers(): void {}
}
