import {
    loadBucketAudio,
    wait,
    loadBucketAssets,
    EASINGS,
    getQuizColors
} from "../utils";

export type QuizSceneBase = Phaser.Scene & {
    w(fraction?: number): number;
    h(fraction?: number): number;
    playSound(key: string, config?: Phaser.Types.Sound.SoundConfig): void;
    stopSound(key: string): void;
    getUiScene(): QuizUiSceneBase;
};

export type QuizUiSceneBase = Phaser.Scene & {
    showQuizQuestion(question: string): void;
    showMaskImage(show?: boolean): void;
    destroyQuizQuestion(instant?: boolean): void;
    getQuizQuestionText(): string | undefined;
    emitConfetti(duration?: number): void;
};

export interface QuizBaseConfig {
    question: string;
    onCompletion: (correctAnswer?: boolean) => void;
    instant?: boolean;
    canBeFailed?: boolean;
}

export abstract class QuizBase extends Phaser.GameObjects.Container {
    protected cScene: QuizSceneBase;

    private whiteOverlay: Phaser.GameObjects.Image;
    private neutralBackground: Phaser.GameObjects.Graphics;

    private question: string;
    private onCompletion: (correctAnswer?: boolean) => void;
    private backgroundColors: {
        topLeft: number;
        topRight: number;
        bottomLeft: number;
        bottomRight: number;
    };

    private completed: boolean;
    constructor(
        scene: QuizSceneBase,
        question: string,
        onCompletion: () => void,
        backgroundColors = {
            topLeft: getQuizColors().BACKGROUND_PRIMARY,
            topRight: getQuizColors().BACKGROUND_PRIMARY,
            bottomLeft: getQuizColors().BACKGROUND_SECONDARY,
            bottomRight: getQuizColors().BACKGROUND_SECONDARY
        }
    ) {
        super(scene, scene.scale.width * 0.5, scene.scale.height * 0.5);
        this.cScene = scene;
        this.question = question;
        this.onCompletion = onCompletion;
        this.completed = false;

        this.initializeBackgrounds(backgroundColors);

        this.add([this.whiteOverlay, this.neutralBackground]);

        this.showQuizQuestion();
        this.cScene.getUiScene().showMaskImage?.(false);

        this.bindSceneEventHandlers();
    }

    public static preloadAssets(scene: Phaser.Scene): void {
        loadBucketAudio(scene, {
            folderPath: ["Quizes", "Audio", "Optimized"],
            keyList: ["claps", "confetti_gun", "win", "wrong"]
        });
        loadBucketAssets(scene, {
            folderPath: ["Quizes"],
            keyList: ["checkmark"]
        });
    }

    private bindSceneEventHandlers(): void {
        this.scene.events.on("wake", () => {
            this.showQuizQuestion();
            this.cScene.getUiScene().showMaskImage?.(false);
        });
        this.scene.events.on("sleep", () => {
            this.destroyQuizQuestion();
            this.cScene.getUiScene().showMaskImage?.(true);
        });
    }

    protected async cleanup(): Promise<void> {
        // NOTE: This is a HACK. We give each quiz time to finish up camera tweens and so on so we can show the next quiz on the same scene.
        // TODO: Make sure every quiz is cleaning up after itself and only then proceed!
        await wait(500);
        this.scene.cameras.main.zoom = 1;
        this.scene.cameras.main.scrollX = 0;
        this.scene.cameras.main.scrollY = 0;
    }

    protected async complete(
        instant = false,
        correctAnswer = true
    ): Promise<void> {
        if (this.completed) {
            return;
        }
        this.completed = true;
        this.destroyQuizQuestion();
        if (!instant && correctAnswer) {
            await this.showWinAnimation();
        } else {
            // TODO: Show fail animation?
        }
        await this.cleanup();
        this.onCompletion =
            this.onCompletion ??
            (() => {
                console.warn("onCompletion() callback not provided!");
            });
        this.onCompletion(correctAnswer);
    }

    private initializeBackgrounds(backgroundColors: {
        topLeft: number;
        topRight: number;
        bottomLeft: number;
        bottomRight: number;
    }): void {
        this.whiteOverlay = this.scene.add.image(0, 0, "__WHITE");

        const width = this.cScene.w(1.2);
        const height = this.cScene.h(1.2);
        this.neutralBackground = this.scene.add.graphics();

        this.neutralBackground.fillGradientStyle(
            backgroundColors.topLeft,
            backgroundColors.topRight,
            backgroundColors.bottomLeft,
            backgroundColors.bottomRight
        );
        this.neutralBackground.fillRect(
            this.cScene.w(-0.6),
            this.cScene.h(-0.6),
            width,
            height
        );

        this.whiteOverlay.setDisplaySize(
            this.scene.scale.width * 1.2,
            this.scene.scale.height * 1.2
        );
    }

    public isCompleted(): boolean {
        return this.completed;
    }

    public showQuizQuestion(): void {
        const uiScene = this.cScene.getUiScene();
        if (uiScene) {
            uiScene.showQuizQuestion(this.question);
        }
    }

    public destroyQuizQuestion(instant = false): void {
        const uiScene = this.cScene.getUiScene();
        if (uiScene) {
            if (this.question === uiScene.getQuizQuestionText()) {
                uiScene.destroyQuizQuestion(instant);
            }
        }
    }

    private async showWinAnimation(): Promise<void> {
        const uiScene = this.cScene.getUiScene();
        if (uiScene) {
            uiScene.emitConfetti(1500);

            this.cScene?.playSound("confetti_gun", { volume: 0.05 });
            this.cScene?.playSound("claps", { volume: 0.1 });

            await wait(500);

            // Draw transparent green wipe across the screen
            const wipe = this.scene.add.rectangle(
                0,
                0,
                this.scene.scale.width,
                this.scene.scale.height,
                0x00ff00,
                0.5
            );
            wipe.setOrigin(0);
            wipe.setAlpha(0);
            wipe.setInteractive();
            wipe.setScrollFactor(0);

            // Tween to make the wipe visible
            this.scene?.tweens.add({
                targets: wipe,
                alpha: 0.5,
                duration: 500
            });

            // Tween to move the green banner horizontally
            const banner = this.scene.add.graphics();
            banner.fillStyle(0x31cb00);
            banner.fillRect(
                this.scene.scale.width,
                this.scene.scale.height / 2 - 100,
                this.scene.scale.width,
                250
            );
            banner.setAlpha(0);
            banner.setScrollFactor(0);

            this.scene?.tweens.add({
                targets: banner,
                x: 0,
                duration: 0,
                onComplete: () => {
                    // Tween to remove the green banner
                    this.scene?.tweens.add({
                        targets: banner,
                        alpha: 0.8,
                        x: -this.scene.scale.width,
                        duration: 200,
                        delay: 200,
                        onComplete: () => {}
                    });
                }
            });

            // Tween to animate the "checkmark" sprite
            const checkmark = this.scene.add.sprite(
                this.scene.scale.width / 2,
                this.scene.scale.height / 2,
                "checkmark"
            );
            checkmark.setOrigin(0.5);
            checkmark.setScale(0);
            checkmark.setScrollFactor(0);

            await wait(300);

            this.scene?.tweens.add({
                targets: checkmark,
                scale: 1.2,
                duration: 200,
                ease: EASINGS.BounceEaseOut
            });
            this.cScene?.playSound("win", { volume: 0.3 });

            await wait(2500);
        }
    }
}
