import {
    getCurrentLanguage,
    getTextColor,
    getKeywordHighlightColor,
    getKeywordsWithDefinitions,
    hexNumberToString,
    asyncAnimation,
    EASINGS,
    wait
} from "../utils";
import { BackgroundBox } from "./backgrounds/BackgroundBox";
import type BBCodeText from "phaser3-rex-plugins/plugins/bbcodetext";

export class ArkitectParagraphConfig {
    x?: number;
    y?: number;
    textStyle: Phaser.Types.GameObjects.Text.TextStyle;
    padding: number;
    backgroundConfig?: any;
}

export class ArkitectParagraph extends Phaser.GameObjects.Container {
    private config: ArkitectParagraphConfig;
    private keywords: string[];
    private bbCodeText: BBCodeText;
    private background: BackgroundBox;

    constructor(
        scene: Phaser.Scene,
        text: string,
        config?: Partial<ArkitectParagraphConfig>
    ) {
        super(scene, config?.x ?? 0, config?.y ?? scene.scale.height * 0.12);

        this.keywords = [];

        this.config = {
            x: 0,
            y: scene.scale.height * 0.12,
            padding: scene.scale.width * 0.03,
            textStyle: this.getDefaultTextStyle(scene),
            ...config
        };

        if (this.config.backgroundConfig?.width) {
            this.config.textStyle.wordWrap = {
                width:
                    this.config.backgroundConfig.width - this.config.padding * 2
            };
        }

        this.bbCodeText = this.scene.add.rexBBCodeText(
            this.scene.scale.width > 800
                ? this.scene.scale.width * 0.5
                : this.config.padding * 2,
            this.config.padding,
            this.addKeywordsToText(text),
            //@ts-ignore - NOTE: Types missmatch for color property. Harmlessly ignore
            this.config.textStyle
        );
        if (this.scene.scale.width > 800) {
            this.bbCodeText.setOrigin(0.5, 0);
        }

        this.bbCodeText.on("areaclick", (data: string) => {
            const knownKeywordsMap = getKeywordsWithDefinitions();
            //@ts-ignore
            this.scene.getUiScene()?.showToast(
                knownKeywordsMap.get(data.toLowerCase())?.definition ?? "",
                "top",
                "top",
                {
                    fontSize: "35px"
                },
                0,
                1
            );
        });

        this.background = new BackgroundBox(
            this.scene,
            0,
            0,
            this.config.backgroundConfig
        );

        this.resizeBackground();

        this.add([this.background, this.bbCodeText]);
        this.setSize(this.background.width, this.background.height);

        this.setDepth(5);
        this.setScrollFactor(0);

        // const typing = new TextTyping(this.bbCodeText, {
        //     speed: 300
        // });
        // typing.start(this._addKeywordsToText(text));

        this.scene.add.existing(this);
    }

    public addText(
        text: string,
        fromNewline = true,
        align = undefined,
        size = undefined
    ): void {
        if (align) {
            text = `[align=${align}]${text}[/align]`;
        }
        if (size) {
            text = `[size=${size}]${text}[/size]`;
        }
        this.bbCodeText.appendText(this.addKeywordsToText(text), fromNewline);

        this.resizeBackground();
    }

    public addImage(
        key: string,
        textureKey: string,
        align: string,
        config: {
            frame?: string;
            width?: number;
            height?: number;
            y?: number;
            left?: number;
            right?: number;
        }
    ): void {
        this.bbCodeText.addImage(key, { key: textureKey, ...config });

        const alignString = align ? `[align=${align}]` : "";
        const alignEndString = align ? "[/align]" : "";

        this.addText(`${alignString}[img=${key}]${alignEndString}`);

        this.resizeBackground();
    }
    /**
     *
     * @param {number} count - The number of empty lines to add.
     */
    public addEmptyLine(count = 1): void {
        for (let i = 0; i < count; i++) {
            this.addText("");
        }
    }

    /**
     *
     * Change displayed text in the paragraph. This will also resize the background box.
     * It will work properly only with standard settings.
     * TODO: Add support for custom width and height / passable configuration object
     */
    public async setText(
        text: string,
        instant = false,
        posY = 0.12,
        delay = 0
    ): Promise<void> {
        // reset keywords because we are clearing the text
        this.keywords = [];
        if (instant) {
            this.changeText(text);
            return;
        }
        await asyncAnimation(
            this.scene.tweens.add({
                targets: this,
                ease: EASINGS.SineEaseOut,
                duration: 250,
                alpha: 0,
                onComplete: (tween) => {
                    tween.emit("done");
                }
            })
        );
        this.setPositionY(posY);
        this.changeText(text);
        if (delay) {
            await wait(delay);
        }
        await asyncAnimation(
            this.scene.tweens.add({
                targets: this,
                ease: EASINGS.SineEaseIn,
                duration: 250,
                alpha: 1,
                onComplete: (tween) => {
                    tween.emit("done");
                }
            })
        );
    }

    public setPositionY(posY: number): void {
        this.y = this.scene.scale.height * posY;
    }

    private changeText(text: string): void {
        this.bbCodeText.setText(this.addKeywordsToText(text));
        this.background.resize(
            0,
            this.bbCodeText.height + this.config.padding * 2
        );
        const bounds = this.getBounds();
        this.setSize(bounds.width, bounds.height);
    }

    public setHeight(height: number): void {
        this.background.resize(0, height);
        const bounds = this.getBounds();
        this.setSize(bounds.width, bounds.height);
    }

    private resizeBackground(): void {
        this.background.resize(
            0,
            this.bbCodeText.height + this.config.padding * 2
        );
        const bounds = this.getBounds();
        this.setSize(bounds.width, bounds.height);
    }

    private getDefaultTextStyle(scene: Phaser.Scene) {
        return {
            fontFamily: "Mulish",
            fontSize: 32,
            color: hexNumberToString(getTextColor()),
            align: getCurrentLanguage() === "ar" ? "right" : "left",
            maxLines: 0,
            lineSpacing: 14,
            fixedWidth:
                scene.scale.width > 800 ? 800 : scene.scale.width * 0.88,
            fixedHeight: 0,
            wordWrap: {
                width: scene.scale.width > 800 ? 800 : scene.scale.width * 0.88
            },
            interactive: true
        } as Phaser.Types.GameObjects.Text.TextStyle;
    }

    private setKeywordsInText(text: string) {
        const keywordsData = getKeywordsWithDefinitions();

        if (keywordsData.size === 0) {
            return text;
        }
        const keywords = Array.from(keywordsData.keys());
        const regex = new RegExp(
            `(?<!\\[img=)\\b(${keywords.join("|")})\\b`,
            "gi"
        );
        const keywordedText = text.replace(regex, (match, keyword) => {
            // if (this.keywords.includes(keyword.toLowerCase())) {
            //     return match;
            // }
            this.keywords.push(keyword.toLowerCase());
            return `[area=${keyword}][color=${hexNumberToString(getKeywordHighlightColor())}][b]${keyword}[/b][/color][/area]`;
        });

        return keywordedText;
    }

    private addKeywordsToText(text: string) {
        const keywordedText = this.setKeywordsInText(text ?? "");

        return keywordedText;
    }
}
