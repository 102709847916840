import { useUserStore } from "../../../../store/userStore";
import { SFX, SFXManager } from "../../../utils/effects/SFXManager";
import { EASINGS } from "../../../utils/utils";

export class Spaceship extends Phaser.GameObjects.Container {
    private image: Phaser.GameObjects.Image;
    private flame: Phaser.GameObjects.Sprite;
    constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, x, y);

        SFXManager.initialize(this.scene);

        this.image = this.scene.add.image(0, 0, "userShip").setScale(0.5);
        this.flame = this.scene.add
            .sprite(-80, 0, "")
            .setAngle(-90)
            .setScale(0.5, 0.5)
            .play(SFX.JetFire10Loop);

        this.add([this.flame, this.image]);

        this.playWiggleAnimation();

        this.scene.add.existing(this);
    }

    public static preloadAssets(scene: Phaser.Scene) {
        SFXManager.preloadAssets(scene, {
            [SFX.JetFire10Loop]: true
        });
        const uStore = useUserStore();
        let userShipTexturePath = uStore.userPersonalization?.selected_ship
            ?.blob_link
            ? `${bucketBaseUrl}/${uStore.userPersonalization.selected_ship.blob_link}`
            : `${bucketBaseUrl}/General/Assets/Ships/1.png`;

        scene.load.image("userShip", userShipTexturePath);
    }

    private playWiggleAnimation(): void {
        this.scene.tweens.add({
            targets: [this.image, this.flame],
            y: 15,
            duration: 1000,
            ease: EASINGS.SineEaseInOut,
            yoyo: true,
            repeat: -1
        });
    }
}
