import { TexturesHelper } from "../../TexturesHelper";
import { EASINGS } from "../../utils";

export interface JoystickConfig {
    idleColor?: number;
    hoverColor?: number;
    range?: number;
}

export class Joystick extends Phaser.GameObjects.Arc {
    private config: Required<JoystickConfig>;
    private defaultPosition: { x: number; y: number };

    private setToPositionWithAnimationTween?: Phaser.Tweens.Tween;
    private resetToDefaultPositionTween?: Phaser.Tweens.Tween;

    private dragged: boolean;

    constructor(
        scene: Phaser.Scene,
        x: number,
        y: number,
        config?: JoystickConfig
    ) {
        super(scene, x, y, 100, 0, 360, false, 0x000000, 0.25);

        this.dragged = false;

        this.config = {
            idleColor: 0x000000,
            hoverColor: 0xffffff,
            range: 200,
            ...config
        };

        this.defaultPosition = { x, y };

        this.scene.add.existing(this);
    }

    static preloadAssets(scene: Phaser.Scene): void {}

    public moveJoystickTo(x: number, y: number) {
        const pos = this.getClampedPosition(x, y);
        this.setPosition(pos.x, pos.y);

        const { power, angle } = this.getPowerAndAngle(x, y);
        this.emit("position-changed", angle, power);
    }

    public setPositionWithAnimation(x: number, y: number): void {
        const pos = this.getClampedPosition(x, y);
        this.setToPositionWithAnimationTween = this.scene?.tweens.add({
            targets: this,
            x: pos.x,
            y: pos.y,
            duration: 300,
            ease: EASINGS.BackEaseOut,
            onUpdate: () => {
                const { power, angle } = this.getPowerAndAngle(this.x, this.y);
                this.emit("position-changed", angle, power);
            },
            onComplete: () => {
                this.setPosition(pos.x, pos.y);
            }
        });
    }

    public resetToDefaultPosition(): void {
        this.resetToDefaultPositionTween = this.scene?.tweens.add({
            targets: this,
            x: this.defaultPosition.x,
            y: this.defaultPosition.y,
            duration: 300,
            ease: EASINGS.BackEaseOut,
            onComplete: () => {
                this.setPosition(
                    this.defaultPosition.x,
                    this.defaultPosition.y
                );
            }
        });
    }

    public stopResettingToDefaultPosition(): void {
        this.resetToDefaultPositionTween?.stop();
    }

    public stopSetToPositionWithAnimation(): void {
        this.setToPositionWithAnimationTween?.stop();
    }

    public setDragged(val: boolean): void {
        this.dragged = val;
    }

    public isBeingDragged(): boolean {
        return this.dragged;
    }

    private getClampedPosition(x: number, y: number) {
        const distance = Phaser.Math.Distance.Between(
            this.defaultPosition.x,
            this.defaultPosition.y,
            x,
            y
        );
        const angle = Phaser.Math.Angle.Between(
            this.defaultPosition.x,
            this.defaultPosition.y,
            x,
            y
        );
        if (distance < this.config.range) {
            return { x, y };
        } else {
            const clampedX = 0 + Math.cos(angle) * this.config.range;
            const clampedY = 0 + Math.sin(angle) * this.config.range;
            return { x: clampedX, y: clampedY };
        }
    }

    private getPowerAndAngle(x: number, y: number) {
        const distance = Phaser.Math.Distance.Between(
            this.defaultPosition.x,
            this.defaultPosition.y,
            x,
            y
        );
        const angle = Phaser.Math.Angle.Between(
            this.defaultPosition.x,
            this.defaultPosition.y,
            x,
            y
        );
        const power = Math.min(distance / this.config.range, 1);

        let deg = Phaser.Math.RadToDeg(angle);
        deg = deg > 0 ? deg : 360 - Math.abs(deg);

        return { power, angle: deg };
    }
}
