import {
    EASINGS,
    getQuizColors,
    getTextColor,
    hexNumberToString,
    isBetween,
    wait
} from "../../utils";
import { QuizBase, type QuizBaseConfig, type QuizSceneBase } from "../QuizBase";
import { SquaresQuizRecord } from "./SquaresQuizRecord";

export interface SquaresQuizConfig extends QuizBaseConfig {
    options: SquaresQuizOptions;
}

export type SquaresQuizOptions = {
    answers: string[];
    correctAnswer: string;
};

export class SquaresQuiz extends QuizBase {
    private currentlyHighlightedRecord?: SquaresQuizRecord;
    private config: SquaresQuizConfig;

    private records: SquaresQuizRecord[];
    private questionBox: SquaresQuizRecord;

    private readonly COLORS = getQuizColors().SQUARES;
    private readonly SPACING = 36;
    private readonly DIMENSION;
    private readonly LEFT;
    private readonly RIGHT;
    private readonly TOP;
    private readonly BOTTOM;

    constructor(scene: QuizSceneBase, config: SquaresQuizConfig) {
        super(scene, config.question, config.onCompletion);

        this.currentlyHighlightedRecord = undefined;

        this.config = config ?? {
            question: "What is the default port for HTTP?",
            answers: ["80", "443", "22", "20"],
            instant: false,
            correctAnswer: "80",
            onCompletion: () => {}
        };

        this.DIMENSION = Math.min(
            500,
            scene.scale.width > 1000
                ? scene.scale.width * 0.4
                : scene.scale.width * 0.45
        );
        this.LEFT = -this.DIMENSION * 0.5 - this.SPACING * 0.5;
        this.RIGHT = this.DIMENSION * 0.5 + this.SPACING * 0.5;
        this.TOP = -this.scene.scale.height * 0.095;
        this.BOTTOM = this.TOP + this.DIMENSION + this.SPACING;

        this.initializeQuestionBox();
        this.initializeRecords();

        this.add([...this.records, this.questionBox]);

        this.bindEventHandlers();

        this.scene.add.existing(this);
    }

    static override preloadAssets(scene: Phaser.Scene): void {
        QuizBase.preloadAssets(scene);
        SquaresQuizRecord.preloadAssets(scene);
    }

    private initializeRecords(): void {
        this.records = [];
        Phaser.Utils.Array.Shuffle(this.config.options.answers);
        for (let i = 0; i < this.config.options.answers.length; i++) {
            const record = new SquaresQuizRecord(
                this.scene,
                0,
                0,
                this.config.options.answers[i],
                {
                    idleColor: this.COLORS[i].idle,
                    hoverColor: this.COLORS[i].hover,
                    textColor: hexNumberToString(this.COLORS[i].text),
                    backgroundAlpha: 0.75
                }
            );
            record.setColor(this.COLORS[i].idle);
            this.records.push(record);
        }

        this.records[0].setPosition(this.LEFT, this.TOP);
        this.records[0].setDefaultPosition(this.LEFT, this.TOP);
        this.records[1].setPosition(this.RIGHT, this.TOP);
        this.records[1].setDefaultPosition(this.RIGHT, this.TOP);
        this.records[2].setPosition(this.LEFT, this.BOTTOM);
        this.records[2].setDefaultPosition(this.LEFT, this.BOTTOM);
        this.records[3].setPosition(this.RIGHT, this.BOTTOM);
        this.records[3].setDefaultPosition(this.RIGHT, this.BOTTOM);
    }

    private initializeQuestionBox(): void {
        this.questionBox = new SquaresQuizRecord(
            this.scene,
            0,
            this.TOP + this.DIMENSION * 0.5 + this.SPACING * 0.5,
            "",
            {
                idleColor: 0x2c3e50,
                hoverColor: 0x34495e,
                textColor: "#ffffff",
                backgroundAlpha: 0.35
            }
        );
    }

    private bindEventHandlers(): void {
        this.records.forEach((record) => {
            record.on(Phaser.Input.Events.POINTER_DOWN, () => {
                this.questionBox.setPositionWithAnimation(record.x, record.y);
                record.highlight(true);
                this.currentlyHighlightedRecord = record;
                this.zoomCameraOnRecord(record);
            });
            record.on(Phaser.Input.Events.POINTER_UP, () => {
                this.handleUserAnswer();
                this.resetCamera();
            });
        });

        this.questionBox.on(Phaser.Input.Events.POINTER_UP, () => {
            this.handleUserAnswer();
            this.resetCamera();
        });
        this.questionBox.on(
            Phaser.Input.Events.POINTER_OUT,
            (pointer: Phaser.Input.Pointer) => {
                if (!pointer.isDown) {
                    return;
                }
                this.questionBox.resetToDefaultPosition();
                this.resetCamera();
                this.currentlyHighlightedRecord?.highlight(false);
                this.currentlyHighlightedRecord = undefined;
            }
        );
        this.questionBox.on(Phaser.Input.Events.DRAG_START, () => {});
        this.questionBox.on(Phaser.Input.Events.DRAG_END, () => {
            if (!this.currentlyHighlightedRecord) {
                this.questionBox.resetToDefaultPosition();
                this.resetCamera();
            } else {
                this.questionBox.setPositionWithAnimation(
                    this.currentlyHighlightedRecord.x,
                    this.currentlyHighlightedRecord.y
                );
            }
        });
        this.questionBox.on(
            Phaser.Input.Events.DRAG,
            (pointer: Phaser.Input.Pointer, dragX: number, dragY: number) => {
                this.questionBox.setPosition(dragX, dragY);
                if (!this.currentlyHighlightedRecord) {
                    for (const record of this.records) {
                        if (this.isQuestionAboveRecord(record)) {
                            this.currentlyHighlightedRecord = record;
                            record.highlight(true);
                            this.zoomCameraOnRecord(record);
                        }
                    }
                } else {
                    if (
                        !this.isQuestionAboveRecord(
                            this.currentlyHighlightedRecord
                        )
                    ) {
                        this.currentlyHighlightedRecord.highlight(false);
                        this.currentlyHighlightedRecord = undefined;
                        this.resetCamera();
                    }
                }
            }
        );
    }

    private isQuestionAboveRecord(record: SquaresQuizRecord): boolean {
        const precision = 150;
        return (
            isBetween(
                this.questionBox.x,
                record.x - precision,
                record.x + precision
            ) &&
            isBetween(
                this.questionBox.y,
                record.y - precision,
                record.y + precision
            )
        );
    }

    private isAnswerCorrect(): boolean {
        return (
            this.currentlyHighlightedRecord?.getAnswer() ===
            this.config.options.correctAnswer
        );
    }

    private handleUserAnswer(): void {
        if (!this.currentlyHighlightedRecord) {
            return;
        }
        if (this.config.canBeFailed) {
            this.complete(this.config.instant, this.isAnswerCorrect());
        } else {
            if (this.isAnswerCorrect()) {
                this.complete(this.config.instant);
            } else {
                this.disableInteractivity(true);
                this.currentlyHighlightedRecord.highlight(false);
                this.cScene.playSound("wrong", { volume: 0.3 });
                this.questionBox.resetToDefaultPosition();
                this.currentlyHighlightedRecord.shake().then(() => {
                    this.disableInteractivity(false);
                });
                this.currentlyHighlightedRecord = undefined;
                this.resetCamera();
            }
        }
    }

    private async zoomCameraOnRecord(record: SquaresQuizRecord): Promise<void> {
        this.scene.cameras.main.zoomTo(1.1, 350, EASINGS.BackEaseOut, true);
        this.scene.cameras.main.pan(
            this.x + record.x * 0.2,
            this.y + record.y * 0.2,
            350,
            EASINGS.BackEaseOut,
            true
        );
    }

    private async resetCamera(): Promise<void> {
        this.scene.cameras.main.zoomTo(1, 350, EASINGS.BackEaseOut, true);
        this.scene.cameras.main.pan(
            this.scene.scale.width * 0.5,
            this.scene.scale.height * 0.5,
            350,
            EASINGS.BackEaseOut,
            true
        );
    }

    private disableInteractivity(disable: boolean): void {
        if (disable) {
            this.questionBox.disableInteractive();
            for (const record of this.records) {
                record.disableInteractive();
            }
        } else {
            this.questionBox.setInteractive();
            for (const record of this.records) {
                record.setInteractive();
            }
        }
    }
}
