<template>
    <div>
        <ion-input type="email" :placeholder="$t('home.signup.email.placeholder')" clearInput
            class="ion-margin-top ion-margin-bottom custom" v-model="email" @input="checkEmail()"></ion-input>
        <div class="email-status center-text" v-if="emailStatusMessage" :style="{ fontSize: computedFontSize + 'px' }">
            <!-- Issue Ari - need help finding a way to get the translation onto data -->
            {{
                emailStatusMessage
            }}
        </div>

    </div>
</template>

<script>
import { useGuestStore } from '@/store/guestStore';
import debounce from 'lodash/debounce';

const gStore = useGuestStore();

export default {
    props: {
        fontSize: {
            type: String,
            default: 'md'
        }
    },
    emits: ['update:email', 'update:isEmailTaken'],
    data() {
        return {
            email: '',
            isEmailTaken: false,
            emailStatusMessage: 'Begin typing to check email availability.',
            domainExtensions: ['.com', '.cn', '.de', '.net', '.uk', '.org', '.ru', '.br', '.nl', '.it',
                '.fr', '.au', '.jp', '.pl', '.es', '.ca', '.se', '.in', '.us', '.kr',
                '.io', '.ch', '.mx', '.co', '.ir', '.edu', '.me', '.site'
            ]
        }
    },
    created() {
        this.debouncedCheckEmail = debounce(this.checkEmail, 400);
    },
    computed: {
        computedFontSize() {
            let size = 14;
            switch (this.fontSize) {
                case 'sm':
                    size = 12;
                    break;
                case 'lg':
                    size = 16;
                    break;
                default:
                    size = 14;
            }
            return size;
        }
    },
    methods: {
        async checkEmail() {
            const emailRegex = /^[^\s@,]+@[^\s@,]+\.[^\s@,]+$/;

            if (!emailRegex.test(this.email)) {
                this.updateEmailStatus(true, 'Invalid email format.');
                return;
            }

            if (this.domainExtensions.some(extension => this.email.endsWith(extension))) {
                try {
                    const response = await fetch(`${this.$config.public.API_BASE_URL}user/check_email/?email=${encodeURIComponent(this.email.toLowerCase())}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    const result = await response.json();
                    this.isEmailTaken = result.isTaken;
                    if (this.isEmailTaken) {
                        this.updateEmailStatus(true, 'This email is already taken.');
                    } else {
                        this.updateEmailStatus(false, 'Email is available!');
                    }
                } catch (error) {
                    console.error('Error checking email:', error);
                    this.updateEmailStatus(true, 'An error occurred while checking the email.');
                }
            } else {
                this.updateEmailStatus(true, 'Invalid email domain.');
            }
        },
        updateEmailStatus(isTaken, message) {
            this.isEmailTaken = isTaken;
            this.emailStatusMessage = message;
            this.$emit('update:isEmailTaken', this.isEmailTaken);
            if (!isTaken) {
                this.$emit('update:email', this.email);
            }
        },
    },
    watch: {
        email() {
            this.debouncedCheckEmail();
        },
        'gStore.userSignupData.email': {
            handler() {
                this.email = gStore.userSignupData.email;
            },
            immediate: true
        }
    }
};
</script>