import { MatchSidesQuiz } from "../../../utils/quizes/MatchSidesQuiz/MatchSidesQuiz";
import type { QuizSceneBase, QuizBase } from "../../../utils/quizes/QuizBase";
import {
    getQuestionConfigsFromStore,
    QuizType,
    type QuestionConfig,
    type QuizConfig
} from "../../../utils/quizes/quizUtils";
import { SlicesQuiz } from "../../../utils/quizes/SlicesQuiz/SlicesQuiz";
import { SquaresQuiz } from "../../../utils/quizes/SquaresQuiz/SquaresQuiz";
import { getCurrentLanguage, wait } from "../../../utils/utils";

export enum ExamDisplayerEvent {
    QuestionAnswered = "ExamDisplayerEvent:QuestionAnswered",
    ExamFinished = "ExamDisplayerEvent:ExamFinished"
}

export class ExamDisplayer extends Phaser.Events.EventEmitter {
    private scene: QuizSceneBase;

    private currentQuiz?: QuizBase;

    private availableQuestions: QuestionConfig[];

    private totalQuestionsCount = 0;
    private questionsAnswered = 0;
    private questionsAnsweredPerNode: {
        [key: string]: { correct: number; total: number };
    } = {};
    private score = 0;
    private maxScore = 0;

    constructor(scene: QuizSceneBase, nodeIds?: number[]) {
        super();

        try {
            this.scene = scene;

            // NOTE: Get all of the questions available for now
            this.availableQuestions = [
                ...getQuestionConfigsFromStore(undefined, undefined, nodeIds)
            ];
            if (
                !this.availableQuestions ||
                this.availableQuestions.length === 0
            ) {
                throw new Error("Question not found in store");
            }
            Phaser.Utils.Array.Shuffle(this.availableQuestions);

            this.totalQuestionsCount = this.availableQuestions.length;

            this.showNextQuestion();

            this.bindEventHandlers();
        } catch (error) {
            console.warn(error);
            console.warn("COULD NOT DISPLAY QUIZ. SKIPPING");
        }
    }

    public static preloadAssets(scene: Phaser.Scene) {
        MatchSidesQuiz.preloadAssets(scene);
        SquaresQuiz.preloadAssets(scene);
        SlicesQuiz.preloadAssets(scene);
    }

    public destroy(): void {
        this.currentQuiz?.destroy();
        super.destroy();
    }

    public update(time: number, dt: number): void {
        this.currentQuiz?.update(time, dt);
    }

    public getTotalQuestionsCount(): number {
        return this.totalQuestionsCount;
    }

    public getQuestionsAnsweredCount(): number {
        return this.questionsAnswered;
    }

    private bindEventHandlers(): void {}

    private showNextQuestion(): boolean {
        const question = this.availableQuestions.shift();
        if (!question) {
            return false;
        }
        const quizConfig = this.getQuizConfigFromQuestionConfig(question);
        this.initializeQuiz(quizConfig);
        return true;
    }

    private initializeQuiz(quizConfiguration: QuizConfig) {
        switch (quizConfiguration.quizType) {
            case QuizType.MatchSides:
                this.currentQuiz = new MatchSidesQuiz(
                    this.scene,
                    quizConfiguration.config
                );
                break;
            case QuizType.Squares:
                this.currentQuiz = new SquaresQuiz(
                    this.scene,
                    quizConfiguration.config
                );
                break;
            case QuizType.Slices:
                this.currentQuiz = new SlicesQuiz(
                    this.scene,
                    quizConfiguration.config
                );
                break;
        }
    }

    private getQuizConfigFromQuestionConfig(
        questionConfig: QuestionConfig
    ): QuizConfig {
        const language = getCurrentLanguage();
        const quizConfig: QuizConfig = {
            quizType: questionConfig.quiz_type,
            config: {
                question:
                    questionConfig.question[language] ??
                    questionConfig.question["en"],
                options:
                    questionConfig.options[language] ??
                    questionConfig.options["en"],
                instant: true,
                canBeFailed: true,
                onCompletion: async (correctAnswer?: boolean) => {
                    this.score += correctAnswer ? 1 : 0;
                    this.questionsAnswered++;
                    this.maxScore++;
                    if (this.questionsAnsweredPerNode[questionConfig.node]) {
                        this.questionsAnsweredPerNode[questionConfig.node]
                            .total++;
                        if (correctAnswer) {
                            this.questionsAnsweredPerNode[questionConfig.node]
                                .correct++;
                        }
                    } else {
                        this.questionsAnsweredPerNode[questionConfig.node] = {
                            correct: correctAnswer ? 1 : 0,
                            total: 1
                        };
                    }

                    this.emit(ExamDisplayerEvent.QuestionAnswered);

                    this.scene.cameras.main.fadeOut(250, 0, 0, 0);
                    await wait(250);
                    this.currentQuiz?.destroy();
                    if (!this.showNextQuestion()) {
                        this.emit(
                            ExamDisplayerEvent.ExamFinished,
                            this.score,
                            this.maxScore,
                            this.questionsAnsweredPerNode
                        );
                    }
                    this.scene.cameras.main.fadeIn(250, 0, 0, 0);
                }
            }
        };

        return quizConfig;
    }
}
