<template>
    <ion-content>
        <SubscriptionCard @completedSubscription="nextSlide(true)" />
        <ion-button fill="outline" @click="nextSlide(false)" class="maybe-later-button">
            <ion-text color="dark">{{ $t('home.signup.subscription.maybe_later')
                }}</ion-text>
        </ion-button>
    </ion-content>
</template>

<script>
import SubscriptionCard from '../subscription/SubscriptionCard.vue';
import GlobalIcon from '../GlobalIcon.vue';
import { arrowBackOutline } from 'ionicons/icons';

export default {
    name: 'SubscriptionSlide',
    emits: ['didSubscribe', 'loginComplete'],
    components: {
        GlobalIcon,
        SubscriptionCard
    },
    setup() {
        return {
            arrowBackOutline
        };
    },
    data() {
        return {
            swiper: null
        }
    },
    methods: {
        nextSlide(subscribed = false) {
            this.$emit('didSubscribe', subscribed);
            this.swiper.slideNext()
        }
    },
    mounted() {
        this.swiper = useSwiper()
    }
}
</script>

<style scoped>
.maybe-later-button {
    display: block;
    margin: 0 auto;
    text-align: center;
    width: 15em;
    padding-bottom: 6em;
}
</style>
