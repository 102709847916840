<template>
    <ion-modal :isOpen="isOpen" @didDismiss="closeModal">
        <div class="modal-content">
            <modalToolbar :nodeTitle="$t('patchnotes.title')" @closeModal="closeModal" />
            <div class="patch-notes">
                <PatchNotes />
            </div>
        </div>
    </ion-modal>
</template>

<script lang="ts">
import PatchNotes from './modal/PatchNotes.vue';
import { useModalStore } from '../store/modalStore';
import modalToolbar from './modal/modalToolbar.vue';

const mStore = useModalStore();

export default {
    components: {
        PatchNotes,
        modalToolbar
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        closeModal() {
            mStore.togglePatchNotesModal(false);
        }
    }
}
</script>

<style scoped>
ion-modal {
    --height: auto;
    --border-radius: 16px;
    position: absolute;
    padding: 3%;
    padding-bottom: 2%;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.patch-notes {
    width: 100%;
    max-width: 650px;
    padding: 1rem;
    flex: 1;
    overflow-y: auto;
}
</style>