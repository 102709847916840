import {
    RatingFeedbackModule,
    StarsFeedbackModuleEvent
} from "./RatingFeedbackModule";
import {
    LikeFeedbackModule,
    LikeFeedbackModuleEvent
} from "./LikeFeedbackModule";
import { TexturesHelper } from "../TexturesHelper";
import { FeedbackButton } from "./FeedbackButton";
import {
    EASINGS,
    asyncAnimation,
    getBackgroundColor,
    getTextColor,
    hexNumberToString
} from "../utils";
import {
    ProgressBarFeedbackModule,
    ProgressBarFeedbackModuleEvent
} from "./ProgressBarFeedbackModule";

export default class FeedbackPopup extends Phaser.GameObjects.Container {
    constructor(scene, x, y, config) {
        super(scene, x, y);

        this.showPromise = undefined;

        this.config = config ?? {
            headerText: "RATE US!",
            feedbackTypes: ["like", "rating"],
            progressBarOptions: [],
            showSubmitButton: true,
            onClose: undefined,
            sceneKey: this.scene.scene.key
        };

        this.feedback = {
            comment: "",
            rating: 0,
            liked: null
        };

        this.createdModules = [];

        this.popupWidth = this.scene.scale.width * 0.95;
        this.headerPadding = this.scene.scale.height * 0.05;
        this.buttonMargin = this.scene.scale.height * 0.05;
        this.buttonHeight = this.scene.scale.height * 0.1;
        this.modulesSpacing = this.scene.scale.height * 0.05;

        this._initializeFadingLayer();

        let backgroundHeight = 0;
        backgroundHeight += this._initializeHeaderText(this.config.headerText);
        backgroundHeight += this._initializeModules(this.config.feedbackTypes);
        if (this.config.showSubmitButton) {
            backgroundHeight += this._initializeButtons();
        } else {
            backgroundHeight += this.buttonMargin;
        }
        this._initializeBackground(backgroundHeight);
        this._repositionComponents();

        if (this.config.showSubmitButton) {
            this._drawButtonLines();
        }

        this._drawHeaderLine();

        this.add([this.background, this.headerText, this.headerLine]);

        if (this.config.showSubmitButton) {
            this.add([this.submitButton, this.buttonsLines]);
        }
        [
            this.commentFeedbackModule,
            this.likeFeedbackModule,
            this.ratingFeedbackModule,
            this.progressBarFeedbackModule
        ].forEach((module) => {
            if (!module) {
                return;
            }
            this.add(module);
        });

        // NOTE: This is a workaround for the comment feedback module of the default state
        this.feedback.comment =
            this.progressBarFeedbackModule?.getSelectedOption() ?? "";

        const bounds = this.getBounds();
        this.setSize(bounds.width, bounds.height);

        this._bindEventHandlers();

        scene.add.existing(this);

        // Input for comment
        // Doesn't work, but I think it might be possible
        // this.commentInput = scene.add.dom(x, y + 200).createFromHTML(`
        //     <textarea name="comment" rows="4" cols="50" placeholder="Enter your comment here..." style="font-size: 16px; padding: 10px; background-color: white; border: 1px solid #ccc; border-radius: 4px;"></textarea>
        // `);
        // this.commentInput.addListener('input');
        // this.commentInput.on('input', function (event) {
        //     if (event.target.name === 'comment') {
        //         this.feedback.comment = event.target.value;
        //     }
        // }, this);
    }

    static preloadAssets(scene) {
        RatingFeedbackModule.preloadAssets(scene);
        LikeFeedbackModule.preloadAssets(scene);
    }

    async open() {
        this.showPromise = new Promise((resolve) => {
            this.once("close", () => {
                resolve();
            });
        });
        await this.playShowAnimation();
        return this.showPromise;
    }

    async close() {
        this.config.onClose?.();
        await this.playShowAnimation(false);
        this.fadingLayer.destroy();
        this.emit("close");
        this.destroy();
    }

    async playShowAnimation(show = true) {
        this.scene?.tweens.add({
            targets: [this.fadingLayer],
            duration: 1000,
            fillAlpha: show ? 0.25 : 0,
            ease: EASINGS.QuadEaseOut
        });

        const destY = this.y;
        this.y = show ? -this.displayHeight : destY;

        await asyncAnimation(
            this.scene?.tweens.add({
                targets: [this],
                duration: 1000,
                y: show ? destY : -this.displayHeight,
                ease: show ? EASINGS.BackEaseOut : EASINGS.BackEaseIn,
                onComplete: (tween) => {
                    tween.emit("done");
                }
            })
        );
    }

    _initializeFadingLayer() {
        this.fadingLayer = this.scene.add
            .rectangle(
                this.scene.scale.width * 0.5,
                this.scene.scale.height * 0.5,
                this.scene.scale.width + this.scene.scale.width * 0.02,
                this.scene.scale.height + this.scene.scale.height * 0.01,
                0x000000,
                0
            )
            .setInteractive();
    }

    _initializeBackground(height) {
        TexturesHelper.createRoundedRectangleTexture(
            this.scene,
            "rateUsPopupBackground",
            this.scene.scale.width * 0.95,
            height,
            30,
            getBackgroundColor()
        );

        this.background = this.scene.add.image(0, 0, "rateUsPopupBackground");
    }

    _repositionComponents() {
        this.headerText.y =
            this.background.getTopLeft().y +
            this.headerText.height * 0.5 +
            this.headerPadding;

        let currentY =
            this.headerText.y +
            this.headerText.height * 0.5 +
            this.headerPadding;

        for (const module of this.createdModules) {
            module.y = currentY + module.displayHeight * 0.5;
            currentY += module.displayHeight + this.modulesSpacing;
        }

        if (this.config.showSubmitButton) {
            this.submitButton.y =
                this.background.getBottomCenter().y -
                this.submitButton.displayHeight * 0.5;
        }
    }

    _drawHeaderLine() {
        this.headerLine = this.scene.add.graphics();
        this.headerLine.lineStyle(2, 0xb9b9b9, 1);
        const h = this.headerText.y + this.headerText.height * 0.5 + 25;
        this.headerLine.lineBetween(
            -this.background.displayWidth * 0.5,
            h,
            this.background.displayWidth * 0.5,
            h
        );
    }

    _drawButtonLines() {
        this.buttonsLines = this.scene.add.graphics();
        this.buttonsLines.lineStyle(2, 0xb9b9b9, 1);
        const h = this.submitButton.y - this.submitButton.displayHeight * 0.5;
        this.buttonsLines.lineBetween(
            -this.background.displayWidth * 0.5,
            h,
            this.background.displayWidth * 0.5,
            h
        );
    }

    _initializeButtons() {
        TexturesHelper.createRoundedRectangleTexture(
            this.scene,
            "rateUsPopupButton",
            this.popupWidth,
            this.buttonHeight,
            {
                tl: 0,
                tr: 0,
                bl: 32,
                br: 32
            },
            getBackgroundColor()
        );

        this.submitButton = new FeedbackButton(
            this.scene,
            0,
            0,
            "rateUsPopupButton",
            false,
            "SUBMIT"
        );

        return this.buttonHeight + this.buttonMargin;
    }

    _initializeModules(feedbackTypes) {
        this.ratingFeedbackModule = undefined;
        this.likeFeedbackModule = undefined;
        this.commentFeedbackModule = undefined;
        this.progressBarFeedbackModule = undefined;

        let heightNeeded = 0;

        for (const feedbackType of feedbackTypes) {
            if (feedbackType === "like" && !this.likeFeedbackModule) {
                this.likeFeedbackModule = new LikeFeedbackModule(
                    this.scene,
                    0,
                    0
                );
                heightNeeded += this.likeFeedbackModule.displayHeight;
                this.createdModules.push(this.likeFeedbackModule);
                continue;
            }
            if (feedbackType === "comment" && !this.commentFeedbackModule) {
                continue;
            }
            if (feedbackType === "rating" && !this.ratingFeedbackModule) {
                this.ratingFeedbackModule = new RatingFeedbackModule(
                    this.scene,
                    0,
                    0
                );
                heightNeeded += this.ratingFeedbackModule.displayHeight;
                this.createdModules.push(this.ratingFeedbackModule);
                continue;
            }
            if (
                feedbackType === "progressBar" &&
                !this.progressBarFeedbackModule
            ) {
                this.progressBarFeedbackModule = new ProgressBarFeedbackModule(
                    this.scene,
                    0,
                    0,
                    {
                        options: this.config.progressBarOptions
                    }
                );
                heightNeeded += this.progressBarFeedbackModule.displayHeight;
                this.createdModules.push(this.progressBarFeedbackModule);
                continue;
            }
            console.warn(
                "WARNING: Not supported feedback type: " + feedbackType
            );
        }

        heightNeeded += (this.createdModules.length - 1) * this.modulesSpacing;

        return heightNeeded;
    }

    _initializeHeaderText(text) {
        this.headerText = this.scene.add
            .text(0, 0, text, {
                fontFamily: "Work Sans",
                fontSize: 60,
                color: hexNumberToString(getTextColor())
            })
            .setAlign("center")
            .setWordWrapWidth(this.popupWidth * 0.8)
            .setOrigin(0.5);

        return this.headerText.height + this.headerPadding * 2;
    }

    _bindEventHandlers() {
        this.ratingFeedbackModule?.on(
            StarsFeedbackModuleEvent.Rated,
            async (rating) => {
                this.feedback.rating = rating;
                if (!this.config.showSubmitButton) {
                    await this.submitFeedback();
                    this.close();
                }
            }
        );
        this.likeFeedbackModule?.on(
            LikeFeedbackModuleEvent.Rated,
            async (liked) => {
                this.feedback.liked = liked;
                if (!this.config.showSubmitButton) {
                    await this.submitFeedback();
                    this.close();
                }
            }
        );
        this.progressBarFeedbackModule?.on(
            ProgressBarFeedbackModuleEvent.Rated,
            async (comment) => {
                this.feedback.comment = comment;
                if (!this.config.showSubmitButton) {
                    await this.submitFeedback();
                    this.close();
                }
            }
        );

        if (this.config.showSubmitButton) {
            this.submitButton.on("pointerup", async () => {
                await this.submitFeedback();
                this.close();
            });
        }

        this.fadingLayer.on(Phaser.Input.Events.POINTER_UP, () => {
            this.close();
        });
    }

    async submitFeedback() {
        this.emit("submit", {
            comment: `${this.config.headerText}: ${this.feedback.comment}`,
            rating: this.feedback.rating === 0 ? null : this.feedback.rating,
            liked: this.feedback.liked !== null ? this.feedback.liked : null
        });
    }
}
