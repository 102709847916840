<template>
    <ion-content :forceOverscroll="false">
        <transition name="fade">
            <div v-show="!isLoading" id="phaser-game" class="phaser-canvas">
            </div>
        </transition>
        <transition name="fade">
            <PhaserLoading v-if="isLoading" class="loading-overlay"></PhaserLoading>
        </transition>
        <PhaserFeedback :sceneKey="sceneKey">
        </PhaserFeedback>
        <!-- Preload fonts here. This is a hack. -->
        <div style="font-family: Work Sans; position: absolute; left: -1000px;">.</div>
        <div style="font-family: Poppins; position: absolute; left: -1000px;">.</div>
        <div style="font-family: Pixelify Sans; position: absolute; left: -1000px;">.</div>
        <div style="font-family: Permanent Marker; position: absolute; left: -1000px;">.</div>
        <div style="font-family: Caveat Brush; position: absolute; left: -1000px;">.</div>
        <div style="font-family: Mulish; position: absolute; left: -1000px;">.</div>
    </ion-content>
</template>

<script lang="ts">
import Phaser from 'phaser'
import BBCodeTextPlugin from 'phaser3-rex-plugins/plugins/bbcodetext-plugin.js';
import TextTypingPlugin from 'phaser3-rex-plugins/plugins/texttyping-plugin.js';
import UIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';
import PhaserLoading from './PhaserLoading.vue'
import PhaserFeedback from '../PhaserFeedback.vue';
import { useUserStore } from '../../store/userStore';
import { useNodeStore } from '../../store/nodeStore';
import { useModalStore } from '../../store/modalStore'
import { useGuestStore } from '../../store/guestStore';
import { useVngStore } from '../../store/vngStore';
import GreatLibraryMainHallScene from '../../phaser/scenes/greatLibrary/GreatLibraryMainHallScene';
import GreatLibraryFlashCardsScene from '../../phaser/scenes/greatLibrary/GreatLibraryFlashCardsScene';
import GreatLibraryFlashCardsOptionsScene from '../../phaser/scenes/greatLibrary/GreatLibraryFlashCardsOptionsScene';
import GreatLibraryExamScene from '../../phaser/scenes/greatLibrary/GreatLibraryExamScene';
import MainScene from '../../phaser/mainScene';
import { GreatLibrarySceneKey } from '../../phaser/scenes/greatLibrary/GreatLibraryScenesManager';
import GreatLibraryUiScene from '../../phaser/scenes/greatLibrary/GreatLibraryUiScene';
import GreatLibraryExamOptionsScene from '../../phaser/scenes/greatLibrary/GreatLibraryExamOptionsScene';
import { COLORS, hexNumberToString } from '../../phaser/utils/utils';
import type { LayoutNodeType } from '../../interfaces/nodes';

const mStore = useModalStore();
const nStore = useNodeStore();
const uStore = useUserStore();
const gStore = useGuestStore();

export default {
    data() {
        return {
            game: null,
            isLoading: true,
            sceneKey: 'N/A'
        }
    },
    mounted() {
        this.addEventListeners()
        setTimeout(() => {
            this.initPhaser();
        }, 250);
    },
    beforeDestroy() {
        this.removeEventListeners()
    },
    beforeUnmount() {
        this.destroyPhaser()
    },
    components: {
        PhaserLoading,
        PhaserFeedback
    },
    methods: {
        resizeCanvas() {
            const DEFAULT_WIDTH = 720;
            const DEFAULT_HEIGHT = 1440;
            const DEFAULT_ASPECT_RATIO = DEFAULT_HEIGHT / DEFAULT_WIDTH;

            const MIN_ASPECT_RATIO = 1.2;
            const MAX_ASPECT_RATIO = 2.17;
            // 1.78
            const DEVICE_ASPECT_RATIO = Phaser.Math.Clamp(window.innerHeight / window.innerWidth, MIN_ASPECT_RATIO, MAX_ASPECT_RATIO);

            let width = DEFAULT_WIDTH;
            let height = DEFAULT_HEIGHT;

            // device is taller - 2.17
            if (DEFAULT_ASPECT_RATIO < DEVICE_ASPECT_RATIO) {
                height = width * DEVICE_ASPECT_RATIO;
                // device is wider 1.5 (we would add 240px)
            } else if (DEFAULT_ASPECT_RATIO > DEVICE_ASPECT_RATIO) {
                width = height / DEVICE_ASPECT_RATIO;
            }

            return { width, height };
        },
        initPhaser() {
            const { width, height } = this.resizeCanvas();
            const self = this;
            const nodeData = {
                scene_folder: nStore.availableNodeContent[nStore.selectedNodeID].scene_folder,
                node_id: nStore.selectedNodeID
            };
            const bootscene = (nStore.selectedNodeID === -1)
                ? [{
                    key: 'BootstrapScene',
                    create: function () {
                        this.scene.start(GreatLibrarySceneKey.MainHall, {
                            nodeData: nodeData,
                            nuxtConfigData: self.$config.public
                        });
                    },
                }, GreatLibraryMainHallScene, GreatLibraryUiScene, GreatLibraryFlashCardsOptionsScene, GreatLibraryFlashCardsScene, GreatLibraryExamOptionsScene, GreatLibraryExamScene]
                : [{
                    key: 'BootstrapScene',
                    create: function () {
                        this.scene.start('MainScene', {
                            nodeData: nodeData,
                            nuxtConfigData: self.$config.public,
                        });
                    },
                }, MainScene];
            const config = {
                type: Phaser.WEBGL,
                scale: {
                    width: width,
                    height: height,
                    autoCenter: Phaser.Scale.CENTER_BOTH,
                    mode: Phaser.Scale.FIT,
                },
                disablePreFX: false,
                disablePostFX: false,
                disableContextMenu: true,
                powerPreference: 'high-performance',
                parent: 'phaser-game',
                scene: bootscene,
                transparent: false,
                backgroundColor: hexNumberToString(this.darkMode ? COLORS.DARK_MODE.BACKGROUND : COLORS.LIGHT_MODE.BACKGROUND),
                pixelArt: false,
                antialias: true,
                roundedPixels: false,
                physics: {
                    default: 'matter',
                    matter: {
                        gravity: { y: 0 },
                        debug: false
                    }
                },
                plugins: {
                    scene: [{
                        key: 'rexUI',
                        plugin: UIPlugin,
                        mapping: 'rexUI'
                    }],
                    global: [{
                        key: 'rexBBCodeTextPlugin',
                        plugin: BBCodeTextPlugin,
                        start: true
                    },
                    {
                        key: 'rexTextTyping',
                        plugin: TextTypingPlugin,
                        start: true
                    },]
                }
            }
            console.log(config);
            this.game = new Phaser.Game(config)
        },
        isTouchingCanvas(event) {
            if (event.target === this.game.canvas) {
                this.game.input.enabled = true;
            } else {
                this.game.input.enabled = false;
            }
        },
        destroyPhaser() {
            if (this.game) {
                try {
                    this.game.destroy(true);
                } catch (e) {
                    console.error(e)
                }
            }
        },
        async handlePhaserCompletion() {
            if (uStore.loggedIn && uStore.user) {
                try {
                    await uStore.addNode(nStore.selectedNodeID);
                    await uStore.updateQuestlineDetails()
                } catch (err) {
                    console.log(err);
                }
            } else {
                gStore.updateCompletedNodes(nStore.selectedNodeID);
                gStore.submitActivity("node_completed", nStore.availableNodes[nStore.selectedNodeID]);
            }
            this.destroyPhaser()
        },
        addEventListeners() {
            document.addEventListener('phaserComplete', this.handlePhaserCompletion)
            document.addEventListener('phaserNext', this.handlePhaserNext)
            document.addEventListener('sceneChanged', this.handleSceneChanged)
            document.addEventListener('scene1Loaded', this.handleScene1Loaded)
            document.addEventListener('greatLibraryLoaded', this.handleGreatLibraryLoaded)
            document.addEventListener('feedbackButtonPressed', this.feedbackButtonPressed)
            document.addEventListener('touchstart', this.isTouchingCanvas)
        },
        removeEventListeners() {
            document.removeEventListener('phaserComplete', this.handlePhaserCompletion)
            document.removeEventListener('phaserNext', this.handlePhaserNext)
            document.removeEventListener('sceneChanged', this.handleSceneChanged)
            document.removeEventListener('scene1Loaded', this.handleScene1Loaded)
            document.removeEventListener('greatLibraryLoaded', this.handleGreatLibraryLoaded)
            document.removeEventListener('feedbackButtonPressed', this.feedbackButtonPressed)
            document.removeEventListener('touchstart', this.isTouchingCanvas)
        },
        handleSceneChanged(event: any) {
            // console.log('Scene changed!')
        },
        handleScene1Loaded() {
            console.log('Scene 1 loaded!')
            setTimeout(() => {
                this.isLoading = false
            }, 1000);
        },
        handleGreatLibraryLoaded() {
            console.log('Great Library loaded!')
            this.isLoading = false
        },
        feedbackButtonPressed(event: any) {
            this.sceneKey = event.detail.sceneKey
            mStore.togglePhaserFeedbackModal()
        },
        async handlePhaserNext() {
            this.isLoading = true;
            this.destroyPhaser();
            let nodeLayoutDetails: LayoutNodeType = nStore.layouts.nodes[nStore.selectedNodeID];
            await useVngStore().ship.startOrbitingTarget(
                nodeLayoutDetails?.x ?? 0,
                nodeLayoutDetails?.y ?? 0,
                nStore.selectedNodeID
            );
            this.initPhaser();
        }
    },
    computed: {
        darkMode() {
            const uStore = useUserStore();
            return uStore.userPrefs?.dark_mode ?? false;
        }
    }
}
</script>

<style scoped>
ion-modal {
    --box-shadow: none;
}

.phaser-canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
    background-color: transparent;
}

.loading-overlay {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>