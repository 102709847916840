import {
    EASINGS,
    getPrimaryColor,
    getPrimaryContrastColor,
    hexNumberToString,
    loadBucketAudio,
    TEXT_FONT
} from "../../utils";

export enum GraphicsButtonEvent {
    Pressed = "pressed",
    Released = "released"
}

export interface GraphicsButtonConfig {
    width: number;
    height: number;
    backgroundColor?: number;
    strokeColor?: number;
    strokeWidth?: number;
    text?: string;
    textStyle?: Phaser.Types.GameObjects.Text.TextStyle;
    image?: { key: string; frame?: string | number };
    imageScale?: number;
    imageTint?: number;
}

export class GraphicsButton extends Phaser.GameObjects.Container {
    private background: Phaser.GameObjects.Graphics;
    protected text?: Phaser.GameObjects.Text;
    protected image?: Phaser.GameObjects.Image;

    private config: GraphicsButtonConfig;

    private pressed: boolean;
    private disabled: boolean = false;

    constructor(
        scene: Phaser.Scene,
        x: number,
        y: number,
        config?: Partial<GraphicsButtonConfig>
    ) {
        super(scene, x, y);
        this.config = {
            width: 600,
            height: 150,
            backgroundColor: getPrimaryColor(),
            imageTint: getPrimaryContrastColor(),
            ...config
        };

        this.pressed = false;

        this.initializeBackground();
        this.tryInitializeText();
        this.tryInitializeImage();

        this.setSize(this.config.width, this.config.height);

        this.setInteractive({ cursor: "pointer" });

        this.bindEventHandlers();

        this.scene.add.existing(this);
    }

    public static preloadAssets(scene: Phaser.Scene): void {
        loadBucketAudio(scene, {
            folderPath: ["General", "Audio"],
            keyList: ["tap"]
        });
    }

    public disable(disable = true): void {
        this.disabled = disable;
        if (disable) {
            this.setAlpha(0.5);
            this.disableInteractive();
        } else {
            this.setAlpha(1);
            this.setInteractive({ cursor: "pointer" });
        }
    }

    public changeBackgroundColor(color: number): void {
        this.background.clear();
        this.background.fillStyle(color, 1);
        const width = this.config.width;
        const height = this.config.height;
        this.background.fillRoundedRect(
            -width / 2,
            -height / 2,
            width,
            height,
            24
        );
    }

    public changeText(text: string): void {
        if (this.text) {
            this.text.setText(text);
        }
    }

    public changeTexture(key: string, frame?: string | number): void {
        if (this.image) {
            this.image.setTexture(key, frame);
            this.image.setScale(this.config.imageScale ?? 1);
            this.image.setTintFill(this.config.imageTint);
        }
    }

    private initializeBackground(): void {
        this.background = this.scene.add.graphics();
        this.background.fillStyle(this.config.backgroundColor, 1);
        const width = this.config.width;
        const height = this.config.height;
        this.background.fillRoundedRect(
            -width / 2,
            -height / 2,
            width,
            height,
            24
        );

        if (this.config.strokeColor !== undefined) {
            this.background.lineStyle(
                this.config.strokeWidth ?? 4,
                this.config.strokeColor,
                1
            );
            this.background.strokeRoundedRect(
                -width / 2,
                -height / 2,
                width,
                height,
                24
            );
        }

        this.add(this.background);
    }
    private tryInitializeText(): void {
        if (!this.config.text) {
            return;
        }
        this.text = this.scene.add
            .text(0, 0, this.config.text, {
                color: hexNumberToString(getPrimaryContrastColor()),
                fontFamily: TEXT_FONT.WorkSans,
                fontSize: 40,
                ...this.config.textStyle
            })
            .setOrigin(0.5);

        this.add(this.text);
    }

    private tryInitializeImage(): void {
        if (!this.config.image) {
            return;
        }
        this.image = this.scene.add.image(
            0,
            0,
            this.config.image.key,
            this.config.image.frame
        );
        this.image.setScale(this.config.imageScale ?? 1);
        this.image.setTintFill(
            this.config.imageTint ?? getPrimaryContrastColor()
        );
        this.add(this.image);
    }

    private bindEventHandlers() {
        this.on(Phaser.Input.Events.POINTER_DOWN, () => {
            this.pressed = true;
            this.emit(GraphicsButtonEvent.Pressed);
            this.animatePress();
        });
        this.on(Phaser.Input.Events.POINTER_UP, () => {
            if (this.pressed) {
                this.scene.playSound?.("tap", { volume: 0.5 });
                this.emit(GraphicsButtonEvent.Released);
                this.pressed = false;
                this.animatePress(true);
            }
        });
        this.on(Phaser.Input.Events.POINTER_OUT, () => {
            this.pressed = false;
            this.animatePress(true);
        });
    }

    private animatePress(release = false): void {
        this.scene?.tweens.add({
            targets: [this],
            duration: release ? 200 : 200,
            ease: release ? EASINGS.BackEaseOut : EASINGS.CubicEaseOut,
            scale: release ? 1 : 0.95
        });
    }
}
