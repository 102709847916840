<template>
    <ion-popover @didPresent="didPresent()">
        <ion-content class="ion-padding" :forceOverscroll="false">
            <div class="text">
                <h3>{{ $t('streak.header') }}</h3>
                <div class="streak-container">
                    <span class="current-streak">{{ uStore.currentStreak }}</span>
                    <p class="days">{{ $t('streak.days') }}</p>
                </div>
            </div>
            <Vue3Lottie class="xfire" :delay="25" :animationData="xfireAnimation" :noMargin="true" autoplay
                :scale=".75" />
            <Vue3Lottie class="nightsky" :delay="100" :animationData="nightskyAnimation" :noMargin="true" autoplay
                :scale="2" :loop="false" />
            <p class="sub-text center-text">{{ $t('streak.message') }}</p>
        </ion-content>

        <Vue3Lottie class="cat" :delay="3750" :animationData="catAnimation" :noMargin="true" autoplay :scale="1"
            :loop="false" />
    </ion-popover>
</template>

<script lang="ts">
import { Vue3Lottie } from 'vue3-lottie';
import type { TranslateResult } from 'vue-i18n';

import audioMixin from '../,,/../mixins/audioMixin';
import { useUserStore } from '../store/userStore';
import xfireAnimation from '../assets/lotties/xfire/xfire.json';
import catAnimation from '../assets/lotties/cat/cat.json';
import nightskyAnimation from '../assets/lotties/nightsky/nightsky.json';

export default {
    mixins: [audioMixin],
    components: {
        Vue3Lottie
    },
    setup() {
        const uStore = useUserStore();

        return {
            uStore
        }
    },
    data() {
        return {
            xfireAnimation: xfireAnimation,
            catAnimation: catAnimation,
            nightskyAnimation: nightskyAnimation
        }
    },
    methods: {
        didPresent() {
            this.playStreakSound();
        }
    }
}
</script>

<style scoped>
ion-popover {
    border-radius: 16px;

}

ion-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    overflow: visible;
}

.cat {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.xfire {
    position: relative;
    top: 3.5rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    pointer-events: none;
    overflow: visible;
}

.nightsky {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 0;
    pointer-events: none;
    overflow: visible;
}

.text {
    text-align: center;
    z-index: 10;
    position: relative;
}

.sub-text {
    text-align: center;
    font-size: 0.75rem;
    color: var(--ion-color-medium);
    padding-top: .25rem;
}

.streak-container {
    position: absolute;
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
}

.current-streak {
    font-size: 6em;
    display: block;
}

.days {
    font-size: 1em;
}
</style>