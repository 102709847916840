import { useNodeStore } from "../../../store/nodeStore";
import { useUserStore } from "../../../store/userStore";
import { SFXManager } from "../effects/SFXManager";
import { EASINGS, getCurrentLanguage, wait } from "../utils";
import { SmokeCurtain } from "./SmokeCurtain";
import { SummaryCard, SummaryCardEvent } from "./SummaryCard";
import { SummarySpaceship } from "./SummarySpaceship";
import summaryCardPopupText from "../../local_text/summaryCardPopup.json";

export enum SummaryCardPopupEvent {
    NextNodeRequested = "nextNodeRequested",
    ExitToMapRequested = "exitToMapRequested"
}

export class SummaryCardPopup extends Phaser.GameObjects.Container {
    private darkeningLayer: Phaser.GameObjects.Rectangle;
    private lessonCompletedTextTop: Phaser.GameObjects.Text[];
    private lessonCompletedTextBottom: Phaser.GameObjects.Text[];
    private smokeCurtain: SmokeCurtain;
    private spaceship: SummarySpaceship;
    private summaryCard: SummaryCard;

    constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, x, y);

        SFXManager.initialize(this.scene);

        this.initializeCompletedText();
        this.initializeSmokeCurtain();
        this.initializeSpaceship();
        this.initializeDarkeningLayer();
        this.initializeSummaryCard();

        this.add([
            this.darkeningLayer,
            this.summaryCard,
            ...this.lessonCompletedTextTop,
            ...this.lessonCompletedTextBottom
        ]);

        this.bindEventHandlers();

        this.playShowPopupAnimation();

        this.scene.add.existing(this);
    }

    public static preloadAssets(scene: Phaser.Scene): void {
        SummarySpaceship.preloadAssets(scene);
        SmokeCurtain.preloadAssets(scene);
        SummaryCard.preloadAssets(scene);
    }

    private bindEventHandlers(): void {
        this.summaryCard.on(
            SummaryCardEvent.NextNodeRequested,
            (nodeId: number) => {
                this.emit(SummaryCardPopupEvent.NextNodeRequested, nodeId);
            }
        );
        this.summaryCard.on(SummaryCardEvent.ExitToMapRequested, () => {
            this.emit(SummaryCardPopupEvent.ExitToMapRequested);
        });
    }

    private async playShowPopupAnimation(): Promise<void> {
        await wait(1000);
        this.playShowLessonCompletedText();
        await wait(500);
        this.playShowSpaceshipFromBottom();
        await wait(2000);
        this.playFlySpaceshipAway();
        await wait(300);
        this.smokeCurtain.playShowSmokeAnimation();
        await wait(420);
        this.spaceship.stopSmokeParticles();
        this.playScatterLettersAnimation(this.lessonCompletedTextBottom);
        await wait(100);
        this.playScatterLettersAnimation(this.lessonCompletedTextTop);
        await wait(1000);
        this.summaryCard.setVisible(true);
        this.lessonCompletedTextTop.forEach((text) => {
            text.setVisible(false);
        });
        this.lessonCompletedTextBottom.forEach((text) => {
            text.setVisible(false);
        });
        this.smokeCurtain.playHideSmokeAnimation();
    }

    private createWordFromLetters(centerX: number, y: number, text: string) {
        const letterStyle = {
            fontFamily: "Work Sans",
            fontSize: "70px",
            fill: "#ffffff",
            fontStyle: "Bold",
            stroke: "#000000",
            strokeThickness: 2
        };

        const lettersArray: Phaser.GameObjects.Text[] = [];
        for (const letter of text) {
            const lastLetter = lettersArray[lettersArray.length - 1];
            const nextX = (lastLetter?.x ?? centerX) + (lastLetter?.width ?? 0);
            const letterText = this.scene.add
                .text(nextX, y, letter, letterStyle)
                .setOrigin(0, 0.5);
            letterText.setShadow(3, 3, "#333333", 2, true, false);
            lettersArray.push(letterText);
        }
        const lastLetter = lettersArray[lettersArray.length - 1];
        const totalWidth =
            lastLetter.x +
            lastLetter.width * 0.5 -
            lettersArray[0].x -
            lettersArray[0].width * 0.5;
        lettersArray.forEach((letter) => {
            letter.x -= totalWidth * 0.5 + 20;
        });
        return lettersArray;
    }

    private initializeCompletedText(): void {
        this.lessonCompletedTextTop = this.createWordFromLetters(
            0,
            -300,
            summaryCardPopupText[getCurrentLanguage()].lessonCompletedTopText
        );
        this.lessonCompletedTextBottom = this.createWordFromLetters(
            0,
            -200,
            summaryCardPopupText[getCurrentLanguage()].lessonCompletedBottomText
        );
        [
            ...this.lessonCompletedTextBottom,
            ...this.lessonCompletedTextTop
        ].forEach((text) => text.setScale(0));
    }

    private initializeSmokeCurtain(): void {
        this.smokeCurtain = new SmokeCurtain(this.scene, 0, 0).setDepth(6);
    }

    private initializeSpaceship(): void {
        this.spaceship = new SummarySpaceship(
            this.scene,
            this.scene.scale.width * 0.5,
            this.scene.scale.height * 1.5
        ).setDepth(5);
    }

    private initializeDarkeningLayer(): void {
        this.darkeningLayer = this.scene.add
            .rectangle(
                0,
                0,
                this.scene.scale.width,
                this.scene.scale.height,
                0x000000,
                0.5
            )
            .setInteractive()
            .setAlpha(0);

        this.scene.tweens.add({
            targets: this.darkeningLayer,
            alpha: 1,
            duration: 1000,
            ease: EASINGS.CubicEaseIn
        });
    }

    private initializeSummaryCard(): void {
        const nStore = useNodeStore();
        const uStore = useUserStore();

        const gemsEarned =
            nStore.getNodeStatus(nStore.selectedNodeID) === "completed" ? 2 : 5;

        this.summaryCard = new SummaryCard(this.scene, 0, 0, {
            gemsEarned,
            gemsTotal: (uStore.userPersonalization?.coins ?? 0) + gemsEarned,
            nextNodes: this.getNextNodes(),
            nodeName: nStore.availableNodes[nStore.selectedNodeID].name,
            streak: 3
        }).setVisible(false);
    }

    private playShowSpaceshipFromBottom(): void {
        this.scene.tweens.add({
            targets: this.spaceship,
            y: this.scene.scale.height * 0.6,
            duration: 1500,
            ease: EASINGS.BackEaseOut
        });
    }

    private playFlySpaceshipAway(): void {
        this.scene.tweens.add({
            targets: this.spaceship,
            y: -this.scene.scale.height * 0.5,
            duration: 1000,
            ease: EASINGS.BackEaseIn
        });
    }

    private playShowLessonCompletedText(): void {
        const letters = [
            ...this.lessonCompletedTextTop,
            ...this.lessonCompletedTextBottom
        ];
        let i = 0;
        for (const letter of letters) {
            this.scene.tweens.add({
                delay: 50 * i,
                targets: letter,
                scale: 1,
                duration: 500,
                ease: EASINGS.BackEaseOut
            });
            i++;
        }
    }

    private playScatterLettersAnimation(
        letters: Phaser.GameObjects.Text[]
    ): void {
        const distance = 1500;
        const angleStart = -150;
        const angleTotal = 120;
        for (let i = 0; i < letters.length; i++) {
            const angleStep = angleTotal / letters.length;
            const angle = angleStart + angleStep * i;
            const x = Math.cos(Phaser.Math.DegToRad(angle)) * distance;
            const y = Math.sin(Phaser.Math.DegToRad(angle)) * distance;
            this.scene.tweens.add({
                targets: letters[i],
                x: x,
                y: y,
                angle,
                duration: 500
            });
        }
    }

    private getNextNodes(): { id: number; name: string }[] {
        const nStore = useNodeStore();
        const nextNodes: { id: number; name: string }[] = [];
        const lookingAtAvailableNodes =
            nStore.getNodeStatus(nStore.selectedNodeID) === "completed";
        const edgesToLookFrom = lookingAtAvailableNodes
            ? nStore.availableEdges
            : nStore.unavailableEdges;

        for (const edgeName in edgesToLookFrom) {
            const [sourceId, destId] = edgeName.split("edge")[1].split("-");
            if (parseInt(sourceId) === nStore.selectedNodeID) {
                if (!nStore.availableNodeContent[destId].scene_folder) {
                    continue;
                }
                nextNodes.push({
                    id: parseInt(destId),
                    name: lookingAtAvailableNodes
                        ? nStore.availableNodes[parseInt(destId)].name
                        : nStore.unavailableNodes[parseInt(destId)].name
                });
            }
        }
        return nextNodes.slice(0, 4);
    }
}
