import { EASINGS, asyncAnimation } from "../../utils";
import type UIScene from "../UIscene";

export class TutorialOverlay extends Phaser.Events.EventEmitter {
    private scene: UIScene;

    private darkeningLayer: Phaser.GameObjects.Rectangle;
    private darkeningLayerMask: Phaser.Display.Masks.BitmapMask;
    private darkeningLayerMaskRectangle?: Phaser.GameObjects.Graphics;

    constructor(scene: UIScene) {
        super();
        this.scene = scene;

        this.initializeDarkeningLayer();
        this.initializeDarkeningLayerMask();

        this.bindEventHandlers();
    }

    public changeDarkeningLayerMaskWithRectangle(
        x: number,
        y: number,
        width: number,
        height: number
    ): void {
        this.darkeningLayerMaskRectangle?.destroy();
        this.darkeningLayerMaskRectangle = this.scene.make.graphics();
        this.darkeningLayerMaskRectangle.fillStyle(0xffffff);
        this.darkeningLayerMaskRectangle.fillRect(x, y, width, height);

        this.darkeningLayerMask?.setBitmap(this.darkeningLayerMaskRectangle);
        this.darkeningLayer?.setMask(this.darkeningLayerMask);
    }

    public async playShowDarkeningLayerAnimation(): Promise<void> {
        await asyncAnimation(
            this.scene?.tweens.add({
                targets: this.darkeningLayer,
                fillAlpha: 0.75,
                duration: 1000,
                ease: EASINGS.SineEaseOut,
                onComplete: (tween) => {
                    tween.emit("done");
                }
            })
        );
    }

    public changeDarkeningLayerMaskWithGameObject(
        obj: Phaser.GameObjects.GameObject
    ): void {
        this.darkeningLayerMask.setBitmap(obj);
        this.darkeningLayer.setMask(this.darkeningLayerMask);
    }

    public resetMask(): void {
        this.darkeningLayer.setMask(undefined);
    }

    public destroy(): void {
        this.darkeningLayer.destroy();
        this.darkeningLayerMask.destroy();
        this.darkeningLayerMaskRectangle?.destroy();
        super.destroy();
    }

    private initializeDarkeningLayer(): void {
        this.darkeningLayer = this.scene.add
            .rectangle(
                this.scene.w(0.5),
                this.scene.h(0.5),
                this.scene.w(),
                this.scene.h(),
                0x000000,
                0
            )
            .setInteractive();
    }

    private initializeDarkeningLayerMask(): void {
        this.darkeningLayerMask = new Phaser.Display.Masks.BitmapMask(
            this.scene
        );
        this.darkeningLayerMask.invertAlpha = true;
    }

    private bindEventHandlers(): void {
        this.darkeningLayer.on(Phaser.Input.Events.POINTER_UP, () => {
            this.emit(Phaser.Input.Events.POINTER_UP);
        });
    }
}
