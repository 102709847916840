import { useNodeStore } from "../../../store/nodeStore";
import { TexturesHelper } from "../TexturesHelper";
import {
    GraphicsButton,
    GraphicsButtonEvent
} from "../ui/buttons/GraphicsButton";
import {
    COLORS,
    getBackgroundColor,
    getCurrentLanguage,
    getSecondaryColor,
    getTertiaryColor,
    getTextColor,
    hexNumberToString,
    TEXT_FONT
} from "../utils";
import { NextNodeButton } from "./NextNodeButton";
import summaryCardPopupText from "../../local_text/summaryCardPopup.json";

export enum SummaryCardEvent {
    NextNodeRequested = "nextNodeRequested",
    ExitToMapRequested = "exitToMapRequested"
}

export interface SummaryCardConfig {
    nodeName: string;
    gemsEarned: number;
    gemsTotal: number;
    streak: number;
    nextNodes: { id: number; name: string }[];
}

export class SummaryCard extends Phaser.GameObjects.Container {
    private background: Phaser.GameObjects.Graphics;
    private astronaut?: Phaser.GameObjects.Image;
    private noMoreLessonsText?: Phaser.GameObjects.Text;
    private lessonCompleteText: Phaser.GameObjects.Text;
    private nodeNameText: Phaser.GameObjects.Text;
    private nextButtons: NextNodeButton[];
    private nextLessonText?: Phaser.GameObjects.Text;
    private orText: Phaser.GameObjects.Text;
    private exitButton: GraphicsButton;

    private earnedText: Phaser.GameObjects.Text;
    private earnedTextCount: Phaser.GameObjects.Text;
    private earnedGemImage: Phaser.GameObjects.Image;
    private divierLine: Phaser.GameObjects.Graphics;
    private totalText: Phaser.GameObjects.Text;
    private totalTextCount: Phaser.GameObjects.Text;
    private totalGemImage: Phaser.GameObjects.Image;
    private streakText: Phaser.GameObjects.Text;
    private streakTextCount: Phaser.GameObjects.Text;
    private streakFlameImage: Phaser.GameObjects.Image;

    private config: SummaryCardConfig;

    private readonly BACKGROUND_WIDTH: number;
    private readonly BACKGROUND_HEIGHT: number;
    private readonly PADDING: number = 15;

    private readonly EARNED_HEIGHT: number;
    private readonly DIVIDER_HEIGHT: number;
    private readonly TOTAL_HEIGHT: number;
    private readonly STREAK_HEIGHT: number;

    private readonly CALL_TO_ACTION_TEXTS: string[];

    constructor(
        scene: Phaser.Scene,
        x: number,
        y: number,
        config: SummaryCardConfig
    ) {
        super(scene, x, y);

        this.config = config;

        this.CALL_TO_ACTION_TEXTS = structuredClone(
            summaryCardPopupText[getCurrentLanguage()].callToActionText
        );

        this.BACKGROUND_WIDTH = this.scene.scale.width * 0.9;
        this.BACKGROUND_HEIGHT = this.scene.scale.height * 0.9;

        this.EARNED_HEIGHT = -this.BACKGROUND_HEIGHT * 0.325;
        this.DIVIDER_HEIGHT = -this.BACKGROUND_HEIGHT * 0.285;
        this.TOTAL_HEIGHT = -this.BACKGROUND_HEIGHT * 0.245;
        this.STREAK_HEIGHT = -this.BACKGROUND_HEIGHT * 0.165;

        this.setSize(this.BACKGROUND_WIDTH, this.BACKGROUND_HEIGHT);

        this.initializeBackground();
        this.initializeLessonCompleteText();
        this.initializeEarnedText();
        this.initializeEarnedTextCount();
        this.initializeEarnedGemImage();
        this.initializeDividerLine();
        this.initializeTotalText();
        this.initializeTotalTextCount();
        this.initializeTotalGemImage();
        this.initializeNodeNameText();
        this.initializeExitButtons();
        this.initializeOrText();
        this.initializeNextButtons();
        this.tryInitializeAstronaut();
        this.tryInitializeNextLessonText();
        this.initializeStreakText();
        this.tryInitializeNoMoreLessonsText();
        this.initializeStreakTextCount();
        this.initializeStreakFlameImage();

        this.add([
            this.background,
            this.lessonCompleteText,
            this.nodeNameText,
            this.earnedText,
            this.earnedTextCount,
            this.divierLine,
            this.totalText,
            this.totalTextCount,
            this.earnedGemImage,
            this.totalGemImage,
            ...this.nextButtons,
            this.orText,
            this.streakText,
            this.streakTextCount,
            this.streakFlameImage,
            this.exitButton
        ]);

        this.orText.setVisible(this.nextButtons.length > 0);

        if (this.nextLessonText) {
            this.add(this.nextLessonText);
        }

        if (this.astronaut) {
            this.add(this.astronaut);
            // NOTE: There's a bug with types for moveBelow method
            //@ts-ignore
            this.moveBelow(this.astronaut, this.lessonCompleteText);
        }

        if (this.noMoreLessonsText) {
            this.add(this.noMoreLessonsText);
        }

        this.bindEventHandlers();
        this.scene.add.existing(this);
    }

    public static preloadAssets(scene: Phaser.Scene): void {
        scene.load.image("gem", "images/gem.png");
        scene.load.image("flame", "images/flame.png");
        scene.load.image("astronaut", "images/astronaut.png");
    }

    private bindEventHandlers(): void {
        this.nextButtons.forEach((button) => {
            button.on(GraphicsButtonEvent.Released, () => {
                this.emit(
                    SummaryCardEvent.NextNodeRequested,
                    button.getNodeId()
                );
            });
        });
        this.exitButton.on(GraphicsButtonEvent.Released, () => {
            this.emit(SummaryCardEvent.ExitToMapRequested);
        });
    }

    private initializeBackground(): void {
        this.background = this.scene.add
            .graphics()
            .fillStyle(getBackgroundColor(), 1)
            .lineStyle(5, 0x000000)
            .fillRoundedRect(
                -this.BACKGROUND_WIDTH * 0.5,
                -this.BACKGROUND_HEIGHT * 0.5,
                this.BACKGROUND_WIDTH,
                this.BACKGROUND_HEIGHT,
                50
            );
    }

    private initializeLessonCompleteText(): void {
        this.lessonCompleteText = this.scene.add
            .text(
                0,
                -this.BACKGROUND_HEIGHT * 0.475,
                summaryCardPopupText[getCurrentLanguage()].header,
                {
                    fontFamily: TEXT_FONT.WorkSans,
                    fontSize: 50,
                    color: hexNumberToString(getTextColor())
                }
            )
            .setOrigin(0.5, 0);
    }

    private initializeEarnedText(): void {
        this.earnedText = this.scene.add
            .text(
                -250,
                this.EARNED_HEIGHT,
                summaryCardPopupText[getCurrentLanguage()].earned,
                {
                    fontFamily: TEXT_FONT.WorkSans,
                    fontSize: 50,
                    color: hexNumberToString(getTextColor())
                }
            )
            .setOrigin(0, 0.5);
    }

    private initializeEarnedTextCount(): void {
        this.earnedTextCount = this.scene.add
            .text(100, this.EARNED_HEIGHT, `${this.config.gemsEarned}`, {
                fontFamily: TEXT_FONT.WorkSans,
                fontSize: 50,
                color: hexNumberToString(getTextColor())
            })
            .setOrigin(1, 0.5);
    }

    private initializeEarnedGemImage(): void {
        this.earnedGemImage = this.scene.add
            .image(250, this.EARNED_HEIGHT, "gem")
            .setScale(0.15);
        this.earnedGemImage.x -= this.earnedGemImage.displayWidth * 0.5;
    }

    private initializeDividerLine(): void {
        this.divierLine = TexturesHelper.drawLine(
            this.scene,
            -this.BACKGROUND_WIDTH * 0.4,
            this.BACKGROUND_WIDTH * 0.4,
            this.DIVIDER_HEIGHT,
            this.DIVIDER_HEIGHT,
            3,
            getTextColor()
        );
    }

    private initializeTotalText(): void {
        this.totalText = this.scene.add
            .text(
                -250,
                this.TOTAL_HEIGHT,
                summaryCardPopupText[getCurrentLanguage()].total,
                {
                    fontFamily: TEXT_FONT.WorkSans,
                    fontSize: 50,
                    color: hexNumberToString(getTextColor())
                }
            )
            .setOrigin(0, 0.5);
    }

    private initializeTotalTextCount(): void {
        this.totalTextCount = this.scene.add
            .text(100, this.TOTAL_HEIGHT, `${this.config.gemsTotal}`, {
                fontFamily: TEXT_FONT.WorkSans,
                fontSize: 50,
                color: hexNumberToString(getTextColor())
            })
            .setOrigin(1, 0.5);
    }

    private initializeTotalGemImage(): void {
        this.totalGemImage = this.scene.add
            .image(250, this.TOTAL_HEIGHT, "gem")
            .setScale(0.15);
        this.totalGemImage.x -= this.totalGemImage.displayWidth * 0.5;
    }

    private initializeStreakText(): void {
        this.streakText = this.scene.add
            .text(
                -250,
                this.STREAK_HEIGHT,
                summaryCardPopupText[getCurrentLanguage()].streak,
                {
                    fontFamily: TEXT_FONT.WorkSans,
                    fontSize: 50,
                    color: hexNumberToString(getTextColor())
                }
            )
            .setOrigin(0, 0.5);
    }
    private initializeStreakTextCount(): void {
        this.streakTextCount = this.scene.add
            .text(100, this.STREAK_HEIGHT, `x${this.config.streak}`, {
                fontFamily: TEXT_FONT.WorkSans,
                fontSize: 50,
                color: hexNumberToString(getTextColor())
            })
            .setOrigin(1, 0.5);
    }
    private initializeStreakFlameImage(): void {
        this.streakFlameImage = this.scene.add
            .image(250, this.STREAK_HEIGHT, "flame")
            .setScale(0.15);
        this.streakFlameImage.x -= this.totalGemImage.displayWidth * 0.5;
    }

    private initializeNodeNameText(): void {
        this.nodeNameText = this.scene.add
            .text(0, -this.BACKGROUND_HEIGHT * 0.425, this.config.nodeName, {
                fontFamily: TEXT_FONT.WorkSans,
                fontSize: 60,
                fontStyle: "bold",
                color: hexNumberToString(getTextColor())
            })
            .setOrigin(0.5, 0);
    }

    private initializeNextButtons(): void {
        const nStore = useNodeStore();
        nStore.nod;
        this.nextButtons = [];
        for (const { id, name } of this.config.nextNodes) {
            const button = new NextNodeButton(this.scene, 0, 0, id, {
                text: name,
                height: 120,
                backgroundColor: getTertiaryColor(),
                textStyle: {
                    fontFamily: TEXT_FONT.WorkSans,
                    color: "#ffffff",
                    fontSize: 50,
                    fontStyle: "bold"
                }
                // topText: popRandom(this.CALL_TO_ACTION_TEXTS)
            });

            if (this.nextButtons.length > 0) {
                const prevButton =
                    this.nextButtons[this.nextButtons.length - 1];
                button.y =
                    prevButton.y -
                    prevButton.displayHeight * 0.5 -
                    button.displayHeight * 0.5 -
                    this.PADDING;
            } else {
                button.y =
                    this.orText.y -
                    this.orText.displayHeight -
                    button.displayHeight * 0.5 -
                    this.PADDING;
            }

            this.nextButtons.push(button);
        }
    }

    private tryInitializeNextLessonText(): void {
        if (this.nextButtons.length === 0) {
            return;
        }
        const topButton = this.nextButtons[this.nextButtons.length - 1];
        this.nextLessonText = this.scene.add
            .text(
                0,
                topButton.y - topButton.displayHeight * 0.5 - this.PADDING,
                summaryCardPopupText[getCurrentLanguage()].nextLessonText,
                {
                    fontFamily: TEXT_FONT.WorkSans,
                    fontSize: 40,
                    // fontStyle: "bold",
                    color: hexNumberToString(getTextColor())
                }
            )
            .setOrigin(0.5, 1);
    }

    private tryInitializeNoMoreLessonsText(): void {
        if (this.nextButtons.length > 0) {
            return;
        }
        this.noMoreLessonsText = this.scene.add
            .text(
                0,
                this.streakText.y + this.BACKGROUND_HEIGHT * 0.15,
                summaryCardPopupText[getCurrentLanguage()].noMoreLessonsText,
                {
                    fontFamily: TEXT_FONT.WorkSans,
                    fontSize: 40,
                    color: hexNumberToString(getTextColor())
                }
            )
            .setAlign("center")
            .setWordWrapWidth(this.BACKGROUND_WIDTH * 0.8)
            .setOrigin(0.5, 1);
    }

    private initializeOrText(): void {
        this.orText = this.scene.add
            .text(
                0,
                this.exitButton.y -
                    this.exitButton.displayHeight * 0.5 -
                    this.PADDING,
                summaryCardPopupText[getCurrentLanguage()].or,
                {
                    fontFamily: TEXT_FONT.WorkSans,
                    fontSize: 40,
                    // fontStyle: "bold",
                    color: hexNumberToString(getTextColor())
                }
            )
            .setOrigin(0.5, 1);
    }

    private tryInitializeAstronaut(): void {
        if (this.nextButtons.length > 0) {
            return;
        }
        this.astronaut = this.scene.add
            .image(0, 250, "astronaut")
            .setScale(0.5)
            .setAlpha(0.5);
    }

    private initializeExitButtons(): void {
        this.exitButton = new GraphicsButton(this.scene, 0, 0, {
            text: summaryCardPopupText[getCurrentLanguage()].returnToMap,
            height: 100,
            backgroundColor: getSecondaryColor(),
            textStyle: {
                fontFamily: TEXT_FONT.WorkSans,
                fontSize: 40,
                color: hexNumberToString(COLORS.LIGHT_MODE.TEXT)
            }
        });

        this.exitButton.y =
            this.displayHeight * 0.5 -
            this.exitButton.displayHeight * 0.5 -
            this.PADDING;
    }
}
