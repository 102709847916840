<template>
    <ion-modal ref="signupActionModal" :isOpen="isOpen" @didDismiss="closeModal">
        <ion-content :fullscreen="true" class="ion-padding">
            <modalToolbar class="ion-margin-top" :nodeTitle="''" @closeModal="closeModal" />
            <img class="logo center-text" src="~assets/logos/logo-dark.png" />
            <h1 style="position: inherit;" class="center-text keep-learning">{{ $t('home.signup.action.header') }}</h1>
            <div class="center-sub-container">
                <p> {{ $t('home.signup.action.sub.1') }}
                    <ion-text color="primary"> {{ $t('home.signup.action.sub.2') }}
                    </ion-text> {{ $t('home.signup.action.sub.3') }}
                </p>
            </div>
            <EmailInput @update:email=" email = $event" @update:isEmailTaken="isTaken = $event" fontSize="sm">
            </EmailInput>
            <ion-button expand="block" color="primary" class="ion-padding-top ion-padding-bottom"
                @click="updateUserData()" :disabled="isTaken"> {{ $t('home.signup.action.button') }}
            </ion-button>
            <sub class="center-text ion-padding-bottom">Or</sub>
            <Sso @loginComplete="closeModal"></Sso>
        </ion-content>
    </ion-modal>
</template>

<script>
import { useGuestStore } from '@/store/guestStore';
import modalToolbar from '~/components/modal/modalToolbar.vue';
import { useModalStore } from '@/store/modalStore';
import Sso from '~/components/Sso.vue'
import EmailInput from './EmailInput.vue';

const mStore = useModalStore();
const gStore = useGuestStore();

export default {
    name: 'signupActionModal',
    components: {
        Sso,
        modalToolbar,
        EmailInput
    },
    data() {
        return {
            email: '',
            isTaken: true
        }
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        closeModal() {
            if (mStore.isSignupActionModalOpen) {
                mStore.toggleSignupActionModal(false);
            }
        },
        updateUserData() {
            gStore.updateUserSignupData({ email: this.email });
            this.isTaken = true;
            mStore.toggleSignupActionModal(false);
            mStore.toggleSignupModal(true);
        }
    }
}
</script>

<style scoped>
ion-modal {
    padding-top: 0%;
}

ion-modal::part(backdrop) {
    background: rgb(54, 55, 57);
    opacity: 1;
}

.center-sub-container {
    margin-top: 2rem;
    padding-bottom: 15px;
    text-align: center;
}

.logo {
    width: 10rem;
    height: auto;
    align-content: center;
    margin: 0px auto;
}

.keep-learning {
    margin-top: 35px;
    font-size: 2em;
}
</style>
