import { useUserStore } from "../../../store/userStore";
import { SFX, SFXManager } from "../effects/SFXManager";
import { EASINGS, loadBucketAtlas } from "../utils";

export class SummarySpaceship extends Phaser.GameObjects.Container {
    private image: Phaser.GameObjects.Image;
    private flame: Phaser.GameObjects.Sprite;
    private smokeParticlesEmitter: Phaser.GameObjects.Particles.ParticleEmitter;
    constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, x, y);

        SFXManager.initialize(this.scene);

        this.image = this.scene.add.image(0, 0, "userShip").setAngle(-90);

        try {
            this.flame = this.scene.add
                .sprite(0, 200, "")
                .setFlipY(true)
                .play(SFX.JetFire10Loop);

            this.add(this.flame);
        } catch (error) {
            // If the animation doesn't exist, do nothing
            console.warn(error);
        }

        this.initializeSmokeParticles();

        this.add([this.smokeParticlesEmitter, this.image]);

        this.scene.add.existing(this);
    }

    public static preloadAssets(scene: Phaser.Scene) {
        SFXManager.preloadAssets(scene, {
            [SFX.JetFire10Loop]: true
        });
        loadBucketAtlas(scene, {
            folderPath: ["General", "Particles"],
            keyList: ["smoke"]
        });
        const uStore = useUserStore();
        let userShipTexturePath = uStore.userPersonalization?.selected_ship
            ?.blob_link
            ? `${bucketBaseUrl}/${uStore.userPersonalization.selected_ship.blob_link}`
            : `${bucketBaseUrl}/General/Assets/Ships/1.png`;

        scene.load.image("userShip", userShipTexturePath);
    }

    public stopSmokeParticles(): void {
        this.smokeParticlesEmitter.stop();
    }

    private initializeSmokeParticles(): void {
        this.smokeParticlesEmitter = this.scene.add.particles(0, 150, "smoke", {
            frame: [0, 1, 2],
            lifespan: 1200,
            angle: { min: -180, max: 180 },
            scale: { start: 0.5, end: 3, ease: EASINGS.CubicEaseOut },
            alpha: { start: 1, end: 0, ease: EASINGS.CubicEaseIn },

            speedY: { min: 500, max: 1000 },
            speedX: { min: -200, max: 200 },
            frequency: 50
        });
    }
}
