<template>
    <div>
        <div v-if="isLoading" class="sso-loading center-text">
            <ion-spinner name="lines-sharp"></ion-spinner>
        </div>
        <div v-if="!isLoading">
            <ion-button class="ion-margin-top ion-margin-bottom clear-button" mode="md" color="clear"
                @click="loginWithGoogle()">
                <GlobalIcon :iconName="logoGoogle" color="secondary" :size="'large'" />
            </ion-button>
            <ion-button v-if="showApple" class="ion-margin-top ion-margin-bottom clear-button" mode="md" color="clear"
                @click="loginWithApple()">
                <GlobalIcon :iconName="logoApple" color="secondary" :size="'large'" />
            </ion-button>
        </div>
    </div>
</template>

<script>
import GlobalIcon from '~/components/GlobalIcon.vue';
import { logoGoogle, logoApple } from 'ionicons/icons';
import { SsoService } from '@/services/SsoService.js';
import { useUserStore } from '@/store/userStore'
import { useFriendStore } from '@/store/friendStore';
import { useKeywordQuestionStore } from '@/store/keywordQuestionStore';
import { Capacitor } from "@capacitor/core";

const uStore = useUserStore()
const fStore = useFriendStore()
const kqStore = useKeywordQuestionStore()

export default {
    name: 'Sso',
    components: {
        GlobalIcon
    },
    setup() {
        return {
            logoGoogle,
            logoApple
        };
    },
    data() {
        return {
            ssoService: null,
            loggingIn: false,
            showApple: Capacitor.getPlatform() === 'ios',
            isLoading: false
        }
    },
    mounted() {
        this.ssoService = new SsoService(this.$config, uStore, fStore, kqStore, this.$router, this);
    },
    methods: {
        loginWithGoogle() {
            this.isLoading = true;
            this.ssoService.loginWithGoogle().then(() => {
                this.isLoading = false;
            }).catch((error) => {
                this.isLoading = false;
                console.error("Google login failed:", error);
            });
        },
        loginWithApple() {
            this.isLoading = true;
            this.ssoService.loginWithApple().then(() => {
                this.isLoading = false;
            }).catch((error) => {
                this.isLoading = false;
                console.error("Apple login failed:", error);
            });
        }
    }
}
</script>

<style scoped>
.clear-button {
    justify-content: center;
    align-items: center;
    width: 100%;
}

.sso-loading {
    justify-content: center;
    align-items: center;
    z-index: 100;
    padding: 1.75rem;
}
</style>