export const EVENTS = {
    UIScene: {
        buttonRightPressed: "event:buttonRightPressed",
        buttonLeftPressed: "event:buttonLeftPressed",
        nextSceneRequested: "event:nextSceneRequested",
        previousSceneRequested: "event:previousSceneRequested",
        homeButtonClicked: "event:homeButtonClicked",
        feebackButtonClicked: "event:feebackButtonClicked",
        keywordButtonClicked: "event:keywordButtonClicked",
        summaryCardNextNodeRequested: "event:summaryCardNextNodeRequested",
        summaryCardExitToMapRequested: "event:summaryCardExitToMapequested"
    }
};
