import {
    EASINGS,
    getQuizColors,
    getTextColor,
    hexNumberToString,
    isBetween,
    wait
} from "../../utils";
import { QuizBase, type QuizBaseConfig, type QuizSceneBase } from "../QuizBase";
import { Joystick } from "./Joystick";

export interface SlicesQuizConfig extends QuizBaseConfig {
    options: SlicesQuizOptions;
}

export type SlicesQuizOptions = {
    answers: {
        text: string;
        fontSize: number;
        wordWrapWidth?: number;
    }[];
    correctAnswer: string;
};

export class SlicesQuiz extends QuizBase {
    private inputBackground: Phaser.GameObjects.Rectangle;
    private shapes: {
        shape: Phaser.GameObjects.Graphics;
        highlighted: boolean;
        angleStart: number;
        angleEnd: number;
        points: { x: number; y: number }[];
    }[];
    private texts: Phaser.GameObjects.Text[];
    private lines: Phaser.GameObjects.Graphics;
    private joystick: Joystick;

    private currentlyHighlightedAnswer?: string;
    private config: SlicesQuizConfig;

    private scaleDownTween?: Phaser.Tweens.Tween;

    private readonly COLORS = getQuizColors().SLICES;

    constructor(scene: QuizSceneBase, config: SlicesQuizConfig) {
        super(scene, config.question, config.onCompletion);

        this.currentlyHighlightedAnswer = undefined;

        this.config = config ?? {
            question: "What is the default port for HTTP?",
            answers: ["80", "443", "22"],
            correctAnswer: "80",
            onCompletion: () => {}
        };

        this.initializeInputBackground();
        this.initializeJoystick();
        this.initializeLines();
        this.generateShapes();
        this.generateTexts();

        this.add([
            this.inputBackground,
            ...this.shapes.map((data) => data.shape),
            ...this.texts,
            this.lines,
            this.joystick
        ]);

        this.bindEventHandlers();

        this.scene.add.existing(this);
    }

    public static preloadAssets(scene: Phaser.Scene): void {
        Joystick.preloadAssets(scene);
        QuizBase.preloadAssets(scene);
    }

    protected async cleanup(): Promise<void> {
        // do not handle any more movement
        this.joystick.off("position-changed");
        this.inputBackground.off("pointerdown");
        this.inputBackground.off("pointerup");
        this.inputBackground.off("pointermove");
        // rest camera scroll and zoom levels to default
        await super.cleanup();
    }

    private initializeInputBackground(): void {
        this.inputBackground = this.scene.add
            .rectangle(
                0,
                0,
                this.scene.scale.width,
                this.scene.scale.height,
                0x000000,
                0.0
            )
            .setScrollFactor(0)
            .setInteractive();
    }

    private initializeJoystick(): void {
        this.joystick = new Joystick(this.scene, 0, 0);
    }

    // TODO: How to stop drawing line at the edge of the screen?
    private initializeLines(): void {
        this.lines = this.scene.add.graphics();
        this.lines.lineStyle(5, 0xffffff, 1);
        const step = 360 / this.config.options.answers.length;
        const angleOffset =
            this.config.options.answers.length % 2 === 0 ? 0 : 0;
        for (let i = 0; i < this.config.options.answers.length; i += 1) {
            const angle = Phaser.Math.DegToRad(angleOffset + i * step);
            let x = Math.cos(angle) * this.scene.scale.height * 0.7;
            let y = Math.sin(angle) * this.scene.scale.height * 0.7;

            this.lines.lineBetween(0, 0, x, y);
        }
    }

    private generateShapes(): void {
        this.shapes = [];
        const step = 360 / this.config.options.answers.length;

        for (let i = 0; i < this.config.options.answers.length; i += 1) {
            const shape = this.scene.add.graphics();
            shape.fillStyle(this.COLORS[i % this.COLORS.length].idle, 1);

            const angleStart = (i * step * Math.PI) / 180;
            const angleEnd =
                (((i + 1) % this.config.options.answers.length) *
                    step *
                    Math.PI) /
                180;
            let x1 = Math.cos(angleStart) * this.scene.scale.height * 0.7;
            let y1 = Math.sin(angleStart) * this.scene.scale.height * 0.7;
            let x2 = Math.cos(angleEnd) * this.scene.scale.height * 0.7;
            let y2 = Math.sin(angleEnd) * this.scene.scale.height * 0.7;

            shape.beginPath();
            shape.moveTo(0, 0);
            shape.lineTo(x1, y1);
            const corners = this.getCornersBetweenAngles(
                Phaser.Math.RadToDeg(angleStart),
                Phaser.Math.RadToDeg(angleEnd) === 0
                    ? 360
                    : Phaser.Math.RadToDeg(angleEnd)
            );
            for (let corner of corners) {
                shape.lineTo(corner.x, corner.y);
            }
            shape.lineTo(x2, y2);
            shape.closePath();
            shape.fillPath();

            this.shapes.push({
                shape,
                highlighted: false,
                angleStart: Phaser.Math.RadToDeg(angleStart),
                angleEnd:
                    Phaser.Math.RadToDeg(angleEnd) === 0
                        ? 360
                        : Phaser.Math.RadToDeg(angleEnd),
                points: [{ x: x1, y: y1 }, ...corners, { x: x2, y: y2 }]
            });
        }
    }

    private generateTexts(): void {
        this.texts = [];
        const step = 360 / this.config.options.answers.length;
        const offset = step / 2;
        const distance = this.scene.scale.height * 0.15;

        for (let i = 0; i < this.config.options.answers.length; i += 1) {
            const angle = Phaser.Math.DegToRad(step * i + offset);
            let x = Math.cos(angle) * distance;
            let y = Math.sin(angle) * distance;
            const text = this.scene.add
                .text(x, y, this.config.options.answers[i].text, {
                    fontFamily: "Work Sans",
                    fontSize: `${this.config.options.answers[i].fontSize}px`,
                    color: hexNumberToString(this.COLORS[i].text)
                })
                .setOrigin(0.5, 0.5);
            // TODO: AUTOMATICALLY DEDUCE CORRECT SIZE FOR TEXT
            if (this.config.options.answers[i].wordWrapWidth) {
                text.setWordWrapWidth(
                    this.config.options.answers[i].wordWrapWidth
                );
                text.setAlign("center");
            }
            this.texts.push(text);
        }
    }

    private getCornersBetweenAngles(
        angle1: number,
        angle2: number
    ): { x: number; y: number }[] {
        const corners = [];
        if (isBetween(45, angle1, angle2)) {
            // bottom right
            corners.push({
                x: this.scene.scale.width * 0.7,
                y: this.scene.scale.height * 0.7
            });
        }
        if (isBetween(135, angle1, angle2)) {
            // bottom left
            corners.push({
                x: -this.scene.scale.width * 0.7,
                y: this.scene.scale.height * 0.7
            });
        }
        if (isBetween(225, angle1, angle2)) {
            // top left
            corners.push({
                x: -this.scene.scale.width * 0.7,
                y: -this.scene.scale.height * 0.7
            });
        }
        if (isBetween(315, angle1, angle2)) {
            // top right
            corners.push({
                x: this.scene.scale.width * 0.7,
                y: -this.scene.scale.height * 0.7
            });
        }
        return corners;
    }

    private bindEventHandlers(): void {
        this.joystick.on("position-changed", (angle: number, power: number) => {
            this.scaleDownTween?.stop();
            this.joystick.setScale(Phaser.Math.Clamp(0.5 + power, 1, 1.5));
            this.scene.cameras.main.pan(
                this.x + Math.cos(Phaser.Math.DegToRad(angle)) * power * 100,
                this.y + Math.sin(Phaser.Math.DegToRad(angle)) * power * 100,
                350,
                EASINGS.BackEaseOut,
                true
            );
            if (!this.joystick.isBeingDragged()) {
                this.resetCamera();
                return;
            }
            if (power < 0.5) {
                this.turnAllShapedHighlightOff();
                this.currentlyHighlightedAnswer = undefined;
                return;
            }
            for (let i = 0; i < this.shapes.length; i += 1) {
                if (
                    isBetween(
                        angle,
                        this.shapes[i].angleStart,
                        this.shapes[i].angleEnd
                    )
                ) {
                    this.turnAllShapedHighlightOff();
                    this.highlightShape(i, true);
                    this.currentlyHighlightedAnswer =
                        this.config.options.answers[i].text;
                    break;
                }
            }
        });

        this.inputBackground.on(
            "pointerdown",
            (pointer: Phaser.Input.Pointer) => {
                const x = pointer.x - this.x;
                const y = pointer.y - this.y;
                this.joystick.setPositionWithAnimation(x, y);
                this.joystick.setDragged(true);
                this.scene.cameras.main.zoomTo(
                    1.1,
                    350,
                    EASINGS.BackEaseOut,
                    true
                );
            }
        );

        this.inputBackground.on("pointerup", () => {
            this.handleUserAnswer();
        });

        this.inputBackground.on(
            "pointermove",
            (pointer: Phaser.Input.Pointer) => {
                if (this.joystick.isBeingDragged()) {
                    this.joystick.stopSetToPositionWithAnimation();
                    this.joystick.stopResettingToDefaultPosition();
                    const x = pointer.x - this.x;
                    const y = pointer.y - this.y;
                    this.joystick.moveJoystickTo(x, y);
                }
            }
        );
    }

    private turnAllShapedHighlightOff(): void {
        for (let i = 0; i < this.shapes.length; i += 1) {
            this.highlightShape(i, false);
        }
    }

    private highlightShape(index: number, val: boolean): void {
        const shapeData = this.shapes[index];
        if (shapeData.highlighted === val) {
            return;
        }
        const shape = shapeData.shape;

        shape.clear();
        shape.fillStyle(
            val ? this.COLORS[index].hover : this.COLORS[index].idle,
            1
        );
        shape.beginPath();
        shape.moveTo(0, 0);
        for (const point of shapeData.points) {
            shape.lineTo(point.x, point.y);
        }
        shape.closePath();
        shape.fillPath();

        shapeData.highlighted = val;
    }

    private resetCamera(): void {
        this.scene.cameras.main.pan(
            this.scene.scale.width * 0.5,
            this.scene.scale.height * 0.5,
            350,
            EASINGS.BackEaseOut,
            true
        );
        this.scene.cameras.main.zoomTo(1, 350, EASINGS.BackEaseOut, true);
        this.scaleDownTween = this.scene?.tweens.add({
            targets: this.joystick,
            scale: 1,
            duration: 250,
            ease: EASINGS.BackEaseOut
        });
    }

    private handleUserAnswer(): void {
        if (!this.currentlyHighlightedAnswer) {
            this.reset();
            return;
        }
        if (this.config.canBeFailed) {
            this.complete(this.config.instant, this.isAnswerCorrect());
        } else {
            if (this.isAnswerCorrect()) {
                this.complete(this.config.instant);
            } else {
                this.reset();
            }
        }
    }

    private reset(): void {
        this.resetCamera();
        this.joystick.setDragged(false);
        this.joystick.resetToDefaultPosition();
        this.turnAllShapedHighlightOff();
    }

    private isAnswerCorrect(): boolean {
        return (
            this.currentlyHighlightedAnswer ===
            this.config.options.correctAnswer
        );
    }
}
