import { useKeywordQuestionStore } from "../../../store/keywordQuestionStore";
import type {
    MatchSidesQuizConfig,
    MatchSidesQuizOptions
} from "./MatchSidesQuiz/MatchSidesQuiz";
import type {
    SlicesQuizConfig,
    SlicesQuizOptions
} from "./SlicesQuiz/SlicesQuiz";
import type {
    SquaresQuizConfig,
    SquaresQuizOptions
} from "./SquaresQuiz/SquaresQuiz";

export enum QuizType {
    MatchSides = "MatchSides",
    Squares = "Squares",
    Slices = "Slices"
}

export type QuestionConfig = {
    id: number;
    question: { [key: string]: string };
    slot: number;
    first_time: boolean;
    node: number;
} & QuestionOptions;

export type QuestionOptions =
    | {
          quiz_type: QuizType.MatchSides;
          options: { [key: string]: MatchSidesQuizOptions };
      }
    | {
          quiz_type: QuizType.Squares;
          options: { [key: string]: SquaresQuizOptions };
      }
    | {
          quiz_type: QuizType.Slices;
          options: { [key: string]: SlicesQuizOptions };
      };

export type QuizConfig =
    | {
          quizType: QuizType.MatchSides;
          config: MatchSidesQuizConfig;
      }
    | {
          quizType: QuizType.Squares;
          config: SquaresQuizConfig;
      }
    | {
          quizType: QuizType.Slices;
          config: SlicesQuizConfig;
      };

export function getQuestionConfigsFromStore(
    slot?: number,
    firstTime?: boolean,
    nodeIds?: number[],
    includeUnknownQuestions?: boolean
): QuestionConfig[] {
    const store = useKeywordQuestionStore();
    let questions: QuestionConfig[] = includeUnknownQuestions
        ? [...store.unknownQuestions, ...store.knownQuestions]
        : store.knownQuestions;
    if (slot !== undefined) {
        questions = questions.filter((q) => q.slot === slot);
    }
    if (firstTime) {
        questions = questions.filter((q) => q.first_time === firstTime);
    }
    if (nodeIds) {
        questions = questions.filter((q) => nodeIds.includes(q.node));
    }

    return questions;
}
