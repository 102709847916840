<template>
    <ion-modal :isOpen="isOpen" @didDismiss="closeModal" :backdrop-dismiss="false">
        <div class="modal-container">
            <ion-toolbar class="lattice-modal">
                <ion-title class="ion-align-center lattice-modal-title">Finishing up</ion-title>
            </ion-toolbar>
            <ion-content v-if="!isLoading" class="ion-padding">
                <h1 class="center-text">Welcome to the community!</h1>
                <ion-text class="center-text">Get started by choosing a unique username or hit skip and we'll generate
                    one for you.</ion-text>
                <div class="ion-padding">
                    <UsernameInput :username="username" @update:username="username = $event"
                        @update:isUsernameTaken="isUsernameTaken = $event" />
                </div>
                <ion-button expand="block" color="primary" @click="updateUserInfo" class="ion-margin-top"
                    :disabled="isUsernameTaken || username.trim() === ''">Save</ion-button>
            </ion-content>
            <loading v-if="isLoading" />
        </div>
    </ion-modal>
</template>


<script>
import { useUserStore } from '@/store/userStore'
import { useModalStore } from '@/store/modalStore'
import GlobalIcon from '~/components/GlobalIcon.vue';
import UsernameInput from '~/components/signup/UsernameInput.vue';
import loading from '../loading.vue';

export default {
    name: 'SsoModal',
    props: ['isOpen'],
    components: {
        GlobalIcon,
        UsernameInput,
        loading
    },
    setup() {
        return {
        };
    },
    data() {
        return {
            userInfo: {},
            isLoading: false,
            username: '',
            isUsernameTaken: true
        }
    },
    methods: {
        async updateUserInfo() {
            try {
                this.isLoading = true;
                const uStore = useUserStore();
                await uStore.updateUser({ username: this.username });
                this.closeModal();
            } catch (err) {
                console.log('Error saving user:', err);
            } finally {
                this.isLoading = false;
            }
        },
        closeModal() {
            const mStore = useModalStore();
            if (mStore.isSsoModalOpen) {
                mStore.toggleSsoModal()
            }
        }
    }
}
</script>

<style scoped>
ion-input {
    color: #414042;
}

.swiper {
    width: 100%;
    height: 100%;
}

.modal-container {
    z-index: 2;
    position: relative;
    height: 100%;
}

.modal-background {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>
