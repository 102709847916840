import {
    EASINGS,
    getQuizColors,
    loadBucketAudio,
    popRandom,
    wait
} from "../../utils";
import { MorseCodeLine } from "./MorseCodeLine";
import { QuizBase, type QuizBaseConfig, type QuizSceneBase } from "../QuizBase";
import { MatchSidesQuizRecord } from "./MatchsidesQuizRecord";

export interface MatchSidesQuizConfig extends QuizBaseConfig {
    options: MatchSidesQuizOptions;
}

export type MatchSidesQuizOptions = [string, string][];

export class MatchSidesQuiz extends QuizBase {
    private colors: number[];
    private currentColor: number;
    private leftOptions: string[];
    private rightOptions: string[];
    private connecting: boolean;
    private connectionsAnimationInProgress: boolean;

    private config: MatchSidesQuizConfig;

    private recordsMap: Map<string, MatchSidesQuizRecord>;
    private colorsMap: Map<MatchSidesQuizRecord, number>;
    private recordToConnectionMap: Map<
        MatchSidesQuizRecord,
        Phaser.GameObjects.Graphics
    >;
    private connectionToRecordsMap: Map<
        Phaser.GameObjects.Graphics,
        { records: MatchSidesQuizRecord[]; color: number }
    >;

    private liveConnectionGraphics: Phaser.GameObjects.Graphics;
    private connectionStart?: MatchSidesQuizRecord;
    private connectionEnd?: MatchSidesQuizRecord;
    private leftRecords: MatchSidesQuizRecord[];
    private rightRecords: MatchSidesQuizRecord[];

    private morseCodeLine: MorseCodeLine;

    constructor(scene: QuizSceneBase, config: MatchSidesQuizConfig) {
        super(scene, config.question, config.onCompletion);

        this.connecting = false;
        this.connectionStart = undefined;
        this.connectionEnd = undefined;

        this.connectionsAnimationInProgress = false;

        this.config = config ?? {
            options: [
                ["A", "1"],
                ["B", "2"],
                ["C", "3"],
                ["D", "4"]
            ],
            instant: false,
            onCompletion: () => {
                console.log("quiz completed!");
            }
        };

        this.colors = getQuizColors().MATCH_SIDES;
        this.currentColor = popRandom(this.colors);

        this.leftOptions = Phaser.Utils.Array.Shuffle(
            this.config.options.map((option) => option[0])
        );
        this.rightOptions = Phaser.Utils.Array.Shuffle(
            this.config.options.map((option) => option[1])
        );

        this.leftRecords = [];
        this.rightRecords = [];

        this.recordsMap = new Map();
        this.colorsMap = new Map();

        this.liveConnectionGraphics = this.scene.add.graphics();
        this.recordToConnectionMap = new Map();
        this.connectionToRecordsMap = new Map();

        this._initializeLeftRecords();
        this._initializeRightRecords();

        this.morseCodeLine = new MorseCodeLine(
            this.scene,
            -this.scene.scale.height * 0.3,
            0,
            "YOU FOUND THE SECRET",
            0xffffff,
            0.5
        )
            .setAngle(90)
            .setScale(0.75);

        this.add([
            this.morseCodeLine,
            ...this.leftRecords,
            ...this.rightRecords,
            this.liveConnectionGraphics
        ]);

        this._bindEventHandlers();

        this.scene.add.existing(this);
    }

    public static preloadAssets(scene: Phaser.Scene): void {
        QuizBase.preloadAssets(scene);
        MatchSidesQuizRecord.preloadAssets(scene);
        loadBucketAudio(scene, {
            folderPath: ["Quizes", "Audio", "Optimized"],
            keyList: [
                "rubber_snap_hard",
                "rubber_snap_soft",
                "rubber_stretch",
                "cut1"
            ]
        });
    }

    public override update(time: number, dt: number) {
        if (this.connectionsAnimationInProgress) {
            this.connectionToRecordsMap.forEach((value, connection) => {
                connection.clear();
                connection.lineStyle(12, value.color, 1);
                connection.fillStyle(value.color);
                connection.fillCircle(
                    value.records[0].getAnchorX(),
                    value.records[0].y,
                    16
                );
                connection.fillCircle(
                    value.records[1].getAnchorX(),
                    value.records[1].y,
                    16
                );
                connection.lineBetween(
                    value.records[0].getAnchorX(),
                    value.records[0].y,
                    value.records[1].getAnchorX(),
                    value.records[1].y
                );
            });
        }
    }

    _initializeLeftRecords() {
        const spacingY = Math.min(
            200,
            (this.scene.scale.height * 0.6) / this.leftOptions.length
        );
        for (let i = 0; i < this.leftOptions.length; i++) {
            const record = new MatchSidesQuizRecord(
                this.scene,
                -this.scene.scale.width * 0.25,
                -this.scene.scale.height * 0.15 + spacingY * i,
                this.leftOptions[i],
                true
            );
            this.leftRecords.push(record);
            this.recordsMap.set(this.leftOptions[i], record);
        }
    }

    _initializeRightRecords() {
        const spacingY = Math.min(
            200,
            (this.scene.scale.height * 0.6) / this.rightOptions.length
        );
        for (let i = 0; i < this.rightOptions.length; i++) {
            const record = new MatchSidesQuizRecord(
                this.scene,
                this.scene.scale.width * 0.25,
                -this.scene.scale.height * 0.15 + spacingY * i,
                this.rightOptions[i],
                false
            );
            this.rightRecords.push(record);
            this.recordsMap.set(this.rightOptions[i], record);
        }
    }

    _bindEventHandlers() {
        [...this.leftRecords, ...this.rightRecords].forEach((record) => {
            record.on("pointerdown", () => {
                this.connecting = true;
                this.connectionStart = record;

                this._clearOldConnection(record);
            });
            record.on("drag", () => {
                record.stopResettingToDefaultPosition();
            });
            record.on("pointerup", () => {
                this.connecting = false;
                this.connectionEnd = record;
                if (!this.connectionStart || !this.connectionEnd) {
                    return;
                }
                if (
                    this.connectionStart === this.connectionEnd ||
                    !this._isConnectingOpposites(
                        this.connectionStart,
                        this.connectionEnd
                    )
                ) {
                    this.connectionStart?.resetToDefaultPosition();
                    this._clearLiveConnection();
                    return;
                }
                this._clearOldConnection(record);
                record.stopResettingToDefaultPosition();
                this._addNewConnection();

                if (this._getAnswers().length === this.config.options.length) {
                    this._handleAllMatched();
                }
            });
        });

        this.scene.input.on("pointermove", (pointer: Phaser.Input.Pointer) => {
            if (!this.connecting || !this.connectionStart) {
                return;
            }
            this._drawLiveConnection(
                this.connectionStart.getAnchorX(),
                this.connectionStart.y,
                pointer.x - this.x,
                pointer.y - this.y
            );

            const force = this._getForceBetweenTwoPoints(
                this.connectionStart.getAnchorX(),
                this.connectionStart.y,
                pointer.x - this.x,
                pointer.y - this.y
            );

            this.connectionStart?.setPositionOffset(force.x, force.y);
        });

        this.scene.input.on("pointerup", () => {
            if (this.connecting) {
                this.connectionStart?.resetToDefaultPosition();
                this._clearLiveConnection();
            }
        });
    }

    _drawLiveConnection(x1: number, y1: number, x2: number, y2: number) {
        this.liveConnectionGraphics.clear();
        this.liveConnectionGraphics.lineStyle(
            12,
            this.currentColor ?? 0x000000,
            1
        );
        this.liveConnectionGraphics.fillStyle(this.currentColor ?? 0x000000);
        this.liveConnectionGraphics.fillCircle(x1, y1, 16);
        this.liveConnectionGraphics.fillCircle(x2, y2, 16);
        this.liveConnectionGraphics.lineBetween(x1, y1, x2, y2);
    }

    _clearLiveConnection() {
        this.liveConnectionGraphics.clear();
        this.connecting = false;
        this.connectionStart = undefined;
        this.connectionEnd = undefined;
    }

    _addNewConnection() {
        this.cScene?.playSound("rubber_snap_soft", { volume: 0.25 });
        const connection = this.scene.add.graphics();
        this.add(connection);

        if (!this.connectionStart || !this.connectionEnd) {
            return;
        }

        // adding two references to the same connection because we don't know which one player might click first
        this.recordToConnectionMap.set(this.connectionStart, connection);
        this.recordToConnectionMap.set(this.connectionEnd, connection);

        const forceStart = this._getForceBetweenTwoPoints(
            this.connectionStart.getAnchorX(),
            this.connectionStart.y,
            this.connectionEnd.getAnchorX(),
            this.connectionEnd.y
        );
        const forceEnd = this._getForceBetweenTwoPoints(
            this.connectionEnd.getAnchorX(),
            this.connectionEnd.y,
            this.connectionStart.getAnchorX(),
            this.connectionStart.y
        );

        this.connectionStart.setPositionOffset(forceStart.x, forceStart.y);

        const tween = this.connectionEnd.setPositionOffset(
            forceEnd.x,
            forceEnd.y,
            false
        );

        const startX = this.connectionStart.getAnchorX();
        const startY = this.connectionStart.y;
        let endX = this.connectionEnd.getAnchorX();
        let endOffsetXValue = this.connectionEnd.getAnchorXOffsetValue();
        let endY = this.connectionEnd.y;
        const color = this.currentColor;

        this.connectionToRecordsMap.set(connection, {
            records: [this.connectionStart, this.connectionEnd],
            color
        });

        tween?.on(
            "update",
            (
                tween: Phaser.Tweens.Tween,
                key: string,
                target: Phaser.GameObjects.GameObject,
                current: number
            ) => {
                if (key === "x") {
                    endX = current;
                } else if (key === "y") {
                    endY = current;
                }
                connection.clear();
                connection.lineStyle(12, color ?? 0x000000, 1);
                connection.fillStyle(color ?? 0x000000);
                connection.fillCircle(startX, startY, 16);
                connection.fillCircle(endX + endOffsetXValue, endY, 16);
                connection.lineBetween(
                    startX,
                    startY,
                    endX + endOffsetXValue,
                    endY
                );
            }
        );

        this.connectionStart.setColor(color);
        this.connectionEnd.setColor(color);

        this.colorsMap.set(this.connectionStart, this.currentColor);
        this.colorsMap.set(this.connectionEnd, this.currentColor);

        this.connectionStart.setConnection(this.connectionEnd);
        this.connectionEnd.setConnection(this.connectionStart);

        this.currentColor = popRandom(this.colors);

        this._clearLiveConnection();
    }

    _isConnectingOpposites(
        record1: MatchSidesQuizRecord,
        record2: MatchSidesQuizRecord
    ) {
        const leftRecord = this.leftRecords.find(
            (record) => record === record1 || record === record2
        );
        const rightRecord = this.rightRecords.find(
            (record) => record === record1 || record === record2
        );

        if (!leftRecord || !rightRecord) {
            return false;
        }

        return (
            this.leftRecords.includes(leftRecord) &&
            this.rightRecords.includes(rightRecord)
        );
    }

    _clearOldConnection(record: MatchSidesQuizRecord, playCutSound = true) {
        const connectedRecord = record.getConnection();
        if (connectedRecord) {
            if (playCutSound) {
                this.cScene?.playSound("cut1", { volume: 0.25 });
            }
            connectedRecord.setConnection(undefined);
            record.setConnection(undefined);

            const connection = this.recordToConnectionMap.get(record);
            if (connection) {
                this.connectionToRecordsMap.delete(connection);
                connection.destroy();
            }
            this.recordToConnectionMap.delete(record);
            this.recordToConnectionMap.delete(connectedRecord);

            record.clearColor();
            connectedRecord.clearColor();

            this.colors.push(this.colorsMap.get(record) ?? 0x000000);
            this.colorsMap.delete(record);
            this.colorsMap.delete(connectedRecord);

            record.resetToDefaultPosition();
            connectedRecord.resetToDefaultPosition();

            return;
        }
    }

    _getAnswers() {
        const answers: [string, string][] = [];
        this.leftRecords.forEach((record) => {
            const connection = record.getConnection();
            if (connection) {
                answers.push([record.getText(), connection.getText()]);
            }
        });
        return answers;
    }

    _areAnswersCorrect() {
        const answers = this._getAnswers();
        return answers.every((answer) => {
            const [left, right] = answer;
            return this.config.options.some(
                (config) => config[0] === left && config[1] === right
            );
        });
    }

    _getWrongAnswers() {
        const answers = this._getAnswers();
        return answers.filter((answer) => {
            const [left, right] = answer;
            return !this.config.options.some(
                (config) => config[0] === left && config[1] === right
            );
        });
    }

    _getWrongRecords() {
        const wrongAnswers = this._getWrongAnswers();
        const wrongRecords: MatchSidesQuizRecord[] = [];
        wrongAnswers.forEach((answer) => {
            const [left, right] = answer;
            const leftRecord = this.recordsMap.get(left);
            const rightRecord = this.recordsMap.get(right);
            if (!leftRecord || !rightRecord) {
                return;
            }
            wrongRecords.push(leftRecord);
            wrongRecords.push(rightRecord);
        });
        return wrongRecords;
    }

    _getForceBetweenTwoPoints(x1: number, y1: number, x2: number, y2: number) {
        const xDistance = x2 - x1;
        const yDistance = y2 - y1;
        return {
            x: (xDistance / this.scene.scale.width) * 0.5,
            y: (yDistance / this.scene.scale.height) * 0.5
        };
    }

    async _handleAllMatched() {
        await wait(200);
        const isCorrect = this._areAnswersCorrect();
        // TODO: Create a generic method to handle quiz result in QuizBase class
        if (this.config.canBeFailed) {
            this.complete(this.config.instant, isCorrect);
        } else {
            this.scene.cameras.main.zoomTo(
                0.9,
                1000,
                EASINGS.QuadEaseOut,
                true
            );
            await this._playStretchRecordsAnimation();
            this.cScene.stopSound("rubber_stretch");
            this.scene.cameras.main.zoomTo(1, 250, EASINGS.BackEaseOut, true);
            // handle win
            if (isCorrect) {
                this.complete(this.config.instant, isCorrect);

                // handle wrong matches
            } else {
                this.cScene.playSound("rubber_snap_hard", { volume: 0.25 });
            }
        }
    }

    async _playStretchRecordsAnimation() {
        this.cScene.playSound("rubber_stretch", { volume: 0.25 });
        this.connectionsAnimationInProgress = true;
        const wrongRecords = this._getWrongRecords();
        this.connectionToRecordsMap.forEach((value) => {
            value.records.forEach(async (record) => {
                const isWrong = wrongRecords.includes(record);
                await record.playStretchAnimation(isWrong);
                if (isWrong) {
                    this._clearOldConnection(record, false);
                }
            });
        });
        await wait(1000);
        this.connectionsAnimationInProgress = false;
    }
}
