import {
    ProgressBar,
    type ProgressBarCustomColors
} from "../../scenes/ui/widgets/ProgressBar";
import {
    GraphicsButton,
    GraphicsButtonEvent
} from "../ui/buttons/GraphicsButton";
import {
    getBackgroundColor,
    getTextColor,
    loadBucketAssets,
    TEXT_FONT
} from "../utils";
import type UIScene from "./UIscene";

export const TopBarEvents = {
    homeButtonClicked: "homeButtonClicked",
    feebackButtonClicked: "feebackButtonClicked",
    keywordButtonClicked: "keywordButtonClicked"
};

export class TopBar extends Phaser.GameObjects.Container {
    private homeButton: GraphicsButton;
    private feedbackButton: GraphicsButton;
    private progressBar: ProgressBar;
    private sceneNumberText: Phaser.GameObjects.Text;

    private maskImage: Phaser.GameObjects.Image;

    private padding: { x: number; y: number };
    private sceneCount: number;

    constructor(
        scene: UIScene,
        padding: { x: number; y: number },
        sceneCount: number,
        customProgressBarColors: ProgressBarCustomColors
    ) {
        super(scene, 0, 0);

        this.padding = padding;
        this.sceneCount = sceneCount;

        this.initializeHomeButton();
        this.initializeFeedbackButton();
        this.initializeProgressBar(customProgressBarColors);
        this.initializeSceneNumberText();
        this.initializeMaskImage();

        this.add([
            this.maskImage,
            this.homeButton,
            this.feedbackButton,
            this.progressBar,
            this.sceneNumberText
        ]);

        this.bindEventHandlers();

        this.scene.add.existing(this);
    }

    public static preloadAssets(scene: Phaser.Scene, folderName: string): void {
        loadBucketAssets(scene, {
            folderPath: ["Icons"],
            keyList: ["close", "feedback"]
        });
        loadBucketAssets(scene, {
            folderPath: ["General", "Masks"],
            keyList: ["rectangular"]
        });
    }

    public handleEnterDebugMode(): void {
        this.sceneNumberText.setVisible(true);
    }

    public updateProgressBar(index: number, visitedIndexes: number[]): void {
        this.progressBar.updateProgress(index, visitedIndexes);
    }

    public hideProgressBar(hide: boolean = true): void {
        this.progressBar.setVisible(!hide);
    }

    public updateSceneNumberText(index: number): void {
        this.sceneNumberText.setText(`${index + 1}`);
    }

    public showMaskImage(show: boolean = true): void {
        this.maskImage.setVisible(show);
    }

    public initializeHomeButton(): void {
        this.homeButton = new GraphicsButton(
            this.scene,
            this.padding.x,
            this.padding.y,
            {
                width: 80,
                height: 80,
                image: { key: "close" },
                imageScale: 0.3
            }
        );

        this.homeButton.x += this.homeButton.displayWidth * 0.5;
        this.homeButton.y += this.homeButton.displayHeight * 0.5;
    }

    public initializeFeedbackButton(): void {
        const feedbackButtonPadding =
            this.homeButton.displayWidth + this.padding.x * 1.5;

        this.feedbackButton = new GraphicsButton(
            this.scene,
            feedbackButtonPadding,
            this.padding.y,
            {
                width: 80,
                height: 80,
                image: { key: "feedback" },
                imageScale: 0.1
            }
        );

        this.feedbackButton.x += this.feedbackButton.displayWidth * 0.5;
        this.feedbackButton.y += this.feedbackButton.displayHeight * 0.5;
    }

    public initializeProgressBar(
        customProgressBarColors: ProgressBarCustomColors
    ): void {
        const progressBarX = this.feedbackButton.x + this.padding.x * 1.5;

        this.progressBar = new ProgressBar(
            this.scene,
            progressBarX,
            this.padding.y,
            this.sceneCount,
            customProgressBarColors
        );
    }

    private initializeSceneNumberText(): void {
        this.sceneNumberText = this.scene.add
            .text(this.scene.scale.width * 1 - 50, 50, `1`, {
                fontFamily: TEXT_FONT.WorkSans,
                fontSize: 100,
                color: getTextColor()
            })
            .setOrigin(1, 0)
            .setVisible(this.scene.registry.get("debugMode"));
    }

    private initializeMaskImage(): void {
        this.maskImage = this.scene.add
            .image(this.scene.scale.width * 0.5, 0, "rectangular")
            .setFlipY(true)
            .setDisplaySize(this.scene.scale.width, 400)
            .setOrigin(0.5, 0)
            .setTint(getBackgroundColor());
    }

    private bindEventHandlers(): void {
        this.homeButton.on(GraphicsButtonEvent.Released, () => {
            this.emit(TopBarEvents.homeButtonClicked);
        });

        this.feedbackButton.on(GraphicsButtonEvent.Released, () => {
            this.emit(TopBarEvents.feebackButtonClicked);
        });
    }
}
