import { useUserStore } from "../../../../store/userStore";
import { useGuestStore } from "../../../../store/guestStore";
import { ArkitectParagraph } from "../../ui/ArkitectParagraph";
import { HandPointer } from "../../ui/hand/HandPointer";
import { EASINGS } from "../../utils";
import type UIScene from "../UIscene";
import { TutorialOverlay } from "./TutorialOverlay";

enum AppExplanationTutorialStep {
    WelcomeText,
    ExplainProgressBar,
    ExplainFeedback,
    ExplainQuit,
    FinishTutorial
}

export class AppExplanationTutorial extends Phaser.Events.EventEmitter {
    private scene: UIScene;

    private tutorialOverlay: TutorialOverlay;
    private text: ArkitectParagraph;
    private handPointer: HandPointer;

    private tutorialStep: AppExplanationTutorialStep =
        AppExplanationTutorialStep.WelcomeText - 1;
    private tutorialDelay: number = 6000;
    private tutorialInteractive: boolean = false;

    constructor(scene: UIScene) {
        super();
        this.scene = scene;
    }

    static preloadAssets(scene: Phaser.Scene): void {
        HandPointer.preloadAssets(scene);
    }

    public startTutorial(delay?: number): void {
        this.scene.time.delayedCall(delay ?? this.tutorialDelay, async () => {
            await this.tutorialOverlay.playShowDarkeningLayerAnimation();
            this.nextStep();
            this.tutorialInteractive = true;
        });
        this.tutorialOverlay = new TutorialOverlay(this.scene);

        this.text = new ArkitectParagraph(this.scene, "", {
            y: this.scene.h(-0.5),
            textStyle: {
                fontSize: 50
            }
        });

        this.handPointer = new HandPointer(this.scene);

        this.bindEventHandlers();
    }

    private bindEventHandlers(): void {
        this.tutorialOverlay.on(Phaser.Input.Events.POINTER_UP, () => {
            if (!this.tutorialInteractive) {
                return;
            }
            this.nextStep();
        });
    }

    private nextStep(): void {
        this.tutorialStep++;

        switch (this.tutorialStep) {
            case AppExplanationTutorialStep.WelcomeText:
                this.welcomeStep();
                break;
            case AppExplanationTutorialStep.ExplainProgressBar:
                this.explainProgressBarStep();
                break;
            case AppExplanationTutorialStep.ExplainFeedback:
                this.explainFeedbackStep();
                break;
            case AppExplanationTutorialStep.ExplainQuit:
                this.explainQuitStep();
                break;
            case AppExplanationTutorialStep.FinishTutorial:
                this.finishTutorialStep();
                break;
        }
    }

    private welcomeStep(): void {
        this.text.setText(
            "Welcome!\n\nHere's a quick tutorial that'll teach you how to use the app.\n\nYou can continue through the lesson by tapping this button.\n\nTap anywhere to continue."
        );

        const nextButton = this.scene.bottomBar?.getRightButton();

        if (!nextButton) {
            return;
        }

        this.tutorialOverlay.changeDarkeningLayerMaskWithGameObject(nextButton);

        this.handPointer.startPointingAnimation(
            {
                x: nextButton?.x,
                y: nextButton?.y
            },
            { x: nextButton.x - 100, y: nextButton.y - 100 }
        );

        this.moveTextBox(0.225);
    }

    private explainProgressBarStep(): void {
        this.text.setText(
            "This is your progress bar. It'll keep track of where you are in the lesson."
        );

        this.moveTextBox(0.225);

        const progressBar = this.scene.topBar?.progressBar;

        if (!progressBar) {
            return;
        }

        this.handPointer.startPointingAnimation(
            { x: progressBar.x + 100, y: progressBar.y + 50 },
            { x: progressBar.x + 50, y: progressBar.y + 100 }
        );

        this.tutorialOverlay.changeDarkeningLayerMaskWithGameObject(
            progressBar
        );
    }

    private explainFeedbackStep(): void {
        this.text.setText(
            "If you'd like to give us feedback, you can do so here.\n\nWe appreciate it!"
        );

        const feedback = this.scene.topBar?.feedbackButton;

        if (!feedback) {
            return;
        }

        this.handPointer.startPointingAnimation(
            { x: feedback.x, y: feedback.y + 25 },
            { x: feedback.x + 50, y: feedback.y + 100 },
            false
        );

        this.tutorialOverlay.changeDarkeningLayerMaskWithGameObject(feedback);
    }

    private explainQuitStep(): void {
        this.text.setText(
            "You can exit the lesson by hitting this button at anytime. That's it!\n\nYou're ready to start the lesson.\n\nEnjoy!"
        );

        const quitButton = this.scene.topBar?.homeButton;

        if (!quitButton) {
            return;
        }

        this.handPointer.startPointingAnimation(
            { x: quitButton.x, y: quitButton.y },
            { x: quitButton.x + 100, y: quitButton.y + 100 }
        );

        this.tutorialOverlay.changeDarkeningLayerMaskWithGameObject(quitButton);
    }

    private finishTutorialStep(): void {
        this.tutorialOverlay.destroy();
        this.text.destroy();
        this.handPointer.destroy();

        const uStore = useUserStore();
        const gStore = useGuestStore();
        if (uStore.loggedIn) {
            uStore.updateUser({ completed_tutorial: true });
        } else if (!gStore.completedTutorial) {
            gStore.toggleCompletedTutorial(true);
        }
    }

    private moveTextBox(fractionOfHeight: number): void {
        this.scene?.tweens.add({
            targets: this.text,
            y: this.scene.h(fractionOfHeight),
            duration: 1000,
            ease: EASINGS.BackEaseOut
        });
    }
}
