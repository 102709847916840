import { getBackgroundColor } from "../../utils";

export interface BackgroundBoxConfig {
    color: number;
    outline: number;
    outlineThickness: number;
    radius: number;
    opacity: number;
    margin: number;
    height: number;
    width: number;
}

/**
 * A class to create a background box for text or other UI elements.
 * @extends Phaser.GameObjects.Graphics
    
    * @param {Phaser.Scene} scene - The scene that this background box will be added to.
    * @param {number} yTop - The top y coordinate of the background box.
    * @param {number} yBottom - The bottom y coordinate of the background box.
    * @param {Object} config - The configuration object for the background box.
    * @param {number} config.fill - The color of the background box.
    * @param {number} config.outline - The color of the outline of the background box.
    * @param {number} config.outlineThickness - The thickness of the outline of the background box.
    * @param {number} config.radius - The radius of the background box.
    * @param {number} config.opacity - The opacity of the background box.
    * @param {number} config.margin - The margin of the background box.
 */
export class BackgroundBox extends Phaser.GameObjects.Graphics {
    private config: BackgroundBoxConfig;
    constructor(
        scene: Phaser.Scene,
        yTop: number,
        yBottom: number,
        config?: Partial<BackgroundBoxConfig>
    ) {
        super(scene);

        const margin = scene.scale.width * 0.03;
        this.config = {
            color: getBackgroundColor(),
            outline: 0xffffff,
            outlineThickness: 0,
            radius: 9,
            opacity: 0.9,
            margin,
            height: yBottom - yTop,
            width: this.scene.scale.width - 2 * margin,
            ...config
        };

        this.resize(yTop, this.config.height);

        this.setDepth(-1);

        this.scene.add.existing(this);
    }

    public resize(y: number, height: number): void {
        this.clear();
        this.fillStyle(this.config.color, this.config.opacity)
            .lineStyle(this.config.outlineThickness, this.config.outline)
            .fillRoundedRect(
                this.config.margin,
                y,
                this.config.width,
                height,
                this.config.radius
            )
            .strokeRoundedRect(
                this.config.margin,
                y,
                this.config.width,
                height,
                this.config.radius
            );

        this.config.height = height;
    }

    public get width(): number {
        return this.config.width;
    }

    public get height(): number {
        return this.config.height;
    }
}
