import { useUserStore } from "../../../store/userStore";

export class Toast extends Phaser.GameObjects.Container {
    constructor(scene, x, y, message, textStyle = {}, backgroundAlpha = 0.85) {
        super(scene, x, y);

        this.hiding = false;

        this.style = {
            lightMode: {
                textColor: "#000000",
                backgroundColor: 0xffffff
            },
            darkMode: {
                textColor: "#ffffff",
                backgroundColor: 0x000000
            }
        }[useUserStore().userPrefs?.dark_mode ? "darkMode" : "lightMode"];

        this.backgroundWidth = scene.scale.width * 0.8;

        this._initializeText(message, textStyle);
        this.backgroundHeight = Math.max(150, this.text.height + 50);
        this._initializeBackground(backgroundAlpha);

        this.add([this.background, this.text]);
        this.setSize(this.backgroundWidth, this.backgroundHeight);
        this.setInteractive();

        this._bindEventHandlers();

        this.scene.add.existing(this);
    }

    isHiding() {
        return this.hiding;
    }

    setHiding(hiding) {
        this.hiding = hiding;
    }

    _bindEventHandlers() {
        this.once("pointerup", () => {
            this.emit("clicked");
        });
        this.text.on("pointerup", (...data) => {
            this.emit("clicked");
        });

        // TODO: Make separate callback for each area:url text clicked event to not close Toast when opening the link.

        // console.log(this.text.text);
        // const url = this._getUrlFromString(this.text.text);
        // console.log(url);

        // this.text.on(
        //     `areadown-url:${this._getUrlFromString(this.text.text)}`,
        //     (...data) => {
        //         console.log("ASREA DOWN CLICAFN");
        //     }
        // );
    }

    _initializeBackground(backgroundTranspacency) {
        this.background = this.scene.add.graphics();
        this.background.fillStyle(
            this.style.backgroundColor,
            backgroundTranspacency ?? 0.85
        );
        this.background.fillRoundedRect(
            -this.backgroundWidth * 0.5,
            -this.backgroundHeight * 0.5,
            this.backgroundWidth,
            this.backgroundHeight,
            15
        );
    }

    _initializeText(message, textStyle = {}) {
        this.text = this.scene.add
            .rexBBCodeText(0, 0, message, {
                fontFamily: "Work Sans",
                fontSize: "40px",
                color: this.style.textColor,
                wordWrap: {
                    width: this.backgroundWidth - 20
                },
                interactive: true,
                ...textStyle
            })
            .setAlign("center")
            .setOrigin(0.5);
    }

    _getUrlFromString(string) {
        const regex = /\[url=(.*?)\].*?\[\/url\]/gi;
        const matches = [...string.matchAll(regex)];
        return matches.map((match) => match[1]);
    }
}
