<template>
    <ion-toolbar class="lattice-modal">
        <ion-title class="ion-align-center lattice-modal-title">{{ nodeTitle }}</ion-title>
        <ion-buttons v-if="!hideClose" slot="end">
            <ion-button @click="closeModal">
                <GlobalIcon :size="'large'" :iconName="close" />
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</template>

<script>
import GlobalIcon from '~/components/GlobalIcon.vue';
import { close } from 'ionicons/icons';

export default {
    name: 'ModalToolbar',
    props: ['nodeTitle', 'hideClose'],
    emits: ['closeModal'],
    setup() {
        return {
            close
        }
    },
    components: {
        GlobalIcon
    },
    methods: {
        closeModal() {
            this.$emit('closeModal');
        }
    }
}
</script>

<style scoped>
ion-toolbar {
    padding-top: 1rem;
}
</style>