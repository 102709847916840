export class MorseCodeLine extends Phaser.GameObjects.Graphics {
    constructor(
        scene: Phaser.Scene,
        x: number,
        y: number,
        text: string,
        color: number,
        alpha: number
    ) {
        super(scene);

        const morseText = this.translateToMorse(text);

        const charactersSpacing = 20;

        const size = 6;

        let currentX = 0;
        let index = 0;

        this.fillStyle(color, alpha);
        for (const char of morseText) {
            if (char === ".") {
                this.fillCircle(
                    x + currentX + index * charactersSpacing,
                    y,
                    size
                );
            }
            if (char === "-") {
                this.fillRect(
                    x + currentX + index * charactersSpacing - size,
                    y - size * 0.5,
                    size * 2,
                    size
                );
            }
            index += 1;
        }

        this.scene.add.existing(this);
    }

    private translateToMorse(text: string): string {
        const morseCode = {
            A: ".-",
            B: "-...",
            C: "-.-.",
            D: "-..",
            E: ".",
            F: "..-.",
            G: "--.",
            H: "....",
            I: "..",
            J: ".---",
            K: "-.-",
            L: ".-..",
            M: "--",
            N: "-.",
            O: "---",
            P: ".--.",
            Q: "--.-",
            R: ".-.",
            S: "...",
            T: "-",
            U: "..-",
            V: "...-",
            W: ".--",
            X: "-..-",
            Y: "-.--",
            Z: "--..",
            1: ".----",
            2: "..---",
            3: "...--",
            4: "....-",
            5: ".....",
            6: "-....",
            7: "--...",
            8: "---..",
            9: "----.",
            0: "-----",
            " ": "/"
        };

        return (
            text
                .toUpperCase()
                .split("")
                //@ts-ignore
                .map((char) => morseCode[char] || "")
                .join(" ")
        );
    }
}
