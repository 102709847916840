import Phaser from "phaser";
import GreatLibraryUiScene, {
    GreatLibraryUiSceneEvent
} from "./GreatLibraryUiScene";
import { GreatLibrarySceneKey } from "./GreatLibraryScenesManager";
import type { QuizSceneBase } from "../../utils/quizes/QuizBase";

export abstract class GreatLibraryBaseScene
    extends Phaser.Scene
    implements QuizSceneBase
{
    private homeButtonPressedCallback: () => void;
    constructor(sceneKey: GreatLibrarySceneKey) {
        super(sceneKey);
    }

    public abstract init(): void;

    public abstract preload(): void;

    public create(data?: any) {
        this.homeButtonPressedCallback = () => {
            this.handleUiSceneHomeButtonPressed();
        };
        this.getUiScene().events.on(
            GreatLibraryUiSceneEvent.HomeButtonPressed,
            this.homeButtonPressedCallback
        );

        this.events.on("shutdown", () => {
            this.getUiScene().events.off(
                GreatLibraryUiSceneEvent.HomeButtonPressed,
                this.homeButtonPressedCallback
            );
        });
    }

    public w(fraction?: number): number {
        return this.scale.width * (fraction ?? 1);
    }
    public h(fraction?: number): number {
        return this.scale.height * (fraction ?? 1);
    }

    public playSound(
        key: string,
        config: Phaser.Types.Sound.SoundConfig
    ): void {
        this.getUiScene().getAudioMananger().play(this, key, config);
    }

    public stopSound(key: string): void {
        this.getUiScene().getAudioMananger().stop(this, key);
    }

    public playMusic(
        key: string,
        config: Phaser.Types.Sound.SoundConfig
    ): void {
        this.getUiScene().getAudioMananger().playMusic(this, key, config);
    }

    public getUiScene(): GreatLibraryUiScene {
        return this.scene.get(GreatLibrarySceneKey.Ui) as GreatLibraryUiScene;
    }

    protected abstract handleUiSceneHomeButtonPressed(): void;
}
