<template>
    <div class="container">
        <img :id="'logo'" src="~/assets/logos/logo.png" alt="Arkitect Logo" />
    </div>
</template>

<style scoped>
.container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes float {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-15px);
    }

    100% {
        transform: translateY(0px);
    }
}

img {
    width: 12rem;
    height: auto;
    animation: float 5s ease-in-out infinite;
}
</style>
