<template>
    <ion-modal :isOpen="isOpen" @didDismiss="closeModal">
        <ion-content :forceOverscroll="false">
            <ion-toolbar class="lattice-modal ion-padding-top">
                <ion-buttons slot="start">
                    <ion-button id="click-trigger">
                        <GlobalIcon :size="'large'" :iconName="alertCircleOutline" />
                    </ion-button>
                </ion-buttons>
                <ion-buttons slot="end">
                    <ion-button @click="closeModal">
                        <GlobalIcon :size="'large'" :iconName="close" />
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
            <ion-popover class="patch-notes" trigger="click-trigger" trigger-action="click">
                <PatchNotes />
            </ion-popover>
            <ArkitectLogo class="ArkitectLogo"></ArkitectLogo>
            <Swiper class="swiper" :modules="[SwiperAutoplay, SwiperPagination, SwiperEffectCreative]"
                :effect="'creative'" :slides-per-view="1" :loop="true" :pagination="true"
                :dir="$i18n.localeProperties.dir" :autoplay="{
                    delay: 3000,
                    disableOnInteraction: false
                }" :creativeEffect="{
                    prev: {
                        shadow: false,
                        translate: [0, 0, -50],
                        rotate: [0, 180, 0],
                    },
                    next: {
                        shadow: false,
                        translate: [0, 0, -50],
                        rotate: [0, -180, 0],
                    }
                }">
                <SwiperSlide v-for="(header, index) in $tm('welcomemodal.headers')" :key="'slide-' + index"
                    class="slide-layout">
                    <TextBox class="TextBox" :cardImage="cardImages[index]" :backgroundColor="backgroundColors[index]"
                        :header="$rt(header)" :text="$tm('welcomemodal.texts')[index]">
                    </TextBox>
                </SwiperSlide>
            </Swiper>
        </ion-content>
    </ion-modal>
</template>

<script>
import GlobalIcon from '~/components/GlobalIcon.vue';
import { close, alertCircleOutline } from 'ionicons/icons';
import { useModalStore } from '../store/modalStore';
import TextBox from './welcome/TextBox.vue';
import ArkitectLogo from './welcome/ArkitectLogo.vue';
import PatchNotes from './modal/PatchNotes.vue';

const mStore = useModalStore();

export default {
    name: 'WelcomeModal',
    setup() {
        return {
            close,
            alertCircleOutline
        }
    },
    components: {
        TextBox,
        ArkitectLogo,
        GlobalIcon,
        PatchNotes
    },
    data() {
        return {
            backgroundColors: ['#FFFFFF', '#FFFFFF', '#FFFFFF'],
            cardImages: ['../welcome/learn.png', '../welcome/certified.png', '../welcome/affordable.png']
        };
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        closeModal() {
            mStore.toggleWelcomeModal(false);
        }
    }
}
</script>

<style scoped>
ion-modal {
    padding-top: 0%;
    padding-bottom: 0%;
    overflow: hidden;
    --background: none;
}

ion-modal::part(backdrop) {
    background: rgb(54, 55, 57);
    opacity: 1;
}

ion-content {
    --background: none;
    background-image: url('../assets/backgrounds/welcome.png');
}

.container {
    margin-top: -15px;
}

.swiper {
    width: 100%;
    height: 47rem;
}

.slide-layout {
    display: flex;
    flex-direction: column;
}

.ArkitectLogo {
    position: absolute;
    margin-top: 4rem;
}

.TextBox {
    position: absolute;
    bottom: 5%;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
}

.patch-notes {
    --width: 80%;
}
</style>