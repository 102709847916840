<template>
    <div>
        <ion-img :src="cardImage"></ion-img>
        <h1><b>{{ header }}</b></h1>
        <p>{{ text }}</p>
        <ion-button @click="signup" class="signup">{{ $t('welcomemodal.signup') }}<ion-icon style="padding-left: 3px;"
                :icon="rocketOutline"></ion-icon>
        </ion-button>
        <br>
        <sub>{{ $t('welcomemodal.sub') }}</sub>
    </div>
</template>

<script lang="ts">
import { useModalStore } from '../../store/modalStore';
import GlobalIcon from '../../components/GlobalIcon.vue';
import { rocketOutline } from 'ionicons/icons';
import { useGuestStore } from '../../store/guestStore';

export default {
    components: {
        GlobalIcon
    },
    setup() {
        return {
            rocketOutline
        };
    },
    props: {
        header: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        backgroundColor: {
            type: String,
            default: 'lightblue'
        },
        cardImage: {
            type: String,
            default: ''
        }
    },
    mounted() {
        this.$el.style.backgroundColor = this.backgroundColor;
    },
    methods: {
        signup() {
            const mStore = useModalStore();
            useGuestStore().submitActivity("signup_opened", 'From WelcomeModal');
            mStore.toggleWelcomeModal(false);
            mStore.toggleSignupModal(true);
        }
    }
}
</script>

<style scoped>
div {
    background-color: lightblue;
    padding: 10px;
    text-align: center;
    border-radius: 16px 16px 16px 16px;
}

p {
    padding: 10px;
    font-size: 1.1em;
}

ion-img {
    width: 20%;
    height: auto;
    justify-content: center;
    margin: auto;
}

.signup {
    margin-top: 10px;
    width: 90%;
}
</style>