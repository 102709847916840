<!-- NotiToast.vue Usage: this.$toast(`html message here`, delay); -->

<template>
    <ion-toast ref="NotiToast" :message="message" :duration="duration" swipe-gesture="vertical"
        position="top"></ion-toast>
</template>

<script>
export default {
    data() {
        return {
            message: '',
            duration: 5000,
        };
    },
    methods: {
        present(text, dur = 5000) {
            this.message = text;
            this.duration = dur;
            this.$refs.NotiToast.present();
        },
    },
};
</script>