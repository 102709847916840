import { getTextColor, hexNumberToString } from "../utils";

export class FeedbackButton extends Phaser.GameObjects.Container {
    constructor(scene, x, y, textureKey, flipped, text) {
        super(scene, x, y);

        this.pressed = false;

        this.button = this.scene.add
            .image(0, 0, textureKey)
            .setInteractive({ cursor: "pointer", pixelPefect: true });
        if (flipped) {
            this.button.flipX = true;
        }

        this.text = this.scene.add
            .text(0, 0, text, {
                fontFamily: "Work Sans",
                fontSize: 50,
                color: hexNumberToString(getTextColor())
            })
            .setOrigin(0.5);

        this.add([this.button, this.text]);

        const bounds = this.getBounds();
        this.setSize(bounds.width, bounds.height);

        this._bindEventHandlers();

        this.scene.add.existing(this);
    }

    _bindEventHandlers() {
        this.button.on("pointerdown", (...data) => {
            this.pressed = true;
            this.button.setTint(0xecf0f1);
            this.emit("pointerdown", data);
        });
        this.button.on("pointerup", (...data) => {
            if (this.pressed) {
                this.button.clearTint();
                this.pressed = false;
                this.emit("pointerup", data);
            }
        });
        this.button.on("pointerout", (...data) => {
            this.button.clearTint();
            this.pressed = false;
        });
    }
}
