import Phaser from "phaser";
import { GreatLibraryBaseScene } from "./GreatLibraryBaseScene";
import {
    GreatLibrarySceneKey,
    GreatLibraryScenesManager
} from "./GreatLibraryScenesManager";
import { ExamDisplayer, ExamDisplayerEvent } from "./widgets/ExamDisplayer";
import {
    getBackgroundColor,
    getCurrentLanguage,
    getTextColor,
    hexNumberToString,
    loadBucketAudio,
    TEXT_FONT
} from "../../utils/utils";
import { ExamSummary } from "./widgets/ExamSummary";
import {
    DraggableBox,
    DraggableBoxEvent
} from "../../utils/ui/draggables/DraggableBox";
import glText from "../../local_text/greatLibrary.json";
import {
    GraphicsButton,
    GraphicsButtonEvent
} from "../../utils/ui/buttons/GraphicsButton";

class GreatLibraryExamScene extends GreatLibraryBaseScene {
    private background: Phaser.GameObjects.Rectangle;
    private backButton: GraphicsButton;
    private examDisplayer: ExamDisplayer;
    private draggableBox: DraggableBox;

    private examSummary: ExamSummary;
    private examSummaryMask: Phaser.Display.Masks.GeometryMask;
    constructor() {
        super(GreatLibrarySceneKey.Exam);
    }

    public init() {}

    public preload() {
        ExamDisplayer.preloadAssets(this);
        loadBucketAudio(this, {
            folderPath: ["Quizes", "Audio", "Optimized"],
            keyList: ["claps", "confetti_gun"]
        });
    }

    public override create(data: { nodes: number[] }) {
        super.create();
        this.initializeBackground();
        this.initializeMask();
        this.initializeDragBox();

        this.examDisplayer = new ExamDisplayer(this, data.nodes);

        this.getUiScene().initializeSpaceshipProgressBar(
            this.examDisplayer.getTotalQuestionsCount()
        );

        this.bindEventHandlers();
    }

    public override update(time: number, dt: number): void {
        this.examDisplayer.update(time, dt);
    }

    protected handleUiSceneHomeButtonPressed(): void {
        if (!this.scene.isActive()) {
            return;
        }
        this.getUiScene().destroyQuizQuestion();
        this.getUiScene().destroySpaceshipProgressBar();
        this.examDisplayer.destroy();
        GreatLibraryScenesManager.changeScene(
            GreatLibrarySceneKey.ExamOptions,
            true
        );
    }

    private bindEventHandlers(): void {
        this.examDisplayer.on(
            ExamDisplayerEvent.ExamFinished,
            (
                score: number,
                maxScore: number,
                answersPerNode: {
                    [key: string]: { correct: number; total: number };
                }
            ) => {
                this.examSummary = new ExamSummary(
                    this,
                    this.w(0.5),
                    this.h(0.3),
                    answersPerNode
                );
                this.examSummary.setMask(this.examSummaryMask);
                this.clearExamDisplayer();
                // this.getUiScene().showExamQuestionsCounter(false);
                this.showResults(score, maxScore);
                const percentageScore = score / maxScore;
                if (percentageScore > 0.5) {
                    this.playSound("claps", { volume: 0.1 });
                }
                if (percentageScore > 0.8) {
                    this.getUiScene().emitConfetti();
                    this.playSound("confetti_gun", { volume: 0.05 });
                }
                this.initializeBackButton();
            }
        );

        this.examDisplayer.on(ExamDisplayerEvent.QuestionAnswered, () => {
            this.getUiScene().progressSpaceshipProgressBar();
        });

        this.draggableBox?.on(
            DraggableBoxEvent.DragBy,
            (x: number, y: number) => {
                if (this.examSummary?.y > this.h(0.3) && y > 0) {
                    return;
                }
                if (
                    this.examSummary?.y + this.examSummary.displayHeight <
                        this.h(0.7) &&
                    y < 0
                ) {
                    return;
                }
                this.examSummary.y += y;
            }
        );
    }

    private clearExamDisplayer(): void {
        this.getUiScene().destroyQuizQuestion();
        this.getUiScene().destroySpaceshipProgressBar();
        this.examDisplayer.destroy();
    }

    private showResults(score: number, maxScore: number): void {
        this.add
            .text(
                this.w(0.5),
                this.h(0.15),
                `${glText[getCurrentLanguage()].exam.score}: ${score}/${maxScore}`,
                {
                    fontFamily: TEXT_FONT.WorkSans,
                    fontSize: 80,
                    color: hexNumberToString(getTextColor())
                }
            )
            .setOrigin(0.5);
    }

    private initializeBackButton(): void {
        this.backButton = new GraphicsButton(this, this.w(0.5), this.h(0.875), {
            text: glText[getCurrentLanguage()].exam.back
        });

        this.backButton.on(GraphicsButtonEvent.Released, () => {
            GreatLibraryScenesManager.changeScene(
                GreatLibrarySceneKey.ExamOptions,
                true
            );
        });
    }

    private initializeBackground(): void {
        this.background = this.add.rectangle(
            this.scale.width * 0.5,
            this.scale.height * 0.5,
            this.scale.width,
            this.scale.height,
            getBackgroundColor()
        );
    }

    private initializeMask(): void {
        this.examSummaryMask = this.make
            .graphics()
            .fillStyle(0xff0000, 0.5)
            .fillRect(0, this.h(0.2), this.w(), this.h(0.6))
            .createGeometryMask();
    }

    private initializeDragBox(): void {
        this.draggableBox = new DraggableBox(this, {
            x: this.w(0.5),
            y: this.h(0.45),
            width: this.w(),
            height: this.h(0.7)
        });
    }
}

export default GreatLibraryExamScene;
