export interface DraggableBoxConfig {
    x: number;
    y: number;
    width: number;
    height: number;
    showDebug?: boolean;
}

export enum DraggableBoxEvent {
    DragBy = "dragBy"
}

export class DraggableBox extends Phaser.Events.EventEmitter {
    private scene: Phaser.Scene;
    private interactiveRect: Phaser.GameObjects.Rectangle;

    private config: DraggableBoxConfig;

    private lastDragPos?: { x: number; y: number };

    constructor(scene: Phaser.Scene, config: DraggableBoxConfig) {
        super();
        this.scene = scene;
        this.config = config;

        this.initializeInteractiveRect();
        this.bindEventHandlers();
    }

    public getConfig(): DraggableBoxConfig {
        return this.config;
    }

    private initializeInteractiveRect(): void {
        this.interactiveRect = this.scene.add
            .rectangle(
                this.config.x,
                this.config.y,
                this.config.width,
                this.config.height,
                0x00ff00,
                this.config.showDebug ? 0.5 : 0
            )
            .setInteractive({ draggable: true });
    }

    private bindEventHandlers(): void {
        this.interactiveRect.on(
            Phaser.Input.Events.DRAG_START,
            (
                pointer: Phaser.Input.Pointer,
                localX: number,
                localY: number
            ) => {}
        );

        this.interactiveRect.on(
            Phaser.Input.Events.DRAG,
            (pointer: Phaser.Input.Pointer, x: number, y: number) => {
                if (!this.lastDragPos) {
                    this.lastDragPos = { x, y };
                } else {
                    this.emit(
                        DraggableBoxEvent.DragBy,
                        x - this.lastDragPos.x,
                        y - this.lastDragPos.y
                    );
                    this.lastDragPos = { x, y };
                }
            }
        );

        this.interactiveRect.on(
            Phaser.Input.Events.DRAG_END,
            (pointer: Phaser.Input.Pointer) => {
                this.emit("dragend", pointer);
                this.lastDragPos = undefined;
            }
        );
    }
}
