<template>
    <ion-modal :isOpen="isOpen" @didDismiss="closeModal">
        <div class="modal-container">
            <modalToolbar :nodeTitle="'Password Reset'" @closeModal="closeModal" />
            <ion-content v-if="!isLoading" class="ion-padding">
                <div class="auth-form ion-margin">
                    <h3 class="ion-padding-start">Find your Arkitect account</h3>
                    <p class="ion-padding-start ion-padding-end">
                        Enter the email or username associated with your account to change your password.</p>
                    <ion-input v-model="userDetail" placeholder="Email or Username"
                        class="ion-padding custom"></ion-input>
                    <p class="error-text" v-if="errorText">{{ errorText }}</p>
                </div>
                <div class="ion-padding">
                    <ion-button class="next-button" expand="block" color="primary" @click="checkIfExists()"
                        :disabled="!userDetail">Request Email</ion-button>
                </div>
            </ion-content>
            <loading v-if="isLoading" />
        </div>
    </ion-modal>
</template>

<script>
import { useModalStore } from '../store/modalStore';
import modalToolbar from '~/components/modal/modalToolbar.vue';

const mStore = useModalStore();

export default {
    name: 'PasswordResetModal',
    components: {
        modalToolbar
    },
    data() {
        return {
            userDetail: '',
            isLoading: false,
            errorText: ''
        };
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        closeModal() {
            mStore.togglePasswordResetModal(false);
        },
        async resetPassword(type) {
            try {
                const response = await fetch(`${this.$config.public.API_BASE_URL}auth/emails/reset_password_email/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        user_detail: this.userDetail.toLowerCase(),
                        string_type: type
                    })
                });
                if (response['status'] === 200) {
                    this.$nextTick(() => {
                        this.$toast(`<h3>Password reset email sent.</h3><p>Check your email for further instructions.<p>`, 7500);
                    });
                    this.errorText = '';
                } else {
                    this.errorText = "There was an error resetting your password. Please try again.";
                }
            } catch (error) {
                console.error('Error checking username:', error);
            }

        },
        async checkIfExists() {
            if (this.userDetail.includes('@')) {
                try {
                    const response = await fetch(`${this.$config.public.API_BASE_URL}user/check_email/?email=${encodeURIComponent(this.userDetail.toLowerCase())}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    const result = await response.json();
                    if (result.isTaken) {
                        if (result.sso) {
                            this.errorText = "This account was created using Google or Apple Sign-In. Password resets aren't available.";
                        } else {
                            await this.resetPassword('email');
                        }
                    } else {
                        this.errorText = "This email isn't associated with an account. Please try again.";
                    }
                } catch (error) {
                    console.error('Error checking username:', error);
                }
            } else {
                try {
                    const response = await fetch(`${this.$config.public.API_BASE_URL}user/check_username/?username=${encodeURIComponent(this.userDetail.toLowerCase())}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    const result = await response.json();
                    if (result.isTaken) {
                        if (result.sso) {
                            this.errorText = "This account was created using Google or Apple Sign-In. Password resets aren't available.";
                        } else {
                            this.resetPassword('username');
                        }
                    } else {
                        this.errorText = "This username isn't associated with an account. Please try again.";
                    }
                } catch (error) {
                    console.error('Error checking username:', error);

                }
            }
        }
    }
}
</script>

<style scoped>
.modal-container {
    z-index: 2;
    position: relative;
    height: 100%;
    --ion-background-color: rgb(246, 248, 248);
    color: #414042;
}

.modal-background {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.error-text {
    color: #ff0000;
}
</style>