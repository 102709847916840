<template>
    <div class="ion-margin">
        <h1 class="center-text">{{ $t('home.signup.signin.header') }}</h1>
        <ion-text class="center-text">{{ $t('home.signup.signin.text') }}</ion-text>
        <loading />
    </div>
</template>

<script>
export default {
    name: 'SigningInSlide',
    mounted() {
        this.swiper = useSwiper()
    }
}
</script>