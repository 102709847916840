import Phaser from "phaser";
import {
    EASINGS,
    getCurrentLanguage,
    loadBucketAssets,
    loadBucketAtlas
} from "../../utils/utils";
import GreatLibraryFlashCardsScene from "./GreatLibraryFlashCardsScene";
import GreatLibraryUiScene from "./GreatLibraryUiScene";
import { useModalStore } from "../../../store/modalStore";
import { GreatLibraryBaseScene } from "./GreatLibraryBaseScene";
import {
    GreatLibrarySceneKey,
    GreatLibraryScenesManager
} from "./GreatLibraryScenesManager";
import { GreatLibraryCheckButton } from "./widgets/GreatLibraryCheckButton";
import { SpaceShipProgressBar } from "./widgets/SpaceShipProgressBar";
import glText from "../../local_text/greatLibrary.json";
import { ArkitectParagraph } from "../../utils/ui/ArkitectParagraph";
import {
    GraphicsButton,
    GraphicsButtonEvent
} from "../../utils/ui/buttons/GraphicsButton";

class GreatLibraryMainHallScene extends GreatLibraryBaseScene {
    private background: Phaser.GameObjects.Image;
    private bookCounter: Phaser.GameObjects.Image;
    private librarian: Phaser.GameObjects.Image;

    private flashCardsButton: GraphicsButton;
    private examButton: GraphicsButton;

    private mStore: unknown;

    constructor() {
        super(GreatLibrarySceneKey.MainHall);
    }

    public init() {}

    public preload() {
        SpaceShipProgressBar.preloadAssets(this);
        GreatLibraryCheckButton.preloadAssets(this);
        GreatLibraryUiScene.preloadAssets(this);
        GreatLibraryFlashCardsScene.preloadAssets(this);
        loadBucketAtlas(this, {
            folderPath: ["OperatingSystems", "Assets", "Atlases"],
            keyList: ["ui"]
        });
        loadBucketAssets(this, {
            folderPath: ["GreatLibrary", "Assets"],
            keyList: ["bgSpaceBlackBottom", "bookCounter", "alienLibrarian"]
        });
    }

    public override create() {
        new ArkitectParagraph(
            this,
            glText[getCurrentLanguage()].mainHall.paragraph
        );
        super.create();
        GreatLibraryScenesManager.initialize(this);
        this.scene.run(GreatLibrarySceneKey.Ui);
        this.mStore = useModalStore();
        this.initializeBackground();
        this.initializeLibrarian();
        this.initializeBookCounter();
        this.initializeFlashCardsButton();
        this.initializeExamButton();

        this.bindEventHandlers();

        document.dispatchEvent(new Event("greatLibraryLoaded"));
    }

    protected handleUiSceneHomeButtonPressed(): void {
        if (this.scene.isSleeping()) {
            return;
        }
        //@ts-ignore
        this.mStore.toggleNode(false);
    }

    private bindEventHandlers(): void {
        this.flashCardsButton.on(GraphicsButtonEvent.Released, () => {
            GreatLibraryScenesManager.changeScene(
                GreatLibrarySceneKey.FlashCardsOptions
            );
        });
        this.examButton.on(GraphicsButtonEvent.Released, () => {
            GreatLibraryScenesManager.changeScene(
                GreatLibrarySceneKey.ExamOptions
            );
        });
    }

    private initializeBackground(): void {
        this.background = this.add
            .image(
                this.scale.width * 0.5,
                this.scale.height * 0,
                "bgSpaceBlackBottom"
            )
            .setOrigin(0.5, 0);
    }
    private initializeBookCounter(): void {
        this.bookCounter = this.add.image(
            this.scale.width * 0.5,
            this.scale.height * 0.625,
            "bookCounter"
        );
        this.bookCounter.setScale(
            (this.scale.width * 1.2) / this.bookCounter.displayWidth
        );
    }
    private initializeLibrarian(): void {
        this.librarian = this.add
            .image(
                this.scale.width * 0.5,
                this.scale.height * 0.7,
                "alienLibrarian"
            )
            .setOrigin(0.5, 1)
            .setScale(0.7);

        this.tweens.add({
            targets: this.librarian,
            scaleY: 0.69,
            duration: 1000,
            ease: EASINGS.SineEaseInOut,
            yoyo: true,
            repeat: -1
        });
    }

    private initializeFlashCardsButton(): void {
        this.flashCardsButton = new GraphicsButton(
            this,
            this.w(0.5),
            this.h(0.75),
            {
                width: 600,
                text: glText[getCurrentLanguage()].mainHall.flashCards
            }
        );
    }

    private initializeExamButton(): void {
        this.examButton = new GraphicsButton(this, this.w(0.5), this.h(0.875), {
            width: 600,
            text: glText[getCurrentLanguage()].mainHall.exam
        });
    }
}

export default GreatLibraryMainHallScene;
