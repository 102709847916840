<template>
    <ion-modal @didPresent="modalDidOpen" :isOpen="isOpen" @didDismiss="closeModal">
        <modalToolbar :nodeTitle="$t('shop.title')" @closeModal="closeModal" />
        <div class="modal-container">
            <ion-row class="ion-justify-content-center ion-align-items-end ion-padding description-text">
                <ion-label>
                    {{ $t('shop.welcome') }}
                </ion-label>
            </ion-row>
        </div>
        <div ref="pixiContainer" class="pixi-container"></div>
        <div class="modal-background"></div>
    </ion-modal>
</template>


<script>
import { useModalStore } from '../store/modalStore';
import GlobalIcon from '~/components/GlobalIcon.vue';
import ItemShop from '~/utils/itemShop';
import modalToolbar from '~/components/modal/modalToolbar.vue';
import audioMixin from '~/mixins/audioMixin';
import { orbitUserNode } from "../utils/latticeUtils";

const mStore = useModalStore();

export default {
    name: 'UserPersonalizationModal',
    mixins: [audioMixin],
    created() {
        this.itemShop = new ItemShop();
    },
    components: {
        GlobalIcon,
        modalToolbar
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        modalDidOpen() {
            const pixiContainer = this.$refs.pixiContainer;
            this.playShopSound();
            this.itemShop.initPixi(pixiContainer);
        },
        closeModal() {
            if (mStore.isUserPersModalOpen) {
                this.itemShop.destroy();
                mStore.toggleUserPersModal()
                setTimeout(() => {
                    orbitUserNode(true);
                }, 25);
            }
        }
    },
    beforeUnmount() {
        if (this.itemShop) {
            this.itemShop.destroy();
        }
    },
}
</script>

<style scoped>
.description-text ion-label {
    color: #414042;
    font-size: 1.25rem;
    background-color: rgba(255, 255, 255, 0.75);
    padding: 2%;
    border-radius: 7px;
    display: block;
    border: 2px solid white;
    text-align: center;
}

.pixi-container {
    z-index: 2;
    position: absolute;
    top: 50%;
    width: 100%;
    height: 50%;
    transform: translateY(-100%);
}

.modal-container {
    z-index: 2;
    position: relative;
    height: 100%;
}

.description-text {
    z-index: 3;
}

.modal-background {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(100% 100% at 50% 100%, #6161BA 0%, #2C2C54 100%);

}
</style>
