import {
    DraggableBox,
    DraggableBoxEvent
} from "../../../utils/ui/draggables/DraggableBox";
import { NodesChecklist, NodesChecklistEvent } from "./NodesChecklist";
import {
    COLORS,
    getCurrentLanguage,
    getPadding,
    getTextColor,
    hexNumberToString,
    isDarkMode,
    TEXT_FONT
} from "../../../utils/utils";
import glText from "../../../local_text/greatLibrary.json";
import type { GreatLibraryBaseScene } from "../GreatLibraryBaseScene";
import { TexturesHelper } from "../../../utils/TexturesHelper";
import { ScrollBar } from "../../../utils/ui/draggables/ScrollBar";

export enum NodesListEvent {
    NodesChecklistChanged = "NodesListEvent::NodesChecklistChanged"
}

export class NodesList extends Phaser.Events.EventEmitter {
    private scene: GreatLibraryBaseScene;

    private draggableBox: DraggableBox;
    private nodesChecklist: NodesChecklist;
    private checklistMask: Phaser.Display.Masks.GeometryMask;
    private headerText: Phaser.GameObjects.Text;
    private scrollBar: ScrollBar;

    private startY: number;
    private listHeight: number;
    private topLineY: number;
    private recordHeight: number;

    constructor(
        scene: GreatLibraryBaseScene,
        resource: "keywords" | "questions"
    ) {
        super();

        this.scene = scene;

        this.startY = this.scene.h(0.1) + getPadding().y;
        this.listHeight = this.scene.h(0.55) - getPadding().y;
        this.recordHeight = 125;

        this.initializeHeaderText();
        this.topLineY = this.headerText.y + this.headerText.height;
        this.initializeMask();
        this.initializeDragBox();
        this.initializeNodesChecklist(resource);
        this.initializeScrollBar();

        this.bindEventHandlers();
    }

    public getSelectedNodeIds(): number[] {
        return this.nodesChecklist.getSelectedNodeIds();
    }

    private initializeHeaderText(): void {
        this.headerText = this.scene.add
            .text(
                this.scene.w(0.9),
                this.startY,
                glText[getCurrentLanguage()].options.lessonSelection,
                {
                    fontFamily: TEXT_FONT.WorkSans,
                    fontSize: 50,
                    color: hexNumberToString(getTextColor())
                }
            )
            .setOrigin(1, 0.5);
    }

    private initializeMask(): void {
        TexturesHelper.drawLine(
            this.scene,
            this.scene.w(0.1),
            this.scene.w(0.9),
            this.topLineY,
            this.topLineY,
            3,
            getTextColor(),
            0.25
        );
        TexturesHelper.drawLine(
            this.scene,
            this.scene.w(0.1),
            this.scene.w(0.9),
            this.topLineY + this.listHeight,
            this.topLineY + this.listHeight,
            3,
            getTextColor(),
            0.25
        );
        this.checklistMask = this.scene.make
            .graphics()
            .fillStyle(0xff0000, 0.5)
            .fillRect(0, this.topLineY, this.scene.w(), this.listHeight)
            .createGeometryMask();
    }

    private initializeNodesChecklist(resource: "keywords" | "questions"): void {
        this.nodesChecklist = new NodesChecklist(
            this.scene,
            this.scene.w(0.5),
            this.topLineY + this.recordHeight * 0.5,
            {
                resource
            }
        );
        this.nodesChecklist.setMask(this.checklistMask);
    }

    private initializeDragBox(): void {
        this.draggableBox = new DraggableBox(this.scene, {
            x: this.scene.w(0.5),
            y:
                this.headerText.y +
                this.headerText.height +
                this.listHeight * 0.5,
            width: this.scene.w(),
            height: this.listHeight
        });
    }

    private initializeScrollBar(): void {
        this.scrollBar = new ScrollBar(
            this.scene,
            this.scene.w(0.925),
            this.topLineY + this.listHeight * 0.5,
            {
                width: 16,
                height: this.listHeight * 0.9,
                backgroundColor: isDarkMode()
                    ? COLORS.DARK_MODE.TEXT
                    : COLORS.LIGHT_MODE.TEXT,
                scrollColor: isDarkMode()
                    ? COLORS.DARK_MODE.TEXT
                    : COLORS.LIGHT_MODE.TEXT,
                visibleDistance: this.listHeight,
                totalDistance: this.nodesChecklist.displayHeight
            }
        ).setVisible(this.nodesChecklist.displayHeight > this.listHeight);
    }

    private bindEventHandlers(): void {
        this.draggableBox.on(
            DraggableBoxEvent.DragBy,
            (x: number, y: number) => {
                // list to short to scroll
                if (this.nodesChecklist.displayHeight < this.listHeight) {
                    return;
                }
                const halfRecordHeight = this.recordHeight * 0.5;
                const min = this.topLineY + this.listHeight;
                const max =
                    min + this.nodesChecklist.displayHeight - this.listHeight;
                const maxShift = max - min;
                const scrollPercentage =
                    (this.topLineY - this.nodesChecklist.y) / maxShift;
                // NOTE: This is a hack, for now
                this.scrollBar.scrollTo(
                    Phaser.Math.Clamp(scrollPercentage, 0, 1)
                );
                // scrolled to the top
                if (
                    this.nodesChecklist.y + y >
                        this.topLineY + halfRecordHeight &&
                    y > 0
                ) {
                    this.nodesChecklist.y = this.topLineY + halfRecordHeight;
                    return;
                }
                // scrolled to the bottom
                if (
                    this.nodesChecklist.y +
                        y +
                        this.nodesChecklist.displayHeight <
                        min &&
                    y < 0
                ) {
                    this.nodesChecklist.y =
                        min - this.nodesChecklist.displayHeight;
                    return;
                }
                this.nodesChecklist.y += y;
            }
        );

        this.nodesChecklist.on(NodesChecklistEvent.Changed, () => {
            this.emit(NodesListEvent.NodesChecklistChanged);
        });
    }
}
