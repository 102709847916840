import Phaser from "phaser";
import {
    asyncAnimation,
    EASINGS,
    getPadding,
    getPrimaryContrastColor,
    loadBucketAssets,
    wait
} from "../../utils/utils";
import { GreatLibrarySceneKey } from "./GreatLibraryScenesManager";
import { TexturesHelper } from "../../utils/TexturesHelper";
import { AudioManager } from "../../utils/sound/AudioManager";
import type { QuizUiSceneBase } from "../../utils/quizes/QuizBase";
import { SpaceShipProgressBar } from "./widgets/SpaceShipProgressBar";
import { GraphicsButton } from "../../utils/ui/buttons/GraphicsButton";

export enum GreatLibraryUiSceneEvent {
    HomeButtonPressed = "GreatLibraryUiSceneEvent:homeButtonPressed"
}

class GreatLibraryUiScene extends Phaser.Scene implements QuizUiSceneBase {
    private homeButton: GraphicsButton;
    private confettiEmitter: Phaser.GameObjects.Particles.ParticleEmitter;
    private audioManager: AudioManager;

    private questionText: Phaser.GameObjects.Text;
    private questionBackground: Phaser.GameObjects.Rectangle;
    private spaceshipProgressBar: SpaceShipProgressBar;

    private padding: { x: number; y: number };

    constructor() {
        super(GreatLibrarySceneKey.Ui);
    }

    public init() {}

    public preload() {
        GraphicsButton.preloadAssets(this);
        loadBucketAssets(this, {
            folderPath: ["Icons"],
            keyList: ["close", "back"]
        });
    }

    public create() {
        this.padding = getPadding();
        this.initializeConfettiEmitter();
        this.initializeAudioManager();
        this.initializeHomeButton();
        this.bindEventHandlers();
    }

    getQuizQuestionText() {
        return this.questionText.text;
    }

    public showQuizQuestion(question: string): void {
        const margin = this.scale.height * 0.01;
        const posX = this.scale.width * 0.5;
        const posY = 225;
        this.questionText = this.add
            .text(posX, 0, question, {
                fontFamily: "Work Sans",
                fontSize: "45px",
                color: "#ffffff"
            })
            .setWordWrapWidth(this.scale.width * 0.9)
            .setAlign("center")
            .setOrigin(0.5, 0)
            .setScrollFactor(0);
        this.questionBackground = this.add
            .rectangle(
                posX,
                posY,
                this.scale.width,
                this.questionText.height + margin * 2,
                0x000000,
                0.35
            )
            .setOrigin(0.5, 0)
            .setScrollFactor(0);

        this.questionText.y = posY + margin;
        this.questionText.setDepth(1);
    }

    public async destroyQuizQuestion(instant = false): Promise<void> {
        if (this.questionText && this.questionBackground) {
            if (this) {
                if (!instant) {
                    await asyncAnimation(
                        this.tweens.add({
                            targets: [
                                this.questionText,
                                this.questionBackground
                            ],
                            alpha: 0,
                            duration: 250,
                            ease: EASINGS.CubicEaseOut,
                            onComplete: (tween) => {
                                tween.emit("done");
                            }
                        })
                    );
                }
            }
            this.questionBackground.destroy();
            this.questionText.destroy();
        }
    }

    private bindEventHandlers(): void {
        this.homeButton.on(Phaser.Input.Events.POINTER_UP, () => {
            this.events.emit(GreatLibraryUiSceneEvent.HomeButtonPressed);
        });
    }

    public static preloadAssets(scene: Phaser.Scene): void {
        GraphicsButton.preloadAssets(scene);
    }

    public initializeSpaceshipProgressBar(steps: number): void {
        this.spaceshipProgressBar = new SpaceShipProgressBar(
            this,
            this.w(0.3),
            this.h(0.05) + getPadding().y,
            {
                steps,
                width: this.w(0.6)
            }
        ).setDepth(-1);
    }

    public destroySpaceshipProgressBar(): void {
        if (this.spaceshipProgressBar) {
            this.spaceshipProgressBar.destroy();
        }
    }

    public progressSpaceshipProgressBar(): void {
        this.spaceshipProgressBar?.progress();
    }

    public async emitConfetti(duration = 1000) {
        this.confettiEmitter.start();
        await wait(duration);
        this.confettiEmitter.stop();
    }

    public setHomeButtonToBack(): void {
        this.homeButton.changeTexture("back", undefined);
    }

    public setHomeButtonToExit(): void {
        this.homeButton.changeTexture("close", undefined);
    }

    public getAudioMananger(): AudioManager {
        return this.audioManager;
    }

    private initializeHomeButton() {
        this.homeButton = new GraphicsButton(
            this,
            this.padding.x,
            this.padding.y,
            {
                width: 80,
                height: 80,
                image: { key: "close" },
                imageScale: 0.3
            }
        );

        this.homeButton.x += this.homeButton.displayWidth * 0.5;
        this.homeButton.y += this.homeButton.displayHeight * 0.5;
    }

    private initializeConfettiEmitter(): void {
        TexturesHelper.createRectangleTexture(
            this,
            "confetti",
            Math.ceil(this.scale.width * 0.036),
            Math.ceil(this.scale.height * 0.006),
            0xffffff
        );
        this.confettiEmitter = this.add.particles(
            this.scale.width * 0.5,
            -this.scale.height * 0.1,
            "confetti",
            {
                lifespan: { min: 2000, max: 3000 },
                gravityY: 1440,
                rotate: { min: 0, max: 360 },
                scale: { min: 1, max: 2 },
                speedX: { min: -500, max: 500 },
                tint: [0xf1c40f, 0xe74c3c, 0x9b59b6, 0x1abc9c],
                frequency: 10
            }
        );
        this.confettiEmitter.stop();
    }

    private initializeAudioManager(): void {
        this.audioManager = new AudioManager();
    }

    public w(fraction = 1): number {
        return this.scale.width * fraction;
    }

    public h(fraction = 1): number {
        return this.scale.height * fraction;
    }
}

export default GreatLibraryUiScene;
