import { EASINGS, loadBucketAssets } from "../utils";

export const LikeFeedbackModuleEvent = {
    Rated: "rated"
};

export class LikeFeedbackModule extends Phaser.GameObjects.Container {
    constructor(scene, x, y) {
        super(scene, x, y);

        this.pressThumbUpAnimationTween = undefined;
        this.pressThumbDownAnimationTween = undefined;

        this._initializeThumbs();

        this._bindEventHandlers();

        this.add([this.thumbUp, this.thumbDown]);

        const bounds = this.getBounds();
        this.setSize(bounds.width, bounds.height);

        this.scene.add.existing(this);
    }

    static preloadAssets(scene) {
        loadBucketAssets(scene, {
            folderPath: ["General", "UIElements", "Feedback"],
            keyList: ["up", "down"],
            aliases: { up: "thumbUp", down: "thumbDown" }
        });
    }

    _initializeThumbs() {
        this.thumbUp = this.scene.add
            .image(-100, 0, "thumbUp")
            .setScale(2)
            .setInteractive();
        this.thumbDown = this.scene.add
            .image(100, 0, "thumbDown")
            .setScale(2)
            .setInteractive();
    }

    _bindEventHandlers() {
        this.thumbUp.on("pointerup", () => {
            this.emit(LikeFeedbackModuleEvent.Rated, true);
            this._handleThumbPressed(true);
            this.thumbUp.disableInteractive();
            this.thumbDown.setInteractive();
        });
        this.thumbDown.on("pointerup", () => {
            this.emit(LikeFeedbackModuleEvent.Rated, false);
            this._handleThumbPressed(false);
            this.thumbDown.disableInteractive();
            this.thumbUp.setInteractive();
        });
    }

    _handleThumbPressed(thumbUpPressed) {
        this.thumbUp.setTexture(thumbUpPressed ? "thumbUpFilled" : "thumbUp");
        this.thumbDown.setTexture(
            thumbUpPressed === false ? "thumbDownFilled" : "thumbDown"
        );

        if (thumbUpPressed) {
            this.pressThumbUpAnimationTween = this._playPressedAnimation(true);
        } else {
            this.pressThumbDownAnimationTween =
                this._playPressedAnimation(false);
        }
    }

    _playPressedAnimation(thumbUpPressed) {
        const tween = thumbUpPressed
            ? this.pressThumbUpAnimationTween
            : this.pressThumbDownAnimationTween;
        if (tween?.isPlaying()) {
            tween.stop();
            thumbUpPressed
                ? this.thumbUp.setScale(2)
                : this.thumbDown.setScale(2);
        }
        return this.scene?.tweens.add({
            targets: [thumbUpPressed ? this.thumbUp : this.thumbDown],
            duration: 200,
            scale: 2.4,
            yoyo: true,
            ease: EASINGS.QuadEaseOut
        });
    }
}
