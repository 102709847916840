<template>
    <div class="icon-container" :class="{ 'pulse-animation': !hasAnimated }" @animationend="handleAnimationEnd">
        <GlobalIcon :color="flameColor" :size="'large'" :iconName="flame" :shadow="true" :badge="currentStreak" />
    </div>
</template>

<script>
import { useUserStore } from '@/store/userStore'
import GlobalIcon from './GlobalIcon.vue'
import { flame } from 'ionicons/icons';

export default {
    components: {
        GlobalIcon
    },
    setup() {
        const uStore = useUserStore()

        return {
            uStore,
            flame
        }
    },
    data() {
        return {
            currentStreak: 0,
            flameColor: 'warning',
            hasAnimated: false
        }
    },
    mounted() {
        this.currentStreak = this.uStore.currentStreak
        if (this.currentStreak < 3) {
            this.flameColor = 'white'
        } else if (this.currentStreak < 7) {
            this.flameColor = 'warning'
        } else {
            this.flameColor = 'danger'
        }
    },
    methods: {
        handleAnimationEnd() {
            this.hasAnimated = true
        }
    }
}
</script>

<style scoped>
ion-text {
    font-size: 1.25rem;
    color: white;
}

.icon-container {
    display: inline-block;
}

.pulse-animation {
    animation-name: pulse-animation;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 4;
    animation-fill-mode: both;
}

@keyframes pulse-animation {

    0%,
    100% {
        transform: scale(1) rotate(0deg);
    }

    50% {
        transform: scale(1.1) rotate(-5deg);
    }
}
</style>
