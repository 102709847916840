import { EASINGS, asyncAnimation, loadBucketAssets } from "../../utils";

export class HandPointer extends Phaser.GameObjects.Image {
    private hidden: boolean = false;
    private angleOffset: number = 0;

    private pointingAnimationTween?: Phaser.Tweens.Tween;
    private appearAnimationTween?: Phaser.Tweens.Tween;

    constructor(
        scene: Phaser.Scene,
        customImageKey?: string,
        customImageFrame?: string | number,
        customImageAngleOffset: number = 0
    ) {
        super(scene, 0, 0, customImageKey ?? "handPointer", customImageFrame);

        this.hidden = true;
        this.angleOffset = customImageAngleOffset ?? 0;

        this.setScale(0.75);
        this.setOrigin(0.3, 0);
        this.setAngle(220 + this.angleOffset);
        this.setAlpha(0);

        this.scene.add.existing(this);
    }

    static preloadAssets(scene: Phaser.Scene) {
        loadBucketAssets(scene, {
            folderPath: ["General", "Assets"],
            keyList: ["handPointer"]
        });
    }

    async startPointingAnimation(
        atPoint: { x: number; y: number },
        fromPoint: { x: number; y: number },
        instant = true
    ) {
        try {
            this.hidden = false;
            this.pointingAnimationTween?.stop();
            this.appearAnimationTween?.stop();

            const angle =
                (Phaser.Math.Angle.BetweenPoints(fromPoint, atPoint) * 180) /
                    Math.PI +
                90 +
                this.angleOffset;

            if (!instant) {
                this.appearAnimationTween = this.scene?.tweens.add({
                    targets: this,
                    angle,
                    x: fromPoint.x,
                    y: fromPoint.y,
                    alpha: 1,
                    duration: 750,
                    ease: EASINGS.BackEaseOut,
                    onComplete: (tween) => {
                        tween.emit("done");
                    }
                });
                await asyncAnimation(this.appearAnimationTween);
                this.pointingAnimationTween = this.scene?.tweens.add({
                    targets: this,
                    x: atPoint.x,
                    y: atPoint.y,
                    duration: 750,
                    ease: EASINGS.SineEaseInOut,
                    yoyo: true,
                    repeat: -1
                });
                return;
            }

            this.setPosition(fromPoint.x, fromPoint.y);
            this.setAngle(angle);

            this.appearAnimationTween = this.scene?.tweens.add({
                targets: this,
                alpha: 1,
                duration: 750,
                ease: EASINGS.SineEaseInOut
            });

            this.pointingAnimationTween = this.scene?.tweens.add({
                targets: this,
                x: atPoint.x,
                y: atPoint.y,
                duration: 1250,
                ease: EASINGS.SineEaseInOut,
                yoyo: true,
                repeat: -1
            });
        } catch {
            console.warn("Failed to start pointing animation");
        }
    }

    isHidden() {
        return this.hidden;
    }

    async hide() {
        this.appearAnimationTween?.stop();
        await asyncAnimation(
            this.scene?.tweens.add({
                targets: this,
                alpha: 0,
                duration: 500,
                onComplete: (tween) => {
                    this.pointingAnimationTween?.stop();
                    this.hidden = true;
                    tween.emit("done");
                }
            })
        );
    }
}
