<template>
    <div class="scrollable-list">
        <ion-list>
            <ion-item v-for="questline in questlines" :key="questline.name">
                <div class="button-container">
                    <ion-button :disabled="!questline.available" @click="$emit('selectedQuestline', questline.name)">
                        <div class="button-content">
                            <span>{{ questline.name }}</span>
                            <span v-if="!questline.available" class="coming-soon-badge">{{
                                $t('home.signup.questline_selection.coming_soon') }}</span>
                        </div>
                    </ion-button>
                </div>
            </ion-item>
        </ion-list>
    </div>
</template>

<script lang="ts">
export default {
    name: 'QuestlineSelection',
    emits: ['selectedQuestline'],
    data() {
        // TODO: fetch questlines from API
        return {
            questlines: [
                { name: 'Comptia A+', available: true },
                { name: 'Certified Ethical Hacker', available: false },
                { name: 'Comptia Security+', available: false },
                { name: 'Comptia Network+', available: false },
                { name: 'CCNA', available: false },
                { name: 'CCNP', available: false },
                { name: 'CCIE', available: false }
            ],
            selectedQuestline: '',
        }
    },
}
</script>

<style scoped>
.scrollable-list {
    max-height: 100%;
    overflow-y: scroll;
    margin-top: 1rem;
    position: relative;
}

.button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
}

ion-list {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

ion-button {
    font-size: 1.1rem;
    width: 100%;
    height: 4rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: relative;
    font-weight: bold;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

ion-button:disabled {
    background-color: #d3d3d3;
    color: #808080;
    cursor: not-allowed;
}

.button-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.coming-soon-badge {
    background-color: #ff6347;
    color: white;
    font-size: 0.75rem;
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
    margin-top: 0.25rem;
    text-transform: uppercase;
}
</style>