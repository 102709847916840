<template>
  <ion-modal ref="LoginModal" :isOpen="isOpen" @didDismiss="closeModal(false)">
    <div class="modal-container">
      <modalToolbar :nodeTitle="$t('home.login.header')" @closeModal="closeModal(false)" />
      <ion-content :forceOverscroll="false" v-if="!isLoading" class="ion-padding">
        <div class="auth-form ion-margin">
          <h1 class="ion-padding center-text">{{ $t('home.login.welcome_back') }}</h1>
          <ion-input v-model="userInfo.email" :placeholder="$t('home.login.email')"
            class="ion-margin-top ion-margin-bottom ion-padding custom"></ion-input>
          <ion-input v-model="userInfo.password" :placeholder="$t('home.login.password')"
            class="ion-margin-top ion-margin-bottom ion-padding custom" type="password"></ion-input>
          <ion-text v-if="errorText" class="center-text ion-margin-top">
            <sub class="error-text">{{ errorText }}</sub>
          </ion-text>
          <ion-button expand="block" @click="loginWithCredentials(this.userInfo)" class="ion-padding"
            :disabled="!userInfo.email || !userInfo.password">
            {{ $t('home.login.login') }}
          </ion-button>
          <ion-text class="center-text ion-padding-top ion-padding-bottom">{{ $t('home.login.or') }}</ion-text>
          <Sso @loginComplete="closeModal(true)" @loginFailed="loginFailed($event)"></Sso>
          <ion-text class="center-text ion-margin-top" @click="signupButton()">
            {{ $t('home.login.no_account') }} <b>{{ $t('home.login.signup') }}</b>
          </ion-text>
          <ion-text class="center-text ion-padding-top">
            <b>...</b>
          </ion-text>
          <ion-text class="center-text ion-margin-top" @click="resetPasswordButton()">
            {{ $t('home.login.forgot_password') }} <b>{{ $t('home.login.reset_password') }}</b>
          </ion-text>
        </div>
      </ion-content>
      <loading v-if="isLoading" />
    </div>
  </ion-modal>
</template>

<script lang="ts">
import modalToolbar from '../components/modal/modalToolbar.vue';
import GlobalIcon from '../components/GlobalIcon.vue';
import { arrowForwardOutline } from 'ionicons/icons';
import { useModalStore } from '../store/modalStore';
import { useGuestStore } from '../store/guestStore';
import { orbitUserNode } from "../utils/latticeUtils";
import Sso from '../components/Sso.vue'

const mStore = useModalStore();

export default {
  name: 'LoginModal',
  components: {
    GlobalIcon,
    Sso,
    modalToolbar
  },
  setup() {
    return {
      arrowForwardOutline
    };
  },
  data() {
    return {
      userInfo: {
        email: '',
        password: ''
      },
      errorText: '',
      isLoading: false,
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal(orbit: boolean = false) {
      mStore.toggleLoginModal(false);
      if (orbit) {
        setTimeout(() => {
          orbitUserNode();
        }, 1250);
      }
    },
    async loginWithCredentials(credentials) {
      this.isLoading = true;
      try {
        await useGuestStore().signUserIn(credentials);
        this.closeModal(true);
        this.userInfo = { email: '', password: '' };
        this.$router.push({ name: "index" });
      } catch (error) {
        this.errorText = error.message;
      } finally {
        this.isLoading = false;
      }
    },
    loginFailed(error) {
      this.errorText = error;
    },
    signupButton() {
      useGuestStore().submitActivity("signup_opened", "From LoginModal");
      mStore.toggleLoginModal(false);
      mStore.toggleSignupModal(true);
    },
    resetPasswordButton() {
      mStore.toggleLoginModal(false);
      mStore.togglePasswordResetModal(true);
    }
  }
}
</script>

<style scoped>
.modal-container {
  z-index: 2;
  position: relative;
  height: 100%;
  --ion-background-color: rgb(246, 248, 248);
  color: #414042;
}

.modal-background {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.error-text {
  color: #ff0000;
}
</style>
