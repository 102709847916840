import { TexturesHelper } from "../../TexturesHelper";
import {
    EASINGS,
    asyncAnimation,
    getTextColor,
    hexNumberToString
} from "../../utils";

export interface SquareQuizRecordConfig {
    idleColor: number;
    hoverColor: number;
    textColor: string;
    backgroundAlpha: number;
}

export class SquaresQuizRecord extends Phaser.GameObjects.Container {
    private background: Phaser.GameObjects.Image;
    private text: Phaser.GameObjects.Text;

    private answer: string;
    private defaultPosition: { x: number; y: number };
    private config: SquareQuizRecordConfig;

    private resetToDefaultPositionTween?: Phaser.Tweens.Tween;

    constructor(
        scene: Phaser.Scene,
        x: number,
        y: number,
        text: string,
        config?: SquareQuizRecordConfig
    ) {
        super(scene, x, y);

        this.answer = text;

        this.config = {
            idleColor: 0x000000,
            hoverColor: 0xffffff,
            textColor: "#ffffff",
            backgroundAlpha: 0.35,
            ...config
        };

        this.defaultPosition = { x, y };

        this.background = this.scene.add
            .image(0, 0, "recordBackground2")
            .setOrigin(0.5)
            .setAlpha(this.config.backgroundAlpha);

        this.text = this.scene.add
            .text(0, 0, text, {
                fontFamily: "Work Sans",
                fontSize: 38,
                color: this.config.textColor
            })
            .setOrigin(0.5)
            .setWordWrapWidth(this.background.width * 0.95, true)
            .setAlign("center");

        this.add([this.background, this.text]);

        const bounds = this.getBounds();
        this.setSize(bounds.width, bounds.height);

        this.setColor(this.config.idleColor);

        this.setInteractive();
        this.scene.input.setDraggable(this);

        this.scene.add.existing(this);
    }

    static preloadAssets(scene: Phaser.Scene): void {
        const dimension =
            scene.scale.width > 1000
                ? scene.scale.width * 0.4
                : scene.scale.width * 0.45;
        TexturesHelper.createRoundedRectangleTexture(
            scene,
            "recordBackground2",
            Math.min(500, dimension),
            Math.min(500, dimension),
            16,
            0x2c3e50,
            1
        );
    }

    public setDefaultPosition(x: number, y: number): void {
        this.defaultPosition = { x, y };
    }

    public getAnswer(): string {
        return this.answer;
    }

    public async shake(): Promise<void> {
        this.x = this.defaultPosition.x;
        return asyncAnimation(
            this.scene?.tweens.add({
                targets: [this],
                duration: 50,
                ease: EASINGS.Linear,
                x: this.x + 15,
                yoyo: true,
                loop: 12,
                onComplete: (tween) => {
                    tween.emit("done");
                }
            })
        );
    }

    public highlight(val: boolean) {
        if (val) {
            this.setColor(this.config.hoverColor);
        } else {
            this.setColor(this.config.idleColor);
        }
    }

    public setColor(color: number): void {
        this.background.setTintFill(color);
    }

    public clearColor(): void {
        this.background.clearTint();
    }

    public async setPositionWithAnimation(x: number, y: number): Promise<void> {
        return asyncAnimation(
            this.scene?.tweens.add({
                targets: this,
                x,
                y,
                duration: 300,
                ease: EASINGS.BackEaseOut,
                onComplete: () => {
                    this.setPosition(x, y);
                }
            })
        );
    }

    public resetToDefaultPosition(): void {
        this.resetToDefaultPositionTween = this.scene?.tweens.add({
            targets: this,
            x: this.defaultPosition.x,
            y: this.defaultPosition.y,
            duration: 300,
            ease: EASINGS.BackEaseOut,
            onComplete: () => {
                this.setPosition(
                    this.defaultPosition.x,
                    this.defaultPosition.y
                );
            }
        });
    }

    public stopResettingToDefaultPosition(): void {
        this.resetToDefaultPositionTween?.stop();
    }
}
