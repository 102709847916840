import { EASINGS, loadBucketAtlas, randomFromArray } from "../utils";

export class SmokeCurtain extends Phaser.GameObjects.Container {
    private smoke: Phaser.GameObjects.Image[][];
    private idleSmokeTweens: Phaser.Tweens.Tween[][];
    constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, 0, 0);

        this.initializeSmoke();

        this.scene.add.existing(this);
    }

    public static preloadAssets(scene: Phaser.Scene) {
        loadBucketAtlas(scene, {
            folderPath: ["General", "Particles"],
            keyList: ["smoke"]
        });
    }

    public async playShowSmokeAnimation(): Promise<void> {
        for (let i = 0; i < this.smoke.length; i++) {
            let rowDelay = 0;
            for (let j = this.smoke[i].length - 1; j > 0; j--) {
                const columns = this.smoke.length;
                rowDelay += 35;
                this.scene.tweens.add({
                    targets: this.smoke[i][j],
                    delay:
                        rowDelay +
                        Math.abs(Math.floor(columns / 2) - i) *
                            Phaser.Math.Between(25, 100),
                    scale: 3,
                    duration: 350,
                    ease: EASINGS.BackEaseOut,
                    onComplete: () => {
                        this.idleSmokeTweens[i][j].play();
                    }
                });
            }
        }
    }

    public async playHideSmokeAnimation(): Promise<void> {
        for (let i = 0; i < this.smoke.length; i++) {
            for (let j = this.smoke[i].length - 1; j > 0; j--) {
                const columns = this.smoke.length;
                const rows = this.smoke[i].length;
                this.scene.tweens.add({
                    targets: this.smoke[i][j],
                    delay:
                        Math.abs(Math.floor(rows / 2) - j) *
                            Phaser.Math.Between(25, 100) +
                        Math.abs(Math.floor(columns / 2) - i) *
                            Phaser.Math.Between(25, 100),
                    scale: 0,
                    duration: 500,
                    ease: EASINGS.BackEaseIn,
                    onStart: () => {
                        this.idleSmokeTweens[i][j].stop();
                    }
                });
            }
        }
    }

    private initializeSmoke(): void {
        this.smoke = [];
        this.idleSmokeTweens = [];
        const widthCount = Math.ceil(this.scene.scale.width / 75);
        const heightCount = Math.ceil(this.scene.scale.height / 75) + 1;

        for (let i = 0; i < widthCount; i++) {
            this.smoke[i] = [];
            this.idleSmokeTweens[i] = [];
            for (let j = 0; j < heightCount; j++) {
                this.smoke[i][j] = this.scene.add
                    .image(
                        37 + i * 75,
                        -37 + j * 75,
                        "smoke",
                        randomFromArray([0, 1, 2])
                    )
                    .setAngle(Phaser.Math.Between(-45, 45))
                    .setScale(0);
                this.idleSmokeTweens[i][j] = this.scene.tweens.add({
                    targets: this.smoke[i][j],
                    scale: 2.8,
                    angle: `+=${Phaser.Math.Between(-10, 10)}`,
                    duration: 2000,
                    ease: EASINGS.SineEaseInOut,
                    yoyo: true,
                    repeat: -1,
                    paused: true
                });
                this.add(this.smoke[i][j]);
            }
        }
        this.shuffle();
    }
}
