import { useNuxtApp } from "nuxt/app";
import { useGuestStore } from "../store/guestStore";
import { useModalStore } from "../store/modalStore";
import { generateUsername } from "../utils/utils";
import { SignInWithApple } from "@capacitor-community/apple-sign-in";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { Capacitor } from "@capacitor/core";

export class SsoService {
    constructor(config, uStore, fStore, kqStore, router, componentInstance) {
        this.config = config;
        this.uStore = uStore;
        this.fStore = fStore;
        this.kqStore = kqStore;
        this.router = router;
        this.componentInstance = componentInstance;

        if (Capacitor.isNativePlatform()) {
            GoogleAuth.initialize({});
        } else {
            GoogleAuth.initialize({
                clientId:
                    "99401212140-qmg2n0kv4f01674ov1srnfhtim8qq4b1.apps.googleusercontent.com",
                scopes: ["profile", "email"]
            });
        }
    }

    loginWithGoogle() {
        return new Promise(async (resolve, reject) => {
            try {
                await GoogleAuth.signOut();
                const code = await GoogleAuth.signIn();
                const loginResp = await this.useGoogleLogin(code);
                if (loginResp.error) {
                    this.componentInstance.$emit(
                        "loginFailed",
                        loginResp.error
                    );
                    reject(loginResp.error);
                } else {
                    this.handleLogin(loginResp);
                    this.componentInstance.$emit("loginComplete");
                    useModalStore().toggleSignupModal(false);
                    useModalStore().toggleLoginModal(false);
                    resolve(loginResp);
                }
            } catch (error) {
                console.error("Error occurred during Google sign-in", error);
                this.componentInstance.$emit("loginFailed", error.message);
                reject(error);
            }
        });
    }

    loginWithApple() {
        return new Promise(async (resolve, reject) => {
            try {
                const resp = await SignInWithApple.authorize({
                    scopes: "email name"
                });
                const loginResp = await this.useAppleLogin(resp.response);

                if (loginResp.error) {
                    this.componentInstance.$emit(
                        "loginFailed",
                        loginResp.error
                    );
                    reject(loginResp.error);
                } else {
                    this.handleLogin(loginResp);
                    this.componentInstance.$emit("loginComplete");
                    useModalStore().toggleSignupModal(false);
                    useModalStore().toggleLoginModal(false);
                    resolve(loginResp);
                }
            } catch (error) {
                console.warn("Error occurred during Apple sign-in", error);
                this.componentInstance.$emit("loginFailed", error.message);
                reject(error);
            }
        });
    }

    async useGoogleLogin(code) {
        const guestStore = useGuestStore();
        if (!guestStore.signupPlatform) {
            guestStore.setPlatform();
        }
        const cleanedState = guestStore.cleanedState;

        const apiUrl =
            this.config.public.API_BASE_URL +
            `auth/sso_handler/use_google_login/`;
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                code: code,
                guestState: cleanedState,
                username: generateUsername()
            })
        });
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error);
        }
        return await response.json();
    }

    async useAppleLogin(response) {
        const guestStore = useGuestStore();
        if (!guestStore.signupPlatform) {
            guestStore.setPlatform();
        }
        const cleanedState = guestStore.cleanedState;

        const apiUrl =
            this.config.public.API_BASE_URL +
            `auth/sso_handler/use_apple_login/`;
        const res = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                response: response,
                guestState: cleanedState,
                username: generateUsername()
            })
        });
        if (!res.ok) {
            const errorData = await res.json();
            throw new Error(errorData.error);
        }
        return await res.json();
    }

    async handleLogin(response) {
        try {
            this.uStore.handleLoginResponse(response);
            if (Capacitor.isNativePlatform()) {
                useNuxtApp().vueApp.config.globalProperties.$subscriptionService.updateApplicationUsername();
            }
            this.fStore.setFriends(response.friends);
        } catch (err) {
            console.error(err);
        }
    }
}
